import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - view:                 (string) list, grid ou thumbnails
 *  - displayViews:         (boolean|array) affiche les boutons contenus dans le tableau (list, grid, thumbnails)
 *  - data:                 (array|string|function(start, end)) Charge les éléments à afficher.
 *                          Si data est un tableau, alors ce sont les éléments à charger.
 *                          Si data est une chaîne de caractères, alors il s'agit de l'url des éléments à récupérer
 *                          Si data est une fonction, elle reçoit en premier paramètre la fonction de callback
 *                          à appeler argument avec les éléments à charger et le chemin du dossier courant
 *  - onLoad:               (function(events)) Fonction appelée après le chargement des éléments
 *  - elementNew:           (function($event,event)) Fonction appelée lors de l'ajout d'un élément. Si la fonction
 *                          retourne false, l'élément est supprimé
 *  - onSort
 *
 * Méthodes publiques :
 *
 * Structures:
 *  Element: {
 *    - id:         (mixed) facultatif (attribué par le plugin si indéfini)
 *    - dateModif:  (string) la date de création ou de dernière modif de l'élément
 *    - filesize:   (float) la date de fin de l'événement correspondant au format
 *    - type:       (string) repertoire ou fichier
 *  }
 */
$webf('filemanager', {
    options: {
        filemanagerClass:   '',
        data:               {},
        view:               ['thumbnails'],
        displayViews:       ['list', 'grid', 'thumbnails'],
        onLoad:             webf.noop(),
        elementNew:         webf.noop(),
        onSort:             webf.noop()
    },

    editableOptions: {
    },

    _create: function()
    {
        this.loading = true;

        this._drawFilemanager();
        this._bindEvents();
    },

    _drawFilemanager: function()
    {
        this.e.addClass(['webf-filemanager'].concat(this.option('filemanagerClass').split(' ')).join(' '));
    },

    _loadEvents: function()
    {
        var self = this,
            data = this.option('data');

        var afterLoadElements = function(elements) {
            webf.each(elements || [], function(i, element) {
                self.addElement(element, false);
            });

            self._renderElements(self.getElements());

            self._call(self.options.onLoad, self.events);
        };

        if (webf.isFunction(data)) {
            this._call(data, function(elements) {
                afterLoadElements(elements);
            }, this.path);
        } else if (webf.isString(data)) {
            var params = webf.extend(true, this.option('urlParams', {
                path: this.path
            }));

            $.ajax( {
                url: data,
                data: params,
                success: afterLoadElements(),
                type: 'post',
                dataType: 'json'
            });
        } else {
            afterLoadElements.call(data);
        }
    },

    _bindEvents: function()
    {

    },

    _renderElements: function () {

    },

    selectedElements: function()
    {
        // return this.elems.getSelectedRecords();
    },

    selectElements: function(elements)
    {
        // this.elems.selectRecords(records);
        // this._call(this.option('onSelect'), this.selectedRecords());
    },

    getElements: function()
    {
        // return this.elems.getRecords();
    },

    count: function()
    {
        // return this.elems.count;
    },

    /**
     * Ajoute un élément. Si _new est true, c un nouvel élément dont le nom doit être saisi par l'utilisateur
     *
     * @param element
     * @param _new
     */
    addElement: function(element, _new)
    {
        // this.elems.add(records);
    },

    remove: function(elements)
    {
        // this.elems.remove(records);
    },

    update: function(elements)
    {
        // this.remove(elements);
        // this.add(elements)
    },

    sort: function(field, direction)
    {
        // this.elems.sort(field, direction);
        //
        // this._call(this.option('onSort'), field, direction);
    },

    refresh: function()
    {
    },

    getElementById: function(id)
    {
    },

    unSelect: function(records)
    {
    },

    _destroy: function()
    {
        this.e.empty();
        this.e.removeClass('webf-filemanager');
    }
});
