import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.dialog = {
        close: 'Close'
    };
})(webf);

