import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.agenda = {
        'pick a date': 'Pick a date',

        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',

        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',

        mo: 'Mo',
        tu: 'Tu',
        we: 'We',
        th: 'Th',
        fr: 'Fr',
        sa: 'Sa',
        su: 'Su',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',

        jan: 'Jan.',
        feb: 'Feb.',
        mar: 'Mar.',
        apr: 'Apr.',
        'may.': 'May',
        jun: 'Jun.',
        'jul.': 'Jul.',
        aug: 'Aug.',
        sept: 'Sept.',
        oct: 'Oct.',
        nov: 'Nov.',
        dec: 'Dec.',

        today: 'Today',
        prev_day: 'Previous day',
        next_day: 'Next day',
        prev_week: 'Previous week',
        next_week: 'Next week',
        prev_month: 'Previous month',
        next_month: 'Next month',
        week: 'Week',
        month: 'Month',
        day: 'Day',
        am: 'am',
        pm: 'pm',

        new_event: 'New event',

        date_title_month: 'MMMM yyyy',
        date_title_week: 'MMM d yyyy',
        date_title_day: 'EEEE d, MMMM yyyy',
        date_col_day: 'EE d, MMM'
    };
})(webf);

