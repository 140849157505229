import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Table = webf.RichTextEditor.Control.extend({
    zIndex: null,

    init: function(editor, options) {
        this.maxNbRows = options.maxNbRows;
        this.maxNbCols = options.maxNbCols;
        this.sizeRows = options.sizeRows;
        this.sizeColumns = options.sizeColumns;
        this.valign = options.valign;
        this.zIndex = editor.option('zIndex');
    },
    render: function(editor, $btn) {
        var self = this;

        $btn.addClass('webf-select-button webf-btn-table')
            .append("<label><i class='fas fa-table'></i></label>")
            .append("<i class='fas fa-caret-down'></i>");

        var $table = generateTable(this.maxNbRows, this.maxNbCols);

        this.$menu = $("<div class='webf-richtexteditor-table-selector'></div>").append($table);

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-table-selector',
            onFirstOpen: function() {
                var that = this;

                editor._on(self.$menu, 'td', {
                    mouseenter: function() {
                        var $table = $(this).closest('table'),
                            coords = [$(this).prevAll('td').length + 1, $(this).closest('tr').prevAll('tr').length + 1];

                        $table.find('td').removeClass('hover');

                        $table.find('tr').slice(0, coords[1]).each(function() {
                            $(this).find('td').slice(0, coords[0]).addClass('hover');
                        });
                    },
                    click: function() {
                        var coords = [$(this).prevAll('td').length + 1, $(this).closest('tr').prevAll('tr').length + 1],
                            table = generateTable(coords[1], coords[0]),
                            formGroups = '';

                        var $table = $(table).addClass('webf-table');

                        if (self.sizeColumns) {
                            for (var i=0; i<coords[0]; i++) {
                                formGroups += '' +
                                    '<div class="form-group size">' +
                                    '    <label class="col-sm-3 control-label">' +
                                    '        <span>' + editor._('columnSize') + ' ' + (i+1) + '</span>' +
                                    '    </label>' +
                                    '    <div class="col-sm-6">' +
                                    '        <input data-col="' + (i+1) + '" class="form-control" type="text" value="100">' +
                                    '    </div>' +
                                    '    <div class="col-sm-3">' +
                                    '        <select class="form-control">' +
                                    '            <option value="px">px</option>' +
                                    '            <option value="mm">mm</option>' +
                                    '        </select>' +
                                    '    </div>' +
                                    '</div>' +
                                '';
                            }
                        }

                        if (self.sizeRows) {
                            for (var j=0; j<coords[1]; j++) {
                                formGroups += '' +
                                    '<div class="form-group size">' +
                                    '    <label class="col-sm-3 control-label">' +
                                    '        <span>' + editor._('rowSize') + ' ' + (j+1) + '</span>' +
                                    '    </label>' +
                                    '    <div class="col-sm-6">' +
                                    '        <input data-row="' + (j+1) + '" class="form-control" type="text" value="30">' +
                                    '    </div>' +
                                    '    <div class="col-sm-3">' +
                                    '        <select class="form-control">' +
                                    '            <option value="px">px</option>' +
                                    '            <option value="mm">mm</option>' +
                                    '        </select>' +
                                    '    </div>' +
                                    '</div>' +
                                '';
                            }
                        }

                        if (self.valign) {
                            formGroups += '' +
                                '<div class="form-group vertical-alignment">' +
                                '    <label class="col-sm-3 control-label">' +
                                '        <span>' + editor._('verticalAlignment') + '</span>' +
                                '    </label>' +
                                '    <div class="col-sm-9">' +
                                '        <select class="form-control">' +
                                '            <option value="">' + editor._('alignUndefined') + '</option>' +
                                '            <option value="top">' + editor._('alignTop') + '</option>' +
                                '            <option value="middle">' + editor._('alignMiddle') + '</option>' +
                                '            <option value="bottom">' + editor._('alignBottom') + '</option>' +
                                '        </select>' +
                                '    </div>' +
                                '</div>' +
                            '';
                        }

                        var dialog = '' +
                            '<div>' +
                            '   <form class="form-horizontal">' +
                                    formGroups +
                            '   </form>' +
                            '   <div class="apercu">' +
                            '   </div>' +
                            '</div>' +
                        '';

                        var $dialog = $(dialog);

                        $dialog.webfDialog({
                            dialogClass: 'dialog-responsive dialog-table-properties',
                            box: {
                                boxClass: 'primary plain',
                                header: {
                                    title: editor._('tableProperties')
                                },
                                footer: {
                                    closeButton: false,
                                    buttons: {
                                        validate: {
                                            label: editor._('validate'),
                                            cls: 'primary',
                                            click: function() {
                                                var $widget = $(this).webfDialog('widget');
                                                var $apercu = $widget.find('.apercu');

                                                editor.pasteHtml($apercu.html(), false);

                                                $(this).webfDialog('close');
                                            }
                                        },
                                        cancel: {
                                            label: editor._('cancel'),
                                            click: function() {
                                                $(this).webfDialog('close');
                                            }
                                        }
                                    }
                                }
                            },
                            onFirstOpen: function() {
                                var handleDialog = this,
                                    $widget = $(handleDialog).webfDialog('widget'),
                                    $form = $widget.find('form');

                                var makeApercu = function() {
                                    $form.find('.size').each(function() {
                                        var unit = $(this).find('select').val(),
                                            $input = $(this).find('input'),
                                            size = $input.val();

                                        if ($input.data('col')) {
                                            $table.find('tr').each(function() {
                                                $(this).find('td').eq($input.data('col') - 1).css('width', size + unit);
                                            });
                                        } else {
                                            $table.find('tr').eq($input.data('row') - 1).find('td').css('height', size + unit);
                                        }
                                    });

                                    var verticalAlignment = $form.find('.vertical-alignment select').val();
                                    $table.find('tr').css('vertical-align', verticalAlignment);

                                    $widget.find('.apercu').empty().append($table);

                                    $(handleDialog).webfDialog('refreshPosition');
                                };

                                $form.find(':input').on('change', makeApercu);

                                makeApercu();
                            }
                        });

                        $(that).webfDropdown('close');
                    }
                });
            },
            onClose: function() {
                self.$menu.find('td').removeClass('hover');
            }
        });
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    types: 'action',
    text_tooltip: 'table',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});

var generateTable = function(nbRows, nbCols)
{
    var $table = $('<table>');

    for (var i=0; i<nbRows; i++) {
        var $tr = $('<tr>');
        for (var j=0; j<nbCols; j++) {
            $tr.append('<td>');
        }

        $table.append($tr);
    }

    return $table;
};

