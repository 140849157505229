import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.FontName = webf.RichTextEditor.Control.extend({
    fonts: null,
    zIndex: null,

    init: function(editor, options) {
        this.fonts = webf.isArray(options.fonts) && options.fonts.length
            ? options.fonts
            : ['Arial', 'Calibri', 'Comic sans Ms', 'Courier New', 'Georgia', 'Tahoma', 'Times New Roman', 'Trebuchet Ms', 'Verdana'];

        this.zIndex = editor.option('zIndex');
    },
    render: function(editor, $btn) {
        var self = this;

        $btn.addClass('webf-select-button fontname')
            .append("<label>" + editor._('font') + "</label>")
            .append("<i class='fas fa-caret-down'></i>");

        this.$menu = $(
            "<ul class='webf-list'>" +
                webf.map(this.fonts, function(i, font) {
                    return "<li class='webf-list-item' style='font-family: " + font + ";'><a>" + font + "</a></li>";
                }).join('') +
            "</ul>"
        );

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-fontname',
            onFirstOpen: function() {
                self.$menu.find('a').on('click', function() {
                    editor._execCommand('fontname', $(this).text());
                });
            }
        });
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    refresh: function(editor, $btn) {
        try {
            var value = editor._queryCommandValue('fontname');
        } catch(e) {}

        value = value.replace(/['"]/g, '');

        if (!webf.inArray(value, this.fonts)) {
            value = editor._('font');
        }

        $btn.find('label').text(value.replace(/^'/,"").replace(/'$/,""));
    },
    types: 'action',
    text_tooltip: 'font',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});

