import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './imageloader'
import $ from 'jquery'

/**
 * options :
 *  - classWrapper
 *  - autoHide
 *  - handles
 *  - selection
 *  - movable
 *  - keyTick
 *  - shiftKeyTick
 *  - useKeys
 *  - onInit
 *  - onSelectionStart
 *  - onSelectionChange
 *  - onSelectionEnd
 *
 * Méthodes publiques :
 *  - getSelection
 */
$webf('imagecrop', {
    options: {
        classWrapper:       '',
        autoHide:           false,
        handles:            true,
        selection:          null,
        movable:            true,
        keyTick:            1,
        shiftKeyTick:       10,
        useKeys:            true,
        onInit:             webf.noop,
        onSelectionStart:   webf.noop,
        onSelectionChange:  webf.noop,
        onSelectionEnd:     webf.noop
    },

    _create: function()
    {
        var self = this;

        this.borderWidth = 1;
        this.selection = {};
        this.$handles = [];
        this.moving = false;
        this.resizing = false;
        this.keys = { shift: false, alt: false };
        this.drawing = false;
        this.selection_drawn = false;
        this.begin_draw = true;

        var image = new Image();
        image.src = this.e.prop("src");
        this.realSize = {
            width: image.width,
            height: image.height
        };

        $(this.e).webfImageloader( {
            onLoaded: function() {
                self._initCrop();
            }
        });
    },

    _initCrop: function()
    {
        this._initSelection();
        this._bindEvents();

        this._call(this.option('onInit'));
    },

    _initSelection: function()
    {
        var self = this,
            $widget;

        this.e.wrapAll("<div class='webf-image-crop " + this.option('classWrapper') + "'></div>");

        this.selection = this.option('selection');

        this.widget = $widget = this.e.parent();//.width(dimImg.width).height(dimImg.height);
        var edges = ['n','e','s','w'],
            corners = ['nw','ne','se','sw'],
            allHandles = corners.concat(edges),
            handles = this.option('handles') || [];

        if (handles === true || handles == 'all') {
            handles = allHandles;
        } else if (handles == 'corners') {
            handles = corners;
        } else if (handles == 'edges') {
            handles = edges;
        }

        webf.each(allHandles, function() {
            $widget.append("<div class='outer-selection " + this.toString() + "'></div>");
        });

        webf.each(edges, function() {
            $widget.append("<div class='selection-border " + this.toString() + "'></div>");
        });

        $widget.append("<div class='selected-area'></div>");
        this.$selectedArea = $widget.find('.selected-area');

        webf.each(handles,function() {
            var $handle = $("<div class='handle " + this.toString() + "' data-handle='" + this.toString() + "'></div>");
            self.$handles.push($handle);
            $widget.append($handle);
        });

        this.widget = $widget;

        if (this.option('selection')) {
            this._setSelection(this.option('selection'));
        } else {
            this._reset();
        }
    },

    _drawSelection: function()
    {
        var $outers = this.widget.find('.outer-selection'),
            $borders = this.widget.find('.selection-border'),
            $handles = this.widget.find('.handle'),
            s = this.selection,
            mousedownEvent = $.Event('mousedown', { which: 1 });

        if (s.x1 > s.x2 && s.y1 > s.y2) {
            var tmp = s.x2;
            s.x2 = s.x1;
            s.x1 = tmp;

            tmp = s.y2;
            s.y2 = s.y1;
            s.y1 = tmp;

            if (this.active_handle == 'nw') {
                this._trigger(this.widget.find('.handle.se'), mousedownEvent);
            } else if (this.active_handle == 'ne') {
                this._trigger(this.widget.find('.handle.sw'), mousedownEvent);
            } else if (this.active_handle == 'sw') {
                this._trigger(this.widget.find('.handle.ne'), mousedownEvent);
            } else if (this.active_handle == 'se') {
                this._trigger(this.widget.find('.handle.se'), mousedownEvent);
            }

        } else if (s.x1 > s.x2) {
            var tmp = s.x2;
            s.x2 = s.x1;
            s.x1 = tmp;

            if (this.active_handle == 'w') {
                this._trigger(this.widget.find('.handle.e'), mousedownEvent);
            } else if (this.active_handle == 'e') {
                this._trigger(this.widget.find('.handle.w'), mousedownEvent);
            } else if (this.active_handle == 'nw') {
                this._trigger(this.widget.find('.handle.ne'), mousedownEvent);
            } else if (this.active_handle == 'ne') {
                this._trigger(this.widget.find('.handle.nw'), mousedownEvent);
            } else if (this.active_handle == 'sw') {
                this._trigger(this.widget.find('.handle.se'), mousedownEvent);
            } else if (this.active_handle == 'se') {
                this._trigger(this.widget.find('.handle.sw'), mousedownEvent);
            }

        } else if (s.y1 > s.y2) {
            var tmp = s.y2;
            s.y2 = s.y1;
            s.y1 = tmp;

            if (this.active_handle == 'nw') {
                this._trigger(this.widget.find('.handle.sw'), mousedownEvent);
            } else if (this.active_handle == 'ne') {
                this._trigger(this.widget.find('.handle.se'), mousedownEvent);
            } else if (this.active_handle == 'sw') {
                this._trigger(this.widget.find('.handle.nw'), mousedownEvent);
            } else if (this.active_handle == 'se') {
                this._trigger(this.widget.find('.handle.ne'), mousedownEvent);
            } else if (this.active_handle == 's') {
                this._trigger(this.widget.find('.handle.n'), mousedownEvent);
            } else if (this.active_handle == 'n') {
                this._trigger(this.widget.find('.handle.s'), mousedownEvent);
            }
        }

        this.selection = s;

        var l = s.x1, t = s.y1, w = s.x2 - l, h = s.y2 - t, bw = this.borderWidth,
            w_image = this.e.width(), h_image = this.e.height(),
            lp = l*100,
            tp = t*100,
            wp = w*100,
            hp = h*100,
            bwph = (bw / w_image)*100,
            bwpv = (bw / h_image)*100;

        $outers.filter('.nw').css(  { width: lp+'%', height: tp+'%' });
        $outers.filter('.n').css(   { left: lp+'%', width: wp+'%', height: tp+'%' });
        $outers.filter('.ne').css(  { width: (100 - (wp + lp))+'%', height: tp+'%' });
        $outers.filter('.e').css(   { top: tp+'%', width: (100 - (wp + lp))+'%', height: hp+'%' });
        $outers.filter('.se').css(  { top: (tp + hp)+'%', width: (100 - (wp + lp))+'%', height: (100 - (hp + tp))+'%' });
        $outers.filter('.s').css(   { top: (tp + hp)+'%', left: lp+'%', width: wp+'%', height: (100 - (hp + tp))+'%' });
        $outers.filter('.sw').css(  { top: (tp + hp)+'%', width: lp+'%', height: (100 - (hp + tp))+'%' });
        $outers.filter('.w').css(   { top: tp+'%', width: lp+'%', height: hp+'%' });

        $borders.filter('.n').css( { top: tp+'%', left: lp+'%', width: (wp - bwph)+'%' });
        $borders.filter('.e').css( { top: tp+'%', left: (lp + wp - bwph)+'%', height: (hp - bwph)+'%' });
        $borders.filter('.s').css( { top: (tp + hp - bwph)+'%', left: lp+'%', width: (wp - bwpv)+'%' });
        $borders.filter('.w').css( { top: tp+'%', left: lp+'%', height: (hp - bwph)+'%' });

        $handles.filter('.nw').css( { top: tp+'%', left: lp+'%' });
        $handles.filter('.n').css(  { top: tp+'%', left: (lp + wp/2)+'%' });
        $handles.filter('.ne').css( { top: tp+'%', left: (lp + wp - bwph)+'%' });
        $handles.filter('.e').css(  { top: (tp + hp/2)+'%', left: (lp + wp - bwph)+'%' });
        $handles.filter('.se').css( { top: (tp + hp - bwpv)+'%', left: (lp + wp - bwph)+'%' });
        $handles.filter('.s').css(  { top: (tp + hp - bwpv)+'%', left: (lp + wp/2)+'%' });
        $handles.filter('.sw').css( { top: (tp + hp - bwpv)+'%', left: lp+'%'});
        $handles.filter('.w').css(  { top: (tp + hp/2)+'%', left: lp+'%' });

        if (this.option('movable')) {
            this.$selectedArea.css( { top: tp+'%', left: lp+'%', width: wp+'%', height: hp+'%' }).show();
        }

        $borders.show();
        $outers.show();
        $handles.show();
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(document, {
            'mousemove touchmove': function(ev) {
                if (self.resizing || self.moving || self.drawing) {
                    ev.preventDefault();

                    var mp = $webf.getMousePos(ev),
                        pos_e = self.e.offset(),
                        e = {
                            top: pos_e.top,
                            left: pos_e.left
                        },
                        mouseCoords = {
                            x: mp.x - e.left,
                            y: mp.y - e.top
                        },
                        x1, x2, y1, y2,
                        w = self.e.width(),
                        h = self.e.height(),
                        w_selection = (self.selection.x2 - self.selection.x1) * w,
                        h_selection = (self.selection.y2 - self.selection.y1) * h;

                    self.curMouseCoords = mouseCoords;


                    if (self.resizing || self.drawing) {
                        switch (self.active_handle) {
                            case 'n': y1 = Math.max(mouseCoords.y, 0); break;
                            case 'e': x2 = Math.min(mouseCoords.x, w); break;
                            case 's': y2 = Math.min(mouseCoords.y, h); break;
                            case 'w': x1 = Math.max(mouseCoords.x, 0); break;
                            default:
                                if (mouseCoords.x < self.startPoint.x) {
                                    x1 = Math.max(mouseCoords.x, 0);
                                    x2 = self.startPoint.x;
                                } else {
                                    x1 = self.startPoint.x;
                                    x2 = Math.min(mouseCoords.x, w);
                                }

                                if (mouseCoords.y < self.startPoint.y) {
                                    y1 = Math.max(mouseCoords.y, 0);
                                    y2 = self.startPoint.y;
                                } else {
                                    y1 = self.startPoint.y;
                                    y2 = Math.min(mouseCoords.y, h);
                                }

                                if (self.keys.shift) {
                                    var lng = Math.min(x2 - x1, y2 - y1);
                                    if (mouseCoords.x < self.startPoint.x) {
                                        x1 = x2 - lng;
                                    } else {
                                        x2 = x1 + lng;
                                    }

                                    if (mouseCoords.y < self.startPoint.y) {
                                        y1 = y2 - lng;
                                    } else {
                                        y2 = y1 + lng;
                                    }
                                }
                        }

                        x1 = webf.isUndefined(x1) ? self.selection.x1 * w : x1;
                        x2 = webf.isUndefined(x2) ? self.selection.x2 * w : x2;
                        y1 = webf.isUndefined(y1) ? self.selection.y1 * h : y1;
                        y2 = webf.isUndefined(y2) ? self.selection.y2 * h : y2;
                    } else if (self.moving) {
                        x1 = webf.between(mouseCoords.x - self.startPoint.x, 0, w - w_selection);
                        y1 = webf.between(mouseCoords.y - self.startPoint.y, 0, h - h_selection);
                        x2 = x1 + w_selection;
                        y2 = y1 + h_selection;
                    }

                    self._setSelection({
                        x1: x1 / w,
                        x2: x2 / w,
                        y1: y1 / h,
                        y2: y2 / h
                    });

                    self._call(self.option('onSelectionChange'));
                }
            },
            'mouseup touchend endresizing': endMovingAndResizing
        })._on(window, {
            blur: endMovingAndResizing
        });

        function endMovingAndResizing(ev) {
            if (self.startPoint) {
                var e = self.e.offset(),
                    mp = $webf.getMousePos(ev);

                if (!webf.isUndefined(mp)) {
                    var mouseCoords = {
                        x: mp.x - e.left,
                        y: mp.y - e.top
                    };

                    if (mouseCoords.x == self.startPoint.x && mouseCoords.y == self.startPoint.y) {
                        self._reset();
                    }
                }
            }

            $webf.enableSelection();
            self.keys = { shift: false, alt: false };

            if (self.resizing || self.moving || self.drawing) {
                self.resizing = self.moving = self.drawing = false;
                self._call(self.option('onSelectionEnd'));
            }
        };

        this._on(this.widget, {
            'mousedown touchstart': function(ev) {
                if (ev.type == 'mousedown' && ev.which != 1) return;

                var e = self.e.offset(),
                    w = self.e.width(),
                    h = self.e.height(),
                    s = self.selection,
                    $target = $(ev.target),
                    mp = $webf.getMousePos(ev);

                $webf.disableSelection();

                if ($target.hasClass('handle')) {
                    var name_handle = $target.data('handle'),
                        handle,
                        widget = self.widget;

                    self.resizing = true;
                    self.active_handle = name_handle;

                    switch (name_handle) {
                        case 'nw': handle = widget.find('.handle.se'); break;
                        case 'ne': handle = widget.find('.handle.sw'); break;
                        case 'se': handle = widget.find('.handle.nw'); break;
                        case 'sw': handle = widget.find('.handle.ne'); break;

                        default: handle = widget.find('.handle.' + name_handle);
                    }

                    var pos_handle = handle.position();

                    self.startPoint = {
                        x: pos_handle.left,
                        y: pos_handle.top
                    };

                } else if ($target.hasClass('selected-area')) {
                    if (self.option('movable')) {
                        $webf.disableSelection();
                        self.moving = true;

                        self.startPoint = {
                            x: (mp.x - (e.left + s.x1 * w)),
                            y: (mp.y - (e.top + s.y1 * h))
                        };
                    }

                } else {
                    self.drawing = true;
                    self.startPoint = {
                        x: (mp.x - e.left),
                        y: (mp.y - e.top)
                    };
                }
            }
        });

        if (this.option('useKeys')) {
            this._on('html', {
                keydown: function(ev) {
                    if (ev.which == 16) {
                        self.keys.shift = true;
                    } else if (ev.which == 18) {
                        self.keys.alt = true;
                    }

                    if ((self.drawing || self.resizing) && self.curMouseCoords) {
                        if ((ev.which == 16 || ev.which == 18) && self.resizing || self.drawing) {
                            var e = self.e.offset();
                            var mousemoveEvent = $.Event('mousemove', {
                                which: 1,
                                pageX: self.curMouseCoords.x + e.left,
                                pageY: self.curMouseCoords.y + e.top
                            });

                            self._trigger(document, mousemoveEvent);
                        }
                    }

                    if (ev.which >= 37 && ev.which <= 40) {
                        ev.preventDefault();
                        var s = self.selection,
                            w = self.e.width(),
                            h = self.e.height();

                        switch (ev.which) {
                            case 37:
                                var tmp_x1 = s.x1;
                                s.x1 = (Math.max((s.x1 * w) - (self.keys.shift ? self.option('shiftKeyTick') : self.option('keyTick')), 0)) / w;
                                s.x2 += (s.x1 - tmp_x1);
                                break;

                            case 38:
                                var tmp_y1 = s.y1;
                                s.y1 = (Math.max((s.y1 * h) - (self.keys.shift ? self.option('shiftKeyTick') : self.option('keyTick')), 0)) / h;
                                s.y2 += (s.y1 - tmp_y1);
                                break;

                            case 39:
                                var tmp_x2 = s.x2;
                                s.x2 = (Math.min((s.x2 * w) + (self.keys.shift ? self.option('shiftKeyTick') : self.option('keyTick')), w)) / w;
                                s.x1 += (s.x2 - tmp_x2);
                                break;

                            case 40:
                                var tmp_y2 = s.y2;
                                s.y2 = (Math.min((s.y2 * h) + (self.keys.shift ? self.option('shiftKeyTick') : self.option('keyTick')), h)) / h;
                                s.y1 += (s.y2 - tmp_y2);
                                break;
                        }

                        self._setSelection(s);

                        self._call(self.option('onSelectionChange'));
                    }
                },
                keyup: function(ev) {
                    if (ev.which == 16) {
                        self.keys.shift = false;
                    } else if (ev.which == 18) {
                        self.keys.alt = false;
                    }

                    if (self.drawing || self.resizing) {
                        var e = self.e.offset();
                        var mousemoveEvent = $.Event('mousemove', {
                            which: 1,
                            pageX: self.curMouseCoords.x + e.left,
                            pageY: self.curMouseCoords.y + e.top
                        });

                        self._trigger(document, mousemoveEvent);
                    }
                }
            });
        }
    },

    _setSelection: function(selection)
    {
        this.selection = selection;
        this._drawSelection();
    },

    _hideSelection: function()
    {
        this.widget.find(".outer-selection, .selection-border, .handle").hide();
    },

    _reset: function()
    {
        this._setSelection({ x1: 0, x2: 0, y1: 0, y2: 0 });
        this._hideSelection();
    },

    getSelection: function()
    {
        var s = webf.clone(this.selection),
            w = this.e.width(),
            h = this.e.height(),
            scale_x = this.realSize.width / w,
            scale_y = this.realSize.height / h;

        s.x1 = s.x1 * w;
        s.x2 = s.x2 * w;
        s.y1 = s.y1 * h;
        s.y2 = s.y2 * h;
        s.width = s.x2 - s.x1;
        s.height = s.y2 - s.y1;
        s.scale = scale_x + ':' + scale_y;
        s.native_x1 = scale_x * s.x1;
        s.native_x2 = scale_x * s.x2;
        s.native_y1 = scale_y * s.y1;
        s.native_y2 = scale_y * s.y2;
        s.native_width = s.native_x2 - s.native_x1;
        s.native_height = s.native_y2 - s.native_y1;

        return s;
    },

    _destroy: function()
    {
        this.widget && this.widget.find(".outer-selection, .selection-border, .handle, .selected-area").remove();
        this.e.unwrap();
    }
});

