import webf from '../../utils/core'
import webfClass from '../../utils/class'
import $ from 'jquery'

webf.Select = webfClass.extend({

    constructor(plugin)
    {
        this.plugin = plugin;
        this.e = plugin.e;
    },

    _getOptions()
    {
        const $options = $("<ul>").addClass('options webf-list');

        this.e.find('optgroup, option').each((i, option) => {
            const $opt = $(option);
            const tag = $opt[0].tagName;

            if (tag == 'OPTGROUP') {
                $options.append(
                    this._createGroupItem($opt.prop('label'))
                );
            } else if (tag == 'OPTION') {
                $options.append(
                    this._createOptionItem($opt.val(), $opt.text(), !$opt.prop('disabled'), $opt.prop('selected'), $opt.parent()[0].tagName == 'OPTGROUP')
                );
            }
        });

        return $options;
    },

    _searchDelay()
    {
        clearTimeout(this.timeoutSearch); this.timeoutSearch = null;

        this.timeoutSearch = setTimeout(() => {
            var term = this._getInputValue();

            this._search(term);
        }, 100);
    },

    _search(term)
    {
        const matcher = new RegExp(term.toLowerCase().noAccent().escapeRegex(), "i");

        this.index = null;
        this.menu.children('ul.options').scrollTop(0);

        this.menu.find('.webf-list-item-option > a, .webf-list-item-option > label').each((i, elemHtml) => {
            const $item = $(elemHtml).closest('li');
            const elem = $(elemHtml).text();

            if (matcher.test(elem.toLowerCase().noAccent())) {
                this.showItem($item);
                $item.children('a, label').html(webf.hilite(elem, term));
            } else {
                this.hideItem($item);
            }
        });

        var $groups = this.menu.find('.webf-list-item-group');

        $groups.each((i, group) => {
            if ($(group).nextUntil('.webf-list-item-group', ':visible')[0]) {
                $(group).show();
            } else {
                $(group).hide();
            }
        });

        this._resetNavigation();
    },

    /**
     * @abstract
     */
    _resetNavigation() {},

    _move(delta)
    {
        const $items = this.menu.find('li.webf-list-item-option:visible');
        const nbItems = $items.length;

        if (this.index === null) {
            if (delta > 0) {
                this.index = delta - 1;
            } else if (delta < 0) {
                this.index = nbItems + delta;
            }
        } else {
            this.index = (this.index + delta) % nbItems;
        }

        if (this.index < 0) {
            this.index = nbItems + this.index;
        }

        const $item = $items.eq(this.index);
        this._focusItem($item);

        var $menu = this.menu.children('ul.options').first(),
            scrollTop = $menu.scrollTop(),
            heightMenu = $menu.height(),
            $a = $item.children('a'),
            heightItem = $a.outerHeight(),
            topItem = heightItem * this.index;

        if (topItem + heightItem > scrollTop + heightMenu) {
            scrollTop = (topItem + heightItem) - heightMenu;
        } else if (topItem < scrollTop) {
            scrollTop = topItem;
        }

        $menu.scrollTop(scrollTop);
    },

    _getInputValue()
    {
        return this.input.val();
    },

    _createOptionItem(value, label, enabled, selected, group)
    {
        enabled = webf.isUndefined(enabled) ? true : enabled;

        var $option = $("<li>")
            .addClass(['webf-list-item', 'webf-list-item-option', enabled ? 'enabled' : 'disabled', group ? 'group' : ''].join(' '));

        $option.append($('<a>').text(label));

        return $option.data('value', value);
    },

    _createGroupItem(label)
    {
        return $("<li>")
            .addClass('webf-list-item webf-list-item-group')
            .data('label', label)
            .append($('<label>')
            .text(label));
    },

    focusItem($item)
    {
        this.index = $item.prevAll('.webf-list-item-option').length;
        this.curItem = $item;
        this.blurItem();
        $item.addClass('webf-focus');
        this.plugin._call(this.plugin.option('onFocus'), $item, $item.data('value'));
    },

    blurItem($item)
    {
        this.curItem = null;

        if (!$item) {
            this.menu.find('.webf-list-item-option').removeClass('webf-focus');
        } else {
            $item.removeClass('webf-focus');
        }
    },

    showItem($item)
    {
        if (webf.isUndefined($item)) {
            this.menu.find('.webf-list-item').removeClass('webf-item-hidden');
        } else {
            $item.removeClass('webf-item-hidden');
        }
    },

    hideItem($item)
    {
        $item.addClass('webf-item-hidden');
    },

    isOpen()
    {
        return this.menu.is(':visible');
    },

    appendGroup(label)
    {
        this.e.append($('<optgroup>').prop('label', label));

        var $group = this._createGroupItem(label);
        this.menu.find('ul.options').append($group);

        return this.e;
    },

    appendItem(value, label, group, enabled, select, trigger)
    {
        var $option = $("<option value='" + (value + '').htmlsimplequotes() + "'>" + label + "</option>"),
            $item = this._createOptionItem(value, label, enabled, select, !!group);

        if (webf.isString(group) && group.length) {
            var $group = this.e.children("optgroup[label='" + group + "']"),
                $options = this.menu.find('ul.options');

            if (!$group.length) {
                this.appendGroup(group);
                $group = this.e.children("optgroup[label='" + group + "']");

                $options.append($item);
            } else {
                var $itemGroup = this.menu.find('ul.options .webf-list-item-group').findByData('label', group),
                    $nextGroup = $itemGroup.nextAll('.webf-list-item-group').first();

                if (!$nextGroup[0]) {
                    $options.append($item);
                } else {
                    $nextGroup.before($item);
                }
            }

            $group.append($option);
        } else {
            this.e.append($option);
            this.menu.find('ul.options').append($item);
        }

        if (select) {
            if (webf.isArray(this.e.val())) {
                this.val(webf.merge(this.e.val(), [value]));
            } else {
                this.val(value);
            }
        }

        trigger && this.e.trigger('change');
        select && this.select($item);

        return this.e;
    },

    _setListDimensions()
    {
        this.menu.outerWidth(this.$select.outerWidth());
    },

    open()
    {
        !this.isOpen() && this.$select.parent().webfDropdown('open');

        return this.e;
    },

    close()
    {
        if (this.isOpen()) {
            // this.$select.parent().webfDropdown('close');

            // this.plugin._call(this.plugin.option('onClose'));
        }

        return this.e;
    },

    removeGroup(group)
    {
        var $groupItem = this.menu.find('.webf-list-item-group').findByData('label', group);

        this.e.children("optgroup[label='" + group +"']").remove(),
        $groupItem.add($groupItem.nextUntil('.webf-list-item-group')).remove();

        return this.e;
    },

    removeItem(value)
    {
        this.e.find("option[value='" + (value + '').htmlsimplequotes() + "']").remove();
        this.menu.find('.webf-list-item-option').findByData('value', value).remove();

        this.val(this.val());

        return this.e;
    },

    removeAllItems()
    {
        this.e.find('option').remove();
        this.menu.find('.webf-list-item-option').remove();

        this.val(this.val());

        return this.e;
    },

    widget()
    {
        return this.menu;
    },

    destroy()
    {
        if (this.$select) {
            var $select = this.$select.find('.select');
            this.e.unwrap().unwrap();
            $select.remove();

            if (this.menu) {
                this.menu.remove();
            }

            if (this.$wrapperDropdown) {
                this.$wrapperDropdown.webfDropdown('destroy');
                this.$wrapperDropdown.remove();
            }
        } else if (this.menu) {
            var $options = this.menu.find('.options');
            this.e.unwrap();
            $options.remove();
        }

        this.e.show();
    }
});

