import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './dropdownmenu'
import $ from 'jquery'

/**
 * options :
 *  - handler
 *  - tooltipClass
 *  - activeClass
 *  - content
 *  - trigger
 *  - closeOnClickElement
 *  - position
 *  - zIndex
 *  - track
 *  - groups
 *  - delayOpen
 *  - delayClose
 *  - animation
 *  - easingOpen
 *  - easingClose
 *  - durationOpen
 *  - durationClose
 *  - interactive
 *  - onBeforeFirstOpen
 *  - onBeforeOpen
 *  - onFirstOpen
 *  - onOpen
 *  - onBeforeClose
 *  - onClose
 *
 * Méthodes publiques :
 *  - updateContent
 *  - tooltip
 *  - open
 *  - close
 */
$webf('tooltip', {
    options: {
        handler:                null,
        tooltipClass:           '',
        activeClass:            '',
        content:                '',
        trigger:                'hover',
        closeOnClickElement:    true,
        position: {
            my: 'center bottom',
            at: 'center top',
            collision: 'fit fit'
        },
        zIndex:                 1000,
        track:                  false,
        groups:                 '',
        delayOpen:              0,
        delayClose:             0,
        animation:              'fading',
        easingOpen:             'swing',
        easingClose:            'swing',
        durationOpen:           0,
        durationClose:          0,
        interactive:            false,
        onBeforeFirstOpen:      webf.noop,
        onBeforeOpen:           webf.noop,
        onFirstOpen:            webf.noop,
        onOpen:                 webf.noop,
        onBeforeClose:          webf.noop,
        onClose:                webf.noop
    },

    editableOptions: {
        content: function(content) {
            this.options.content = content;
        }
    },

    _create: function()
    {
        var self = this,
            behavior = this.option('trigger') == 'hover' ? 'menu-hover' : 'menu-toggle',
            track = behavior == 'menu-hover' && this.option('track'),
            autoPositionning = !track;

        this.e.webfDropdownmenu({
            behavior: behavior,
            handler: this.option('handler'),
            menu: this._getContent(),
            closeOnClickElement: this.option('closeOnClickElement'),
            groups: this.option('groups'),
            autoPositionning: autoPositionning,
            position: this.option('position'),
            menuClass: this.option('tooltipClass'),
            activeClass: this.option('activeClass'),
            zIndex: this.option('zIndex'),
            delayOpen: this.option('track') ? 0 : this.option('delayOpen'),
            delayClose: this.option('track') ? 0 : this.option('delayClose'),
            animation: this.option('animation'),
            durationOpen: this.option('durationOpen'),
            durationClose: this.option('durationClose'),
            lazyLoading: true,
            onBeforeFirstOpen: function() {
                if (!self.option('track') && !self.option('interactive')) {
                    var $menu = $(this).webfDropdownmenu('menu');
                    $menu.on('mouseenter', function(ev) {
                        self.e.webfDropdownmenu('close');
                    });
                }

                self._call(self.option('onBeforeFirstOpen'));
            },
            onBeforeOpen: function($handler) {
                return self._call(self.option('onBeforeOpen'), $handler);
            },
            onFirstOpen: this.option('onFirstOpen'),
            onOpen: this.option('onOpen'),
            onBeforeClose: this.option('onBeforeClose'),
            onClose: this.option('onClose')
        });

        this._bindEvents();
    },

    _bindEvents: function()
    {
        var self = this;

        if (this.option('trigger') == 'hover') {
            if (self.option('track')) {
                this._on(document, {
                    mousemove: function(ev) {
                        if (self.e.webfDropdownmenu('isOpening') || self.e.webfDropdownmenu('isOpened')) {
                            var mp = $webf.getMousePos(ev);
                            mp.x -= $(window).scrollLeft();
                            mp.y -= $(window).scrollTop();

                            if (webf.contains(mp, self.e[0].getBoundingClientRect())) {
                                self.e.webfDropdownmenu('refreshPosition', ev);
                            } else {
                                self.e.webfDropdownmenu('close');
                            }
                        }
                    }
                })._on(this.e, {
                    mouseenter: function(ev) {
                        self.e.webfDropdownmenu('isClosed') && self.e.webfDropdownmenu('open');
                    }
                });
            }
        }
    },

    updateContent: function(content)
    {
        var $tooltip = this.tooltip();

        try {
            if (!$(content)[0]) {
                content = $('<div>').html(content);
            }
        } catch (e) {
            content = $('<div>').html(content);
        }

        $tooltip.empty().append(content);
    },

    _getContent: function()
    {
        var content;

        this.title = content = this.e.prop('title');

        if (!content) {
            content = this.option('content');
        }

        if (webf.isString(content)) {
            try {
                if (!$(content)[0]) {
                    content = $('<div>').html(content);
                }
            } catch (e) {
                content = $('<div>').html(content);
            }
        }

        this.e.removeAttr('title');

        return content;
    },

    tooltip: function()
    {
        return this.e.webfDropdownmenu('menu');
    },

    open: function()
    {
        this.e.webfDropdownmenu('open');
    },

    close: function()
    {
        this.e.webfDropdownmenu('close');
    },

    _destroy: function()
    {
        if (this.title) {
            this.e.attr('title', this.title);
        }

        this.e.webfDropdownmenu('destroy');
    }
});

