import webf from './core'
import $ from 'jquery'

if ($.event) {
    var types = ['wheel', 'touchstart', 'touchmove', 'touchend', 'DOMMouseScroll', 'mousewheel', "MozMousePixelScroll"];

    if ($.event.fixHooks) {
        for (var i = types.length; i;) {
            $.event.fixHooks[types[--i]] = $.event.mouseHooks;
        }
    }

    $.event.special.webfMouseWheel = {
        setup: function () {
            if (this.addEventListener) {
                for (var i = types.length; i;) {
                    this.addEventListener(types[--i], handler, false);
                }
            } else {
                this.onmousewheel = handler;
            }
        },

        teardown: function () {
            if (this.removeEventListener) {
                for (var i = types.length; i;) {
                    this.removeEventListener(types[--i], handler, false);
                }
            } else {
                this.onmousewheel = null;
            }
        }
    };

    $.fn.extend({
        webfMouseWheel: function (fn) {
            return fn ? this.bind("webfMouseWheel", fn) : this.trigger("webfMouseWheel");
        },

        unWebfMouseWheel: function (fn) {
            return this.unbind("webfMouseWheel", fn);
        }
    });


    function handler(event) {
        var orgEvent = event || window.event, args = [].slice.call(arguments, 1), delta = 0, returnValue = true,
            deltaX = 0, deltaY = 0;
        event = $.event.fix(orgEvent);
        event.type = "webfMouseWheel";

        // Old school scrollwheel delta
        if (orgEvent.wheelDelta) {
            delta = orgEvent.wheelDelta / 120;
        }
        if (orgEvent.detail) {
            delta = -orgEvent.detail / 3;
        }
        if (orgEvent.type == 'DOMMouseScroll') {
            delta = -orgEvent.detail / 5;
        }
        if (orgEvent.type == 'MozMousePixelScroll') {
            delta = 0;
        }

        // New school multidimensional scroll (touchpads) deltas
        deltaY = delta;

        // Mobile
        if (orgEvent.type == 'touchstart') {
            // start = {
            // 	x: orgEvent.touches[0].pageX,
            // 	y: orgEvent.touches[0].pageY
            // };

            return;
        } else if (orgEvent.type == 'touchmove') {
            // var pageX = orgEvent.touches[0].pageX;
            // var pageY = orgEvent.touches[0].pageY;
            //
            // deltaX = start.x - pageX;
            // deltaY = (start.y - pageY) / -12;
            // delta = deltaY;
            //
            // start = { x:pageX, y:pageY };
            return;
        } else if (orgEvent.type == 'touchend') {
            // start = { x:0, y:0 };
            return;
        }

        // Gecko
        if (!webf.isUndefined(orgEvent.axis) && orgEvent.axis === orgEvent.HORIZONTAL_AXIS) {
            deltaY = 0;
            deltaX = -1 * delta;
        }

        // Webkit
        if (!webf.isUndefined(orgEvent.wheelDeltaY)) {
            deltaY = orgEvent.wheelDeltaY / 120;
        }
        if (!webf.isUndefined(orgEvent.wheelDeltaX)) {
            deltaX = -1 * orgEvent.wheelDeltaX / 120;
        }

        // wheel event
        if ('deltaY' in orgEvent) {
            deltaY = orgEvent.deltaY * -1 / 40;
            delta = deltaY;
        }

        if ('deltaX' in orgEvent) {
            deltaX = orgEvent.deltaX * 1 / 40;
            if (deltaY === 0) {
                delta = deltaX;
            }
        }

        // Add event and delta to the front of the arguments
        args.unshift(event, delta, deltaX, deltaY);

        return ($.event.dispatch || $.event.handle).apply(this, args);
    }
}
