import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.ForeColor = webf.RichTextEditor.Control.extend({
    zIndex: null,
    customColors: null,

    init: function(editor, options) {
        this.zIndex = editor.option('zIndex');
        this.customColors = options.customColors || [];
    },
    render: function(editor, $btn) {
        var self = this,
            $customPalette;

        $btn = $btn.addClass('webf-select-button forecolor')
            .append("<label><i class='fas fa-font'></i></label>")
            .append("<i class='fas fa-caret-down'></i>");

        if (this.customColors.length) {
            $customPalette = $(
                "<div class='palette custom'>" +
                "</div>"
            );

            for (var i=0; i<Math.ceil(this.customColors.length / 10); i++) {
                $customPalette.append(
                    "<table>" +
                    "    <tr>" +
                            webf.map(this.customColors.slice(i * 10, (i + 1) * 10), function(i, color) {
                                return "<td><a class='cell' data-color='"+color+"'></a></td>";
                            }).join('') +
                    "    </tr>" +
                    "</table>"
                );
            }

            if (this.customColors.length < 10) {
                $customPalette.find('tr').append("<td colspan='"+(10 - this.customColors.length)+"'></td>");
            }
        }

        this.$menu = $("" +
            "<div class='webf-richtexteditor-color-selector'>" +
                 $('<div>').append($customPalette).html() +
            "    <div class='palette nb'>" +
            "         <table>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='000000'></a></td>" + "<td><a class='cell' data-color='434343'></a></td>" + "<td><a class='cell' data-color='666666'></a></td>" + "<td><a class='cell' data-color='999999'></a></td>" + "<td><a class='cell' data-color='B7B7B7'></a></td>" + "<td><a class='cell' data-color='CCCCCC'></a></td>" + "<td><a class='cell' data-color='D9D9D9'></a></td>" + "<td><a class='cell' data-color='EFEFEF'></a></td>" + "<td><a class='cell' data-color='F3F3F3'></a></td>" + "<td><a class='cell' data-color='FFFFFF'></a></td>" +
            "             </tr>" +
            "         </table>" +
            "     </div>" +
            "     <div class='palette fluo'>" +
            "         <table>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='980000'></a></td>" + "<td><a class='cell' data-color='FF0000'></a></td>" + "<td><a class='cell' data-color='FF9900'></a></td>" + "<td><a class='cell' data-color='FFFF00'></a></td>" + "<td><a class='cell' data-color='00FF00'></a></td>" + "<td><a class='cell' data-color='00FFFF'></a></td>" + "<td><a class='cell' data-color='4A86E8'></a></td>" + "<td><a class='cell' data-color='0000FF'></a></td>" + "<td><a class='cell' data-color='9900FF'></a></td>" + "<td><a class='cell' data-color='FF00FF'></a></td>" +
            "             </tr>" +
            "         </table>" +
            "     </div>" +
            "     <div class='palette nuancier'>" +
            "         <table>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='E6B8AF'></a></td>" + "<td><a class='cell' data-color='F4CCCC'></a></td>" + "<td><a class='cell' data-color='FCE5CD'></a></td>" + "<td><a class='cell' data-color='FFF2CC'></a></td>" + "<td><a class='cell' data-color='D9EAD3'></a></td>" + "<td><a class='cell' data-color='D0E0E3'></a></td>" + "<td><a class='cell' data-color='C9DAF8'></a></td>" + "<td><a class='cell' data-color='CFE2F3'></a></td>" + "<td><a class='cell' data-color='D9D2E9'></a></td>" + "<td><a class='cell' data-color='EAD1DC'></a></td>" +
            "             </tr>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='DD7E6B'></a></td>" + "<td><a class='cell' data-color='EA9999'></a></td>" + "<td><a class='cell' data-color='F9CB9C'></a></td>" + "<td><a class='cell' data-color='FFE599'></a></td>" + "<td><a class='cell' data-color='B6D7A8'></a></td>" + "<td><a class='cell' data-color='A2C4C9'></a></td>" + "<td><a class='cell' data-color='A4C2F4'></a></td>" + "<td><a class='cell' data-color='9FC5E8'></a></td>" + "<td><a class='cell' data-color='B4A7D6'></a></td>" + "<td><a class='cell' data-color='D5A6BD'></a></td>" +
            "             </tr>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='CC4125'></a></td>" + "<td><a class='cell' data-color='E06666'></a></td>" + "<td><a class='cell' data-color='F6B26B'></a></td>" + "<td><a class='cell' data-color='FFD966'></a></td>" + "<td><a class='cell' data-color='93C47D'></a></td>" + "<td><a class='cell' data-color='76A5AF'></a></td>" + "<td><a class='cell' data-color='6D9EEB'></a></td>" + "<td><a class='cell' data-color='6FA8DC'></a></td>" + "<td><a class='cell' data-color='8E7CC3'></a></td>" + "<td><a class='cell' data-color='C27BA0'></a></td>" +
            "             </tr>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='A61C00'></a></td>" + "<td><a class='cell' data-color='CC0000'></a></td>" + "<td><a class='cell' data-color='E69138'></a></td>" + "<td><a class='cell' data-color='F1C232'></a></td>" + "<td><a class='cell' data-color='6AA84F'></a></td>" + "<td><a class='cell' data-color='45818E'></a></td>" + "<td><a class='cell' data-color='3C78D8'></a></td>" + "<td><a class='cell' data-color='3D85C6'></a></td>" + "<td><a class='cell' data-color='674EA7'></a></td>" + "<td><a class='cell' data-color='A64D79'></a></td>" +
            "             </tr>" +
            "                 <tr>" +
            "                 <td><a class='cell' data-color='85200C'></a></td>" + "<td><a class='cell' data-color='990000'></a></td>" + "<td><a class='cell' data-color='B45F06'></a></td>" + "<td><a class='cell' data-color='BF9000'></a></td>" + "<td><a class='cell' data-color='38761D'></a></td>" + "<td><a class='cell' data-color='134F5C'></a></td>" + "<td><a class='cell' data-color='1155CC'></a></td>" + "<td><a class='cell' data-color='0B5394'></a></td>" + "<td><a class='cell' data-color='0B5394'></a></td>" + "<td><a class='cell' data-color='351C75'></a></td>" +
            "             </tr>" +
            "             <tr>" +
            "                 <td><a class='cell' data-color='5B0F00'></a></td>" + "<td><a class='cell' data-color='660000'></a></td>" + "<td><a class='cell' data-color='783F04'></a></td>" + "<td><a class='cell' data-color='7F6000'></a></td>" + "<td><a class='cell' data-color='274E13'></a></td>" + "<td><a class='cell' data-color='0C343D'></a></td>" + "<td><a class='cell' data-color='1C4587'></a></td>" + "<td><a class='cell' data-color='073763'></a></td>" + "<td><a class='cell' data-color='20124D'></a></td>" + "<td><a class='cell' data-color='4C1130'></a></td>" +
            "             </tr>" +
            "         </table>" +
            "     </div>" +
            "</div>" +
        "");

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-forecolor',
            onFirstOpen: function() {
                self.$menu.find('a.cell').each( function() {
                    $(this).css('background-color',"#" + $(this).data('color'));
                }).on('click', function() {
                    $btn.find('.color').css('background-color',"#" + $(this).data('color'));

                    var value = $(this).data('color');
                    editor._execCommand('forecolor', value);
                });
            }
        });
    },
    refresh: function(editor, $btn) {
        try {
            var value = editor._queryCommandValue('forecolor');
        } catch(e) {}

        var hexColor;
        if (/#?\S{6}/.test(value)) {
            hexColor = value;
            if ((value + '').indexOf('#') === 0) {
                hexColor = value.substring(1);
            }
        } else if (document.selection) {
            if (!value) {
                hexColor = "000000";
            } else {
                value = ((value & 0x0000ff) << 16) | (value & 0x00ff00) | ((value & 0xff0000) >>> 16);
                hexColor = value.toString(16);
            }
        } else {
            if (!value) {
                value = "rgb(0, 0, 0)";
            }

            if (webf.isInt(value)) {
                value = ((value & 0x0000ff) << 16) | (value & 0x00ff00) | ((value & 0xff0000) >>> 16);
                hexColor = value.toString(16);
            } else {
                var Rgb = /rgba?\((\d+),(\d+),(\d+)\)/.exec((value + '').replace(/\s/g,""));
                hexColor = webf.rgbtohex(Rgb[1], Rgb[2], Rgb[3]);
            }
        }

        $btn.find('label').css('color', "#" + hexColor);
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    types: 'action',
    text_tooltip: 'text_color',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});
