import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - value         (int)       Valeur de départ
 *  - max           (int)       Valeur maximale
 *  - size          (int)       Diamètre en px du cercle
 *  - stroke        (float)     Epaisseur du secteur en proportion de size. Si > 1, la valeur sera considérée comme étant
 *                              exprimée en pourcentage du rayon du cercle
 *  - circleColor   (string)    Couleur du cercle
 *  - strokeColor   (string)    Couleur du secteur
 *  - speedSector   (int)       Vitesse en tours / min du secteur
 *  - onCreate      (int)       Fonction appelée lorsque le progress circle est dessiné
 *  - onComplete    (int)       Fonction appelée lorsque la progression est terminée, càd quand value vaut max
 *
 *  Méthodes publiques:
 *  - value([int])              Sans argument, retourne value, sinon définit la valeur de la progression
 */
$webf('circleprogressbar', {
    options: {
        value:          0,
        max:            100,
        size:           100,
        stroke:         0.2,
        circleColor:    '#ddd',
        strokeColor:    '#4285f4',
        speedSector:    18,
        onCreate:       webf.noop,
        onComplete:     webf.noop
    },

    _create: function()
    {
        const self = this
        const opt = this.options
        this.e.addClass('webf-circleprogressbar')

        const canvas = $(`<canvas style="1px dashed #ccc" width="${this.option('size')}" height="${this.option('size')}"></canvas>`)[0]

        this.e.append(canvas)
        this.value(this.option('value'))

        this._call(this.option('onCreate'));

        const ctx = canvas.getContext('2d');
        const cw = ctx.canvas.width
        const ch = ctx.canvas.height
        const lw = webf.between(0, (this.option('stroke') > 1 ? this.option('stroke') / 100 : this.option('stroke')) * cw/2, cw)
        let diff, prev = new Date().valueOf()
        let start = -Math.PI / 2;

        function animate() {
            const timeElapsed = new Date() - prev
            prev = new Date()

            start += timeElapsed * opt.speedSector * (2*Math.PI) / 60000
            const value = self.value()

            diff = webf.round(((value / 100) * 2*Math.PI), 4);
            ctx.clearRect(0, 0, cw, ch);
            ctx.lineWidth = lw;

            ctx.fillStyle = opt.circleColor;
            ctx.strokeStyle = opt.circleColor;
            ctx.beginPath();
            ctx.arc(cw/2, ch/2, (cw - lw) / 2, 0, 2*Math.PI);
            ctx.stroke();

            ctx.fillStyle = opt.strokeColor;
            ctx.strokeStyle = opt.strokeColor;
            ctx.beginPath();
            ctx.arc(cw/2, ch/2, (cw - lw) / 2, start, diff + start, false);
            ctx.stroke();

            if (value >= opt.max) {

            }

            requestAnimationFrame(animate)
        }

        animate()
    },

    value: function(val)
    {
        if (webf.isUndefined(val)) {
            return this.val;
        }

        if (!webf.isFloat(val)) {
            val = 0;
        }

        this.val = webf.between(val, 0, this.option('max'))

        if (this.val >= this.option('max')) {
            this._call(this.option('onComplete'))
        }
    },

    _destroy: function()
    {
        this.e.find('canvas').remove()
        this.e.removeClass('webf-circleprogressbar')
    }
});
