import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.ajaxform = {
        required: function(name) {
            return "Le champ " + name + " est requis";
        }
    };
})(webf);

