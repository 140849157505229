import webf from '../../utils/core'
import webfClass from '../../utils/class'

webf.RichTextEditor = {
    Controls: [],
    Control: webfClass.extend({
        types: null,
        text_tooltip: null,
        shortcut: {},

        init:    webf.noop,
        render:  webf.noop,
        exec:    webf.noop,
        refresh: webf.noop,
        destroy: webf.noop
    })
};
