import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - wrapperClass:     (string)    Classes CSS à ajouter au wrapper de la zone de texte
 *  - minHeight:        (int)       Hauteur minimale en px
 *  - maxHeight:        (int)       Hauteur maximale en px
 *  - extraLine:        (boolean)   Si true, ajoute la hauteur d'une ligne à la hauteur minimale
 *  - onResize:         (function(oldHeight, newHeight))
 *                                  Fonction appelée lorsque le textarea change de taille
 *
 * Méthodes publiques :
 *  - update()
 */
$webf('textareaautogrow', {
    options: {
        wrapperClass:   '',
        minHeight:      null,
        maxHeight:      null,
        extraLine:      false,
        onResize:       webf.noop
    },

    _create: function()
    {
        const self = this;
        const input = this.e[0];
        const $wrapper = $('<div>').addClass(['wrapper-webf-textarea'].concat(this.option('wrapperClass').split(' ')));
        let minHeight = null;

        const getMinHeight = () => {
            if (minHeight) return minHeight;
            minHeight = this.option('minHeight') ? this.option('minHeight') : this.e.innerHeight();
        }

        const delayedResize = (ev) => {
            if (webf.inArray(ev.type, ['input'])) {
                ev.preventDefault();
                ev.stopPropagation();
                resize(ev.data); // ev.data pour l'event paste
            } else {
                setTimeout(resize, 0);
            }
        }

        const resize = (text = null) => {
            const maxHeight = this.option('maxHeight');

            if (null === text) {
                cloneText.val(this.e.val());
            } else {
                webf.insertTextAtCursor(cloneText[0], text);
            }
            cloneText[0].style.height = 'auto';

            webf.each(['width', 'font-size', 'font-weight', 'line-height'], (i, prop) => {
                cloneText[0].style[prop.camelCase()] = webf.getStyle(input, prop);
            });

            let newHeight = Math.max(cloneText[0].scrollHeight + lineHeight, getMinHeight());

            if (maxHeight && newHeight > maxHeight) {
                newHeight = maxHeight;
                this.e.css('overflow', 'auto');
            } else {
                this.e.css('overflow', 'hidden');
            }

            const oldHeight = parseInt(webf.getStyle(input, 'height'));
            input.style.height = newHeight + 'px';

            if (null !== text) {
                webf.insertTextAtCursor(input, text);
            }

            if (oldHeight !== newHeight) {
                this._call(this.option('onResize'), oldHeight, newHeight);
            }
        }

        const cloneText = this.e.clone()
            .prop('rows', 1)
            .css({
                position: 'absolute',
                overflow: 'hidden',
                padding: 0,
                border: 0,
                // top: 10,
                // left: 10,
                top: -99999,
                left: -99999,
                visibilty: 'hidden'
            })
            .addClass('webf-textarea-mirror')
            .appendTo('body');

        this.e
            .addClass('webf-textarea')
            .addClass('webf-simple-line')
            .prop('rows', 1);

        const lineHeight = this.option('extraLine') ? this.e.innerHeight() : 0;
        this.e.removeClass('webf-textarea webf-simple-line')

        $wrapper[0].style.backgroundColor = webf.getStyle(input, 'background-color');
        $wrapper[0].style.fontSize = '30px';

        webf.each(['padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'margin-top', 'margin-right', 'margin-bottom', 'margin-left', 'border-top-width', 'border-top-style', 'border-top-color', 'border-right-width', 'border-right-style', 'border-right-color', 'border-bottom-width', 'border-bottom-style', 'border-bottom-color', 'border-left-width', 'border-left-style', 'border-left-color', 'display', 'width', 'font-size', 'font-weight', 'font-family'], function(i, prop) {
            $wrapper[0].style[prop.camelCase()] = webf.getStyle(input, prop);
        }, this);

        this.e
            .addClass('webf-textarea')
            .wrap($wrapper);

        this._on(this.e, {
            'paste input update': delayedResize,
        })._on(window, {
            resize: () => {
                this.e.parent()[0].style.width = 'auto';
                this.e.parent()[0].style.width = webf.getStyle(input, 'width');
                resize();
            }
        });

        input.style.height = getMinHeight() + 'px';
        resize();
    },

    update: function()
    {
        this._trigger(this.e, 'update');
    },

    _destroy: function()
    {
        this.e
            .removeClass('webf-textarea')
            .unwrap();
    }
});

