import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/dialog'
import './position'
import $ from 'jquery'

/**
 * options:
 *  - box:              (Object)     boxClass:      classes CSS appliquées à l'élément racine de la webfBox
 *                                   header:        Options du header. Peut être défini à false
 *                                    - title:          Titre de la boite de dialogue affichée dans le header de la webfBox
 *                                    - reduceButton:   Affiche le bouton reduce
 *                                    - maximizeButton: Affiche le bouton pour agrandir la boite de dialogue
 *                                    - closeButton:    Affiche le bouton de fermeture de la boite de dialogue
 *                                    - buttons:        v. boutons du footer
 *                                    - webfButtons:    true ou false pour appliquer ou non les styles des webfButton
 *                                   footer:        Options du footer. Peut être défini à false
 *                                    - html:           Contenu HTML du footer. Surcharge toutes les autres options du footer si renseigné
 *                                    - closeButton:    Affiche le bouton de fermeture de la boite de dialogue
 *                                    - buttons:        Liste d'objets rendu sous forme de webfButton
 *                                                      Chaque bouton peut être écrit sous la forme "label: function() {...}"
 *                                      - cls:              Classes CSS
 *                                      - label:            Le label du bouton
 *                                      - click:            Fonction fournie pour l'événement click
 *                                      - type:             button ou submit. Si submit, le formulaire sera soumis en
 *                                                          exécutant la fonction click du bouton
 *                                    - webfButtons:    true ou false pour appliquer ou non les styles des webfButton
 *  - position:         (Object)     v.plugin WebfPosition
 *  - autoOpen:         (boolean)    Si true, la boite de dialogue s'ouvre à l'instanciation du plugin
 *  - appendTo:         (Element|string)
 *                                   Element ou sélecteur sur lequel ajouter la boite de dialogue
 *  - dialogClass:      (string)     Classes CSS appliquées à l'élément racine de la webfBox
 *  - overlayClass:     (string)     Classes CSS appliquées à l'élément overlay
 *  - draggable:        (boolean)    Si true, la boite de dialogue sera draggable
 *  - modal:            (boolean)    Si true, la boite de dialogue sera modale
 *  - backgroundOverlay:(string)     Couleur d'arrière plan
 *  - opacityOverlay:   (float)      Valeur entre 0 et 1 pour l'opacité de l'arrière plan
 *  - zIndex:           (int)        z-index de la boite de dialogue
 *  - durationOpen:     (int)        Durée de l'animation d'ouverture de la boite de dialogue
 *  - easingOpen:       (string)     Type d'animation pour l'ouverture de la boite de dialogue
 *  - durationClose:    (int)        Durée de l'animation de fermeture de la boite de dialogue
 *  - easingClose:      (string)     Type d'animation pour la fermeture de la boite de dialogue
 *  - closeOnEscape:    (boolean)    Fermeture de la boite de dialogue avec la touche Echap
 *  - closeOnClickOutside:
 *                      (boolean)    Fermeture de la boite de dialogue lors d'un click à l'extérieur de la boiute de dialogue
 *  - fixed:            (boolean)    Si true, la boite de dialogue sera fixe sur l'écran
 *  - resizable:        (boolean)    Permet de redimensionner la boite de dialogue
 *  - minWidth:         (int)        Largeur minimum de la boite de dialogue lors d'un redimensionnement
 *  - minHeight:        (int)        Hauteur minimum de la boite de dialogue lors d'un redimensionnement
 *  - maxWidth:         (int)        Largeur maximum de la boite de dialogue lors d'un redimensionnement
 *  - maxHeight:        (int)        Hauteur maximum de la boite de dialogue lors d'un redimensionnement
 *  - startMaximized:   (boolean)    Ouvrir la boite de dialogue en plein écran
 *  - bindForm:         (boolean)    Si la boite de dialogue contien un formulaire, ce dernier peut être soumis lorsque
 *                                   l'utilisateur appuie sur Entrée dans le formulaire
 *  - onFirstOpen:      (function()) Fonction appelée lors de la première ouverture
 *  - onOpen:           (function()) Fonction appelée lors de chaque ouverture
 *  - onBeforeClose:    (function()) Fonction appelée avant que la boite de dialogue ne soit fermée. Peut retourner false
 *                                   pour annuler la fermeture
 *  - onClose:          (function()) Fonction appelée lors de la fermeture
 *  - onDragStart:      (function()) Fonction appelée lorsque le drag commence
 *  - onDragEnd:        (function()) Fonction appelée lorsque le drag se termine
 *  - onDrag:           (function()) Fonction appelée lors du drag
 *  - onResizeStart:    (function()) Fonction appelée au début du redimensionnement
 *  - onResizeEnd:      (function()) Fonction appelée à la fin du redimensionnement
 *  - onResize:         (function()) Fonction appelée lors du redimensionnement
 *  - onMaximize:       (function()) Fonction appelée lors du passage en plein écran
 *  - onMinimize:       (function()) Fonction appelée lorsque la boite de dialogue est réduite
 *  - onRestore:        (function()) Fonction appelée lorsque la boite de dialogue est restorée
 *
 * Méthodes publiques:
 *  - open              Ouvre la boite de dialogue
 *  - enableDragging    Active le dragging
 *  - disableDragging   Désactive le dragging
 *  - focus             Donne le focus à la boite de dialogue
 *  - close             Ferme la boite de dialogue
 *  - reduce            Réduit la boite de dialogue
 *  - restore           Restaure la boite de dialogue
 *  - maximize          Passe la boite de dialogue en plein écran
 *  - minimize          Passe la boite de dialogue en mode normal
 *  - widget            Retourne la racine de la boite de dialogue
 *  - refreshPosition   Rafraichit la position de la boite de dialogue
 *  - setTitle          Définit le titre de la boite de dialogue
 *  - isOpened          Retourne true si la boite de dialogue est ouverte
 *  - submitForm        Soumet le formulaire de la boite de dialogue
 */
$webf('dialog', {
    options: {
        box: {
            boxClass:           '',
            header: {
                title:          '',
                reduceButton:   false,
                maximizeButton: false,
                closeButton:    true,
                webfButtons:    true
            },
            footer: {
                closeButton:    true,
                buttons:        {},
                webfButtons:    true
            }
        },
        position: {
            my: "center center",
            at: "center center",
            of: typeof window !== "undefined" ? window : null,
            collision: "fit fit"
        },
        autoOpen:               true,
        appendTo:               'body',
        dialogClass:            '',
        overlayClass:           '',
        draggable:              true,
        modal:                  true,
        backgroundOverlay:      '#000000',
        opacityOverlay:         0.12,
        zIndex:                 1100,
        durationOpen:           0,
        easingOpen:             'swing',
        durationClose:          0,
        easingClose:            'swing',
        closeOnEscape:          true,
        closeOnClickOutside:    false,
        fixed:                  false,
        resizable:              false,
        minWidth:               50,
        minHeight:              50,
        maxWidth:               0,
        maxHeight:              0,
        startMaximized:         false,
        bindForm:               true,
        onFirstOpen:            webf.noop,
        onOpen:                 webf.noop,
        onBeforeClose:          webf.noop,
        onClose:                webf.noop,
        onDragStart:            webf.noop,
        onDragEnd:              webf.noop,
        onDrag:                 webf.noop,
        onResizeStart:          webf.noop,
        onResizeEnd:            webf.noop,
        onResize:               webf.noop,
        onCancel:               webf.noop,
        onMaximize:             webf.noop,
        onMinimize:             webf.noop,
        onRestore:              webf.noop,
        onBeforeSubmit:         webf.noop
    },

    _create: function()
    {
        this.firstopen = true;
        this.opened = false;
        this.dragged = false;
        this.reduced = false;
        this.maximized = false;
        this.dragging = false;
        this.resizing = false;
        this.resized = false;

        if (this.option('modal')) {
            this._createOverlay();
        }

        this._createDialog();
        this._bindResize();

        if (this.option('draggable') && !this.option('fixed')) {
            this._bindDraggable();
        }

        if (this.option('closeOnEscape')) {
            this._bindCloseOnEscape();
        }

        if (this.option('closeOnClickOutside')) {
            this._bindCloseOnClickOutside();
        }

        if (this.option('resizable') && !this.option('fixed')) {
            this._bindResizable();
        }

        this.option('bindForm') && this._bindForm();
    },

    _init: function()
    {
        this.option('autoOpen') && this.open();
    },

    _createOverlay: function()
    {
        this.$overlay = $('<div>').addClass(['webf-overlay-dialog'].concat(this.option('overlayClass').split(' ')).join(' '))
            .appendTo($(this.option('appendTo') || 'body'))
            .css({
                zIndex: this.option('zIndex'),
                backgroundColor: this.option('backgroundOverlay'),
                opacity: this.option('opacityOverlay')
            }).hide();
    },

    _createDialog: function()
    {
        var self = this,
            $dialog = $('<div>').addClass(['webf-dialog'].concat(this.option('dialogClass').split(' ')).join(' '))
                .prop('tabIndex', '-1')
                .css('z-index', this.option('zIndex')),
            box = this.option('box');

        if (this.option('fixed')) {
            $dialog.addClass('fixed');
        }

        this._on($dialog, {
            mousedown: function(ev) {
                if (ev.target.tagName != 'OPTION') {
                    self.focus();
                }
            }
        });

        if (box) {
            var headerOpt = box.header,
                footerOpt = box.footer,
                $box = $('<div>').addClass(['webf-box'].concat(box.boxClass.split(' ')).join(' '));

            if (headerOpt) {
                var $header = $('<div>').addClass('box-header');

                var title = this.e.prop('title') || headerOpt.title;

                if (title) {
                    var $h3 = $('<h3>').html(title),
                        $title = $('<div>').addClass('wrapper').append($h3);
                    $header.append($title);
                }

                $header.append($('<div>').addClass('wrap-buttons'));
                var $wrapButtonsHeader = $header.find('.wrap-buttons');

                if (headerOpt.closeButton) {
                    var $closeButton = $('<a>').addClass('webf-button')
                        .append("<i class='fas fa-times'></i>");

                    $wrapButtonsHeader.append($closeButton);

                    this._on($closeButton, {
                        click: function() {
                            self.cancel();
                            self.close();
                        }
                    });
                }

                if (headerOpt.maximizeButton) {
                    var $maximizeButton = $('<a>').addClass('webf-button')
                        .append('<i class="fas fa-window-maximize"></i>');

                    $wrapButtonsHeader.append($maximizeButton);

                    this._on($maximizeButton, {
                        click: function() {
                            if (self.maximized) {
                                self.minimize();
                            } else {
                                self.maximize();
                            }
                        }
                    });
                }

                if (headerOpt.reduceButton) {
                    var $reduceButton = $('<a>').addClass('webf-button')
                        .append('<i class="fas fa-window-minimize"></i>');

                    $wrapButtonsHeader.append($reduceButton);

                    this._on($reduceButton, {
                        click: function() {
                            if (self.reduced) {
                                self.restore();
                            } else {
                                self.reduce();
                            }
                        }
                    });
                }

                webf.each(headerOpt.buttons || [], (label, btn) => {
                    var cls = '', user_func = btn;

                    if (!webf.isFunction(user_func)) {
                        label = !webf.isUndefined(btn.label) ? btn.label : label;
                        cls = !webf.isUndefined(btn.cls) ? btn.cls : '';
                        user_func = btn.click;
                    }
                    var type = !webf.isUndefined(btn.type) ? btn.type : 'button';

                    var clsButton = [headerOpt.webfButtons ? 'webf-button' : ''].concat(cls.split(' ')).join(' ');

                    var $button = $("<button>")
                        .prop('type', type)
                        .html(label);

                    if (type) {
                        $button.data('_type', type);
                    }

                    if (clsButton) {
                        $button.addClass(clsButton);
                    }

                    this._on($button, {
                        click: (ev) => {
                            ev.preventDefault();

                            if ($(ev.target).prop('type') === 'submit') {
                                ev.preventDefault();
                                this.submitForm();
                            } else if (webf.isFunction(user_func)) {
                                this._call(user_func, ev);
                            }
                        }
                    });

                    $wrapButtonsHeader.append($button);
                });

                $box.append($header);
            }

            $('<div>').addClass('box-body').append(this.e.show()).appendTo($box);

            if (footerOpt) {
                var $footer = $('<div>').addClass('box-footer'),
                    buttons = footerOpt.buttons;

                if (footerOpt.closeButton) {
                    buttons.close = buttons.close || {
                        label: this._('close'),
                        cls: 'webf-button-secondary secondary',
                        click: function() {
                            self.cancel();
                            $(this).webfDialog('close');
                        }
                    };
                }

                var $wrapper = $('<div>').addClass('wrapper');

                webf.each(footerOpt.buttons, (label, btn) => {
                    var cls = '', user_func = btn;

                    if (!webf.isFunction(user_func)) {
                        label = !webf.isUndefined(btn.label) ? btn.label : label;
                        cls = !webf.isUndefined(btn.cls) ? btn.cls : '';
                        user_func = btn.click;
                    }
                    var type = !webf.isUndefined(btn.type) ? btn.type : 'button';

                    var clsButton = [footerOpt.webfButtons ? 'webf-button' : ''].concat(cls.split(' ')).join(' ');

                    var $button = $("<button>")
                        .prop('type', type)
                        .html(label);

                    if (type) {
                        $button.data('_type', type);
                    }

                    if (clsButton) {
                        $button.addClass(clsButton);
                    }

                    this._on($button, {
                        click: (ev) => {
                            ev.preventDefault();

                            if ($(ev.target).prop('type') === 'submit') {
                                ev.preventDefault();
                                this.submitForm();
                            } else if (webf.isFunction(user_func)) {
                                this._call(user_func, ev);
                            }
                        }
                    });

                    $wrapper.append($button);
                });

                $box.append($footer.append($wrapper));
            }

            $dialog.append($box);
        } else {
            $dialog.append(this.e.show());
        }

        this.$dialog = $dialog.hide().appendTo($(this.option('appendTo')));
    },

    _bindResizable: function()
    {
        var self = this,
            $boxBody = this.$dialog.find('.box-body'),
            dialogVisible = this.$dialog.is(':visible');

        if (!dialogVisible) {
            this.$dialog.show();
        }

        var minHeight = this.$dialog.outerHeight() - $boxBody.height(),
            minOuterHeight = this.$dialog.outerHeight() - $boxBody.outerHeight();

        if (!dialogVisible) {
            this.$dialog.hide();
        }

        this.$dialog.webfResizable({
            handles: 'all',
            minWidth: 100,
            minHeight: minHeight,
            onResizeStart: function() {
                self.resized = true;
                $boxBody.css('overflow', 'auto');

                self._call(self.option('onResizeStart'));
            },
            onResize: function() {
                var paddingTop = parseFloat($boxBody.css('paddingTop')),
                    paddingBottom = parseFloat($boxBody.css('paddingBottom'));

                $boxBody.height($(this).height() - minOuterHeight - (paddingTop + paddingBottom));

                self._call(self.option('onResize'));
            },
            onResizeEnd: this.option('onResizeEnd')
        });
    },

    _bindResize: function()
    {
        var self = this,
            handler = {};

        handler[this.option('fixed') ? 'resize scroll' : 'resize'] = function() {
            if (self.maximized) {
                self._setBodyHeight();
            } else if (!self.dragged && !self.resized) {
                self._setPosition();
            }
        };

        this._on(window, handler);
    },

    _bindDraggable: function()
    {
        var self = this,
            $header = this.$dialog.find('.box-header').addClass('draggable');

        this._on($header, {
            mousedown: function(ev) {
                if ($(ev.target).closest('.draggable').length && !$(ev.target).closest('.webf-button').length) {
                    ev.preventDefault();
                    self.dragging = 1;
                    self.startPoint = $webf.getMousePos(ev, self.$dialog);
                }
            }
        })._on(document, {
            mouseup: function() {
                self.dragging = false;
                self._call(self.option('onDragEnd'));
            },
            mousemove: function(ev) {
                if (self.dragging === 1) {
                    self.dragging = true;
                    self._call(self.option('onDragStart'));
                }

                if (self.dragging) {
                    ev.preventDefault();
                    self.dragged = true;

                    var mp = $webf.getMousePos(ev);

                    self.$dialog.css( {
                        left: webf.between(mp.x - self.startPoint.x, 0, Math.max(0, $(window).width() - self.$dialog.outerWidth())),
                        top: webf.between(mp.y - self.startPoint.y, 0, $(document).height())
                    });

                    self._call(self.option('onDrag'));
                }
            }
        });
    },

    _bindCloseOnEscape: function()
    {
        this._on(this.$dialog, {
            keydown: ev => {
                if (ev.which == 27 && this.isOpened()) {
                    ev.preventDefault();
                    this.cancel();
                    this.close();
                }
            }
        });
    },

    _bindCloseOnClickOutside: function()
    {
        const $overlay = this.$overlay ? this.$overlay : $(document);

        this._on($overlay, {
            click: (ev) => {
                if (this.isOpened()) {
                    ev.preventDefault();
                    this.cancel();
                    this.close();
                }
            }
        });
    },

    _bindForm: function()
    {
        var self = this;
        var $curForm = this.e.find('form');

        if ($curForm[0] && !$curForm.find('[type="submit"]')[0]) {
            $curForm.append('<button class="webf-dialog-submit" type="submit">');
        }

        this._on(this.$dialog, 'form', {
            submit: function (ev) {
                if (self._call(self.option('onBeforeSubmit')) === false) {
                    ev.preventDefault();
                }
            }
        });
    },

    open: function()
    {
        this._setPosition();
        this.opened = true;

        if (this.$overlay) {
            this.$overlay.fadeTo(this.option('durationOpen'), this.option('opacityOverlay'));
        }

        this.$dialog.fadeIn({
            duration: this.option('durationOpen'),
            easing: this.option('easingOpen')
        });

        this.focus();

        if (this.firstopen) {
            this.firstopen = false;

            this._call(this.option('onFirstOpen'));

            if (this.option('startMaximized')) {
                this.maximize();
            }
        }

        if (this.maximized) {
            $('body').css('overflow', 'hidden');
        }

        this.widget().focus();

        this._call(this.option('onOpen'));
    },

    _setPosition: function()
    {
        var dialogVisible = this.$dialog.is(':visible');
        if (!dialogVisible) {
            this.$dialog.show();
        }

        this.$dialog.webfPosition('destroy');
        this.$dialog.webfPosition(this.option('position'));

        if (!dialogVisible) {
            this.$dialog.hide();
        }
    },

    _setBodyHeight: function()
    {
        var $boxBody = this.$dialog.find('.box-body'),
            paddingTopBody = webf.getStyle($boxBody[0], 'box-sizing') == 'content-box' ? parseFloat($boxBody.css('paddingTop')) : 0,
            paddingBottomBody = webf.getStyle($boxBody[0], 'box-sizing') == 'content-box' ? parseFloat($boxBody.css('paddingBottom')) : 0,
            topFooter = this.$dialog.find('.box-footer')[0] ? this.$dialog.find('.box-footer').offset().top : $(window).height();

        $boxBody.css('height', $(window).scrollTop() + topFooter - $boxBody.offset().top - (paddingTopBody + paddingBottomBody));
    },

    enableDragging: function()
    {
        this.$dialog.find('.box-header').addClass('draggable');
        this.draggingEnabled = true;
    },

    disableDragging: function()
    {
        this.$dialog.find('.box-header').removeClass('draggable');
        this.draggingEnabled = false;
    },

    focus: function()
    {
        var dialogs = this.getOtherInstances(),
            zIndex = parseInt(this.$dialog.css('zIndex'));

        webf.each(dialogs, function(i, dialog) {
            if (dialog.isOpened()) {
                var zIndexDialog = parseInt(dialog.widget().css('zIndex'));
                zIndex = zIndexDialog >= zIndex ? zIndexDialog + 1 : zIndex;
            }
        });

        if (this.$overlay) {
            this.$overlay.css('zIndex', zIndex);
        }

        this.$dialog
            .css('zIndex', zIndex);
        // .focus();
    },

    close: function()
    {
        var self = this;

        if (this.isOpened()) {
            if (this._call(this.option('onBeforeClose')) === false) {
                return;
            }

            this.opened = false;
            var $elements = this.$dialog,
                zIndex = 0,
                nextDialog,
                dialogs = this.getOtherInstances();

            if (this.$overlay) {
                $elements = $elements.add(this.$overlay);
            }

            $elements
                .stop()
                .fadeOut({
                    duration: this.option('durationClose'),
                    easing: this.option('easingClose'),
                    complete: function() {
                        $(this).css('z-index', self.option('zIndex'));

                        webf.each(dialogs, function(i, dialog) {
                            if (dialog.isOpened()) {
                                var dialogZIndex = parseInt(dialog.widget().css('zIndex'));
                                if (dialogZIndex >= zIndex) {
                                    zIndex = dialogZIndex;
                                    nextDialog = dialog;
                                }
                            }
                        });

                        nextDialog && nextDialog.focus();
                    }
                });

            $('body').css(this.origBodyCss || {});

            this._call(this.option('onClose'));
        }
    },

    cancel: function()
    {
        this._call(this.option('onCancel'));
    },

    reduce: function()
    {
        this.minimize();
        this.reduced = true;
        this.$dialog.find('.box-body')
            .add(this.$dialog.find('.box-footer')).hide();
    },

    restore: function()
    {
        this.reduced = false;
        this.$dialog.find('.box-body')
            .add(this.$dialog.find('.box-footer')).show();

        webf.setTimeout(function () {
            !this.dragged && this._setPosition();
            this._call(this.option('onRestore'));
        }, 1, this);
    },

    maximize: function()
    {
        this.reduced && this.restore();
        this.maximized = true;
        this.$dialog.addClass('maximized');
        this.origBodyCss = {};

        this.$dialog.find('.box-header i.fa-window-maximize').removeClass('fa-window-maximize').addClass('fa-window-minimize');

        webf.each(['overflow-x','overflow-y'], function(i, prop) {
            this[prop.camelCase()] = webf.getStyle(document.body, prop);
        }, this.origBodyCss);

        $('body').css('overflow', 'hidden');

        this.option('resizable') && this.$dialog.webfResizable('disable');

        this._setBodyHeight();
        this.disableDragging();

        this._call(this.option('onMaximize'));
    },

    minimize: function()
    {
        this.maximized = false;

        this.$dialog.find('.box-body').css({
            height: 'auto'
        });

        this.$dialog
            .removeClass('maximized')
            .find('.box-header i.fa-window-minimize')
            .removeClass('fa-window-minimize').addClass('fa-window-maximize');

        $('body').css(this.origBodyCss || {});

        webf.setTimeout(function () {
            !this.dragged && this._setPosition();
        }, 1, this);

        this.option('resizable') && this.$dialog.webfResizable('enable');
        this.option('draggable') && this.enableDragging();

        this._call(this.option('onMinimize'));
    },

    widget: function()
    {
        return this.$dialog;
    },

    refreshPosition: function()
    {
        this._setPosition();
    },

    setTitle: function(title)
    {
        if (this.option('box')) {
            var $widget = this.widget();
            $widget.find('.box-header h3').html(title);
        }
    },

    isOpened: function() { return this.opened; },

    submitForm: function()
    {
        var $form = this.$dialog.find('form');

        if ($form[0]) {
            $form.trigger('submit');
        }
    },

    _destroy: function()
    {
        this.$overlay && this.$overlay.remove();
        this.$dialog.remove();
    }
});
