import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 */
$webf("switch", {
    options: {
        checkOnMousedown:     false,
        classWrapper:         ''
    },

    _create: function()
    {
        this.$webfSwitch = null;

        this._draw();
        this._bindEvents();
        this._initState();
    },

    _draw: function()
    {
        this.$webfSwitch = this.e.hide()
            .wrap(`<span class='webf-switch " ${this.option('classWrapper')}'></span>`)
            .parent().prepend($("<a>").addClass('checkbox'));
    },

    _initState: function()
    {
        this.enable(!this.e.prop('disabled'));
        this.check(this.e.prop('checked'), false, false);
    },

    _bindEvents: function()
    {
        var self = this,
            $elements = this.$webfSwitch;

        if (this.e.prop('id')) {
            var $label = $("label[for='" + this.e.prop('id') + "']");

            if ($label.length) {
                $elements = $elements.add($label);
            }
        }

        if (this.option('checkOnMousedown')) {
            this._on($elements, {
                mousedown: function(ev) {
                    if (!self.e.prop('disabled')) {
                        self.toggle();
                    }
                }
            });
        } else {
            this._on($elements, {
                mousedown: function(ev) {
                    if (!self.e.prop('disabled')) {
                        self.$webfSwitch.addClass('mousedown');
                    }
                },
                click: function(ev) {
                    ev.preventDefault();
                    if (!self.e.prop('disabled')) {
                        self.toggle();
                    }
                }
            })._on(document, {
                'mouseup mouseleave': function(ev) {
                    self.$webfSwitch.removeClass('mousedown');
                }
            });
        }
    },

    check: function(checked, trigger = true, call = false)
    {
        if (!checked) {
            this.$webfSwitch.removeClass('checked').addClass('unchecked');
            this.e.prop('checked', false);
            call && this.e.trigger('uncheck');
        } else {
            this.$webfSwitch.removeClass('unchecked').addClass('checked');
            this.e.prop('checked', true);
            call && this.e.trigger('check');
        }

        if (trigger) {
            this.e.trigger('change');
        }
    },

    uncheck: function(trigger, call)
    {
        this.check(false, trigger, call);
    },

    enable: function(enable)
    {
        enable = webf.isUndefined(enable) ? true : enable;

        this.e.prop('disabled', !enable);

        if (enable) {
            this.$webfSwitch.removeClass('disabled');
            this.e.trigger('enable');
        } else {
            this.$webfSwitch.addClass('disabled');
            this.e.trigger('disable');
        }
    },

    disable: function()
    {
        this.enable(false);
    },

    toggle: function(trigger)
    {
        this.check(!this.e.prop('checked'), trigger);
    },

    widget: function()
    {
        return this.$webfSwitch;
    },

    _destroy: function()
    {
        this.e.siblings().remove();
        this.e.unwrap().show();
    }
});

