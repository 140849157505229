import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * Options :
 *  - state:            (string) auto ou accordion, tabs
 *  - tabSelector:      (string) Sélecteur de l'onglet déclenchant l'ouverture des panneaux
 *  - activeTab:        (string|int|string[]|int[]) Onglet(s) ouvert(s) par défaut, peut être soit l'index du panel ou son nom
 *                      ou un tableau de nom ou d'indexes
 *  - orientation:      vertical ou horizontal. Ne s'applique que pour le state 'tabs'.
 *  - event:            (string) click
 *  - webfStyle:        (boolean|string) si string, doit correspondre avec un des styles de webf (primary, dark...)
 *  - animation:        (boolean|string) false ou 'slide'
 *  - animationQueue:   unused
 *  - duration:         (int) Durée de l'animation en ms
 *  - breakPoint:       (int) Largeur en dessous de laquelle un accordéon s'affiche et au dessus de laquelle c'est un
 *                      système d'onglets
 *  - speed:            (int) Vitesse de dfilement des onglets en px / s
 *  - collapsible:      (boolean) Autorise le fait que l'onglet courant soit fermé
 *  - startCollapsed:   (boolean) Tous les panneaux sont fermés à l'ouverture
 *  - multipleExpanded: (boolean) En mode Accordéon, si false : autorise l'ouverture de plusieurs onglets ouvert
 *  - onCreate:         (function($tab, $accordionTab, $panel)) Fonction appellée à la création du plugin
 *  - onOpen:           (function($tab, $accordionTab, $panel)) Fonction appellée à l'ouverture d'un panneau
 *  - onClose:          (function) Fonction appellée à la fermeture d'un panneau
 *  - onChangeState:    (function) Fonction appellée à lorsque le mode (accordéon ou onglets) change
 *
 * Méthodes publiques :
 *  - open:                     (string|int) Ouvre un onglet
 *  - close:                    (string|int) Ferme un onglet
 *  - getCurrentTab             Retourne l'onglet ouvert
 *  - getCurrentPanel           Retourne le ou les panneaux ouverts
 *  - getTab                    (string|int) Retourne un onglet
 *  - getTabs                   Retourne les onglets
 *  - getTabAccordion           (string|int) Retourne un onglet d'accordéon
 *  - getTabsAccordion          Retourne les onglets d'accordéon
 *  - getPanel                  (string|int) Retourne un panneau
 *  - getPanels                 Retourne les panneaux
 */
$webf('horizontaltabs', {
    options: {
        // state:              'auto',
        tabSelector:        'li',
        activeTab:          0,
        // orientation:        'horizontal',
        event:              'click',
        webfStyle:          'primary',
        animation:          false,
        animationQueue:     false,
        duration:           500,
        speed:              450,
        collapsible:        true,
        startCollapsed:     false,
        multipleExpanded:   false,
        onCreate:           webf.noop,
        onOpen:             webf.noop,
        onClose:            webf.noop,
        onChangeState:      webf.noop
    },

    _create: function()
    {
        this.$ul = null;
        this.$queue = $({});

        this._initTabs();
        this._bindEvents();

        this._call(this.option('onCreate'), this.getCurrentTab(), this.getCurrentPanel());
    },

    _initTabs: function()
    {
        // var self = this;
        if (this.e[0].tagName == 'UL') {
            this.$ul = this.e.wrap('<div class="webf-horizontal-tabs-wrapper"></div>');
        } else {
            this.$ul = this.e
                .addClass('webf-horizontal-tabs-wrapper')
                .children('ul');
        }
        this.$ul.addClass('webf-horizontal-tabs');
        this.$ul.children(this.option('tabSelector')).addClass('webf-horizontal-tab');

        setTimeout(this._setNavigation.bind(this), 50);

        var $panels = this.getPanels().addClass('webf-panel'),
            webfStyle = this.option('webfStyle');

        $panels.wrapAll('<div class="webf-panels"></div>');

        if (webfStyle) {
            $panels.addClass('webf-style webf-panel-' + webfStyle);

            this.$ul
                .addClass('webf-style webf-horizontal-tabs-' + webfStyle)
                .children(this.option('tabSelector')).addClass('webf-horizontal-tab webf-horizontal-tab-' + webfStyle);
        }

        let activeTabs = this.option('activeTab');
        if (!webf.isArray(activeTabs)) {
            activeTabs = [activeTabs];
        }

        if (this.option('multipleExpanded') === false) {
            activeTabs = [activeTabs[0]];
        }

        this.getPanels().hide();

        if (this.option('startCollapsed') === false) {
            webf.each(activeTabs, (i, activeTab) => {
                const $tab = this.getTab(activeTab);
                const $panel = this.getPanel(activeTab);

                $tab.addClass('webf-horizontal-tab-active webf-horizontal-tab-opened');
                $panel.show();
            })
        }
    },

    _bindEvents: function()
    {
        // var self = this,
        const activateHandler = {};

        activateHandler[this.option('event')] = (ev) => {
            const $handler = $(ev.currentTarget);
            ev.preventDefault();
            const $tab = $handler,
                $a = $tab.children('a');

            if ($tab.hasClass('webf-horizontal-tab-opened')) {
                if (this.option('collapsible')) {
                    this.close($a.data('tab'), false);
                }
            } else {
                var $currentTab = this.getCurrentTab();

                if (!this.option('multipleExpanded')) {
                    if ($currentTab[0]) {
                        this.close($currentTab.children('a').first().data('tab'));
                    }
                }

                this.open($a.data('tab'));
            }
        };

        this._on(this.$ul, this.option('tabSelector'), activateHandler);

        this._on(window, {
            resize: webf.throttle((ev) => {
                this._setNavigation()
            }, 50, true, true)
        });

        let intervalNavigate;
        const speed = this.option('speed');

        this
            ._on(this.e, '.webf-btn-start', {
                'mousedown touchstart': (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const $btn = $(ev.currentTarget);
                    const $btnEnd = this.e.find('.webf-btn-end');
                    $btnEnd.show();

                    intervalNavigate = setInterval(() => {
                        let marginValue = parseFloat(webf.getStyle(this.$ul[0], '--webf-horizontal-margin-left')) + (speed / 100);

                        if (marginValue > 0) {
                            marginValue = 0;
                            $btn.hide();
                        }

                        this.$ul[0].style.setProperty('--webf-horizontal-margin-left', marginValue + 'px');
                    }, 10)
                }
            })
            ._on(this.e, '.webf-btn-end', {
                'mousedown touchstart': (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const $btn = $(ev.currentTarget);
                    const $btnStart = this.e.find('.webf-btn-start');
                    $btnStart.show();

                    intervalNavigate = setInterval(() => {
                        const $wrapper = this.e.closest('.webf-horizontal-tabs-wrapper');
                        const tabsWidth = parseFloat(webf.getStyle(this.$ul[0], '--webf-horizontal-tabs-width'));
                        let marginValue = parseFloat(webf.getStyle(this.$ul[0], '--webf-horizontal-margin-left')) - (speed / 100);

                        if (tabsWidth + marginValue <= $wrapper.outerWidth()) {
                            marginValue = $wrapper.width() - tabsWidth;
                            $btn.hide();
                        }

                        this.$ul[0].style.setProperty('--webf-horizontal-margin-left', marginValue + 'px');
                    }, 10)
                }
            })
            ._on(document, {
                'mouseup touchend': (ev) => {
                    clearInterval(intervalNavigate)
                    intervalNavigate = null;
                }
            })
    },

    _setNavigation: function()
    {
        const $wrapper = this.e.closest('.webf-horizontal-tabs-wrapper');
        const wrappperWidth = $wrapper.outerWidth();
        const initialValue = 0
        let tabsWidth = this.$ul.find('.webf-horizontal-tab a').map((i, tab) => {
            return $(tab).outerWidth();
        }).get().reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
        tabsWidth = Math.max(wrappperWidth, tabsWidth + 60);

        if (!$wrapper.children('.webf-btn-end')[0]) {
            $wrapper.append('<div class="webf-btn-start"><i class="fas fa-chevron-left"></i></div>');
            $wrapper.append('<div class="webf-btn-end"><i class="fas fa-chevron-right"></i></div>');
        }

        this.$ul[0].style.setProperty('--webf-horizontal-tabs-width', tabsWidth + 'px');
        $wrapper[0].style.setProperty('--webf-horizontal-btn-size', this.$ul.outerHeight() + 'px');

        const $btnStart = this.e.find('.webf-btn-start').show();
        const $btnEnd = this.e.find('.webf-btn-end').show();

        let marginValue = parseFloat(webf.getStyle(this.$ul[0], '--webf-horizontal-margin-left'));

        if (tabsWidth + marginValue <= $wrapper.outerWidth()) {
            marginValue = $wrapper.width() - tabsWidth;
            $btnEnd.hide();
        }

        if (marginValue >= 0) {
            $btnStart.hide();
        }

        this.$ul[0].style.setProperty('--webf-horizontal-margin-left', marginValue + 'px');
    },

    _doTransition: function($tab, method, dequeue, callback)
    {
        var $panel = this.getPanel($tab.children('a').data('tab')),
            animation = this.option('animation'),
            duration = this.option('duration'),
            animOpen, animClose;

        switch (animation) {
            case 'slide':
                animOpen = 'slideDown';
                animClose = 'slideUp';
                break;

            default:
                animOpen = 'show';
                animClose = 'hide';
                duration = 0; break;
        }

        $panel[method == 'open' ? animOpen : animClose](duration, callback);
    },

    open: function(n)
    {
        const $tab = this.getTab(n);
        const $panel = this.getPanel(n);

        if ($tab[0]) {
            this._doTransition($tab, 'open', this.option('animationQueue'), () => {
                this._call(this.option('onOpen'), $tab, $panel);
            });

            $tab.addClass('webf-horizontal-tab-active webf-horizontal-tab-opened');
        }
    },

    close: function(n, dequeue)
    {
        const $tab = this.getTab(n);
        const $panel = this.getPanel(n);

        if ($tab[0]) {
            this._doTransition($tab, 'close', webf.isUndefined(dequeue) ? this.option('animationQueue') : dequeue, () => {
                this._call(this.option('onClose'), $tab, $panel);
            });

            $tab.removeClass('webf-horizontal-tab-opened webf-horizontal-tab-active');
        }
    },

    getCurrentTab: function()
    {
        return this.$ul.children('li.webf-horizontal-tab-opened');
    },

    getCurrentPanel: function()
    {
        var $tab = this.getCurrentTab(),
            tabName = $tab.children('a').data('tab');

        return this.getPanel(tabName);
    },

    getTab: function(n)
    {
        const $tabs = this.getTabs();
        let $tab;

        if (webf.isInteger(n)) {
            $tab = $tabs.eq(n);
        } else {
            $tab = $tabs.filter(function() {
                return ($(this).children('a').first().data('tab') == n);
            });
        }

        return $tab.closest(this.option('tabSelector'));
    },

    getTabs: function()
    {
        return this.$ul.children(this.option('tabSelector'));
    },

    getPanel: function(n)
    {
        var $panels = this.getPanels(),
            $panel;

        if (webf.isInteger(n)) {
            $panel = $panels.eq(n);
        } else {
            $panel = $panels.filter(function() {
                return ($(this).prop('id') == n);
            });
        }

        return $panel;
    },

    getPanels: function()
    {
        return this.e.find($.makeArray((this.getTabs().map(function(i, tab) {
            return "#" + $(tab).children('a').data('tab');
        }))).join(', '));
    },

    refreshTabs: function()
    {
        this._setNavigation()
    },

    _destroy: function()
    {
        this.$ul.removeClass('webf-horizontal-tabs');

        var $panels = this.getPanels().removeClass('webf-panel'),
            webfStyle = this.option('webfStyle') || '';

        $panels.removeClass('webf-panel webf-style webf-panel-' + webfStyle);

        this.$ul
            .removeClass('webf-style webf-horizontal-tabs-' + webfStyle)
            .children('li')
            .removeClass('webf-horizontal-tab webf-horizontal-tab-active webf-horizontal-tab-opened webf-horizontal-tab-accordion webf-horizontal-tab-' + webfStyle)
            .show();
    }
});
