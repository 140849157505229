import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.date = {
        monday: 'lundi',
        tuesday: 'mardi',
        wednesday: 'mercredi',
        thursday: 'jeudi',
        friday: 'vendredi',
        saturday: 'samedi',
        sunday: 'dimanche',

        mon: 'lun',
        tue: 'mar',
        wed: 'mer',
        thu: 'jeu',
        fri: 'ven',
        sat: 'sam',
        sun: 'dim',

        mo: 'Lu',
        tu: 'Ma',
        we: 'Me',
        th: 'Je',
        fr: 'Ve',
        sa: 'Sa',
        su: 'Di',

        january: 'janvier',
        february: 'février',
        march: 'mars',
        april: 'avril',
        may: 'mai',
        june: 'juin',
        july: 'juillet',
        august: 'août',
        september: 'septembre',
        october: 'octobre',
        november: 'novembre',
        december: 'décembre',

        jan: 'janv.',
        feb: 'fév.',
        mar: 'mars',
        apr: 'avr.',
        'may.': 'mai',
        jun: 'juin',
        jul: 'juil.',
        aug: 'août',
        sept: 'sept.',
        oct: 'oct.',
        nov: 'nov.',
        dec: 'déc.'
    };
})(webf);

