import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.richtexteditor = {
        undo: 'Annuler',
        redo: 'Rétablir',
        heading: 'Titre',
        font: 'Police de caractères',
        fontsize: 'Taille',
        fontsizepx: 'Taille',
        bold: 'Gras',
        italic: 'Italique',
        underline: 'Souligné',
        strikethrough: 'Barré',
        subscript: 'Indice',
        superscript: 'Exposant',
        text_color: 'Couleur du texte',
        backcolor: 'Couleur d\'arrière plan du texte',
        alignleft: 'Aligner à gauche',
        aligncenter: 'Centrer',
        alignright: 'Aligner à droite',
        alignjustify: 'Justifer',
        link: 'Ajouter un lien',
        unlink: 'Supprimer un lien',
        insertimage: 'Insérer une image',

        'add a link': 'Ajouter un lien',
        'text of link': 'Texte du lien',
        anchor: 'Ancre',
        invalid_url: 'L\'URL est invalide',
        url_link: 'URL du lien',
        open_in_new_tab: 'Open in new tab',

        'import': 'Importer',
        'insert image' : 'Insérer une image',
        upload_image: 'Uploader une image',
        from_url: 'À partir d\'une URL',
        'drag here': 'Faîtes glisser une image ici.',
        'you can also': 'Vous pouvez aussi',
        'select image to import': 'Sélectionner l\'image à importer',
        paste_url_image: 'Coller l\'URL de l\'image ici :',
        overview_image_from_url: 'Si l\'URL est correcte, vous en verrez un aperçu ici',
        'no image found': 'Aucune image trouvée à cette adresse',
        loading: 'Chargement en cours',
        validate: 'Valider',
        cancel: 'Annuler',
        unordered_list: 'Liste non ordonnée',
        ordered_list: 'Liste numérotée',
        indent: 'Incrémenter',
        outdent: 'Décrémenter',
        hr: 'Ligne horizontale',
        table: 'Tableau',
        tableProperties: 'Propriétés du tableau',
        columnSize: 'Largeur de la colonne',
        rowSize: 'Hauteur de la ligne',
        verticalAlignment: 'Alignement vertical',
        alignTop: 'Haut',
        alignMiddle: 'Centré',
        alignBottom: 'Bas',
        alignUndefined: 'Indéfini'
    };
})(webf);

