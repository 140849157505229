import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/datagrid'
import eventDispatcher from '../utils/eventDispatcher'
import Header from './datagrid/Header'
import Toolbar from './datagrid/Toolbar'
import Footer from './datagrid/Footer'
import Columns from './datagrid/Columns'
import Grid from './datagrid/Grid'
import $ from 'jquery'

/**
 * options:
 *  - columns:              (Column[]) Définition des colonnes du datagrid
 *  - data:                 (string|function|Record[]) Données alimentant le datagrid
 *                          Si string : il s'agit alors d'une URL, qui sera appelée pour récupérer les données à jour chaque fois que nécessaire
 *                          Cette URL doit retourner un objet JSON avec les clés records contenant des enregistrements,
 *                          count un entier représentant le nb. d'enregistrements et footer avec un enregistrement. Seule la clé records est requise
 *                          Si function : La fonction est appelée au chargement des données avec en argument une fonction qui doit être exécutée par l'implémenteur
 *                          avec les enregistrements dans les clés records, count et footer.
 *                          exemple :
 *                          data: function(func) {
 *                              func({
 *                                  records: [
 *                                      {id: 1, field1: 'record value', ...}
 *                                  ],
 *                                  count: 10,
 *                                  footer: {id: -1, field1: 'footer value'}
 *                              });
 *                          }
 *                          Si Array : Les données peuvent être passées directement sous forme de tableau de Record
 *  - method:               (string) Verbe HTTP utilisé pour récupérer les données si data est une URL.
 *  - urlParams:            (object) Si data est une URL, spécifie les paramètres supplémentaires à envoyer
 *  - header:               (object|boolean) Définit le header du datagrid
 *                          Si false : pas de header
 *                          Si Object : les clés possibles sont :
 *                           - title
 *  - toolbar:              (object) Définit la toolbar. Les valeurs possibles sont :
 *                           - refresh
 *                           - search
 *                           NB : C'est en état expérimental, donc ne pas à utiliser en production
 *  - footer:               (Record[])
 *  - minWidth:             (integer) Largeur minimale en px des colonnes lors du redimensionnement
 *  - displayTopScrollbar:  (bool) Si true, Affiche une scollbar horizontale sur le haut du datagrid. Attention, des
 *                          ralentissements sont à prévoir à cause de l'émulation de cette scrollbar
 *  - useLocalStorage:      (boolean) Si true, le redimensionnement des colonnes et la sélection des colonnes depuis la toolbar
 *                          sont conservées dans le LocalStorage afin de conserver l'état du datagrid pendant la navigation.
 *                          Attention : l'élément sur lequel s'applique le datagrid doit avoir un id.
 *  - sortByUrl:            (boolean) Si true et si data est une URL, les tris se feront en appelant l'URL, sinon, les tris
 *                          se feront côté client par le plugin
 *  - infiniteScroll:       (boolean) Si true, le chargement des records suivants se fera lors du scroll vers les derniers éléments.
 *  - onFirstLoad:          (function(records, count, datas)) Fonction appelée une seule fois au premier chargement des données
 *  - onLoad:               (function(records, count, datas)) Fonction appelée à chaque fin de chargement des données
 *  - onClick:              (function) Fonction appelée lors du clic sur une ligne d'enregistrement
 *  - onDblClick:           (function) Fonction appelée lors du double clic sur une ligne d'enregistrement
 *  - onSelect:             (function(selectedRecords, htmlElementsSelectedRecords)) Fonction appelée lors de la sélection de la ligne d'un enregistrement
 *  - onSearch:             (function) Fonction appelée lorsque l'input text de la toolbar change
 *  - onSort:               (function) Fonction appelée après un tri
 *  - onRender:             (function) Fonction appelée après le rendu des records du viewport
 *  - onChangeColumns:      (function(columns)) Fonction appelée lorsque les colonnes affichées sont mises à jour depuis
 *                          le composant de la toolbar
 *  - onResizeColumns:      (function(columns)) Fonction appelée lorsqu'une colonne a été redimensionnée
 *  - onUpdateColumns:      (function(columns)) Fonction appelée lors d'un onChangeColumns ou onResizeColumns
 *  - onEditRecord:         (false|string|function(Record record, Column column, oldValue, value)) Comportement à adopter
 *                          lorsqu'un record est modifié.
 *                           - false : Aucun traitement
 *                           - function : cf. signature de la fonction
 *                           - string : Traité comme la clé url de la structure EditRecordOptions
 *                           - object : cf. structure EditRecordOptions
 *
 * Méthodes publiques :
 *  - selectedRecords:      Retourne la liste des enregistrements actuellement sélectionnés
 *  - selectRecords:        (Record|Record[] record) Sélectionne les enregistrement spécifiés
 *  - getRecords:           Retourne tous les enregistrements visibles
 *  - add:                  (Record|Record[] record) Ajoute les enregistrements au datagrid
 *  - remove:               (Record|Record[] record) Supprime les enregistrements du datagrid
 *  - update:               (Record|Record[] record) Met à jour les enregistrements
 *  - sort:                 ((string) field, (string) direction) Ordonne les résultats en asc ou desc
 *  - refresh:              Refraîchit le datagrid
 *  - unSelect:             (Record|Record[]|undefined) Déselectionne les records
 *
 * Structures:
 *  Column: {
 *    - field:          (string) Nom du champ
 *    - caption:        (string) Intitule de la colonne
 *    - size:           (string) Largeur en px par défaut de la colonne
 *    - columnClass:    (string) Classes CSS à ajouter à la colonne. Chaque cellule de valeur aura ces classes
 *    - type:           (string) Spécifie le type de valeurs de la colonne pour le tri
 *                      Valeurs possibles :
 *                       - money
 *                       - text
 *                       - number
 *                       - date
 *                       - datetime
 *                       - webfDate
 *                       - webfDatetime
 *    - choices:        (Object) Si la colonne est éditable et le type est select, définit la liste des éléments de la liste
 *                      déroulante :
 *                      { value: 'label', value1 : 'label1' }
 *    - format:         (string) Si la colonne est éditable et le type est webDate ou webfDatetime, définit le format de la date
 *                      Utilisé aussi pour spécifier le format de tri par date ou money
 *    - sort:           (boolean|function)
 *                      Si true : la colonne peut être ordonnée par l'utilisateur.
 *                      Si function : Lors d'un clic sur la colonne, cette fonction est appelée avec les paramètres suivants :
 *                      function(rec1, rec2, field, direction)
 *                       - rec1 : Enregistrement 1
 *                       - rec2 : Enregistrement 2
 *                       - field : Nom de la colonne à ordonner
 *                       - direction : asc ou desc
 *    - sortKey:        (string) Si le tri se fait côté serveur, définit le nom de la clé envoyé si différente de field
 *    - render:         (function(field, record)) Fonction appellée pour effectuer le rendu dans la cellule. Si la fonction
 *                      n'est pas fléchée, this est l'objet record traité.
 *    - visible:        (boolean) Colonne affichée ou non
 *    - editable:       (boolean) Champs de cette colonne modifiable ou non
 *  }
 *
 *  Record: {
 *    - id:             Identifiant unique de l'enregistrement. Dans le cas d'un record de footer, mettez -1
 *    - <column field>: Clé de l'enregistrement doivent correspondre au nom des colonnes
 *  }
 *
 *  EditRecordOptions : {
 *    - url:            (string) La chaîne sera traitée comme une URL. Si la chaine comprend le token :id, ce dernier sera
 *                      remplacé par la valeur de l'id du record.
 *    - method:         (string) Verbe HTTP utilisé pour faire l'appel. POST, GET, PUT etc...
 *    - params:         (Object) Paramètres supplémentaires à envoyer lors de l'appel HTTP.
 *    - recordKey:      (string) clé du record dans l'appel HTTP
 *    - done:           (function(datas)) Fonction appelée après que l'appel HTTP a été effectué.
 *  }
 */
webf.Datagrid = {};

const defaultEditRecordOptions = {
    recordKey:  'record',
    method:     'POST',
    url:        '/record/:id',
    params:     {},
    done:       webf.noop
};

$webf('datagrid', {
    options: {
        columns:                [],
        data:                   {
            records: []
        },
        method:                 'GET',
        urlParams:              {},
        header:                 false,
        toolbar:                false,
        footer:                 false,
        minWidth:               30,
        sortByUrl:              false,
        displayTopScrollbar:    true,
        useLocalStorage:        true,
        infiniteScroll:         false,
        onFirstLoad:            webf.noop,
        onLoad:                 webf.noop,
        onClick:                webf.noop,
        onDblClick:             webf.noop,
        onSelect:               webf.noop,
        onSearch:               webf.noop,
        onSort:                 webf.noop,
        onRender:               webf.noop,
        onBeforeLoad:           webf.noop,
        onChangeColumns:        webf.noop,
        onResizeColumns:        webf.noop,
        onUpdateColumns:        webf.noop,
        onEditRecord:           defaultEditRecordOptions
    },

    editableOptions: {
        data: function(data) {
            this.options.data = data;
        },
        urlParams: function(urlParams) {
            this.options.urlParams = webf.extend(this.options.urlParams, urlParams);
        }
    },

    _create: function()
    {
        this.loading = true;
        this.noNewRecord = false;
        this._drawDatagrid();
        this._bindEvents();

        const onRender = this.option('onRender');

        this.options.onRender = () => {
            this._adjust();
            this._call(onRender);
        };
    },

    _drawDatagrid: function()
    {
        this.header = new Header(this, this.option('header'));
        this.footer = new Footer(this, this.option('footer'));
        this.toolbar = new Toolbar(this, this.option('toolbar'));
        this.columns = new Columns(this, this.option('columns'));
        this.grid = new Grid(this);

        const $datagrid = this.e.addClass('webf-datagrid');
        const $header = this.header.render();
        const $columns = this.columns.render();
        const $toolbar = this.toolbar.render();
        const $grid = this.grid.render();
        const $footer = this.footer.render();

        $datagrid
            .append($header)
            .append($toolbar)
            .append($columns)
            .append($grid)
            .append($footer);

        this.e.append($datagrid);

        this.grid.renderRecords();
        this.grid.loadRecords();
    },

    _bindEvents: function()
    {
        this.toolbar.bindEvents();
        this.columns.bindEvents();
        this.grid.bindEvents();

        this._on(window, {
            resize: webf.throttle(() => {
                this._adjust();
            }, 50, true)
        });

        eventDispatcher.addListener('datagrid.updateSelectColumns.' + this.hash, (eventName, orderedFields = null, selectedFields = null) => {
            const columns = this.columns
                .updateVisibleColumns(selectedFields)
                .updateOrderColumns(orderedFields)
                .getAllColumns();

            this.option('useLocalStorage') && this._saveColumnsState();

            const $columns = this.columns.render();
            const $grid = this.grid.render();
            const $footer = this.footer.render();

            this.e.children('.columns').replaceWith($columns);
            this.e.children('.wrapper-grid').replaceWith($grid);
            this.e.children('.footer').replaceWith($footer);

            this.grid.renderRecords();
            this.grid.bindEvents();

            this._call(this.option('onUpdateColumns'), columns);
            this._call(this.option('onChangeColumns'), columns);
        });

        eventDispatcher.addListener('datagrid.resizeColumns.' + this.hash, (eventName, columns) => {
            this.option('useLocalStorage') && this._saveColumnsState();

            this._call(this.option('onUpdateColumns'), columns);
            this._call(this.option('onResizeColumns'), columns);
        });

        eventDispatcher.addListener('datagrid.editRecord.' + this.hash, (eventName, record, column, value, oldValue, elemRecord) => {
            let editRecordOptions = this.option('onEditRecord');

            if (editRecordOptions !== false) {
                if (webf.isString(editRecordOptions)) {
                    editRecordOptions = webf.extend(true, defaultEditRecordOptions, {
                        url: editRecordOptions
                    });
                } else if (webf.isFunction(editRecordOptions)) {
                    return editRecordOptions(record, column, oldValue, value, elemRecord);
                }

                $.ajax({
                    url: editRecordOptions.url.replace(':id', record.id),
                    data: webf.extend(true, {[editRecordOptions.recordKey]: record}, editRecordOptions.params ? editRecordOptions.params : {}),
                    type: editRecordOptions.method ? editRecordOptions.method : 'POST',
                    dataType: 'json'
                }).done(editRecordOptions.done);
            }
        });
    },

    selectedRecords: function()
    {
        return this.grid.getSelectedRecords();
    },

    selectRecords: function(records)
    {
        this.grid.selectRecords(records);

        const htmlElementsSelectedRecords = webf.map(this.selectedRecords(), (i, selectdRecord) => {
            if (this.grid._getUIRecord(selectdRecord)) {
                return this.grid._getUIRecord(selectdRecord)[0];
            }
        });

        this._call(this.option('onSelect'), this.selectedRecords(), htmlElementsSelectedRecords);
    },

    getRecords: function()
    {
        return this.grid.getRecords();
    },

    count: function()
    {
        return this.grid.count;
    },

    add: function(records)
    {
        this.grid.add(records);
    },

    remove: function(records)
    {
        this.grid.remove(records);
        this.footer.remove(records);
    },

    update: function(records)
    {
        this.grid.update(records);
        this.footer.update(records);
    },

    sort: function(field, direction)
    {
        const $grid = this.e.find('.grid');
        const scrollLeft = $grid.scrollLeft();

        this.grid.sort(field, direction);

        $grid.scrollLeft(scrollLeft);

        this._call(this.option('onSort'), field, direction);
    },

    search: function(val)
    {
        this.grid.search(val);
    },

    refresh: function()
    {
        if (!this.loading) {
            this.grid.remove();
            this.loading = true;

            this.grid._load((datas) => {
                this.grid.renderRecords();
                this._adjust();
                this.loading = false;
                this._call(this.option('onLoad'), this.getRecords(), this.count(), datas);
            });
        }
    },

    getRecordById: function(id)
    {
        return this.grid.getRecordById({id: id});
    },

    unSelect: function(records)
    {
        this.grid.unSelect(records);
        this.grid.renderSelectedRecords();
    },

    getAllColumns: function()
    {
        return this.columns.getAllColumns();
    },

    getVisibleColumns: function()
    {
        return this.columns.getVisibleColumns();
    },

    _infiniteScroll: function()
    {
        if (this.option('infiniteScroll')) {
            if (!this.loading && !this.noNewRecord) {
                this.loading = true;

                this.grid._load(() => {
                    this.grid.renderRecords();
                    this._adjust();
                    this.loading = false;
                    this._call(this.option('onLoad'), this.getRecords(), this.count(), this.datas);
                });
            }
        }
    },

    _adjust: function()
    {
        this.columns.adjust();
        this.footer.adjust();
        this.grid.adjust();
    },

    _saveColumnsState: function()
    {
        const id = this.e.prop('id');

        if (!id) {
            window.console.error("L'option useLocalStorage nécessite que l'élément du datagrid ait un id");
        }

        localStorage.setItem('webf.datagrid.columns.' + id, JSON.stringify(this.getAllColumns()));
    },

    _destroy: function()
    {
        this.e.empty();
        this.e.removeClass('webf-datagrid');

        eventDispatcher.removeListener('datagrid.updateSelectColumns.' + this.hash);
        eventDispatcher.removeListener('datagrid.resizeColumns.' + this.hash);
    }
});

