import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/ajaxform'
import './alert'
import $ from 'jquery'

/**
 * options:
 *  - url:              (string) url où envoyer le formulaire
 *  - data:             (Object) Données en plus à envoyer
 *  - exclude:          (string[]) Tableau d'éléments référencés par leur name ou un sélecteur ne devant pas être envoyés
 *  - validators:       (function(value, data, $e)|string)
 *                                      Référence les champs à valider. Les valeurs peuvent être soit des fonctions
 *                                      qui retourne le message d'erreur si l'élément est invalide, soit la chaine 'required'
 *                                      ex: nom: "required", email: function(value, data) { return isMailValid(value || data.value); }
 *  - filters:          (Object) Liste des filtres à appliquer aux données
 *  - required:         (string) Tableau d'éléments référencés par leur name qui doivent passer par le filtre required
 *  - formErrors:       (function(errors))
 *                      Si au moins un des validateurs a retourné une valeur, cette fonction est appelée avec les erreurs en argument
 *                      et le formulaire n'est pas envoyé.
 *  - method:           (string) Méthode d'envoi (post, get...)
 *  - dataType:         (string) Type de retour attendu (json, xml...)
 *  - preventDblClick:  (boolean) Protection contre les double clics
 *  - forceIframe:      (boolean) Force l'utilisation des iframes pour la soumission du formulaire même si
 *                      le navigateur peut utiliser l'objet XMLHttpRequest level 2
 *  - debug:            (boolean) Ajoute quelques éléments pour débugger le plugin
 *  - onBeforeValidate: (function()) Fonction appelée avant que la validation ne soit effectuée.
 *                      Si cette fonction retourne false, la validation par le plugin est annulé.
 *  - onBeforeSubmit:   (function(datas))
 *                      Fonction appelée juste avant que le formulaire soit envoyé, datas représente la tableau de données
 *                      qui sera envoyé.
 *                      Le tableau datas peut être modifiés à cet endroit.
 *                      Si cette fonction retourne false, l'envoi est annulé.
 *  - beforeSend:       (function(event))
 *  - onSuccess:        (function(datas))
 *                      Fonction appelée lorsque le serveur a répondu. datas contient la réponse du serveur dans le format
 *                      spécifié par l'option dataType
 *  - onProgress:       (function(event, loaded, total)
 *                      Fonction appelée pendant la progression de l'upload, si le formulaire est en multipart/form-data
 *                      ou qu'il y a des fichiers à uploader
 *  - progressKeyName:  (string) Nom de la clé à utiliser pour la progression avec APC ou le système de sessions
 *  - urlProgress:      (string) Url à appeler pour connaitre le nb
 *  - freqProgress:     (int) Temps en ms entre chaque appel à urlProgress
 *  - onError:          (function($input, label, message))
 *                      Fonction appelée lorsqu'une erreur sur un champ du formulaire est détectée
 *                      Si false est retournée, le comportement par défaut d'afficher l'erreur sous forme de webfAlert du
 *                      plugin est annulé
 *  - stopOnError:      (boolean) Si true, stoppe la validation dès qu'une erreur est rencontrée
 *                      Si false, toutes les erreurs sont transmises au hook onError
 *
 * Méthodes:
 *  - submit()              Soumet le formulaire
 *  - abort()               Annule la requête en cours
 *  - addData(name, value)  Ajoute des données à envoyer au moment du submit du formulaire
 */
$webf('ajaxform', {
    options: {
        url:                '',
        method:             '',
        dataType:           'json',
        data:               {},
        exclude:            [],
        required:           [],
        validators:         {},
        filters:            {},
        preventDblClick:    true,
        forceIframe:        false,
        debug:              false,
        download:           false,
        /*-- <=IE8 --*/
        progressEntryName:  'APC_UPLOAD_PROGRESS',
        progressKeyName:    'progress_key',
        urlProgress:        '',
        freqProgress:       1000,
        /*-----------*/
        formErrors:         webf.noop,
        onValidate:         webf.noop,
        onBeforeValidate:   webf.noop,
        beforeSubmit:       webf.noop,
        onSuccess:          webf.noop,
        onProgress:         webf.noop,
        onAbort:            webf.noop,
        beforeSend:         webf.noop,
        onError:            webf.noop,
        stopOnError:        true
    },

    editableOptions: {
        url: function(url) {
            this.options.url = url;
        }
    },

    _create: function()
    {
        this.datas = {};
        this.extradatas = this.option('data');
        this.method = this.option('method') || this.e.prop('method') || 'get';
        this.submited = false;
        this.formErrors = null;
        this.elemsBrackets = {};

        if (this.option('onBeforeSubmit')) {
            this.options.beforeSubmit = this.option('onBeforeSubmit');
        }

        this.e.attr('novalidate', 'novalidate');

        this._bindEvents();
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.e, {
            submit: function(ev) {
                self._ajaxSubmit(ev);
            }
        });
    },

    _ajaxSubmit: function(ev)
    {
        ev.preventDefault();

        if (this.option('preventDblClick') && this.submited) {
            return;
        }

        if (this.xhr && this.submited) {
            this.xhr.abort();
            this._call(this.option('onAbort'));
        }

        this.elemsBrackets = {};
        this.datas = this._getFormDatasArray();

        if (this._validate() && this._filters() && this._call(this.option('beforeSubmit'), this.datas) !== false) {
            this._send();
        }
    },

    _validate: function()
    {
        if (this._call(this.option('onBeforeValidate'), this.datas) === false) {
            return true;
        }

        this.formErrors = {};
        var self = this;

        const reqValidator = (elem) => {
            if (elem === '' || (webf.isArray(elem) && !elem.length) || elem === null) {
                return this._('required', this.name);
            }
        }

        const validators = {};
        webf.each(this.option('required'), function(i, name) {
            this[name] = reqValidator;
        }, validators);

        webf.each(webf.extend(validators, this.option('validators')), (name, validator) => {
            if (validator === 'required') {
                validator = reqValidator;
            }

            webf.each(this.datas, (i, data) => {
                if (data.name === name) {
                    const msg = validator.call(data, data.value, data, this) || null;
                    if (msg !== null) {
                        this.formErrors[name] = msg;
                    }

                    return false;
                }
            });
        });

        if (webf.sizeOf(this.formErrors)) {
            this._call(this.option('formErrors'), this.formErrors);
            return false;
        }

        var $form = this.e,
            hasError = false;

        $form.find('.has-error').removeClass('has-error');

        $form.find(':input').each((i, input) => {
            const $input = $(input);
            let readonly = false;

            if ($input.attr('readonly')) {
                readonly = true;
                $input.attr('readonly', false);
            }

            if (webf.inArray($input.attr('name'), this.option('exclude'))) {
                return true;
            }

            let ignoreInput = false;
            webf.each(this.option('exclude'), (i, selector) => {
                if ($input.filter(selector)[0]) {
                    ignoreInput = true;
                }
            });

            if (ignoreInput) return true;

            if ((!hasError || !this.option('stopOnError')) && input.checkValidity && !input.checkValidity()) {
                let $container = $input.closest('.form-group');

                if ($input.closest('.webf-md-text')[0]) {
                    $container = $input.closest('.webf-md-text');
                } else if ($input.closest('.webf-md-textarea')[0]) {
                    $container = $input.closest('.webf-md-textarea');
                } else if ($input.closest('.webf-md-select')[0]) {
                    $container = $input.closest('.webf-md-select').find('.webf-md-text');
                }

                $container.addClass('has-error');
                let label = $container.find('label').first().text();

                if (!label.length) {
                    let $label = $form.find("label[for='" + $input.attr('id') + "']");

                    if ($input.attr('id') && $label[0]) {
                        label = $label.text();
                    } else if ($input.data('labelError')) {
                        label = $input.data('labelError');
                    } else if ($input.hasClass('webf-input-autocomplete')) {
                        const $hiddenInput = $input.closest('.wrapper-webf-aucocomplete').siblings('input[type="hidden"]').first();
                        $label = $form.find("label[for='" + $hiddenInput.attr('id') + "']");
                        label = $label.text();
                    } else {
                        label = $input.attr('name');
                    }
                }

                if (this._call(this.option('onError'), $input, label, input.validationMessage) !== false) {
                    const id = $input.attr('id');

                    if (!($(`#error-${id}`)[0])) {
                        $(`<div id="error-${id}"><b>${label} : </b>${input.validationMessage}</div>`).webfAlert({
                            alertType: 'danger',
                            placement: 'center',
                            autoClose: true,
                            delayClose: 8000,
                            durationClose: 1000
                        });
                    }
                }

                hasError = true;
            }

            if (readonly) {
                $(this).attr('readonly', true);
            }
        });

        return !hasError;
    },

    _filters: function()
    {
        webf.each(this.option('filters'), function(name, filter) {
            webf.each(this, function(i, data) {
                if (data.name == name) {
                    this[i].value = filter.call(data, data.value, this);
                }
            }, this);
        }, this.datas);

        return true;
    },

    _send: function()
    {
        this.submited = true;

        let ajaxOptions = {
            url: this._getUrl(),
            error: () => {
                this.submited = false;
                this.xhr = null;
            },
            beforeSend: (jqXhr, ajaxOptions) => {
                this._call(this.option('beforeSend'), jqXhr, ajaxOptions);
            },
            success: (datas, status, xhr) => {
                this._call(this.option('onSuccess'), datas, status, xhr);
                this.submited = false;
                this.xhr = null;
            },
            type: this.method,
            dataType: this.option('dataType')
        };

        let params;

        if (this._isMultiPart()) {
            if (fileApiAvailable() && !this.option('forceIframe')) {
                ajaxOptions = webf.extend(ajaxOptions, {
                    contentType: false,
                    processData: false,
                    cache: false,
                    data: this._getFormDataObject(),
                    mimeType: 'multipart/form-data',
                    xhr: () => {
                        const xhr = $.ajaxSettings.xhr();

                        if (xhr.upload) {
                            xhr.upload.onprogress = (event) => {
                                if (event.lengthComputable) {
                                    this._call(this.option('onProgress'), event, event.loaded || event.position, event.total);
                                }
                            };
                        }

                        return xhr;
                    }
                });
            } else { // Vieux navigateurs
                var name_iframe = webf.uniqid();
                var iframe_src = /^https/i.test(window.location.href || '') ? 'javascript:false' : 'about:blank';
                var $form = this.e;
                var form = this.e.get(0);
                var additionnalInputs = [];
                var progress_key = webf.uniqid();

                var cssIframe = webf.extend({
                        position: 'absolute'
                    }, this.option('debug')
                        ? { background: '#fff', width: '400px', height: '200px', top: '0px', left: '0px' }
                        : { border: '0', width: '1px', height: '1px', top: '-9999px', left: '-9999px' }
                );

                var $iframe = $('<iframe>')
                    .prop('name', name_iframe)
                    .prop('src', iframe_src)
                    .css(cssIframe)
                ;

                var iframe = $iframe.get(0);

                var serverResponse = {
                    responseText: null,
                    responseXML: null,
                    getResponseHeader: function(header) {
                        var headers = {
                            'content-type': self.option('dataType')
                        };
                        return headers[header];
                    }
                };

                form.setAttribute('target', name_iframe);

                if (this.option('progressKeyName')) {
                    var $hiddenProgress = $('<input>')
                        .prop('type', 'hidden')
                        .prop('name', this.option('progressEntryName'))
                        .val(progress_key)
                    ;
                    $form.prepend($hiddenProgress);
                }

                webf.each(this.datas, (i, data) => {
                    // On ajoute les données de l'utilisateur sous forme d'input hidden dans le formulaire
                    var name = data.name;
                    if (!this.e.find("input[name='" + name + "']").length) {
                        var additionnalInput = $('<input type="hidden" name="' + name + '">').prop('value', data.value);
                        additionnalInputs.push(additionnalInput);
                        $form.append(additionnalInput);
                    }
                });

                try {
                    $iframe.appendTo('body');
                    if (iframe.attachEvent) {
                        iframe.attachEvent('onload', callbackResponse);
                    } else {
                        iframe.addEventListener('load', callbackResponse, false);
                    }
                    form.submit();
                } finally {
                    // Suppression du target du formulaire pour les appels suivants
                    $form.removeAttr('target');

                    // Les champs additionnels dont supprimés du formulaire
                    webf.each(additionnalInputs, function(i, additionnalInput) {
                        $(additionnalInput).remove();
                    });
                }

                var parseResponse = function(resp, type)
                {
                    var contentType = resp.getResponseHeader('content-type') || '';
                    var xml = type === 'xml' || contentType.indexOf('xml') >= 0;
                    var data = xml ? resp.responseXML : resp.responseText;

                    if (xml && data.documentElement.nodeName === 'parsererror') {
                        throw 'Erreur lors du parsing de la réponse';
                    }

                    if (webf.isString(data)) {
                        if (type === 'json' && contentType.indexOf('json') >= 0) {
                            data = $.parseJSON(data);
                        }
                    }

                    return data;
                };

                if (this.option('urlProgress') && this.option('progressKeyName')) {
                    var progress = new Progress();
                }

                return;
            }
        } else {
            const datas = this._serializeDatasAsObject(this.datas);
            params = $.param(datas);

            ajaxOptions = webf.extend(ajaxOptions, {
                data: params
            });
        }

        if (this.option('download') || this.method.toLowerCase() == 'get') {
            ajaxOptions.url += (this.option('url').indexOf('?') >= 0 ? '&' : '?') + params;
            ajaxOptions.data = null;
        }

        if (this.option('download')) {
            $(document).webfAjaxdownload({
                url: ajaxOptions.url,
                onLoading: () => {
                    this._call(this.option('beforeSend'));
                },
                onComplete: () => {
                    this._call(this.option('onSuccess'));
                    this.submited = false;
                }
            });
        } else {
            this.xhr = $.ajax(ajaxOptions);
        }
    },

    _isMultiPart: function()
    {
        let datasHasBlob = false;

        webf.each(this.datas, (name, data) => {
            if (webf.isObject(data.value)) {
                datasHasBlob = true;
                return false;
            }
        });

        return (
            datasHasBlob ||
            (this.e.prop('enctype') == 'multipart/form-data') ||
            (this.e.prop('encoding') == 'multipart/form-data') ||
            (this.e.find("input[type='file']").length > 0)
        );
    },

    _getFormDatasArray: function()
    {
        const $elems = this.e.find(':input');
        const datas = [];

        $elems.each((i, elem) => {
            const $elem = $(elem);
            const type = getTypeElement($elem);
            let name = $elem.prop('name');

            if (webf.inArray(name, this.option('exclude'))) {
                return true;
            } else if (type === "radio") {
                let stop = false;

                webf.each(datas, function(i, elem) {
                    if (name === elem.name && elem.value !== '') {
                        stop = true;
                    }
                });

                if (stop) return true; // continue
            } else if (type === 'checkbox') {
                if (!$elem.prop('checked')) {
                    return true; // continue
                }
            }

            let obj = {};
            if (type !== 'select-multiple') {
                const regBrackets = /(.+?)(\[\]).*/;
                if (regBrackets.test(name)) {
                    const matches = regBrackets.exec(name);
                    this.elemsBrackets[matches[1]] = webf.isUndefined(this.elemsBrackets[matches[1]]) ? 0 : this.elemsBrackets[matches[1]] + 1;
                    const index = this.elemsBrackets[matches[1]];
                    name = name.replace(matches[2], '[' + index + ']').replace(/\[\]/g, '[0]');
                }

                obj = {
                    name: name,
                    value: '',
                    type: type
                };
            }

            switch (type) {
                case 'select':
                    obj.value = $elem.find('option:selected').val();
                    break;

                case 'select-multiple':
                    $elem.find('option:selected').each(function(i) {
                        var obj = {
                            name: name.substr(0, name.length - 2) + '[' + i + ']',
                            value: $(this).val(),
                            type: type
                        };

                        datas.push(obj);
                    });

                    return true;

                case 'checkbox':
                    obj.value = $elem.val() ? $elem.val() : 'on';
                    break;

                case 'radio':
                    if ($elem.prop('checked')) {
                        obj.value = $elem.val();
                    }
                    break;

                case 'file':
                    obj.value = this._getFiles($elem);
                    break;

                case 'button':
                case 'submit':
                    return true;

                default:
                    obj.value = $elem.val();
                    break;
            }

            datas.push(obj);
        });

        webf.each(this.extradatas, function(name, data) {
            datas.push({
                name: name,
                value: data,
                type: 'extra'
            });
        });

        return datas;
    },

    _serializeDatasAsObject: function(datas)
    {
        var d = {};

        webf.each(datas, function(i, data) {
            this[data.name] = data.value;
        }, d);

        return d;
    },

    _getFiles: function($element)
    {
        var element = $element.get(0),
            arrFiles = [];

        var files = element.files;
        if (files) {
            for( var i=0; i<files.length; i++) {
                var finename = files[i].fileName ? files[i].fileName : files[i].name;
                var ext = finename.substringIndex('.', -1).toLowerCase();

                arrFiles.push( {
                    finename: finename,
                    extension: ext,
                    fileSize: files[i].fileSize ? files[i].fileSize : files[i].size,
                    file: files[i]
                });
            }
        } else {
            if ($element.val().length) {
                var filename = $element.val().replace(/.*(\/|\\)/, '');
                var ext = finename.substringIndex('.', -1).toLowerCase();

                arrFiles.push({
                    filename: filename,
                    extension: ext
                });
            }
        }

        return arrFiles;
    },

    _getFormDataObject: function()
    {
        var formData = new FormData();

        webf.each(this.datas, function(i, data) {
            var appendToFormData = function(name, d) {
                if (webf.isPlainObject(d) || webf.isArray(d)) {
                    webf.each(d, function(index, value) {
                        var basename = name + '[' + index + ']',
                            o = appendToFormData(basename, value);

                        if (webf.isObject(o)) {
                            formData.append(basename, webf.isUndefined(o.value) ? '' : o.value);
                        }
                    });
                } else {
                    return {
                        name: name,
                        value: d
                    };
                }
            };

            if (data.type == 'file') {
                webf.each(data.value, function(j, file) {
                    this.append(data.name, file.file);
                }, this);
                return true;
            } else if (webf.isPlainObject(data.value) || webf.isArray(data.value)) {
                appendToFormData(data.name, data.value);
            } else {
                formData.append(data.name, data.value);
            }
        }, formData);

        return formData;
    },

    _getUrl: function()
    {
        return this.option('url') || this.e.prop('action') || document.URL;
    },

    submit: function()
    {
        this.e.submit();
    },

    abort: function()
    {
        if (this.xhr && this.submited) {
            this.xhr.abort();
            this._call(this.option('onAbort'));
        }
    },

    addData: function(name, value)
    {
        this.extradatas[name] = value;
    }
});

function getTypeElement($element)
{
    var tagName = $element.get(0).tagName;

    if (webf.inArray(tagName, ['TEXTAREA','SELECT','BUTTON'])) {
        if (tagName == 'SELECT') {
            if ($element.prop('multiple')) {
                tagName = [tagName,'multiple'].join('-');
            }
        }

        return tagName.toLowerCase();
    }

    return $element.prop('type').toLowerCase();
}

function fileApiAvailable()
{
    return !webf.isUndefined($("<input type='file'/>").get(0).files);
}

function Progress() {
    this.progressEntryName = self.option('progressEntryName');
    this.progressKeyName = self.option('progressKeyName');
    this.progressKey = progress_key;

    webf.setTimeout(function() {
        this.waitProgress();
    }, 100, this);
}

Progress.prototype.waitProgress = function() {
    webf.setTimeout(function() {
        this.onProgress();
    }, self.option('freqProgress'), this);
}

Progress.prototype.onProgress = function() {
    var that = this;

    var data = {};
    data[this.progressKeyName] = this.progressKey;

    $.ajax({
        url: self.option('urlProgress'),
        data: data,
        success: function(response) {
            if (response.current && response.done == 0) {
                self._call(self.option('onProgress'), { response: response }, response.current, response.total);
                that.waitProgress();
            } else if (response.done == 1) {
                self._call(self.option('onProgress'), { response: response }, response.current, response.total);
            } else {
                that.waitProgress();
            }
        },
        type: 'get',
        dataType: 'json'
    });
};

function callbackResponse()
{
    var doc = webf.getIframeDocument(iframe);

    if (!doc || doc.location.href == iframe_src) {
        // Le serveur n'a pas encore répondu
        return;
    }

    // La réponse est arrivée, on détache l'événement load
    if (iframe.detachEvent) {
        iframe.detachEvent('onload', callbackResponse);
    } else {
        iframe.removeEventListener('load', callbackResponse, false);
    }

    try {
        // on récupère le contenu de la réponse à l'intérieur de l'iframe.
        var dataType = self.option('dataType').toLowerCase();
        var docRoot = doc.body ? doc.body : doc.documentElement;
        serverResponse.responseText = docRoot ? docRoot.innerHTML : null;
        serverResponse.responseXML = doc.XMLDocument ? doc.XMLDocument : doc;

        if (dataType == 'json') {
            // Selon les navigateurs, la réponse peut se trouver
            // encapsulée dans une balise <pre> lorsque du json est renvoyé.
            var pre = doc.getElementsByTagName('pre')[0];
            var body = doc.getElementsByTagName('body')[0];
            if (pre) {
                serverResponse.responseText = pre.textContent ? pre.textContent : pre.innerText;
            } else if (body) {
                serverResponse.responseText = body.textContent ? body.textContent : body.innerText;
            }
        } else if (dataType == 'xml') {
            serverResponse.responseXML = $.parseXML(serverResponse.responseText);
        } else if (dataType == 'html') {
            serverResponse.responseXML = $.parseHTML(serverResponse.responseText);
        } else {

        }

        var data = parseResponse(serverResponse, dataType);
    } catch (e) {
        serverResponse.error = e;
    }

    ajaxOptions.success(data, 200, serverResponse);

    if (!self.option('debug')) {
        window.setTimeout(function() {
            // On supprime l'iframe pour les éventuels prochains appels
            $iframe.remove();
        }, 100);
    }
}

