import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.timepicker = {
        'Pick a time': 'Chosissez un moment'
    };
})(webf);

