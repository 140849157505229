import $ from 'jquery'
import webf from '../../utils/core'

var allowedTypes = 'jpg jpeg png gif'.split(' ');

webf.RichTextEditor.Controls.Image = webf.RichTextEditor.Control.extend({
    zIndex: null,

    init: function(editor, options) {
        this.zIndex = editor.option('zIndex');

        var uniqId = webf.uniqid();
        this.$contentDialog = $('' +
            '<div style="display:none">' +
            '  <form class="form-horizontal">' +
            '    <div class="form-group ">' +
            '      <label class="col-sm-3 control-label" for="btn_upload_image">' +
            '        <span>' + editor._('import') + '</span>' +
            '      </label>' +
            '      <div class="col-sm-9 file-type-btn-upload-image">' +
            '        <input id="btn_upload_image'+uniqId+'" class="form-control" type="file" />' +
            '      </div>' +
            '    </div>' +
            '    <div class="form-group ">' +
            '      <label class="col-sm-3 control-label" for="url_link">' +
            '        <span>' + editor._('from_url') + '</span>' +
            '      </label>' +
            '      <div class="col-sm-9">' +
            '        <input id="url_image" class="form-control" type="text" autocomplete="off">' +
            '      </div>' +
            '    </div>' +
            '  </form>' +
            '</div>' +
        '');

        if (options.ajaxUploadOptions) {
            this.$contentDialog.find('#btn_upload_image'+uniqId).replaceWith('<div class="webf-button webf-richtexteditor-upload">' + editor._('import') + '</div>');
        }

        this.$contentDialog.data('optionsImage', options).webfDialog($.extend({
            autoOpen: false,
            dialogClass: 'webf-richtexteditor-dialog webf-richtexteditor-image',
            box: {
                boxClass: 'primary plain',
                header: {
                    title: editor._('insert image')
                },
                footer: {
                    closeButton: false,
                    buttons: {
                        Valider: {
                            cls: 'primary',
                            click: function() {
                                var $widget = $(this).webfDialog('widget'),
                                    url = $widget.find('#url_image').val();

                                if (url && /^https?:\/\//.test(url)) {
                                    editor.pasteHtml('<img src="' + url + '">"');
                                }

                                $(this).webfDialog('close');
                            }
                        },
                        Annuler: {
                            cls: 'white',
                            click: function() {
                                $(this).webfDialog('close');
                            }
                        }
                    }
                }
            },
            onFirstOpen: function() {
                var self = this,
                    $widget = $(this).webfDialog('widget'),
                    fileInput = $widget.find("input")[0],
                    optionsImage = $(this).data('optionsImage');

                if (optionsImage.ajaxUploadOptions) {
                    var optionsAjaxupload = webf.extend(true,
                        optionsImage.ajaxUploadOptions,
                        {
                            multiple: false,
                            onComplete: function (i, datas) {
                                editor.pasteHtml('<img src="' + datas.url + '">', false);
                                $(self).webfDialog('close');
                            }
                        }
                    );

                    $widget.find('.webf-richtexteditor-upload').webfAjaxupload(optionsAjaxupload);
                } else {
                    fileInput.onchange = function () {
                        var files = this.files;

                        $.each(files, function(i, file) {
                            if (webf.inArray(file.type, allowedTypes) > -1) {
                                (function(f) {
                                    var reader = new FileReader();
                                    reader.readAsDataURL(f);

                                    reader.onload = function() {
                                        var imageData = this.result;

                                        editor.pasteHtml('<img src="' + imageData + '">');
                                    };
                                })(file);
                            }
                        });

                        $(self).webfDialog('close');
                    };
                }
            },
            onOpen: function() {
                var $widget = $(this).webfDialog('widget')
                    //rangeSelection = editor.getSelectionRange(),
                    //htmlSelection = editor.getSelectionHtml(),
                    //textSelection = htmlSelection.stripTags(),
                    //elemLink,
                    //openInNewTab = false,
                    //href = 'http://'
                ;

                //if (elemImage = getLinkInRange(rangeSelection)) {
                //    textSelection = elemLink.firstChild.nodeValue;
                //    href = elemLink.getAttribute('href');
                //    openInNewTab = elemLink.hasAttribute('target') && elemLink.getAttribute('target') == '_blank';
                //}
                //
                //$widget.find("#chk_open_in_new_tab").webfCheckbox('check', openInNewTab);
                //
                //$widget.find('#url_link').val(href);
                //$widget.find('#text_link').val(textSelection);
            }
        }, this.zIndex ? {
            zIndex: this.zIndex + 1
        } : {}));
    },
    render: function(editor, $btn) {
        $btn.addClass('link')
            .append("<i class='fas fa-image'></i>");
    },
    exec: function() {
        this.$contentDialog.webfDialog('open');
    },
    blur: function(editor, $btn) {
        this.$contentDialog.webfDialog('close');
    },
    types: 'action',
    text_tooltip: 'insert image',
    shortcut: {
        Shift: true,
        Ctrl: true,
        key: 'I'
    },
    destroy: function () {
        this.$contentDialog.webfDialog('destroy');
        this.$contentDialog.remove();
    }
});

