import webf from './core'
import '../i18n/date'

/**
 * constructor(input, format, lang)
 *                      input: string|Date|Array|webf.Date|object
 *                      format: string ce paramètre n'est utilisé et requis que si input est un string.
 *                      Le format est une chaîne comprenant les éléments permettant d'identifier les composantes
 *                      de la date passée en 1er paramètre.
 *                      lang: Langue à utliser pour cette instance.
 * toDate()             Retourne la date javascript
 * year|month|date|day|hours|minutes|seconds|milliseconds(val)
 *                      Retourne ou définit l'année, mois, jour du mois ou de la semaine, heure, minute seconde
 * unix()               Retourne ou défnit la date à partir du timestamp unix.
 * time()               Retourne définit le nombre de millisecondes depuis le 1er janv. 1970
 * toString(format, lang)
 *                      Affiche la date selon le format et la langue spécifiée.
 *                      Si le format n'est pas précisé, le format par défaut YYYY-MM-dd HH:mm:ss est utilisé
 *                      Si la langue n'est pas précisée, la langue par défaut est utilisée.
 *                      Pour afficher du texte dans le rendu de la chaîne, il faut l'entourer de crochets
 *                      yyyyy :  Année avec 5 chiffres
 *                      yyyy :   Année avec 4 chiffres
 *                      yy :     Année avec 2 chiffres
 *                      MMMMM :  Mois abrégé avec un lettre : Février => F
 *                      MMMM :   Mois compet
 *                      MMM :    Mois abrégé
 *                      MM :     Mois avec 2 chiffres
 *                      M :      Mois avec un ou 2 chiffres
 *                      dd:      Jour du mois avec 2 chiffres
 *                      d :      Jour du mois avec 1 ou 2 chiffres
 *                      DDD :    Jour de l'année avec 3 chiffres
 *                      DD :     Jour de l'année avec 2 ou 3 chiffres
 *                      D :      Jour de l'année avec 1, 2 ou 3 chiffres
 *                      EEEE :   Jour de la semaine en toute lettre Mardi
 *                      EEE :    Jour de la semaine avec 3 lettres
 *                      EE :     Jour de la semaine avec 2 lettres
 *                      E :      Jour de la semaine abrégé en une lettre Mardi => M
 *                      ee :     Numéro du jour de la semaine avec 2 chiffres
 *                      e:       Numéro du jour de la semaine avec 1 chiffre
 *                      HH :     Heures (0-24) avec 2 chiffres
 *                      H :      Heures (0-24) avec 1 ou 2 chiffres
 *                      hh :     Heures (0-12) avec 2 chiffres
 *                      h :      Heures (0-12) avec 1 ou 2 chiffres
 *                      mm :     Minutes avec 2 chiffres
 *                      m :      Minutes avec 1 ou 2 chiffres
 *                      ss :     Secondes avec 1 ou 2 chiffres
 *                      s :      Secondes avec 1 chiffre
 *                      SSS :    Millisecondes en comptants les secondes sur 5 chiffres
 *                      SS :     Millisecondes en comptants les secondes sur 4 ou 5 chiffres
 *                      S :      Millisecondes
 *                      A :      AM / PM
 *                      Z :      Fuseau horaire sous la forme (+/-)HH:MM
 *                      ZZ :     Fuseau horaire sous la forme (+/-)HHMM
 *
 * clone:               Clone l'objet en préservant l'état de l'objet appelant
 * (add|sub)(Year|Month|Day|Hour|Minute|Second|Millisecond)s?(n)
 *                      Ajoute ou soustrait des années, mois, jours, minutes, secondes ou millisecondes
 *                      n: le nombre d'unités
 * (start|end)Of(Year|Month|Day|Hour|Minute|Second)
 *                      Fait coïncider la date au début ou la fin de l'année, mois, jour, heure, minute ou seconde
 *                      de la date courante
 * compare(WebfDate, part)
 *                      Compare la date avec une autre date WebfDate.
 *                      Retourne -1 si la date courante est plus tôt, 1 si plus tard, 0 si elles sont égales
 *                      part: compare les dates à partir de l'année, mois, jour, heure, minute, seconde ou milliseconde
 *                      Si part est non renseigné, les dates sont comparé sur la milliseconde
 * isEarlier(webf.Date, part, strict)
 *                      Retourne true si la date courante est plus tôt que la date comparée.
 *                      strict: si défini à true, et que les dates sont égales, retourne false
 * isLater(webf.Date, part, strict)
 *                      Retourne true si la date courante est plus tard que la date comparée.
 *                      strict: si défini à true, et que les dates sont égales, retourne false
 * isBetween(webf.Date, webf.Date, strict)
 *                      Retourne true si la date courante est après la première date et avant la deuxième date.
 * daysInMonth()        Retourne le nombre de jour du mois de la date courante
 * isLeapYear()         Retourne true si l'année de la date courante est bissextile
 * isToday(d)           Retourne true si la date courante est aujoud'hui. Une date peut être passée en argument comme
 *                      référence de la date d'aujourd'hui.
 * isYesterday(d)       Retourne true si la date courante est hier. Une date peut être passée en argument comme
 *                      référence de la date d'hier.
 * isTomorrow(d)        Retourne true si la date courante est demain. Une date peut être passée en argument comme
 *                      référence de la date de demain.
 * diff(date, part, float)
 *                      Retourne le nb. de millisecondes entre 2 dates.
 *                      si part est spécifié, retourne nb. de secondes, minutes, heures ou jours entre 2 dates
 *                      si float est à true, le résultat est retourné sous forme décimale, sinon la valeur est tronquée
 * getWeeksInMonth()    Retourne un tableau composé des clés start et end correspondant au début et fin de semaine du
 *                      mois de la date courante.
 * earliest(date)       Retourne la date la plus ancienne entre la date courante et date
 * latter(date)         Retourne la date la plus tard entre la date courante et date
 * toArray:             Retourne un tableau dont les valeurs peuvent être passées directement à un objet Date
 * (prev|next)(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)(bool)
 *                      Fixe la date au jour de la semaine suivant ou précédent
 */
var defaultLang = webf.config.LANG,
    defaultFormat = 'yyyy-MM-dd HH:mm:ss';

var dayName = 'sunday monday tuesday wednesday thursday friday saturday'.split(' '),
    monthName = 'january february march april may june july august september october november december'.split(' '),
    shortMonthName = 'jan feb mar apr may. jun jul aug sept oct nov dec'.split(' ');

var regToString = /(\[[^\[]*\])|yyyyy?|yy|MM?M?M?M?|dd?|DD?D?|EE?E?E?|ee?|HH?|hh?|mm?|ss?|SS?S?|A|ZZ?|W|./g,
    regFromString = /(\[[^\[]*\])|yyyyy?|yy|MM?M?M?|dd?|HH?|hh?|mm?|ss?s|S|A|./g,
    reg4_5Digits = /\d{4,5}/,
    reg2Digits = /\d{2}/,
    reg1_2Digits = /\d{1,2}/,
    reg1_3Digits = /\d{1,3}/,
    regMeridian = /AM|PM/i;

var monthsTranslations, shortMonthsTranslations;

webf.Date = function(input, format, lang) {
    return new WebfDate(input, format, lang);
};

function WebfDate(input, format, lang)
{
    if (input && webf.isString(input)) {
        lang = lang || defaultLang;

        monthsTranslations = getMonthsTranslations(lang);
        shortMonthsTranslations = getShortMonthsTranslations(lang);

        input = makeDateFromString(input, format, lang);
    } else {
        if (format && !lang) {
            lang = format;
        }

        if (!input) {
            input = new Date();
        } else if (webf.isArray(input)) {
            input = (function(year, month, date, hour, minute, second, millisecond) {
                return new Date(year || new Date().getFullYear(), month || 0, date || 1, hour || 0, minute || 0, second || 0, millisecond || 0);
            }).apply(null, input);
        } else if (typeof input === 'number') {
            input = new Date(input);
        } else if (input instanceof WebfDate) {
            input = new Date(input.toDate());
        } else if (webf.isPlainObject(input)) {
            var now = new Date(),
                y = input.year || input.years || input.y || now.getFullYear(),
                M = input.month || input.months || input.M || now.getMonth() + 1,
                d = input.day || input.days || input.d || now.getDate(),
                h = input.hour || input.hours || input.h || 0,
                m = input.minute || input.minutes || input.m || 0,
                s = input.second || input.seconds || input.s || 0,
                ms = input.millisecond || input.milliseconds || input.ms || 0;

            input = new Date(y, M-1, d, h, m, s, ms);
        }
    }

    this._date = input;
    this.lang = lang || defaultLang;
}

WebfDate.prototype = {
    toString: function(format, lang)
    {
        var self = this;

        format = format || defaultFormat;
        lang = lang || this.lang;

        var matches = format.match(regToString);

        var arr_tokens = [];

        webf.each(matches, function(i, match) {
            arr_tokens.push(formatFunctions[match] || match);
        });

        var strDate = '';
        webf.each(arr_tokens, function(i, token) {
            if (webf.isFunction(token)) {
                strDate += token.call(self, lang);
            } else {
                strDate += token.replace(/^\[(.+)\]$/,'$1');
            }
        });

        return strDate;
    },

    getWeekNumber: function()
    {
        var d = new Date(Date.UTC(this._date.getFullYear(), this._date.getMonth(), this._date.getDate()));
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    },

    clone: function()
    {
        return new WebfDate(this);
    },

    toDate: function()
    {
        return new Date(+this._date);
    },

    add: function(name, val)
    {
        val = parseInt(val);

        if (name instanceof WebfDate) {
            val = name.time();
            name = 'milliseconds';
        }

        switch(name) {
            case 'year':
            case 'years':
                if (this.month() === 1 && this.date() >= 28 && this.isLeapYear()) {
                    if (this.year(this.year() + val).month() === 2) {
                        this.subDay();
                    }
                } else {
                    this.year(this.year() + val);
                }
                break;

            case 'month':
            case 'months':
                if (val >= 12) {
                    this.addYears(Math.floor(val / 12));
                    val %= 12;
                } else if (val <= -12) {
                    this.subYears(Math.floor(-val / 12));
                    val %= -12;
                }

                var date = this.date();
                if (date >= 28) {
                    if (this.date() !== this.month(this.month() + val).date()) {
                        this.date(0);
                    }
                } else {
                    this.month(this.month() + val);
                }

                break;

            case 'week':
            case 'weeks':
                this.addMilliseconds(val * 604800000);
                break;

            case 'day':
            case 'days':
                this.addMilliseconds(val * 86400000);
                break;

            case 'hour':
            case 'hours':
                this.addMilliseconds(val * 3600000);
                break;

            case 'minute':
            case 'minutes':
                this.addMilliseconds(val * 60000);
                break;

            case 'second':
            case 'seconds':
                this.addMilliseconds(val * 1000);
                break;

            case 'millisecond':
            case 'milliseconds':
                var oldTimeZoneOffset = this.toDate().getTimezoneOffset();

                this.time(new Date(this.time() + val));

                var newTimeZoneOffset = this.toDate().getTimezoneOffset();

                if (newTimeZoneOffset != oldTimeZoneOffset) {
                    this.time(this.time() + (newTimeZoneOffset - oldTimeZoneOffset) * 60000);
                }

                break;
        }

        return this;
    },

    sub: function(name, val)
    {
        val = parseInt(val);

        if (name instanceof WebfDate) {
            val = name.time();
            name = 'milliseconds';
        }

        return this.add(name, -val);
    },

    unix: function(val)
    {
        if (webf.isUndefined(val)) {
            return Math.floor(this.time() / 1000);
        }

        this._date = new Date(val * 1000);
        return this;
    },

    startOf: function(name)
    {
        if (name === 'year') {
            this.month(0);
            this.date(1);
            this.hours(0);
            this.minutes(0);
            this.seconds(0);
            this.milliseconds(0);
        } else if (name === 'month') {
            this.date(1);
            this.hours(0);
            this.minutes(0);
            this.seconds(0);
            this.milliseconds(0);
        } else if (name === 'day') {
            this.hours(0);
            this.minutes(0);
            this.seconds(0);
            this.milliseconds(0);
        } else if (name === 'hour') {
            this.minutes(0);
            this.seconds(0);
            this.milliseconds(0);
        } else if (name === 'minute') {
            this.seconds(0);
            this.milliseconds(0);
        } else if (name === 'second') {
            this.milliseconds(0);
        }

        return this;
    },

    endOf: function(val)
    {
        return this['endOf' + val.ucfirst()]();
    },

    day: function(val)
    {
        if (webf.isUndefined(val)) {
            return this._date.getDay();
        }

        return this.addDays(val - this.day());
    },

    compare: function(date, part)
    {
        var wd = webf.Date(date),
            d1, d2;

        d1 = this.clone().startOf(part).time();
        d2 = wd.clone().startOf(part).time();

        return d1 > d2 ? 1 : (d1 < d2 ? -1 : 0);
    },

    equals: function(wd, part)
    {
        return (this.compare(wd, part) === 0);
    },

    isEarlier: function(wd, part, strict)
    {
        if (webf.isBoolean(part) && webf.isUndefined(strict)) {
            strict = part;
        }

        strict = webf.isUndefined(strict) ? true : strict;

        if (strict) {
            return (this.compare(wd, part) === -1);
        }

        return (this.compare(wd, part) <= 0);
    },

    isLater: function(wd, part, strict)
    {
        if (webf.isBoolean(part) && webf.isUndefined(strict)) {
            strict = part;
        }

        strict = webf.isUndefined(strict) ? true : strict;

        if (strict) {
            return (this.compare(wd, part) === 1);
        }

        return (this.compare(wd, part) >= 0);
    },

    isBetween: function(wd1, wd2, strict)
    {
        return this.isLater(wd1, strict) && this.isEarlier(wd2, strict);
    },

    daysInMonth: function()
    {
        return this.clone().addMonth().date(0).date();
    },

    isLeapYear: function()
    {
        return (this.clone().date(29).month(1).month() === 1);
    },

    _: function(label, lang)
    {
        return webf._(lang || this.lang, 'date', label);
    },

    isToday: function(date)
    {
        return this.equals(webf.Date(date), 'day');
    },

    isYesterday: function(date)
    {
        return this.equals(webf.Date(date).subDay(), 'day');
    },

    isTomorrow: function(date)
    {
        return this.equals(webf.Date(date).addDay(), 'day');
    },

    diff: function(date, part, float)
    {
        var wd = webf.Date(date);
        var divider = 1;
        var ms = this.time() - wd.time();

        if (webf.inArray(part, ['s','second','seconds'])) {
            divider = 1000;
        } else if (webf.inArray(part, ['m','minute','minutes'])) {
            divider = 60000;
        } else if (webf.inArray(part, ['h','hour','hours'])) {
            divider = 3600000;
        } else if (webf.inArray(part, ['d','day','days'])) {
            ms -= (this.toDate().getTimezoneOffset() - wd.toDate().getTimezoneOffset()) * 60000;
            divider = 86400000;
        }

        return float ? ms/divider : Math.floor(ms/divider);
    },

    getWeeksInMonth: function() {
        const weeks = [];
        const wfirstDay = this.clone().startOfMonth();
        let wDay = wfirstDay.clone();

        for (let i=0; i<6; i++) {
            if (wDay.equals(wfirstDay, 'month')) {
                weeks.push({
                    start: wDay.date(),
                    end: wDay.nextSunday().equals(wfirstDay, 'month')
                        ? wDay.date()
                        : wfirstDay.clone().endOfMonth().date()
                });

                wDay.addDay();
            }
        }

        return weeks;
    },

    earliest: function(date)
    {
        return this.compare(date) < 0 ? this : date;
    },

    latter: function(date)
    {
        return this.compare(date) > 0 ? this : date;
    },

    toArray: function()
    {
        return [this.year(), this.month(), this.date(), this.hours(), this.minutes(), this.seconds(), this.milliseconds()];
    }
};

webf.each(['year','month','date','hours','minutes','seconds','milliseconds','time'], function(i, name) {
    WebfDate.prototype[name] = function(val) {
        if (webf.isUndefined(val)) {
            if (name == 'year') {
                name = 'fullYear';
            }

            return this._date['get' + name.ucfirst()]();
        } else {
            this._date['set' + name.ucfirst()](val);
            return this;
        }
    };
});

webf.each(dayName, function(i, name) {
    WebfDate.prototype['next' + name.ucfirst()] = function(nextIfToday) {
        if (nextIfToday) {
            this.add('days', 1);
        }

        while (this.day() != i) {
            this.add('days', 1);
        }

        return this;
    };

    WebfDate.prototype['prev' + name.ucfirst()] = function(prevIfToday) {
        if (prevIfToday) {
            this.sub('days', 1);
        }

        while (this.day() != i) {
            this.sub('days', 1);
        }

        return this;
    };
});

webf.each(['year','month','day','hour','minute','second'], function(i, name) {
    WebfDate.prototype['startOf' + name.ucfirst()] = function() {
        return this.startOf(name);
    };

    WebfDate.prototype['endOf' + name.ucfirst()] = function() {
        this.add(name,1);
        this.startOf(name);
        return this.subMillisecond();
    };
});

webf.each(['year','month','week','day','hour','minute','second','millisecond'], function(i, name) {
    webf.each(['add','sub'], function(j, prefix) {
        WebfDate.prototype[prefix + name.ucfirst()] = WebfDate.prototype[prefix + name.ucfirst() + 's'] = function(val) {
            return this[prefix](name, webf.isUndefined(val) ? 1 : val);
        };
    });
});

function makeDateFromString(input, format, lang)
{
    var date = new Date(0),
        matches = format.replace(/\[[^\[]*\]/g,'').match(regFromString);

    date.setTime(date.getTimezoneOffset() * 60000);

    var dateValue = null;

    webf.each(matches, function(i, match) {
        var regex = getRegexFromString(match, lang);

        if (regex) {
            var dateComponent = getComponentDateFromFormat(match);

            var value = regex.exec(input)[0];

            input = input.replace(new RegExp(value.replace('.','g')),'\.');

            if (dateComponent == 'shortyear') {
                value = parseInt(value);
                value += value > 68 ? 1900 : 2000;
                dateComponent = 'year';
            }

            if (dateComponent == 'year') {
                dateComponent = 'fullYear';
            } else if (dateComponent == 'month') {
                value = parseInt(value) - 1;
            }

            var nameFunction = 'set' + dateComponent.ucfirst();

            if (dateComponent == 'date') {
                dateValue = parseInt(value);
            } else if (Date.prototype[nameFunction]) {
                date[nameFunction](parseInt(value));
            } else {
                switch(dateComponent) {
                    case 'monthname':
                        date.setMonth(monthsTranslations.indexOf(value));
                        break;
                    case 'shortmonthname':
                        date.setMonth(shortMonthsTranslations.indexOf(value));
                        break;
                    case 'meridian':
                        if (value.toUpperCase() == 'PM') {
                            if (date.getHours() > 0 && date.getHours() < 12) {
                                date.setHours(date.getHours() + 12);
                            }
                        } else if (value.toUpperCase() == 'AM') {
                            if (date.getHours() == 12) {
                                date.setHours(0);
                            }
                        }
                        break;
                }
            }
        }
    });

    if (dateValue !== null) {
        date.setDate(dateValue);
    }

    return date;
}

function getRegexFromString(token, lang) {
    switch(token) {
        case 'yyyyy':
        case 'yyyy':
            return reg4_5Digits;
        case 'yy':
            return reg2Digits;
        case 'MMMM':
            var _monthNames = webf.map(monthName, function(i, name) {
                return WebfDate.prototype._.call(null, name, lang);
            });

            return new RegExp(_monthNames.join('|').replace(/(\.)/g,'\$1'),'i');
        case 'MMM':
            var _shortMonthNames = webf.map(shortMonthName, function(i, name) {
                return WebfDate.prototype._.call(null, name, lang);
            });

            return new RegExp(_shortMonthNames.join('|').replace(/(\.)/g,'\$1'),'i');
        case 'MM':
        case 'M':
        case 'dd':
        case 'd':
        case 'HH':
        case 'H':
        case 'hh':
        case 'h':
        case 'mm':
        case 'm':
        case 'ss':
        case 's':
            return reg1_2Digits;
        case 'S':
            return reg1_3Digits;
        case 'A':
            return regMeridian;
    }

    return null;
}

function getComponentDateFromFormat(token)
{
    switch(token) {
        case 'yyyyy':
        case 'yyyy':
            return 'year';
        case 'yy':
            return 'shortyear';
        case 'MMMM':
            return 'monthname';
        case 'MMM':
            return 'shortmonthname';
        case 'MM':
        case 'M':
            return 'month';
        case 'dd':
        case 'd':
            return 'date';
        case 'HH':
        case 'H':
        case 'hh':
        case 'h':
            return 'hours';
        case 'mm':
        case 'm':
            return 'minutes';
        case 'ss':
        case 's':
            return 'seconds';
        case 'S':
            return 'milliseconds';
        case 'A':
            return 'meridian';
    }
}

function getMonthsTranslations(lang)
{
    var _ = WebfDate.prototype._;
    var translations = [];

    webf.each(monthName, function(i, name) {
        translations.push(_.call(null, name, lang));
    });

    return translations;
}

function getShortMonthsTranslations(lang)
{
    var _ = WebfDate.prototype._;
    var translations = [];

    webf.each(shortMonthName, function(i, name) {
        translations.push(_.call(null, name, lang));
    });

    return translations;
}

var formatFunctions = {
    yyyyy: function() {
        return ((this.year() % 100000) + '').pad(5, '0');
    },
    yyyy: function() {
        return ((this.year() % 10000) + '').pad(4, '0');
    },
    yy: function() {
        return ((this.year() % 100) + '').pad(2, '0');
    },
    MMMMM: function(lang) {
        return this._(monthName[this.month()], lang).substr(0,1).toUpperCase();
    },
    MMMM: function(lang) {
        return this._(monthName[this.month()], lang);
    },
    MMM: function(lang) {
        return this._(shortMonthName[this.month()], lang);
    },
    MM: function() {
        return (this.month() + 1 + '').pad(2,'0');
    },
    M: function() {
        return this.month() + 1 + '';
    },
    dd: function() {
        return (this.date() + '').pad(2,'0');
    },
    d: function() {
        return this.date();
    },
    DDD: function() {
        return (Math.ceil((this.toDate() - new Date(this.year(),0,1)) / 86400000) + '').pad(3,'0');
    },
    DD: function() {
        return (Math.ceil((this.toDate() - new Date(this.year(),0,1)) / 86400000) + '').pad(2,'0');
    },
    D: function() {
        return Math.ceil((this.toDate() - new Date(this.year(),0,1)) / 86400000);
    },
    EEEE: function(lang) {
        return this._(dayName[this.day()], lang);
    },
    EEE: function(lang) {
        return this._(dayName[this.day()].substr(0,3), lang);
    },
    EE: function(lang) {
        return this._(dayName[this.day()], lang).substr(0,2);
    },
    E: function(lang) {
        return this._(dayName[this.day()], lang).substr(0,1).toUpperCase();
    },
    ee: function() {
        return (this.day() + '').pad(2,'0');
    },
    e: function() {
        return this.day();
    },
    HH: function() {
        return (this.hours() + '').pad(2,'0');
    },
    H: function() {
        return this.hours();
    },
    hh: function() {
        return (((this.hours() % 12) || 12) + '').pad(2,'0');
    },
    h: function() {
        return ((this.hours() % 12) || 12);
    },
    mm: function() {
        return (this.minutes() + '').pad(2,'0');
    },
    m: function() {
        return this.minutes();
    },
    ss: function() {
        return (this.seconds() + '').pad(2,'0');
    },
    s: function() {
        return this.seconds();
    },
    SSS: function() {
        return formatFunctions.ss.call(this) + '' + (this.milliseconds() + '').pad(3,'0');
    },
    SS: function() {
        return formatFunctions.s.call(this) + '' + (this.milliseconds() + '').pad(3,'0');
    },
    S: function() {
        return (this.milliseconds() + '').pad(3,'0');
    },
    A: function() {
        return this.hours() >= 12 ? 'PM' : 'AM';
    },
    Z: function() {
        var offset = this.toDate().getTimezoneOffset();
        return (offset <= 0 ? '+' : '-') + (Math.floor(-offset / 60) + '').pad(2,'0') + ':' + ((-offset % 60) + '').pad(2,'0');
    },
    ZZ: function() {
        var offset = this.toDate().getTimezoneOffset();
        return (offset <= 0 ? '+' : '-') + (Math.floor(-offset / 60) + '').pad(2,'0') + ((-offset % 60) + '').pad(2,'0');
    },
    W: function() {
        return WebfDate.prototype.getWeekNumber.call(this);
    }
};

export default WebfDate
