import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import WebfCookies from '../utils/cookies'
import '../i18n/cookieconsent'
import $ from 'jquery'

/**
 * options:
 *  - cookieName
 *  - cookiePath
 *  - cookieDomain
 *  - cookieDuration
 *  - cookieSecure
 *  - message
 *  - acceptText
 *  - declineText
 *  - acceptBtnClass
 *  - declineBtnClass
 *  - closeBtnClass
 *  - onAccept
 *  - onDecline
 *  - onClose
 *
 * Méthodes publiques:
 *  - hasAccepted
 *  - hasDeclined
 */
$webf('cookieconsent', {
    options: {
        cookieName:         'cookie-accepted',
        cookiePath:         '/',
        cookieDomain:       null,
        cookieDuration:     365 * 86400,
        cookieSecure:       false,
        message:            'message',
        acceptText:         'accept',
        declineText:        'decline',
        acceptBtnClass:     'webf-button success',
        declineBtnClass:    'webf-button danger',
        closeBtnClass:      'webf-button',
        onAccept:           webf.noop,
        onDecline:          webf.noop,
        onClose:            webf.noop
    },

    _create: function()
    {
        this.cookieJar = new WebfCookies();

        if (this._needConsent()) {
            this._drawBanner();
            this._bindEvents();
        } else {
            if (this.hasAccepted()) {
                this._call(this.option('onAccept'));
            } else {
                this._call(this.option('onDecline'));
            }
        }
    },

    _drawBanner: function()
    {
        var $body = this.e;

        if (webf.isDocument($body[0]) || webf.isWindow($body[0])) {
            $body = $('body');
        }

        var $banner = $('<div></div>').addClass('webf-cookie-consent');
            var $message = $('<div>').addClass('message').html(this._(this.option('message')));
        var $buttons = $('<div>').addClass('buttons');

        $banner.append($message);

        if (this.option('acceptText')) {
            var $btnAccept = $('<button type="button">')
                .addClass('cookie-accept')
                .addClass(this.option('acceptBtnClass'))
                .html(this._(this.option('acceptText')));

            $buttons.append($btnAccept);
        }

        if (this.option('declineText')) {
            var $btnDecline = $('<button type="button">')
                .addClass('cookie-decline')
                .addClass(this.option('declineBtnClass'))
                .html(this._(this.option('declineText')));

            $buttons.append($btnDecline);
        }

        var $closeButton = $('<a>').addClass('close-button')
            .addClass(this.option('closeBtnClass'))
            .append("<i class='fas fa-times'></i>");

        $banner.append($buttons);
        $banner.append($closeButton);

        this.$cookieBanner = $banner;

        $body.append(this.$cookieBanner);
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.$cookieBanner, '.cookie-accept', {
            click: function() {
                if (self._call(self.option('onAccept')) !== false) {
                    self._accept();
                    self._close();
                }
            }
        })._on(this.$cookieBanner, '.cookie-decline', {
            click: function() {
                if (self._call(self.option('onDecline')) !== false) {
                    self._decline();
                    self._close();
                }
            }
        })._on(this.$cookieBanner, '.close-button', {
            click: function() {
                if (self._call(self.option('onClose')) !== false) {
                    self._close();
                }
            }
        });
    },

    _needConsent: function()
    {
        return !this.cookieJar.has(this.option('cookieName'));
    },

    _accept: function()
    {
        this.cookieJar.set(
            this.option('cookieName'),
            1,
            this.option('cookieDuration'),
            this.option('cookiePath'),
            this.option('cookieDomain'),
            this.option('cookieSecure')
        );
    },

    _decline: function()
    {
        this.cookieJar.remove(this.option('cookieName'));
        this.cookieJar.set(
            this.option('cookieName'),
            0,
            this.option('cookieDuration'),
            this.option('cookiePath'),
            this.option('cookieDomain'),
            this.option('cookieSecure')
        );
    },

    hasAccepted: function()
    {
        return !!this.cookieJar.get(this.option('cookieName'));
    },

    hasDeclined: function()
    {
        return !this.hasAccepted();
    },

    _close: function()
    {
        this.$cookieBanner.remove();
    },

    _destroy: function()
    {
        this._close();
    }
});
