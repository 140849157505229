import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Outdent = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-indent-left'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('outdent');
    },
    refresh: function(editor, $btn) {
    },
    types: 'action',
    text_tooltip: 'outdent'
});

