import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './dropdownmenu'
import $ from 'jquery'

/**
 * options:
 *  - menu:             cf. dropdownmenu
 *  - zIndex:           cf. dropdownmenu
 *  - groups:           cf. dropdownmenu
 *  - menuClass:        cf. dropdownmenu
 *  - delayClose:       cf. dropdownmenu
 *  - animation:        cf. dropdownmenu
 *  - durationOpen:     cf. dropdownmenu
 *  - durationClose:    cf. dropdownmenu
 *  - directionOpen:    cf. dropdownmenu
 *  - directionClose:   cf. dropdownmenu
 *  - easingOpen:       cf. dropdownmenu
 *  - easingClose:      cf. dropdownmenu
 *  - onBeforeOpen:     cf. dropdownmenu
 *  - onBeforeClose:    cf. dropdownmenu
 *  - handler:          (string) Sélecteur de l'élément sur lequel se déclenche le menu contextuel
 *                      Le sélecteur peut faire référence à un élément qui n'est pas encore sur la page au miment de l'instanciation
 *  - onOpen:           (function()) : Fonction appelée lorsque le menu contextuel s'ouvre
 *  - onClose:          (function()) : Fonction appelée lorsque le menu contextuel se ferme
 *
 * Méthodes publiques:
 *  - menu:         Retourne le menu contextuel
 *  - getHandler:   Retourne le handler sur lequel le clic droit a eu lieu
 *  - close:        Ferme le menu contextuel
 */
$webf('contextmenu', {
    options: {
        menu:               '',
        handler:            '',
        zIndex:             10000,
        groups:             '',
        menuClass:          '',
        animation:          'sliding',
        durationOpen:       0,
        durationClose:      0,
        directionOpen:      'down',
        directionClose:     'up',
        easingOpen:         'swing',
        easingClose:        'swing',
        onOpen:             webf.noop,
        onClose:            webf.noop,
        onFirstOpen:        webf.noop,
        onBeforeOpen:       webf.noop,
        onBeforeClose:      webf.noop
    },

    _create: function()
    {
        this.firstOpen = true;

        this._bindEvents()
    },

    _bindEvents: function()
    {
        this._on(document, {
            contextmenu: ev => {
                if (
                    (this.$handler && (this.$handler.webfDropdownmenu('isOpened') || this.$handler.webfDropdownmenu('isOpening'))) ||
                    (this.option('handler') && $(ev.target).closest(this.option('handler'))[0]) ||
                    (!this.option('handler') && $(ev.target).closest(this.e)[0])
                ) {
                    ev.preventDefault();
                }
            },
            click: ev => {
                if (!$(ev.target).closest('.webf-context-menu')[0]) {
                    if (this.$handler) {
                        this.$handler.webfDropdownmenu('close')
                        $('.webf-context-menu-handler').remove();
                        this.$handler = null
                    }
                }
            }
        })

        var contextMenuHandler = {
            mousedown: (ev) => {
                if (ev.which == 3) {
                    this.userHandler = ev.currentTarget;
                    this.e.css('userSelect', 'none')
                }

                setTimeout(() => {
                    ev.preventDefault()

                    if (ev.which == 3) {
                        this.$handler = $('<div class="webf-context-menu-handler">').css({
                            position: 'absolute',
                            width: 1,
                            height: 1,
                            top: ev.pageY,
                            left: ev.pageX
                        }).appendTo($('body'))

                        this.$handler.webfDropdownmenu({
                            behavior: 'custom',
                            menu: this.option('menu'),
                            zIndex: this.option('zIndex'),
                            groups: this.option('groups'),
                            menuClass: 'webf-context-menu ' + this.option('menuClass'),
                            delayClose: this.option('delayClose'),
                            animation: this.option('animation'),
                            durationOpen: this.option('durationOpen'),
                            durationClose: this.option('durationClose'),
                            directionOpen: this.option('directionOpen'),
                            directionClose: this.option('directionClose'),
                            easingOpen: this.option('easingOpen'),
                            easingClose: this.option('easingClose'),
                            onBeforeOpen: ($handler, $e) => {
                                this._call(this.option('onBeforeOpen'));
                            },
                            onBeforeClose: () => {
                                this._call(this.option('onBeforeClose'));
                            },
                            position: {
                                my: 'left top',
                                at: 'left bottom',
                                collision: 'flipfit fit',
                                offset: {
                                    top: 0,
                                    left: 1
                                }
                            },
                            onOpen: ($e) => {
                                if (this.firstOpen) {
                                    this.firstOpen = false;
                                    this._call(this.option('onFirstOpen'));
                                }

                                this._call(this.option('onOpen'));

                                $e.webfDropdownmenu('refreshPosition');
                            },
                            onClose: () => {
                                this.e.css('userSelect', 'auto')
                                this._call(this.option('onClose'))
                            }
                        })
                            .webfDropdownmenu('closeOtherMenus');

                        this.$handler.webfDropdownmenu('open');
                    }
                }, 1)
            }
        }

        if (this.option('handler')) {
            this._on(this.e, this.option('handler'), contextMenuHandler)
        } else {
            this._on(this.e, contextMenuHandler)
        }
    },

    menu: function()
    {
        if (this.$handler) {
            return this.$handler.webfDropdownmenu('menu');
        }
    },

    getHandler: function()
    {
        if (this.userHandler) {
            return $(this.userHandler);
        }

        return null;
    },

    close: function()
    {
        if (this.$handler) {
            return this.$handler.webfDropdownmenu('close');
        }
    },

    _destroy: function()
    {
        this.$handler && this.$handler.webfDropdownmenu('destroy')
    }
});
