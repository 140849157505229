import './controls'
import '../../plugins/dropdown'
import '../../plugins/dialog'

import './forecolor'
import './outdent'
import './subscript'
import './indent'
import './unlink'
import './image'
import './bold'
import './alignleft'
import './italic'
import './strikethrough'
import './underline'
import './superscript'
import './unorderedlist'
import './redo'
import './aligncenter'
import './fontname'
import './table'
import './orderedlist'
import './fontsize'
import './fontsizepx'
import './heading'
import './link'
import './undo'
import './hr'
import './alignright'
import './alignjustify'
