import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/richtexteditor'
import '../utils/client'
import './tooltip'
import '../i18n/richtexteditor'
import './richtexteditor/index'
import './checkbox'
import './dialog'
import $ from 'jquery'

/**
 * options :
 *  - controls
 *  - editorClass
 *  - width
 *  - minHeight
 *  - autogrow
 *  - optionsControls
 *  - content
 *  - bodyStyle
 *  - onLoaded
 *  - onChange
 *  - onFocus
 *  - onBlur
 *  - zIndex
 *
 * Méthodes publiques :
 *  - content
 */
$webf('richtexteditor', {
    options: {
        controls:           [
            'undo redo',
            'heading',
            'fontName',
            'fontSizePx',
            'bold italic underline strikeThrough',
            'UnorderedList OrderedList',
            'alignLeft alignCenter alignRight alignJustify',
            'subScript superScript',
            'foreColor',
            'link unlink',
            'image',
            'table',
            'hr'
        ].join(' | '),
        editorClass:        '',
        width:              'auto',
        minHeight:          'auto',
        autogrow:           true,
        optionsControls:    {
            fontName:   {
                fonts: []
            },
            fontSizePx: {
                sizes: []
            },
            table: {
                maxNbCols:      10,
                maxNbRows:      10,
                sizeColumns:    true,
                sizeRows:       true,
                valign:         true
            },
            image: {
                ajaxUploadOptions: false
            }
        },
        content:            null,
        bodyStyle:          {},
        onLoaded:           webf.noop,
        onChange:           webf.noop,
        onFocus:            webf.noop,
        onBlur:             webf.noop,
        zIndex:             null
    },

    _create: function()
    {
        this.activeControls = {};
        this.selectedRange = null;

        this._draw();
        this._bindEvents();
        this._createToolbar();

        this._call(this.option('onLoaded'));
    },

    _draw: function()
    {
        var textarea = this.e[0];

        this.$editor = this.e.wrap($("<div>").addClass('webf-richtexteditor').addClass(this.option('editorClass'))).parent()
            .width(this.option('width') == 'auto' ? webf.getStyle(textarea, 'width') : this.option('width'));

        var $content = $('<div>').addClass('webf-content')
            .attr('contenteditable', true);

        if (this.option('autogrow')) {
            $content.css('minHeight', this.option('minHeight') == 'auto' ? webf.getStyle(textarea, 'height') : this.option('minHeight'));
        } else {
            $content.addClass('fixed-height');
            $content.height(webf.getStyle(textarea, 'height'));
        }

        this.$editor
            .append($content);

        this.content(this.option('content') !== null ? this.option('content') : (textarea.value.length ? textarea.value : ""));

        this.e.hide();
    },

    _bindEvents: function()
    {
        var self = this,
            editor = this;

        this._on(this.$editor, {
            keydown: function(ev) {
                var keysEv = getKeysFromEvent(ev);

                webf.each(self.activeControls, function(name, control) {
                    if (control.shortcut) {
                        var keys = getKeysFromShortcut(control.shortcut);

                        if (webf.compareArray(keysEv, keys)) {
                            ev.preventDefault();

                            var $btn = self.$toolbar.find('.webf-button').findByData('control', name);
                            if (webf.isFunction(control.exec)) {
                                editor.saveSelection();
                                control.exec(self, $btn);
                            }
                        }
                    }
                });

                self._onChange();
            },
            'keyup mouseup': function(ev) {
                self._refresh();
                self._onChange();
            },
            paste: function(ev) {
                ev.preventDefault();

                var e = ev.originalEvent;
                var text = e.clipboardData.getData("text/plain").split("\n").join('<br>');

                self.pasteHtml(text, false);
                self._onChange();
            }
        });

        this._on(this.$editor.find('.webf-content'), {
            focus: function() {
                self._call(self.option('onFocus'), self.content());
            },
            blur: function() {
                self._call(self.option('onBlur'), self.content());
            }
        });
    },

    _createToolbar: function()
    {
        this.$toolbar = $("" +
            "<div class='webf-richtexteditor-toolbar'>" +
            "   <div class='wrap-controls'>" +
            "       <div class='controls'></div>" +
            "   </div>" +
            "</div>" +
            "").insertAfter(this.e);

        var controls = this.option('controls'),
            options = this.option('optionsControls');

        this.names_controls = !controls ? [] : controls.split(/\s+/g);

        webf.each(this.names_controls, function(i, name) {
            if (i === 0 || name == '|') {
                this.$toolbar.find('.controls').append($("<div>").addClass('webf-buttons-group'));

                if (name == '|') {
                    this._addPipe();
                    return true;
                }
            }

            this._addControl(name, i, options[name] || {});
        }, this);
    },

    _addPipe: function()
    {
        this.$toolbar.find('.controls .webf-buttons-group').last().before($('<div>').addClass('pipe'));
    },

    _addControl: function(name, pos, options)
    {
        var Control = webf.RichTextEditor.Controls[name.ucfirst()];
        if (!webf.isFunction(Control)) {
            throw '[RichTextEditor] Control "' + name + '" does not exist, available are [' +
                Object.keys(webf.RichTextEditor.Controls).map(function (v) {
                    return '"' + v.charAt(0).toLowerCase() + v.slice(1) + '"';
                }).join(', ')
            + ']';
        }

        var control = new Control;
        this.activeControls[name] = control;

        control.init && control.init(this, options);

        var self = this,
            editor = this,
            $btn = $("<button type='button' class='webf-button secondary'><span class='btn-tooltip'></span></button>")
                .data('control', name);

        this.$toolbar.find('.controls .webf-buttons-group').last().append($btn);
        control.render(this, $btn);
        control.group && $btn.data('control-group', control.group);

        if (control.text_tooltip) {
            var text_shortcut = control.shortcut ? getTextShortcut(control.shortcut) : '';

            $btn.children('.btn-tooltip').webfTooltip($.extend({
                content:
                    "<div class='webf-richtexteditor-tooltip'>" +
                    "   <div class='triangle'></div>" +
                    "   <div class='text'>" + this._(control.text_tooltip) + (text_shortcut ? " (" + text_shortcut + ")" : "") + "</div>" +
                    "</div>",
                groups: 'webf-richtexteditor-help-tooltip',
                trigger: 'hover',
                position: {
                    my: 'center top',
                    at: 'center bottom',
                    offset: { top: 1 }
                },
                delayOpen: 800,
                delayClose: 130
            }, this.option('zIndex') ? {
                zIndex: this.option('zIndex') + 1
            } : {}));

            self._on($btn, {
                click: function() {
                    $(this).find('.btn-tooltip').webfTooltip('close');
                },
                mousedown: function(ev) {
                    ev.preventDefault();
                    editor.saveSelection();

                    $(this).addClass('webf-active');
                },
                mouseup: function() {
                    $(this).removeClass('webf-active');
                }
            });
        }

        this._on($btn, {
            click: function(ev) {
                editor.saveSelection();
                control.exec && control.exec(self, $(this), webf.getSelectionHtml(window));
            }
        });
    },

    _execCommand: function(cmd, value)
    {
        this.restoreSelection();

        window.document.execCommand(cmd, false, value || null);

        this._refresh();
    },

    _queryCommandState: function(cmd)
    {
        return window.document.queryCommandState(cmd);
    },

    _queryCommandValue: function(cmd)
    {
        return window.document.queryCommandValue(cmd);
    },

    saveSelection: function()
    {
        this.selectedRange = null;

        var sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            this.selectedRange = sel.getRangeAt(0);
        }

        return this.selectedRange;
    },

    restoreSelection: function(range)
    {
        var sel,
            $content = this.$editor.children('.webf-content');

        range = range ? range : this.selectedRange;

        if (!webf.elementContainsSelection($content[0], range)) {
            range = document.createRange();
            range.setStart($content[0], 0);
            range.collapse(true);
        }

        if (range) {
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }

        this.selectedRange = null;
    },

    getSelectionRange: function()
    {
        return this.selectedRange ? this.selectedRange : window.getSelection().getRangeAt(0);
    },

    getSelectionHtml: function()
    {
        return webf.getSelectionHtml(window);
    },

    content: function(html)
    {
        if (webf.isUndefined(html)) {
            return this.$editor.children('.webf-content').html()
                .replace(/<div><br><\/div>/g, '<br>');
        }

        var el = this.$editor.children('.webf-content')[0];
        var range = document.createRange();
        var sel = window.getSelection();

        el.innerHTML = '';
        range.setStart(el, 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);

        webf.pasteHtmlAtCaret(window, html);
    },

    _refresh: function()
    {
        webf.each(this.activeControls, function(name, control) {
            var $btn = this.$toolbar.find('.webf-button').findByData('control', name);

            if (webf.isFunction(control.refresh)) {
                webf.setTimeout(function() {
                    control.refresh(this, $btn);
                }, 1, this);
            }
        }, this);
    },

    pasteHtml: function(html, select)
    {
        this.restoreSelection();

        webf.pasteHtmlAtCaret(window, html, webf.isUndefined(select) ? true : select);

        this._refresh();
    },

    _onChange: function()
    {
        var content = this.content();

        if (this.oldContent != content) {
            this._call(this.option('onChange'), this.content());
        }

        this.oldContent = this.content();
    },

    _destroy: function()
    {
    }
});

// webf.RichTextEditor = {
//     Controls: [],
//     Control: webf.Class.extend({
//         types: null,
//         text_tooltip: null,
//         shortcut: {},
//
//         init:    webf.noop,
//         render:  webf.noop,
//         exec:    webf.noop,
//         refresh: webf.noop,
//         destroy: webf.noop
//     })
// };

function getKeysFromEvent(ev)
{
    var keys = [];

    ((os == 'Mac' && ev.metaKey) || (os != 'Mac' && ev.ctrlKey && !ev.altKey)) && keys.push('Ctrl');
    ev.shiftKey && keys.push('Shift');
    ev.altKey && keys.push('Alt');

    keys.push(String.fromCharCode(ev.which || ev.keyCode));

    return keys;
}

function getTextShortcut(shortcut)
{
    var text = [];

    shortcut.Shift && text.push(os == 'Mac' ? "⇧" : "Shift");
    shortcut.Ctrl && text.push(os == 'Mac' ? "⌘" : "Ctrl");
    shortcut.Alt && text.push(os == 'Mac' ? "⌥" : "Alt");

    text.push(shortcut.key);

    return text.join(os == 'Mac' ? "" : "+");
}

function getKeysFromShortcut(shortcut)
{
    var keys = [];

    shortcut.Ctrl && keys.push('Ctrl');
    shortcut.Shift && keys.push('Shift');
    shortcut.Alt && keys.push('Alt');

    keys.push(shortcut.key);

    return keys;
}

var os = webf.client.os;

