import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.datetimepicker = {
        mo: 'Mo',
        tu: 'Tu',
        we: 'We',
        th: 'Th',
        fr: 'Fr',
        sa: 'Sa',
        su: 'Su',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',

        time: 'Time',
        hour: 'Hours',
        minute: 'Minutes',
        second: 'Seconds',

        today: 'Today',
        close: 'Close'
    };
})(webf);

