import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Bold = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-bold'></i>");
    },
    exec: function(editor, $btn, html) {
        editor._execCommand('bold');
    },
    refresh: function(editor, $btn) {
        if (editor._queryCommandState('bold')) {
            $btn.addClass('webf-active');
        } else {
            $btn.removeClass('webf-active');
        }
    },
    types: 'action',
    text_tooltip: 'bold',
    shortcut: {
        Ctrl: true,
        key: 'B'
    }
});

