/** @namespace webf */
const webf = {
    lang: {},

    config: {
        LANG: 'fr',
        responsive: false,
        breakpoints: {
            xs: '<768',
            sm: '>=768',
            md: '>=992',
            lg: '>=1200'
        }
    }
};

/**
 * Callback for iterations functions
 *
 * @callback eachCallback
 * @param {string|integer} key - key of the current iterated property
 * @param {mixed} value - value of the current iterated property
 * @param {mixed} obj - reference of the initial object
 * @param {integer} index - index of the current iterated property
 */

/**
 * Iterates on an object or an array and executes a function foreach value.
 *
 * @function
 * @memberof webf
 * @param {mixed} obj - the object to iterate
 * @param {eachCallback} callback - return false to break the iteration
 * @param {object} context - the binded 'this' object in the callback function
 */
webf.each = function(obj, callback, context)
{
    if (webf.isString(obj)) {
        obj = new String(obj);
    }

    if (webf.isArray(obj)) {
        for (var i=0; i<obj.length; i++) {
            if (callback.call(context || obj[i], i, obj[i], obj, i) === false) {
                return;
            }
        }
    } else if (webf.isObject(obj)) {
        var index = -1;

        for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
                if (callback.call(context || obj[i], i, obj[i], obj, ++index) === false) {
                    return;
                }
            }
        }
    }

    return obj;
};

/**
 * Returns an array executing a callback function on each value
 *
 * @function
 * @memberof webf
 * @param {array|object} obj - the object toi iterate
 * @param {eachCallback} callback - returns null to remove the element
 * @param {object} context - the binded 'this' object in the callback function
 */
webf.map = function(obj, callback, context)
{
    var results = [];

    webf.each(obj, function(index, value, obj, i) {
        var response = callback.call(context, index, value, obj, i);

        if (response !== null) {
            results.push(response);
        }
    });

    return results;
};

/**
 * Merge the contents of two or more objects together into the first object.
 *
 * @function
 * @memberof webf
 * @param {boolean} [deep=false] - deep copy
 * @param {object} dest - the destination object
 * @param {...object} src - source object
 * @return {object} - resulting object
 */
webf.extend = function()
{
    var deep = false;
    if (webf.isBoolean(arguments[0])) {
        deep = [].shift.apply(arguments);
    }

    if (arguments.length < 2 || webf.isUndefined(arguments[0]) || arguments[0] === null) {
        return arguments[0];
    }

    var dest = arguments[0];
    if (!webf.isObject(dest)) {
        arguments[0] = dest = {};
    }

    for (var i = 1; i < arguments.length; i++) {
        var src = arguments[i];
        if (webf.isObject(src)) {
            for (var name in src) {
                if (deep && webf.isPlainObject(src[name])) {
                    dest[name] = webf.extend(true, {}, dest[name], src[name]);
                } else if (webf.isPlainObject(src[name])) {
                    dest[name] = webf.extend({}, src[name]);
                } else {
                    dest[name] = src[name];
                }
            }
        }
    }

    return dest;
};

/**
 * Create a copy of an object
 *
 * @function
 * @memberof webf
 * @param {object} obj
 * @return {object}
 */
webf.clone = function(obj)
{
    if ((!webf.isObject(obj) && !webf.isArray(obj)) || webf.isWindow(obj)) {
        return obj;
    }

    var clone = {};

    webf.each(obj, function(i, value) {
        if (webf.isObject(value)) {
            clone[i] = webf.clone(value);
        } else {
            clone[i] = value;
        }
    });

    return clone;
};

/**
 * Returns true if o1 is deeply equal to o2
 * Source : https://github.com/angular/angular.js/blob/v1.4.9/src/Angular.js#L939
 *
 * @function
 * @memberof webf
 * @param {*} o1
 * @param {*} o2
 * @return {boolean}
 */
webf.equals = function (o1, o2) {
    if (o1 === o2) {
        return true;
    }

    if (o1 === null || o2 === null) {
        return false;
    }

    if (o1 !== o1 && o2 !== o2) {
        return true; // NaN === NaN
    }

    var t1 = typeof o1,
        t2 = typeof o2,
        length, key, keySet;

    if (t1 == t2) {
        if (t1 == 'object') {
            if (webf.isArray(o1)) {
                if (!webf.isArray(o2)) {
                    return false;
                }

                if ((length = o1.length) == o2.length) {
                    for (key = 0; key < length; key++) {
                        if (!webf.equals(o1[key], o2[key])) {
                            return false;
                        }
                    }
                    return true;
                }
            } else if (webf.isDate(o1)) {
                if (!webf.isDate(o2)) {
                    return false;
                }
                return webf.equals(o1.getTime(), o2.getTime());
            } else if (webf.isRegExp(o1)) {
                return webf.isRegExp(o2) ? o1.toString() == o2.toString() : false;
            } else {
                if (webf.isWindow(o1) || webf.isWindow(o2) || webf.isArray(o2) || webf.isDate(o2) || webf.isRegExp(o2)) {
                    return false;
                }

                keySet = Object.create(null);
                for (key in o1) {
                    if (key.charAt(0) === '$' || webf.isFunction(o1[key])) {
                        continue;
                    }
                    if (!webf.equals(o1[key], o2[key])) {
                        return false;
                    }
                    keySet[key] = true;
                }

                for (key in o2) {
                    if (!(key in keySet) && key.charAt(0) !== '$' &&
                        typeof o2[key] !== 'undefined' && !webf.isFunction(o2[key])) {
                        return false;
                    }
                }

                return true;
            }
        }
    }

    return false;
};

/**
 * Returns true if arr is an array
 *
 * @function
 * @memberof webf
 * @param {*} arr
 * @return {boolean}
 */
webf.isArray = function(arr)
{
    return Array.isArray ? Array.isArray(arr) : Object.prototype.toString.call(arr) == '[object Array]';
};

/**
 * Returns true if f is a function
 *
 * @function
 * @memberof webf
 * @param {*} f
 * @return {boolean}
 */
webf.isFunction = function(f)
{
    return typeof f === 'function';
};

/**
 * Returns true if obj is an object
 *
 * @function
 * @memberof webf
 * @param {*} obj
 * @return {boolean}
 */
webf.isObject = function(obj)
{
    return !!obj && !webf.isArray(obj) && typeof obj === 'object';
};

/**
 * Returns true if obj is a plain object
 *
 * @function
 * @memberof webf
 * @param {*} obj
 * @return {boolean}
 */
webf.isPlainObject = function(obj)
{
    var class2type    = {},
        core_hasOwn   = class2type.hasOwnProperty,
        core_toString = class2type.toString;

    webf.each('Boolean Number String Function Array Date RegExp Object Error'.split(' '), function(i, name) {
        class2type['[object ' + name + ']'] = name.toLowerCase();
    });

    var type;
    if (obj == null) {
        type = String(obj);
    } else if (typeof obj === 'object' || typeof obj === 'function') {
        type = class2type[core_toString.call(obj)] || 'object';
    } else {
        type = typeof obj;
    }

    // Must be an Object.
    // Because of IE, we also have to check the presence of the constructor property.
    // Make sure that DOM nodes and window objects don't pass through, as well
    if (!obj || type !== 'object' || obj.nodeType || webf.isWindow(obj)) {
        return false;
    }

    try {
        // Not own constructor property must be Object
        if (obj.constructor &&
            !core_hasOwn.call(obj, 'constructor') &&
            !core_hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')) {
            return false;
        }
    } catch (e) {
        // IE8,9 Will throw exceptions on certain host objects #9897
        return false;
    }

    // Own properties are enumerated firstly, so to speed up,
    // if last one is own, then all properties are own.

    var key;
    for (key in obj) {}

    return key === undefined || core_hasOwn.call(obj, key);
};

webf.isDomElement = function(obj)
{
    try {
        return obj instanceof HTMLElement;
    } catch(e) {
        return webf.isObject(obj) && obj.nodeType === 1;
    }
};

/**
 * Returns true if obj is a Date
 *
 * @function
 * @memberof webf
 * @param {string} obj
 * @return {boolean}
 */
webf.isDate = function (obj) {
    return Object.prototype.toString.call(obj) === '[object Date]'
};

/**
 * Returns true if obj is a RegExp
 *
 * @function
 * @memberof webf
 * @param {string} obj
 * @return {boolean}
 */
webf.isRegExp = function (obj) {
    return Object.prototype.toString.call(obj) === '[object RegExp]'
};

/**
 * Returns the number of properties in obj
 *
 * @function
 * @memberof webf
 * @param {object|array} obj
 * @return {integer}
 */
webf.sizeOf = function(obj)
{
    return webf.map(obj, webf.noop).length;
};

/**
 * Returns true if str is a string
 *
 * @function
 * @memberof webf
 * @param {string} str
 * @return {boolean}
 */
webf.isString = function(str)
{
    return typeof(str) == 'string' || Object.prototype.toString.call(str) == '[object String]';
};

/**
 * Returns true if bool is a boolean
 *
 * @function isBoolean
 * @memberof webf
 * @param {boolean} bool
 * @return {boolean}
 */
webf.isBoolean = webf.isBool = function(bool)
{
    return bool === true || bool === false;
};

/**
 * Returns true if val is undefined
 *
 * @function
 * @memberof webf
 * @param {*} val
 * @return {boolean}
 */
webf.isUndefined = function(val)
{
    return val === undefined;
};

/**
 * Returns true if obj is a window object
 *
 * @function
 * @memberof webf
 * @param {*} val
 * @return {boolean}
 */
webf.isWindow = function(obj)
{
    return obj && obj == obj.window;
};

/**
 * Returns true if obj is a document object
 *
 * @function
 * @memberof webf
 * @param {*} val
 * @return {boolean}
 */
webf.isDocument = function(obj)
{
    return obj && obj.nodeType == 9;
};

/**
 * Returns true if obj is an Event object
 *
 * @function
 * @memberof webf
 * @param {*} val
 * @return {boolean}
 */
webf.isEvent = function(obj)
{
    return webf.isObject(obj) && !!obj.preventDefault || /\[object Event\]/.test(obj.constructor.toString());
};

/**
 * Search the key associated to a value in an array from an index
 *
 * @function
 * @memberof webf
 * @param {array} arr - array to search through
 * @param {mixed} elt - the value to search
 * @param {integer} from=0 - 0 indexed base from, if negative, it indicates an index from the end
 * @return {integer}
 */
webf.indexOf = function(arr, elt, from)
{
    var len = arr.length;

    from = from || 0;
    from = (from < 0)
        ? Math.ceil(from)
        : Math.floor(from)
    ;

    if (from < 0) {
        from += len;
    }

    for (; from<len; from++) {
        if (from in arr && arr[from] === elt) {
            return from;
        }
    }

    return -1;
};

/**
 * Rounds a value
 *
 * @function
 * @memberof webf
 * @param {float|integer} val
 * @param {integer} [precision=0]
 */
webf.round = function(val, precision)
{
    return Math.round(val * Math.pow(10, precision || 0)) / Math.pow(10, precision || 0);
};

webf.plancher = function(n, precison)
{
    return Math.floor(n / precison) * precison;
};

/**
 * Returns true if a value is found in an array
 *
 * @function
 * @memberof webf
 * @param {mixed} value - the value to search
 * @param {array} arr - the array in which to search
 * @param {integer} [index=0] - the index to search from
 * @param {boolean} [strict=false]
 * @return {boolean}
 */
webf.inArray = function(value, arr, index, strict)
{
    var ret = false;
    index = index || 0;
    strict = strict || false;

    webf.each(arr, function(i, val) {
        if (i >= index) {
            if (strict) {
                if (val === value) {
                    ret = true;
                    return false;
                }
            } else {
                if (val == value) {
                    ret = true;
                    return false;
                }
            }
        }
    });

    return ret;
};

/**
 * Merge 2 arrays, without altering them
 *
 * @function
 * @memberof webf
 * @param {array} first
 * @param {...array} second
 * @return Array
 */
webf.merge = function(first, second)
{
    var result = webf.map(first, function(i, elem) {
        return elem;
    });

    webf.each(second, function(i, elem) {
        result.push(elem);
    });

    if (arguments.length > 2) {
        return webf.merge(result, arguments[2]);
    }

    return result;
};

/**
 * Returns the min value of a list using a user function
 *
 * @function
 * @memberof webf
 * @param {object|array} list
 * @param {function} cmp_func - comparison function : must return -1, 0 or 1.
 * @return {*}
 */
webf.min = function(list, cmp_func)
{
    if (webf.isArray(list)) {
        return Math.min.apply(null, list);
    }

    var min;

    cmp_func = webf.isFunction(cmp_func)
        ? cmp_func
        : function(a, b) {
            return a < b ? -1 : 1;
        }
    ;

    if (webf.isObject(list)) {
        webf.each(list, function(key, val, obj, index) {
            if (index == 0) {
                min = val;
            } else {
                min = cmp_func.call(null, min, val) > 0 ? val : min;
            }
        });
    }

    return min;
};

/**
 * Returns the max value of a list using a user function
 *
 * @function
 * @memberof webf
 * @param {object|array} list
 * @param {function} cmp_func - comparison function : must return -1, 0 or 1.
 * @return {*}
 */
webf.max = function(list, cmp_func)
{
    if (webf.isArray(list)) {
        return Math.max.apply(null, list);
    }

    var max;

    cmp_func = webf.isFunction(cmp_func)
        ? cmp_func
        : function(a, b) {
            return a < b ? 1 : -1;
        }
    ;

    if (webf.isObject(list)) {
        webf.each(list, function(key, val, obj, index) {
            if (index == 0) {
                max = val;
            } else {
                max = cmp_func.call(null, max, val) > 0 ? val : max;
            }
        });
    }

    return max;
};

/**
 * Generates an uniqid
 *
 * @function
 * @memberof webf
 * @return {string}
 */
webf.uniqid = function()
{
    return webf.randAlpha(10);
};

/**
 * Generates a random alphabetic string in lower case
 *
 * @function
 * @memberof webf
 * @param {integer} n - length of the resulting string
 * @return {string}
 */
webf.randAlpha = function(n)
{
    return webf.rand("a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(','), n);
};

/**
 * Generates a random alphabetic string in lower and upper case
 *
 * @function
 * @memberof webf
 * @param {integer} n - length of the resulting string
 * @return {string}
 */
webf.randAlphaCs = function(n)
{
    return webf.rand("a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z".split(','), n);
};

/**
 * Generates a random alphanumeric string and integers in lower case
 *
 * @function
 * @memberof webf
 * @param {integer} n - length of the resulting string
 * @return {string}
 */
webf.randAlphaNum = function(n)
{
    return webf.rand("0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z".split(','), n);
};

/**
 * Generates a random alphanumeric string in lower and upper case
 *
 * @function
 * @memberof webf
 * @param {integer} n - length of the resulting string
 * @return {string}
 */
webf.randAlphaNumCs = function(n)
{
    return webf.rand("0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z".split(','), n);
};

/**
 * Generates a random numeric string
 *
 * @function
 * @memberof webf
 * @param {integer} n - length of the resulting string
 * @return {string}
 */
webf.randNum = function(n)
{
    return webf.rand("0,1,2,3,4,5,6,7,8,9".split(','), n);
};

/**
 * Returns a formatted string
 *
 * @function
 * @memberof webf
 * @param {array} range - range of values for the resulting string
 * @param {integer} n - length of the resulting string
 * @return {string} a random string
 */
webf.rand = function(range, n)
{
    var rand = "";

    for (var i=0; i<n; i++) {
        rand += range[Math.floor(Math.random() * 1000) % range.length];
    }

    return rand;
};

/**
 * Format a number
 *
 * @function
 * @memberof webf
 * @param {string|float|integer} price - the price to format
 * @param {integer} [decimals=2] - number of decimals to display
 * @param {boolean} [forceCentimes=false] - forces the display of decimals even if there is no.
 * @param {string} [thousandSep=''] - Thousand separator
 * @param {string} [pointDecimal='.'] - decimal point
 * @return {string} the formatted price
 */
webf.numberFormat = webf.toPrice = function(price, decimals, forceCentimes, thousandSep, pointDecimal)
{
    decimals = webf.isUndefined(decimals) ? 2 : decimals;
    price = webf.round(price,decimals) + "";
    forceCentimes = webf.isUndefined(forceCentimes) ? false : forceCentimes;
    thousandSep = webf.isUndefined(thousandSep) ? '' : thousandSep;
    pointDecimal = webf.isUndefined(pointDecimal) ? '.' : pointDecimal;

    var separator = '.';
    price = price.replace(',',separator);

    if (decimals == 0) {
        return webf.thousandSeparator(price.indexOf(separator) > -1 ? price.substr(0,price.indexOf(separator)) : price, thousandSep, pointDecimal);
    }

    var pos = price.lastIndexOf(separator);
    if (pos === 0) {
        price += separator + "0".repeat(decimals);
        return price;
    }

    if (pos == -1) {
        if (forceCentimes === true) {
            price += pointDecimal + "0".repeat(decimals);
        }

        return webf.thousandSeparator(price,thousandSep,pointDecimal);
    }

    var chiffres = price.substr(pos+1);
    var nbDigits = chiffres.length;
    if (decimals > nbDigits) {
        return webf.thousandSeparator(price + "0".repeat(decimals - nbDigits), thousandSep, pointDecimal);
    }

    return webf.thousandSeparator(price.substr(0,pos+1+decimals), thousandSep, pointDecimal);
};

/**
 * format a number with a thousand separator
 *
 * @function
 * @memberof webf
 * @param value
 * @param {string} [separator=''] - Thousand separator
 * @param {string} [pointDecimal='.'] - decimal point
 * @return {string}
 */
webf.thousandSeparator = function(value, separator, pointDecimal)
{
    pointDecimal = pointDecimal || '.';
    separator = separator || '';
    value = (value + '').replace(',', '.');

    if (value >= 1000) {
        var intval = Math.floor(value) + '';
        var newval = (intval.reverse().insert(separator.reverse(), 3)).reverse();

        return value.indexOf(".") > 0 ? newval + pointDecimal + value.substringIndex(".",-1) : newval;
    }

    return (value + '').replace('.', pointDecimal);
};

/**
 * Returns a value with min <= value <= max
 *
 * @param {float|integer} value
 * @param {float|integer} min
 * @param {float|integer} max
 * @return {float|integer}
 */
webf.between = function(value, min, max)
{
    if (min === null) {
        return max === null ? value : Math.min(max, value);
    }

    return max === null
        ? Math.max(min, value)
        : Math.max(min, Math.min(max, value));
};

/**
 * Decimal to hexadecimal converter
 *
 * @param string hex
 * @return Number
 */
webf.dec2hex = function(n)
{
    n = parseInt(n);
    var c = 'ABCDEF';
    var b = n / 16;
    var r = n % 16;

    b = b - (r / 16);
    b = ((b >= 0) && (b <= 9)) ? b : c.charAt(b - 10);
    return ((r>=0) && (r<=9)) ? b + '' + r : b + '' + c.charAt(r - 10);
};

webf.rgbtohex = function(r, g, b)
{
    return webf.dec2hex(r) + webf.dec2hex(g) + webf.dec2hex(b);
};

/**
 * Retourne une valeur décimale à partir d'une chaîne hexadécimale
 *
 * @param string hex
 * @return Number
 */
webf.hex2dec = function(hex)
{
    hex = (hex + '').reverse().toUpperCase();
    var c = '0123456789ABCDEF';
    var value = 0;

    for (var i=0; i<hex.length; i++) {
        value += ((c.indexOf(hex.charAt(i))) * Math.pow(2, 4*i));
    }

    return value;
};

webf.readCookie = function(name)
{
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i=0; i<ca.length; i++) {
        var c = ca[i].ltrim();

        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }

    return null;
};

webf.createCookie = function(name, value, seconds)
{
    var expires = "";

    if (seconds) {
        var date = new Date();
        date.setTime(date.getTime() + (seconds*1000));
        expires = "; expires=" + date.toGMTString();
    }

    document.cookie = name + "=" + value + expires + "; path=/";
};

webf.eraseCookie = function(name)
{
    webf.createCookie(name, "", -1);
};

webf.arrayIntersect = function(arr1, arr2)
{
    var arr = webf.map(arr1, function(i, val) {
        if (!webf.inArray(val, arr2)) {
            return null;
        }

        return val;
    });

    if (arguments.length > 2) {
        var args = [].slice.call(arguments);
        args.splice(0, 2, arr);
        return webf.arrayIntersect.apply(null, args);
    }

    return arr;
};

webf.isInt = webf.isInteger = function(n)
{
    return /^[\-]?\d+$/.test(n + '');
};

webf.isFloat = function(n)
{
    return /^[\-]?\d+(\.\d+)?$/.test(n + '');
};

webf.boxDimensions = function (width, height, maxWidth, maxHeight, fill)
{
    var ratioWidth = width/(maxWidth ? maxWidth : -1);
    var ratioHeight = height/(maxHeight ? maxHeight : -1);
    var newWidth, newHeight;

    if (!maxWidth) {
        newHeight = maxHeight;
        newWidth = Math.round(width/ratioHeight);
    }

    if (!maxHeight) {
        newWidth = maxWidth;
        newHeight = Math.round(height/ratioWidth);
    }

    if (fill) {
        if (ratioWidth < ratioHeight) {
            newWidth = maxWidth;
            newHeight = Math.round(height/ratioWidth);
        } else {
            newWidth = Math.round(width/ratioHeight);
            newHeight = maxHeight;
        }
    } else if (ratioWidth <= 1 && ratioHeight <= 1) {
        newWidth = width;
        newHeight = height;
    } else {
        if (ratioWidth < ratioHeight) {
            newHeight = maxHeight;
            newWidth = Math.round(width/ratioHeight);
        } else {
            newWidth = maxWidth;
            newHeight = Math.round(height/ratioWidth);
        }
    }

    var res = [];
    res['width'] = newWidth;
    res['height'] = newHeight;

    return res;
};

webf.parse_url = function(str, component)
{
    var key = ['source', 'scheme', 'authority', 'userInfo', 'user', 'pass', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'fragment'],
        ini = (this.php_js && this.php_js.ini) || {},
        mode = (ini['phpjs.parse_url.mode'] && ini['phpjs.parse_url.mode'].local_value) || 'php',
        parser = {
            php: /^(?:([^:\/?#]+):)?(?:\/\/()(?:(?:()(?:([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?()(?:(()(?:(?:[^?#\/]*\/)*)()(?:[^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
            strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
            loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/\/?)?((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/ // Added one optional slash to post-scheme to catch file:/// (should restrict this)
        }
    ;

    var m = parser[mode].exec(str),
        uri = {},
        i = 14;

    while (i--) {
       if (m[i]) {
           uri[key[i]] = m[i];
       }
    }

    if (component) {
        return uri[component.replace('PHP_URL_', '').toLowerCase()];
    }

    if (mode !== 'php') {
        var name = (ini['phpjs.parse_url.queryKey'] && ini['phpjs.parse_url.queryKey'].local_value) || 'queryKey';
        parser = /(?:^|&)([^&=]*)=?([^&]*)/g;
        uri[name] = {};
        uri[key[12]].replace(parser, function ($0, $1, $2) {
           if ($1) {uri[name][$1] = $2;}
        });
    }

    delete uri.source;
    return uri;
};

webf.addUrlParam = function(url, param, value)
{
    let parseUrl = webf.parse_url(url), pos, hash = "";

    if (webf.isPlainObject(param)) {
        webf.each(param, function(key, val) {
            url = webf.addUrlParam(url, key, val);
        });
    } else {
        if ((pos = url.indexOf("#")) > -1) {
            hash = url.substr(pos);
        }

        if (hash) {
            url = url.substring(0, url.indexOf(hash));
        }

        if (!parseUrl.query) {
            url += "?" + param + "=" + value + hash;
        } else {
            const params = parseUrl.query.split('&');
            let param_exists = false;

            for (var i = 0; i < params.length; i++) {
                if (params[i].indexOf(param + "=") > -1) {
                    param_exists = true;
                    params[i] = param + "=" + value;
                }
            }

            if (param_exists == false) {
                params.push(param + "=" + value);
            }

            if (parseUrl.scheme && parseUrl.host) {
                url = parseUrl.scheme + '://' + parseUrl.host + parseUrl.path + "?" + params.join("&") + hash;
            } else {
                url = parseUrl.path + "?" + params.join("&") + hash;
            }
        }
    }

    return url;
};

/**
 * Retourne un objet aplati
 *
 * @param Object obj
 * @return Array
 */
webf.flatten = function(obj)
{
    if (webf.isObject(obj) || webf.isArray(obj)) {
        return [].concat.apply([], webf.map(obj, function(i, val) {
            return webf.flatten(val);
        }));
    } else {
        return obj;
    }
};

webf.noop = function() {};

webf._ = webf.translate = function(lang, ns, label)
{
    if (!webf.lang || !webf.lang[lang] || !webf.lang[lang][ns]) {
        return label;
    }

    var tr = webf.lang[lang][ns][label];

    if (webf.isFunction(tr)) {
        return tr.apply(null, [].slice.call(arguments, 3));
    }

    return webf.lang[lang][ns][label] || label;
};

/**
 * Surligne en mettant entre des balises tag les occurrences de q dans la chaîne str
 *
 * @param {string} str
 * @param {string|string[]} req
 * @param {string} tag = 'strong'
 * @return {String}
 */
webf.hilite = function(str, req, tag = 'strong')
{
    str = str.decodeHtml();
    let str_folded = str.noAccent().toLowerCase().replace(/[\[\]]+/g, '');
    let q_folded, re, hilite_hints = '';

    if (!webf.isArray(req)) {
        req = [req];
    }

    if (webf.isArray(req)) {
        webf.each(req, (i, q) => {
            if (q.length) {
                q = q.decodeHtml();
                q_folded = q.noAccent().toLowerCase().replace(/[\[\]]+/g, '');

                re = new RegExp(q_folded.escapeRegex(), 'g');
                hilite_hints = str_folded.replace(re, '[' + q_folded + ']');

                str_folded = hilite_hints;
            }
        })
    }

    if (!hilite_hints.length) {
        return str;
    }

    let spos = 0, i;
    let highlighted = '';
    let dirHook = 'end';

    for (i=0; i<hilite_hints.length; i++) {
        const c = str.charAt(spos);
        const h = hilite_hints.charAt(i);

        if (h === '[') {
            if (dirHook == 'end') {
                highlighted += '<' + tag + '>';
                dirHook = 'start';
            }
        } else if (h === ']') {
            if (dirHook == 'start') {
                highlighted += '</' + tag + '>';
                dirHook = 'end';
            }
        } else {
            spos += 1;
            highlighted += c;
        }
    }

    return highlighted
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(new RegExp('&lt;' + tag + '&gt;', 'g'), '<' + tag + '>')
        .replace(new RegExp('&lt;\/' + tag + '&gt;', 'g'), '</' + tag + '>')
        .replace(new RegExp('&lt;br&gt;', 'g'), '<br>')
    ;
};

// http://stackoverflow.com/questions/4928586/get-caret-position-in-html-input
webf.getInputSelection = function(el)
{
    var start = 0, end = 0, normalizedValue, range,
        textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
        start = el.selectionStart;
        end = el.selectionEnd;
    } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                start = end = len;
            } else {
                start = -textInputRange.moveStart("character", -len);
                start += normalizedValue.slice(0, start).split("\n").length - 1;

                if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                    end = len;
                } else {
                    end = -textInputRange.moveEnd("character", -len);
                    end += normalizedValue.slice(0, end).split("\n").length - 1;
                }
            }
        }
    }

    return {
        start: start,
        end: end
    };
};

// http://stackoverflow.com/questions/2897155/get-cursor-position-in-characters-within-a-text-input-field
webf.getCaretPosition = function(oField)
{
    var iCaretPos = 0;

    if (document.selection) {
        oField.focus();
        var oSel = document.selection.createRange();
        oSel.moveStart('character', -oField.value.length);
        iCaretPos = oSel.text.length;
    } else if (oField.selectionStart) {
        iCaretPos = oField.selectionStart;
    }

    return iCaretPos;
};

webf.setCaretPosition = function(elem, caretPos)
{
    if (elem.createTextRange) {
        const range = elem.createTextRange();
        range.move('character', caretPos);
        range.select();
    } else {
        if (elem.selectionStart) {
            elem.focus();
            elem.setSelectionRange(caretPos, caretPos);
        } else {
            elem.focus();
        }
    }
}

webf.formatSize = function(bytes, thousandSeparator, decimalPoint)
{
    thousandSeparator = !webf.isUndefined(thousandSeparator) ? thousandSeparator : '';
    decimalPoint = !webf.isUndefined(decimalPoint) ? decimalPoint : ',';
    bytes = !webf.isUndefined(bytes) ? bytes : 0;

    var i = -1,
        decimals = 0;

    do {
        bytes /= 1024;
        i++;
    } while (bytes > 999);

    if (!webf.isInteger(bytes)) {
        decimals = 1;
    }

    return webf.numberFormat(Math.max(bytes, 0), decimals, true, thousandSeparator, decimalPoint) + [' ko', ' Mo', ' Go', ' To', ' Po', ' Eo'][i];
};

/**
 * Surcharge de setTimeout et setInterval afin de pouvoir binder le this
 */
webf.each(['setTimeout', 'setInterval'], function(i, funcname) {
    const _native_ = funcname === 'setTimeout' ? setTimeout : setInterval

    webf[funcname] = function(callback, delay, context) {
        var args = [].slice.call(arguments, 3);

        return _native_(function() {
            callback.apply(context, args);
        }, delay);
    };
});

webf.serializeToForm = function(datas, objName)
{
    var results = [];

    webf.each(datas, function(key, data) {

        var appendToResult = function(name, d)
        {
            if (webf.isObject(d) || webf.isArray(d)) {
                webf.each(d, function(index, value) {
                    var basename = name + '[' + index + ']';
                    var o = appendToResult(basename, value);
                    if (webf.isObject(o)) {
                        results.push( {
                            name: basename,
                            value: webf.isUndefined(o.value) ? '' : o.value
                        });
                    }
                });
            } else {
                return {
                    name: name,
                    value: d
                };
            }
        };

        if (webf.isObject(data) || webf.isArray(data)) {
            appendToResult(objName + '[' + key + ']', data);
        }
    });

    return results;
};

webf.getSelection = function(win)
{
    win = webf.getContentWindow(webf.isUndefined(win) ? window : win);

    if (!webf.isUndefined(win.getSelection)) {
        return win.getSelection();
    } else if (win.document.selection) {
        return win.document.selection;
    }
};

webf.getSelectionHtml = function(win)
{
    win = webf.getContentWindow(webf.isUndefined(win) ? window : win);

    var html = "";

    if (!webf.isUndefined(win.getSelection)) {
        var sel = win.getSelection();

        if (sel.rangeCount) {
            var container = win.document.createElement("div");
            container.appendChild(sel.getRangeAt(0).cloneContents());

            html = container.innerHTML;
        }
    } else if (!webf.isUndefined(win.document.selection)) {
        if (win.document.selection.type == "Text") {
            html = win.document.selection.createRange().htmlText;
        }
    }

    return html;
};

// http://stackoverflow.com/questions/6690752/insert-html-at-caret-in-a-contenteditable-div/6691294#6691294
webf.pasteHtmlAtCaret = function(win, html, selectPastedContent)
{
    win = webf.getContentWindow(webf.isUndefined(win) ? window : win);

    var sel, range;

    if (win.getSelection) {
        // IE9 and non-IE
        sel = win.getSelection();
        if (sel.getRangeAt) {
            if (!sel.rangeCount) {
                range = win.document.createRange();
                range.setStart(win.document.getElementsByTagName('body')[0], 0);
                sel.addRange(range);
            }

            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            var el = win.document.createElement("div");
            el.innerHTML = html;
            var frag = win.document.createDocumentFragment(), node, lastNode;
            while ( (node = el.firstChild) ) {
                lastNode = frag.appendChild(node);
            }
            var firstNode = frag.firstChild;
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                if (selectPastedContent) {
                    if (!firstNode.childNodes.length) {
                        firstNode.appendChild(document.createTextNode("\u200B"));
                        range.setStartAfter(firstNode);
                    } else {
                        range.setStartBefore(firstNode);
                    }
                } else {
                    range.collapse(true);
                }
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if ((sel = win.document.selection) && sel.type != "Control") {
        // IE < 9
        var originalRange = sel.createRange();
        originalRange.collapse(true);
        sel.createRange().pasteHTML(html);
        if (selectPastedContent) {
            range = sel.createRange();
            range.setEndPoint("StartToStart", originalRange);
            range.select();
        }
    }
};

webf.insertTextAtCursor = function(input, text)
{
    const value = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    input.value = value.slice(0, start) + text + value.slice(end);
    input.selectionStart = input.selectionEnd = start + text.length;
};

webf.getContentWindow = function(iframe)
{
    if (iframe.tagName != 'IFRAME') {
        return iframe;
    }

    if (iframe.contentWindow) {
        iframe = iframe.contentWindow;
    } else {
        if (iframe.contentDocument && iframe.contentDocument.document) {
            iframe = iframe.contentDocument.document;
        } else {
            iframe = iframe.contentDocument;
        }
    }

    return iframe;
};

webf.isOrContains = function(node, container)
{
    while (node) {
        if (node === container) {
            return true;
        }

        node = node.parentNode;
    }

    return false;
};

webf.elementContainsSelection = function(el, range/* =null */)
{
    var sel;

    if (window.getSelection) {
        sel = window.getSelection();

        if (range) {
            if (!webf.isOrContains(range.commonAncestorContainer, el)) {
                return false;
            }
        } else if (sel.rangeCount > 0) {
            for (var i = 0; i < sel.rangeCount; ++i) {
                if (!webf.isOrContains(sel.getRangeAt(i).commonAncestorContainer, el)) {
                    return false;
                }
            }
        }

        return true;
    } else if ((sel = document.selection) && sel.type != "Control") {
        range = range ? range : sel.createRange();

        return webf.isOrContains(range.parentElement(), el);
    }

    return false;
};

webf.compareArray = function(a1, a2)
{
    if (a1.length != a2.length) {
        return false;
    } else {
        for (var a=0; a<a1.length; a++) {
            if (webf.isArray(a1[a])) {
                if (!webf.isArray(a2[a])) {
                    return false;
                } else {
                    return webf.compareArray(a1[a], a2[a]);
                }
            } else if (a1[a] != a2[a]) {
                return false;
            }
        }
    }

    return true;
};


webf.parseFloat = function(val)
{
    if (!val) return 0;

    return parseFloat((val + '')
        .replace(/\s/g, '')
        .replace(',', '.'));
};

webf.getStyle = function(elem, cssRule)
{
    if (webf.isDomElement(elem)) {
        if (document.defaultView && document.defaultView.getComputedStyle) {
            let style = document.defaultView.getComputedStyle(elem, '').getPropertyValue(cssRule);

            return (style + '').length ? style : (elem.style[cssRule] + '').length ? elem.style[cssRule] : '';
        } else if (elem.currentStyle) {
            return elem.currentStyle[cssRule.camelCase()];
        }
    }

    return '';
};

/**
 * returns true if coords are in rect
 *
 * @param {Object} coords
 * @param {ClientRect} rect
 * @return bool
 */
webf.contains = function(coords, rect)
{
    return !!(coords.x >= rect.left && coords.x <= rect.right && coords.y >= rect.top && coords.y <= rect.bottom);
};

webf.flip = function(obj)
{
    var invObj = {};

    webf.each(obj, function(key, val) {
        invObj[val] = key;
    });

    return invObj;
};

webf.isEventSupported = (function(){
    var TAGNAMES = {
        'select':'input',
        'change':'input',
        'submit':'form',
        'reset':'form',
        'error':'img',
        'load':'img',
        'abort':'img'
    };

    function isEventSupported(eventName) {
        var el = document.createElement(TAGNAMES[eventName] || 'div');
        eventName = 'on' + eventName;
        var isSupported = (eventName in el);
        if (!isSupported) {
            el.setAttribute(eventName, 'return;');
            isSupported = typeof el[eventName] == 'function';
        }
        el = null;
        return isSupported;
    }

    return isEventSupported;
})();

webf.isTouchDevice = function()
{
    return webf.isEventSupported('touchstart');
};

webf._scrollBarWith = null;

webf.getScrollbarWidth = function()
{
    if (webf._scrollBarWith === null) {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar";

        document.body.appendChild(outer);

        var widthNoScroll = outer.offsetWidth;
        outer.style.overflow = "scroll";

        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        var widthWithScroll = inner.offsetWidth;

        outer.parentNode.removeChild(outer);

        webf._scrollBarWith = widthNoScroll - widthWithScroll;
    }

    return webf._scrollBarWith;
};

webf.cleanIntArray = function(array)
{
    var i, j, len = array.length, out = [], obj = {};

    for (i = 0; i < len; i++) {
        obj[array[i]] = 0;
    }

    for (j in obj) {
        out.push(parseInt(j));
    }

    return out;
};

/**
 * Sélectionne le texte du noeud
 */
webf.selectText = function(node)
{
    var range;

    if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        var selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
    }
};

webf.range = function(size, startAt)
{
    startAt = !webf.isUndefined(startAt) ? startAt : 0;

    var rng = [];

    for (var i=startAt; i<size + startAt; i++) {
        rng.push(i);
    }

    return rng;
}

/**
 * Retourne le chemin du dossier parent
 *
 * @param path
 * @return {string}
 */
webf.dirname = function(path)
{
    return path.replace(/\\/g, '/')
        .replace(/\/[^/]*\/?$/, '');
};

/**
 */
webf.sortObjects = function(unordered, compareFunction)
{
    var ordered = {};

    Object.keys(unordered).sort(webf.isFunction(compareFunction) ? compareFunction : undefined).forEach(function(key) {
        ordered[key] = unordered[key];
    });

    return ordered;
};

webf.range = function(size, startAt)
{
    startAt = !webf.isUndefined(startAt) ? startAt : 0;

    var rng = [];

    for (var i=startAt; i<size + startAt; i++) {
        rng.push(i);
    }

    return rng;
}

/**
 * Retourne une fonction qui, tant qu'elle est appelée,
 * n'est exécutée au plus qu'une fois toutes les N millisecondes.
 * Paramètres :
 *  - func : la fonction à contrôler
 *  - wait : le nombre de millisecondes (période N) à attendre avant
 *           de pouvoir exécuter à nouveau la function func()
 *  - leading (optionnel) : Appeler également func() à la première
 *                          invocation (Faux par défaut)
 *  - trailing (optionnel) : Appeler également func() à la dernière
 *                           invocation (Faux par défaut)
 *  - context (optionnel) : le contexte dans lequel appeler func()
 *                          (this par défaut)
 */
webf.throttle = function(func, wait, leading, trailing, context)
{
    var ctx, args, result;
    var timeout = null;
    var previous = 0;
    var later = function() {
        previous = new Date;
        timeout = null;
        result = func.apply(ctx, args);
    };

    function throttleFunc() {
        var now = new Date;
        if (!previous && !leading) previous = now;
        var remaining = wait - (now - previous);
        ctx = context || this;
        args = arguments;

        // Si la période d'attente est écoulée
        if (remaining <= 0) {
            // Réinitialiser les compteurs
            clearTimeout(timeout);
            timeout = null;
            // Enregistrer le moment du dernier appel
            previous = now;
            // Appeler la fonction
            result = func.apply(ctx, args);
        } else if (!timeout && trailing) {
            // Sinon on s’endort pendant le temps restant
            timeout = setTimeout(later, remaining);
        }
        return result;
    }

    throttleFunc.cancel = function() {
        clearTimeout(timeout);
    };

    return throttleFunc;
};

webf.debounce = function(func, wait, immediate, context) {
    var result;
    var timeout = null;
    function debouncedFunc() {
        var ctx = context || this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) result = func.apply(ctx, args);
        };
        var callNow = immediate && !timeout;
        // Tant que la fonction est appelée, on reset le timeout.
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) result = func.apply(ctx, args);
        return result;
    }

    debouncedFunc.cancel = function() {
        clearTimeout(timeout);
    };

    return debouncedFunc;
};

webf.isInDOM = function(node)
{
    return document.documentElement.contains(node);
};

/**
 * Retourne true si les 2 Element sont en collision
 */
webf.collide = function(elem1, elem2)
{
    var rect1 = elem1.getBoundingClientRect(),
        rect2 = elem2.getBoundingClientRect();

    if (rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y)
    {
        return true;
    }

    return false;
}

webf.array_unique = function(arr){
    return arr.filter(function(el, index, arr) {
        return index == arr.indexOf(el);
    });
}

webf.sortMixAlphaDigits = (a, b) => {
    let nbA = '';
    let idxDigitA = 0;

    webf.each(a, (i, c) => {
        if (!nbA) {
            idxDigitA = i;
            if (c >= '0' && c <= '9') {
                nbA += c;
            }
        } else {
            if (c >= '0' && c <= '9') {
                nbA += c;
            } else {
                return false;
            }
        }
    });

    let nbB = '';
    let idxDigitB = 0;

    webf.each(b, (i, c) => {
        if (!nbB) {
            idxDigitB = i;
            if (c >= '0' && c <= '9') {
                nbB += c;
            }
        } else {
            if (c >= '0' && c <= '9') {
                nbB += c;
            } else {
                return false;
            }
        }
    });

    if (nbA.length && nbB.length && idxDigitA == idxDigitB) {
        if (idxDigitA > 0 && idxDigitB > 0) {
            if (a.substring(0, idxDigitA) == b.substring(0, idxDigitB)) {
                return parseInt(nbA) > parseInt(nbB) ? 1 : -1;
            }
        } else {
            return parseInt(nbA) > parseInt(nbB) ? 1 : -1;
        }
    }

    return (a > b) ? 1 : ((b > a) ? -1 : 0);
}

webf.arrayDiff = (array1, array2) => {
    return array1.filter(item => !array2.includes(item))
}

/**
 * Remplace les espaces multiples de la chaîne par un espace simple
 */
if (!String.prototype.stripMultipleSpaces)
    String.prototype.stripMultipleSpaces = function()
    {
        return this.trim().replace(/ +/g," ");
    };

/**
 * Supprime les caractères blancs en début et fin de chaîne
 */
if (!String.prototype.trim)
    String.prototype.trim = function(char)
    {
        if (webf.isUndefined(char)) {
            char = '\\s';
        }

        return this.ltrim(char).rtrim(char);
    };

/**
 * Supprime les caractères blancs en début de chaîne
 */
if (!String.prototype.ltrim)
    String.prototype.ltrim = function(char)
    {
        if (webf.isUndefined(char)) {
            char = '\\s';
        }

        return this.replace(new RegExp('^' + char + '+', 'g'), '');
    };

/**
 * Supprime les caractères blancs en fin de chaîne
 */
if (!String.prototype.rtrim)
    String.prototype.rtrim = function(char)
    {
        if (webf.isUndefined(char)) {
            char = '\\s';
        }

        return this.replace(new RegExp(char + '+$', 'g'), '');
    };

/**
 * Remplace les caractères accentués par leur équivalent sans accent
 */
if (!String.prototype.noAccent)
    String.prototype.noAccent = function()
    {
        return this
            .replace(/[àäâ]/g, 'a')
            .replace(/[èéêë]/g, 'e')
            .replace(/[îïí]/g, 'i')
            .replace(/[öô]/g, 'o')
            .replace(/[üù]/g, 'u')
            .replace(/[ç]/g, 'c')
            .replace(/[ÿ]/g, 'y')
            .replace(/[ÀÄÂ]/g, 'A')
            .replace(/[ÈÉÊË]/g, 'E')
            .replace(/[ÎÏÍ]/g, 'I')
            .replace(/[ÖÔ]/g, 'O')
            .replace(/[ÜÙ]/g, 'U')
            .replace(/[Ç]/g, 'C')
            .replace(/[Ÿ]/g, 'Y')
        ;
    };

/**
 * Remplace les balises "<br>" par des "\n"
 */
if (!String.prototype.br2nl)
    String.prototype.br2nl = function()
    {
        return this.split('<br>').join('\n');
    };

/**
 * Remplace les \n par des des balises <br>
 */
if (!String.prototype.nl2br)
    String.prototype.nl2br = function()
    {
        return this.split('\n').join('<br>\n');
    };

/**
 * Remplace les <br> par des \n
 */
if (!String.prototype.br2nl)
    String.prototype.br2nl = function() {
        return this.split('<br>').join('\n').split('<BR>').join('\n');
    };

/**
 * Met le premier caractère en majuscule
 */
if (!String.prototype.ucfirst)
    String.prototype.ucfirst = function()
    {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };

/**
 * Met le premier caractère en minuscule
 */
if (!String.prototype.lcfirst)
    String.prototype.lcfirst = function()
    {
        return this.charAt(0).toLowerCase() + this.slice(1);
    };

/**
 * Entoure la sous chaîne définie par position et length par tagBegin et tagEnd
 *
 * @param tagBegin
 * @param tagEnd
 * @param position
 * @param length
 */
if (!String.prototype.insertTag)
    String.prototype.insertTag = function(tagBegin, tagEnd, position, length)
    {
        return this.substr(0,position) + tagBegin + this.substr(position,length) + tagEnd + this.substr(position + length);
    };

/**
 * Equivalent de la fonction SUBSTRING_INDEX de mysql.
 */
if (!String.prototype.substringIndex)
    String.prototype.substringIndex = function(delimiter, index)
    {
        var input = this + '',
            arr = input.split(delimiter);

        if (index > 0) {
            arr.splice(index, arr.length - index);
        } else if (index < 0) {
            arr.splice(0, arr.length + index);
        }

        return arr.join(delimiter);
    };

/**
 * Remplace les occurrences des caractères spéciaux par leur entité html
 */
if (!String.prototype.escapeHtml)
    String.prototype.escapeHtml = function()
    {
        return this
            .unescapeHtml()
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
        ;
    };

/**
 * Remplace les occurrences des caractères spéciaux par leur entité html
 */
if (!String.prototype.unescapeHtml)
    String.prototype.unescapeHtml = function()
    {
        return this
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, '"')
            .replace(/&#039;/g, "'");
    };

if (!String.prototype.escapeSpaceChars)
    String.prototype.escapeSpaceChars = function()
    {
        return this
            .unescapeSpaceChars()
            .replace(/ /g, "&nbsp;")
        ;
    };

if (!String.prototype.unescapeSpaceChars)
    String.prototype.unescapeSpaceChars = function()
    {
        return this
            .replace(/&nbsp;/g, " ")
        ;
    };

/**
 * Réciproque de la méthode escapeHtml
 */
if (!String.prototype.decodeHtml)
    String.prototype.decodeHtml = function()
    {
        return this
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, '"')
            .replace(/&#039;/g, "'")
        ;
    };

/**
 * Remplace les occurrences des guillemets simples et doubles par leur entité html
 */
if (!String.prototype.htmlquotes)
    String.prototype.htmlquotes = function()
    {
        return this
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
        ;
    };


/**
 * Remplace les occurrences des guillemets simples par leur entité html
 */
if (!String.prototype.htmlsimplequotes)
    String.prototype.htmlsimplequotes = function()
    {
        return this.replace(/'/g, "&#039;");
    };

/**
 * Répète la chaîne n fois
 *
 * @param int n
 */
if (!String.prototype.repeat)
    String.prototype.repeat = function(n)
    {
        return new Array(n + 1).join(this);
    };

/**
 * Supprime les balises HTML
 */
if (!String.prototype.stripTags)
    String.prototype.stripTags = function(tag)
    {
        if (webf.isString(tag)) {
            var rStripTags = new RegExp('<' + tag + '[^>]*>(.*?)</' + tag + '>', 'ig');
            return this.replace(rStripTags, '$1');
        }

        return this.replace(/(<([^>]+)>)/ig,"");
    };

/**
 * Supprime les balises HTML en fin de chaîne
 */
if (!String.prototype.rtrimTags)
    String.prototype.rtrimTags = function()
    {
        return this.replace(/(<([^>]+)>)+$/i,"");
    };

/**
 * Supprime les balises HTML en début de chaîne
 */
if (!String.prototype.ltrimTags)
    String.prototype.ltrimTags = function()
    {
        return this.replace(/^(<([^>]+)>)+/i,"");
    };

/**
 * Supprime les balises HTML en début et fin de chaîne
 */
if (!String.prototype.trimTags)
    String.prototype.trimTags = function()
    {
        return this.rtrimTags(this.ltrimTags());
    };

/**
 * Complète la chaîne jusqu'à une taille donnée
 */
if (!String.prototype.pad)
    String.prototype.pad = function(pad_length, pad_str, pad_type)
    {
        if (pad_length === undefined || this.length >= pad_length) {
            return this;
        }

        pad_str = webf.isUndefined(pad_str) ? ' ' : pad_str + '';
        pad_type = webf.isUndefined(pad_type) ? 'left' : pad_type;

        if (pad_type == 'left') {
            return pad_str.repeat(Math.ceil(pad_length / pad_str.length)).substr(0, pad_length - this.length) + this;
        } else {
            return this + pad_str.repeat(Math.ceil(pad_length / pad_str.length)).substr(0, pad_length - this.length);
        }
    };

/**
 * Convertit la chaîne pour qu'il n'y ait plus d'accent et remplace tous les caractères qui ne sont
 * pas alphanumériques ou des guillemets simples, par des tirets
 */
if (!String.prototype.toUrl)
    String.prototype.toUrl = function()
    {
        return this
            .noAccent()
            .toLowerCase()
            .replace(/[^a-z0-9]/g,'-')
            .replace(/-{2,}/g,'-')
            .trim()
        ;
    };

/**
 * Remplace les chaînes correspondant à des urls par des liens HTML
 */
if (!String.prototype.a)
    String.prototype.a = function()
    {
        var text = this + " ",
            result = text.replace(/((https?|ftp):\/\/)([\S]+)/g,"<a href='$1$3' target='_blank'>$1$3</a>");

        return result.replace(/\s+$/g,'');
    };

/**
 * Retire les éléments <a> d'une chaîne HTML pour ne laisser que les href des liens
 */
if (!String.prototype.stripLinks)
    String.prototype.stripLinks = function()
    {
        return this.replace(/<a(.*)href="(.+?)"(.*)>/g,"$2");
    };

/**
 * Inverse l'odre des caractères
 */
if (!String.prototype.reverse)
    String.prototype.reverse = function() {
        var str = [], i;

        for (i=0; i<this.length; i++) {
            str.unshift(this[i]);
        }

        return str.join('');
    };

if (!String.prototype.insert)
    String.prototype.insert = function(ins, n) {
        if (n >= this.length) {
            return this;
        }

        var newstr = "", i;

        for (i=0; i<this.length; i++) {
            if (i > 0 && i % n == 0) {
                newstr += ins + this[i];
            } else {
                newstr += this[i];
            }
        }

        return newstr;
    };

if (!String.prototype.escapeRegex)
    String.prototype.escapeRegex = function()
    {
        //@see http://stackoverflow.com/questions/3115150/how-to-escape-regular-expression-special-characters-using-javascript
        return this.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
    };

if (!String.prototype.camelCase)
    String.prototype.camelCase = function()
    {
        return this.toLowerCase().replace(/[_-](\w)/g, function(match, p1) {
            return p1.toUpperCase();
        });
    };

if (!String.prototype.format && !String.prototype.f)
    String.prototype.format = String.prototype.f = function() {
        var str = this,
            i = arguments.length;

        while (i--) {
            str = str.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
        }

        return str;
    };

if (!String.prototype.toCssClassName)
    String.prototype.toCssClassName = function() {
        // https://stackoverflow.com/questions/7627000/javascript-convert-string-to-safe-class-name-for-css
        return this.replace(/[^a-z0-9_-]/g, function(s) {
            const c = s.charCodeAt(0);
            if (c == 32) return '-';
            if (c >= 65 && c <= 90) return '_' + s.toLowerCase();
            return '__' + ('000' + c.toString(16)).slice(-4);
        });
    };

if (!String.prototype.isEmailValid)
    String.prototype.isEmailValid = function() {
        const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        return re.test(this);
    };

if (typeof window !== "undefined") {
    window.webf = webf;
}

export default webf
