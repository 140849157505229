import webf from './core'

class EventDispatcher
{
    constructor() {
        this.listeners = {};
    }

    addListener(eventsName, callback, context, ...args) {
        var listener = {
            callback,
            context,
            args
        };

        webf.each(eventsName.split(','), (i, eventName) => {
            eventName = eventName.trim();

            if (this.hasListener(eventName)) {
                this.listeners[eventName].push(listener);
            } else {
                this.listeners[eventName] = [listener];
            }
        });

        return this;
    }

    addListenerOnce(eventsName, callback, context, ...listenerArgs) {
        webf.each(eventsName.split(','), (i, eventName) => {
            eventName = eventName.trim();

            this.addListener(eventName, (eventName, ...args) => {
                callback.apply(context,
                    [eventName]
                        .concat(listenerArgs)
                        .concat(args)
                    );
                this.removeListener(eventName);
            });
        })

        return this;
    }

    dispatch(eventsName, ...args) {
        webf.each(eventsName.split(','), (i, eventName) => {
            eventName = eventName.trim();

            if (this.hasListener(eventName)) {
                webf.each(this.listeners[eventName], (i, listener) => {
                    listener.callback.apply(listener.context,
                        [eventName]
                            .concat(listener.args)
                            .concat(args)
                        );
                });
            }
        });

        return this;
    }

    hasListener(eventName, callback, context) {
        if (webf.isUndefined(callback)) {
            return !webf.isUndefined(this.listeners[eventName]);
        }

        return !!webf.map(this.listeners[eventName], (i, listener) => {
            return (listener.callback === callback && listener.context === context) ? true : null;
        }).length;
    }

    removeListener(eventName, callback, context) {
        if (this.hasListener(eventName, callback, context)) {
            if (webf.isUndefined(callback)) {
                this.listeners[eventName].splice(0);
            } else {
                webf.each(this.listeners[eventName], (i, listener) => {
                    if (listener.callback === callback && context === context) {
                        this.listeners[eventName].splice(i, 1);
                        return false;
                    }
                });
            }
        }

        return this;
    }
}

const eventDispatcher = new EventDispatcher;

webf.EventDispatcher = eventDispatcher;

export default eventDispatcher
