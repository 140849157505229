import webf from '../../utils/core'
import $ from 'jquery'
import eventDispatcher from '../../utils/eventDispatcher'
import '../select'

const Toolbar = webf.Class.extend({
    constructor: function(plugin, toolbarOptions)
    {
        this.plugin = plugin;
        this.toolbarOptions = toolbarOptions;
    },

    render: function()
    {
        var p = this.plugin,
            $toolbar = $('<div>').addClass('toolbar');

        if (this.toolbarOptions) {
            if (this.toolbarOptions.refresh) {
                var $refresh = $('<div>').addClass('component');

                $refresh.append(
                    $('<button>').addClass('webf-button webf-button-outline-dark refresh').append($('<i>').addClass('fas fa-sync'))
                );

                $toolbar.append($refresh);
            }

            if (this.toolbarOptions.search) {
                var $search = $('<div>').addClass('component search')
                    .append($("<input type='text' autocomplete='off'>"))
                    .append($('<div>').addClass('webf-button white btn-search').text(p._('Rechercher')));

                $toolbar.append($search);
            }

            if (this.toolbarOptions.selectColumns) {
                const $selectColumnsComponent = $('<div>').addClass('component');
                const columns = this.plugin.getAllColumns();

                const $selectColumns = $('<select class="selectColumns" multiple></select>')
                    .on('change', ev => eventDispatcher.dispatch('datagrid.updateSelectColumns.' + p.hash, null, $(ev.currentTarget).webfSelect('val')));

                webf.each(columns, function(i, column) {
                    var caption = column.caption ? column.caption : p.translate('abbrColumn') + (i + 1);

                    var $optionColumn = $(`<option value="${column.field}">${caption}</option>`);
                    $optionColumn.prop('disabled', !column.caption);
                    $optionColumn.prop('selected', webf.isUndefined(column.visible) || column.visible);

                    if (column.group) {
                        var $group = $selectColumns.children('optgroup[label="' + column.group + '"]');

                        if (!$group[0]) {
                            $group = $(`<optgroup label="${column.group}">`);
                            $selectColumns.append($group);
                        }

                        $group.append($optionColumn);
                    } else {
                        $selectColumns.append($optionColumn);
                    }
                });

                $selectColumnsComponent.append($selectColumns);
                $toolbar.append($selectColumnsComponent);

                setTimeout(() => {
                    $selectColumns.webfSelect({
                        behavior: 'MDCheckable',
                        inputMdClass: 'outlined small',
                        checkAllItem: {
                            label: p.translate('allColumns')
                        },
                        onDraw: ($e) => {
                            const $menu = $e.webfSelect('widget');

                            $menu.webfSortable({
                                selectorSortables: '.webf-list-item-option',
                                onUpdate: ($item, oldIndex, newIndex, $e) => {
                                    oldIndex--;
                                    newIndex--;

                                    const $optionToMove = $selectColumns.find('option').eq(oldIndex).detach();

                                    if (newIndex >= $selectColumns.find('option').length) {
                                        $selectColumns.append($optionToMove);
                                    } else {
                                        $optionToMove.insertBefore($selectColumns.find('option').eq(newIndex));
                                    }

                                    const fields = $selectColumns.find('option').map((i, option) => {
                                        return $(option).val()
                                    }).get();

                                    eventDispatcher.dispatch('datagrid.updateSelectColumns.' + p.hash, fields)
                                }
                            });
                        }
                    });
                }, 10);
            }

            if (this.toolbarOptions.custom) {
                webf.each(this.toolbarOptions.custom, (i, component) => {
                    $toolbar.append(component.render());
                })
            }
        } else {
            $toolbar.addClass('hidden');
        }

        this.$toolbar = $toolbar;

        return $toolbar;
    },

    bindEvents: function()
    {
        this.plugin._on(this.$toolbar, '.refresh', {
            click: ev => {
                ev.preventDefault();
                p.refresh();
            }
        });

        this.plugin._on(this.$toolbar, '.search .btn-search', {
            click: ev => {
                ev.preventDefault();

                const $component = $(ev.currentTarget).closest('.component');
                p.search($component.find("input[type='text']").val());
            }
        });

        this.plugin._on(this.$toolbar, ".search input[type='text']", {
            keydown: ev => {
                if (ev.which == 13) {
                    ev.preventDefault();

                    p.search($(ev.currentTarget).val());
                }
            }
        });
    }
});

export default Toolbar
