import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.SuperScript = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-superscript'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('superscript');
    },
    refresh: function(editor, $btn) {
        if (editor._queryCommandState('superscript')) {
            $btn.addClass('webf-active');
        } else {
            $btn.removeClass('webf-active');
        }
    },
    types: 'action',
    text_tooltip: 'superscript'
});

