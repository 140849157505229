import './scrollbar'
import './tooltip'
import './navigation'
import eventDispatcher from '../utils/eventDispatcher'
import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import jQuery from 'jquery'

/**
 * @Options:
 *  - treeClass:            (string)            Classe CSS appliqué à la racine du composant.
 *  - tabIndex:             (int)               tab-index du conteneur
 *  - keys:                 (Object)            Keycode des touches décleanchant la navigation
 *                                              - prev: [38]
 *                                              - next: [40, 9]
 *                                              - active: [13]
 *  - throttle:             (int)               Temps en ms entre chaque événement de navigation
 *  - useKeyboard:          (boolean)           Autorise l'utilisation du clavier pour naviguer dans le tree.
 *  - onItemOpen:           (function($item))   Fonction appelée lors de l'ouverture d'un item.
 *  - onItemClose:          (function($item))   Fonction appelée lors de la fermeture d'un item.
 *  - onItemSelect:         (function($item))   Fonction appelée lorsqu'un item est sélectionné, càd lors d'une
 *                                              navigation au clavier.
 *  - onItemDblClick:       (function($item))   Fonction appelé lorsqu'un item est double cliqué
 *  - onChooseItem:         (function($item, ev))
 *                                              Fonction appelée lorsqu'un item est choisi, càd soit cliqué ou qu'un appui
 *                                              sur une des touches actives a eu lieu sur cet item
 *  - onItemActive:         (function($item))   Fonction appelée lorsqu'un item devient actif (click ou touche entrée).
 *  - useWebfScrollbar      (boolean)           Utilise le plugin webfScrollbar.
 *
 * @Methods:
 *  - prepend(string item, jQuery itemRef, Object {name: string, value: string}, open = true)
 *                          Insère item en tant que premier enfant de itemRef.
 *  - append(string item, jQuery itemRef, Object {name: string, value: string}, open = true)
 *                          Insère item en tant que dernier enfant de itemRef.
 *  - insertBefore(string item, jQuery itemRef, Object {name: string, value: string}, open = true)
 *                          Insère item en tant que frère précédent itemRef.
 *  - insertAfter(string item, jQuery itemRef, Object {name: string, value: string}, open = true)
 *                          Insère item en tant que frère suivant itemRef;
 *  - setItemToViewport(jQuery $item)
 *                          Affiche $item dans le cadre du bloc visible du tree.
 *  - getActiveItem()       Retourne l'item actif
 *  - isLeaf($item)         Retourne true si le noeud est un noeud de type leaf
 *  - getItemsByAttr(attr, value)
 *                          Retourne les items dont l'attribut attr est égal à value.
 *  - toggleItem($item)     Ouvre ou ferme l'item
 *  - openItem($item, active=true)
 *                          Ouvre un item.
 *                          Si active est à true, l'item aura la classe active
 *  - closeItem($item)      Ferme l'item
 *  - isItemOpen($item)     Retourne true si l'item est ouvert.
 *  - setItemActive($item)  Ajoute la classe activeClass à l'item mais ne déclenche pas le hook onItemActive
 *  - getParent($item)      Retourne l'item parent de $item
 *  - getAncestors($item)   Retourne les items ascendants de $item
 */
(function ($, window) {
    var $_group = $('<div>').addClass('group');

    $webf('tree', {
        options: {
            treeClass:          '',
            keys: {
                prev: '37 38',
                next: '39 40',
                active: '9 13'
            },
            tabIndex:           -1,
            selectedClass:      'preselected',
            activeClass:        'active',
            useKeyboard:        true,
            useWebfScrollbar:   true,
            onItemOpen:         webf.noop,
            onItemClose:        webf.noop,
            onItemSelect:       webf.noop,
            onItemActive:       webf.noop,
            onItemNew:          webf.noop,
            onItemDblClick:     webf.noop
        },

        _create: function ()
        {
            this._drawTree();
            this._bindEvents();

            var internalEvents = webf.map('openEnd closeEnd insertBefore insertAfter append prepend'.split(' '), function(i, name) {
                return 'webfTree.' + name;
            }).join(',');

            eventDispatcher.addListener(internalEvents, function(eventName, instance, $item) {
                if (instance == this) {
                    this.option('useWebfScrollbar') && this.$tree.webfScrollbar('update');

                    if (webf.inArray(eventName.split('.')[1], ['insertBefore', 'insertAfter', 'append', 'prepend'])) {
                        this._call(this.option('onItemNew'), $item);
                    }
                }
            }.bind(this));
        },

        _drawTree: function () {
            var self = this,
                $webfTree = this.e.wrap($('<div>').addClass('webf-tree ' + this.option('treeClass')))
                .parent();

            if (this.e[0].className.trim().length) {
                $webfTree.addClass(this.e[0].className.trim());
            }

            var $root = $('<div>').addClass('root');

            function addGroup($group, $ul, depth) {
                var $newGroup = $group.append($_group.clone()).children('.group').last();

                $newGroup.prev('.item.open')[0] && $newGroup.addClass('open');

                $ul.children('li').each(function (i, li) {
                    var $li = $(li);

                    $li.children().each(function (j, item) {
                        if (item.tagName == 'UL') {
                            addGroup($newGroup, $(item), $(item).parents('ul').length);
                        } else {
                            if ($(item).parent().hasClass('leaf')) {
                                $newGroup.append(self._createLeaf(item, depth, $li[0].attributes));
                            } else {
                                $newGroup.append(self._createItem(item, depth, $li[0].attributes));
                            }
                        }
                    });
                })
            }

            addGroup($root, this.e, 0);

            $webfTree.prepend($root);

            this.e.hide();

            if (this.option('useWebfScrollbar')) {
                $webfTree.webfScrollbar({
                    enableScrollX: false,
                    enableScrollY: true,
                    stopScrollingOnEnd: true
                });
            }

            this.$tree = $webfTree;
        },

        /**
         * @param {string|Element} item
         * @param {int} depth
         * @param {NamedNodeMap|Object} attributes
         * @return {jQuery}
         */
        _createItem: function(item, depth, attributes)
        {
            var textItem = webf.isDomElement(item) ? $(item).text() : item;

            var $_item = $('<div>').addClass('item'),
                $_indent = $('<div>').addClass('indent'),
                $_iconCaret = $('<i>').addClass('webf-tree-caret fas fa-caret-right'),
                $_iconNode = $('<i>').addClass('node fas fa-folder'),
                $_nodeName = $('<div>').addClass('nom').append($('<span>'));

            var $item = $_item.clone(), k = 0;

            while (k++ < depth) $item.append($_indent.clone());
            $item.append($_iconCaret.clone());
            $item.append($_iconNode.clone());
            $item.append($_nodeName.clone().children('span').text(textItem));

            webf.each(attributes || [], function (i, attr) {
                if (attr.name == 'class') {
                    $item.addClass(attr.value || attr.nodeValue);
                    return true;
                }

                $item.attr(attr.name, attr.nodeValue);
            });

            return $item;
        },

        /**
         * @param {string|Element} item
         * @param {int} depth
         * @param {NamedNodeMap|Object} attributes
         * @return {jQuery}
         */
        _createLeaf: function(item, depth, attributes)
        {
            var textItem = webf.isDomElement(item) ? $(item).text() : item;

            var $_item = $('<div>').addClass('item'),
                $_indent = $('<div>').addClass('indent'),
                $_iconNode = $('<i>').addClass('node fas fa-file'),
                $_nodeName = $('<div>').addClass('nom').append($('<span>'));

            var $item = $_item.clone(), k = 0;

            while (k++ < depth) $item.append($_indent.clone());
            $item.append($_iconNode.clone());
            $item.append($_nodeName.clone().children('span').text(textItem));

            webf.each(attributes || [], function (i, attr) {
                if (attr.name == 'class') {
                    $item.addClass(attr.value || attr.nodeValue);
                    return true;
                }

                $item.attr(attr.name, attr.nodeValue);
            });

            return $item;
        },

        openItem: function($item, active = true)
        {
            const $ancestors = this.getAncestors($item);

            if (!this.isItemOpen($item)) {
                this.toggleItem($item.first());
            }

            if (active) {
                const activeClass = this.option('activeClass');
                this.$tree.find('.item').removeClass(this.option('activeClass'));
                $item.addClass(activeClass);
            }

            $ancestors.each((i, ancestor) => {
                this.openItem($(ancestor), false);
            });
        },

        closeItem: function($item)
        {
            if (this.isItemOpen($item)) {
                this.toggleItem($item.first());
            }
        },

        isItemOpen($item)
        {
            return $item.hasClass('open') || $item.hasClass('opening');
        },

        getAncestors: function($item)
        {
            var $ancestors = $();
            var $ancestorItem = $item;

            while (true) {
                $ancestorItem = this.getParent($ancestorItem);

                if (!$ancestorItem[0]) {
                    return $ancestors;
                }

                if ($ancestors.length) {
                    $ancestors.add($ancestorItem);
                } else {
                    $ancestors = $ancestorItem;
                }
            }
        },

        getParent: function($item)
        {
            var $group = $item.parent();
            return $group.prev('.item');
        },

        setItemActive: function($item)
        {
            this.$tree.webfNavigation('setItemActive', $item);
        },

        toggleItem: function($item)
        {
            let finalHeight,
                $nextGroup = $item.next('.group');

            $item.toggleClass('open');

            if ($nextGroup[0]) {
                if ($nextGroup.hasClass('open')) {
                    if ($nextGroup.hasClass('opening')) {
                        $nextGroup.removeClass('opening');
                        $nextGroup.addClass('closing');
                        $nextGroup.css('height', 0);

                        this._call(this.option('onItemClose'), $item);
                    } else if ($nextGroup.hasClass('closing')) {
                        $nextGroup.removeClass('closing');
                        $nextGroup.addClass('opening');
                        finalHeight = $nextGroup.data('finalHeight');
                        $nextGroup.css('height', finalHeight);

                        this._call(this.option('onItemOpen'), $item);
                    } else {
                        $nextGroup.css('height', $nextGroup.outerHeight());
                        $nextGroup.addClass('closing');

                        setTimeout(() => {
                            $nextGroup.css('height', 0);

                            this._call(this.option('onItemClose'), $item);
                        }, 1);
                    }
                } else {
                    $nextGroup.addClass('open opening');
                    $nextGroup.css('height', 'auto');
                    finalHeight = $nextGroup.outerHeight();
                    $nextGroup.data('finalHeight', finalHeight);
                    $nextGroup.css('height', 0);

                    setTimeout(() => {
                        $nextGroup.css('height', finalHeight);

                        this._call(this.option('onItemOpen'), $item);
                    }, 1);
                }
            }
        },

        _bindEvents: function () {
            var self = this;

            var selectedClass = this.option('selectedClass'),
                activeClass = this.option('activeClass');

            this._on(this.$tree, '.item .webf-tree-caret', {
                mousedown: function (ev) {
                    ev.stopPropagation();
                    self.toggleItem($(ev.target).closest('.item'));
                },
            })._on(this.$tree, '.group', {
                transitionend: function (ev) {
                    var $groups = $(this).closest('.webf-tree').find('.opening, .closing');

                    if ($groups[0]) {
                        var $item = $groups.prev('.item');

                        if ($groups.hasClass('closing')) {
                            $groups.removeData('finalHeight');
                            $groups.removeClass('open opening closing');
                            eventDispatcher.dispatch('webfTree.closeEnd', self, $item);
                        } else if ($groups.hasClass('opening')) {
                            $groups.css('height', 'auto');
                            $groups.removeClass('opening closing');
                            eventDispatcher.dispatch('webfTree.openEnd', self, $item);
                        }
                    }
                }
            })
            ._on(this.$tree, {
                blur: function () {
                    self.$tree.find('.item').removeClass(selectedClass);
                }
            });

            this.$tree.webfTooltip({
                handler: '.item > span',
                interactive: true,
                groups: 'tree-tooltip',
                tooltipClass: 'webf-tree-tooltip',
                position: {
                    my: 'center top',
                    at: 'center bottom',
                    collision: 'none none',
                    offset: {
                        top: 1
                    }
                },
                onBeforeOpen: function($handler) {
                    if ($handler[0].scrollWidth > $handler.innerWidth() + 1) {
                        $(this).webfTooltip('updateContent', $handler.text());
                    } else {
                        return false;
                    }
                },
                animation: 'fading'
            });

            if (this.option('useKeyboard')) {
                var $group, $nextItem, $prevItem, $groupParent;

                this.$tree.webfNavigation({
                    itemsSelector: '.item',
                    tabIndex: this.option('tabIndex'),
                    keys: this.option('keys'),
                    onItemSelect: ($item) => {
                        this._call(this.option('onItemSelect'), $item);
                    },
                    onItemActive: ($item) => {
                        this._call(this.option('onItemActive'), $item);
                    },
                    onChooseItem: ($item, ev) => {
                        this._call(this.option('onChooseItem'), $item, ev);
                    },
                    onDblClick: ($item) => {
                        if (!this.isLeaf($item)) {
                            this.toggleItem($item);
                        }

                        this._call(this.option('onItemDblClick'), $item);
                    },
                    selectedClass: selectedClass,
                    activeClass: activeClass,
                    prev: function($curItem, ev) {
                        if ($curItem) {
                            if (ev.which == 37) {
                                if ($curItem.hasClass('open')) {
                                    self.toggleItem($curItem);
                                    return $curItem;
                                }

                                if ($curItem.parent('.group.open')[0]) {
                                    return $curItem.parent('.group.open').prev('.item')
                                }

                                return $curItem;
                            }

                            $group = $curItem.parent('.group');
                            $prevItem = $curItem.prev('.item');

                            if ($curItem.prev('.group.open')[0]) {
                                var $prevGroupOpen = $curItem.prev('.group.open');

                                if ($prevGroupOpen[0]) {
                                    return $prevGroupOpen.children('.item').last();
                                }
                            } else if ($prevItem[0]) {
                                return $prevItem;
                            } else if ($curItem.prevAll('.item').length) {
                                return $curItem.prevAll('.item').first();
                            } else if ($curItem.parent('.group.open')[0]) {
                                return $curItem.parent('.group.open').prev('.item');
                            }
                        }

                        var $group = self.$tree.find('.group').first();

                        if ($group[0]) {
                            while (true) {
                                if ($group.children().last().hasClass('item')) {
                                    return $group.children().last();
                                }

                                if ($group.children('.group').last().hasClass('open')) {
                                    $group = $group.children('.group').last();
                                } else {
                                    return $group.children('.item').last();
                                }
                            }
                        }

                        return $curItem;
                    },
                    next: function($curItem, ev) {
                        if (!$curItem) {
                            return self.$tree.find('.item').first();
                        }

                        if (ev.which == 39) {
                            !$curItem.hasClass('open') && self.toggleItem($curItem);

                            return $curItem;
                        }

                        $group = $curItem.next('.group');
                        $nextItem = $curItem.nextAll('.item').first();

                        if ($curItem.hasClass('open') && $group[0]) {
                            return $group.children('.item').first();
                        } else if ($nextItem[0]) {
                            return $nextItem;
                        } else {
                            $groupParent = $curItem.parent('.group');

                            if ($groupParent.parent('.group')[0]) {
                                while ($groupParent[0] && !$groupParent.next('.item')[0]) {
                                    $groupParent = $groupParent.parent('.group');
                                }

                                if ($groupParent[0]) {
                                    return $groupParent.next('.item')
                                } else {
                                    return self.$tree.find('.item').first();
                                }
                            }
                        }
                    }
                });
            }
        },

        isLeaf: function($item)
        {
            return $item.hasClass('leaf');
        },

        prepend: function(item, $itemRef, attrs, open = true)
        {
            $itemRef = $itemRef || this.getActiveItem();

            var $group = $itemRef.next('.group'),
                depth = $itemRef.parents('.group').length,
                $item = this._createItem(item, depth, attrs);

            if (!$group[0]) {
                $group = $itemRef.after($_group.clone()).next('.group');
            }

            $group.prepend($item);

            if ($itemRef.hasClass('open')) {
                $group.addClass('open');
            } else if (open) {
                this.toggleItem($itemRef);
            }

            eventDispatcher.dispatch('webfTree.prepend', this, $item);
        },

        append: function(item, $itemRef, attrs, open = true)
        {
            $itemRef = $itemRef || this.getActiveItem();

            var $group = $itemRef.next('.group'),
                depth = $itemRef.parents('.group').length,
                $item = this._createItem(item, depth, attrs);

            if (!$group[0]) {
                $group = $itemRef.after($_group.clone()).next('.group');
            }

            $group.append($item);

            if ($itemRef.hasClass('open')) {
                $group.addClass('open');
            } else if (open) {
                this.toggleItem($itemRef);
            }

            eventDispatcher.dispatch('webfTree.append', this, $item);
        },

        getItemsByAttr(attr, value)
        {
            return this.$tree.find(`.item[${attr}='${value}']`);
        },

        insertBefore: function(item, $itemRef, attrs, open = true)
        {
            $itemRef = $itemRef || this.getActiveItem();

            var depth = $itemRef.parents('.group').length - 1,
                $item = this._createItem(item, depth, attrs),
                $group = $itemRef.prev('.group');

            if ($group[0]) {
                $group.before($item);
            } else if (open) {
                $itemRef.before($item);
            }

            eventDispatcher.dispatch('webfTree.insertBefore', this, $item);
        },

        insertAfter: function(item, $itemRef, attrs, open = true)
        {
            $itemRef = $itemRef || this.getActiveItem();

            var depth = $itemRef.parents('.group').length - 1,
                $item = this._createItem(item, depth, attrs),
                $group = $itemRef.next('.group');

            if ($group[0]) {
                $group.after($item);
            } else if (open) {
                $itemRef.after($item);
            }

            eventDispatcher.dispatch('webfTree.insertAfter', this, $item);
        },

        setItemToViewport: function($item)
        {
            this.$tree.webfNavigation('setItemToViewport', $item);
        },

        getActiveItem: function()
        {
            var $activeItem = this.$tree.find('.item.active');

            if ($activeItem[0]) {
                return $activeItem;
            }

            return this.$tree.find('.item').first();
        },

        destroy: function () {
            this.$tree.remove();
            this.e.show();
        }
    });
})(jQuery, window);
