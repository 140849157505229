import webf from '../utils/core'
import '../i18n/select'
import './select/index'
import './dropdown'
import './navigation'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - width:        (int|string)    Largeur du handler de la liste déroulante.
 *                                  auto par défaut
 *  - filter        (boolean)       Afficher ou non une zone de texte pour rechercher parmi les items
 *  - selectClass   (string)        Classes CSS supplémentaires sur le handler
 *  - inputMdClass  (string)        Classes CSS supplémentaires pour les input text des listes Material Design (filled, outlined)
 *  - dropClass     (string)        Classes CSS supplémentaires sur le menu du dropdown
 *  - wrapperClass  (string)        Classes CSS supplémentaires sur l'élément wrapper de la liste déroulante
 *  - behavior      (string)        Type de liste déroulante, s'il n'est pas précisé, s'adapte automatiquement à l'élément
 *                                  HTML select
 *                                   - simple: Liste déroulante à choix unique
 *                                   - multiple: Liste déroulante à choix multiples affichés sous forme de liste
 *                                   - checkable: Liste déroulante à choix multiples avec des cases à cocher
 *                                   - MDSimple: Liste déroulante à choix unique en Material Design
 *                                   - MDCheckable: Liste déroulante à choix multiples avec des cases à cocher en Material Design
 *  - placeholder   (string)        Chaîne de caractère à afficher sur le handler.
 *                                  Ne s'applique que pour le beshavior simple et checkable
 *  - checkAllItem  (object|false)  Option pour le behavior checkable contrôlant l'affichage de l'option permettant de tout
 *                                  sélectionner. Si false, il n'y a pas d'item pour tout sélectionner
 *                                   - label: (string)
 *  - zIndex        (integer)       zIndex du dropdown
 *  - onDraw        (function()     Fonction appelée lorsque la liste déroulante est rendue
 *  - onFocus       (function()     Fonction appelée lorsque la liste déroulante prend le focus
 *  - onFirstOpen   (function()     Fonction appelée lorsque la liste déroulante est ouverte pour la premmière fois
 *  - onOpen        (function()     Fonction appelée lorsque la liste déroulante est ouverte
 *  - onSelect      (function($item, values))
 *                                  Fonction appelée lorsqu'un item est sélectionné
 *  - onChange      (function($item, values))
 *                                  Fonction appelée lorsque la valeur du select change
 *
 * Méthodes publiques :
 *  - val:              Définit la ou les valeurs sélectionnées de la liste déroulante
 *  - appendItem(value, label, group, enabled = true, select = false, trigger = false)
 *                      Ajoute un item
 *  - removeItem(value) Retire un item
 *  - removeAllItems()  Retire tous les items
 *  - open()            Ouvre la liste déroulante
 *  - close()           Ferme la liste déroulante
 *  - widget()          Retourne le widget
 */
var methods = {};

webf.each('val appendItem removeItem removeAllItems open close widget'.split(' '), function(i, method) {
    methods[method] = function() {
        return this.instance[method].apply(this.instance, arguments);
    };
});

$webf('select', webf.extend(true, methods, {
    options: {
        width:          'auto',
        filter:         true,
        selectClass:    '',
        inputMdClass:   'filled',
        dropClass:      '',
        wrapperClass:   '',
        behavior:       'simple',
        placeholder:    '',
        checkAllItem:   {
            label: 'all',
            value: ''
        },
        zIndex:         1000,
        onDraw:         webf.noop,
        onFocus:        webf.noop,
        onFirstOpen:    webf.noop,
        onOpen:         webf.noop,
        onSelect:       webf.noop,
        onChange:       webf.noop,
        onClose:        webf.noop
    },

    _create: function()
    {
        this.instance = this._instantiateSelect();

        this.placeholder = this.e.attr('placeholder')
            ? this.e.attr('placeholder')
            : this.e.data('placeholder')
                ? this.e.data('placeholder')
                : this.option('placeholder');

        this.instance.draw();
        this._call(this.option('onDraw'));
        this.instance.bindEvents();
    },

    _instantiateSelect: function()
    {
        const behavior = !this.e.prop('multiple')
            ? (this.option('behavior') ? this.option('behavior') : 'simple')
            : (this.option('behavior') == 'simple'
                ? 'multiple'
                : this.option('behavior')
            ),
            selectClass = webf['Select' + behavior.ucfirst()];

        if (webf.isFunction(selectClass)) {
            return new selectClass(this);
        }
    },

    _destroy: function()
    {
        this.instance.destroy();
    }
}));

