import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.cookieconsent = {
        message: 'Les cookies sont importants pour le bon fonctionnement d\'un site. Afin d\'améliorer votre expérience, Nous utilisons des cookies pour conserver les informations de connexion et fournir une connexion sure, collecter les statistiques en vue d\'optimiser les fonctionnalités du site et adapter le contenu à vos centres d\'intérêt. Cliquez sur Accepter et continuer pour accepter les cookies et poursuivre directement sur le site.',
        accept: 'Accepter',
        decline: 'Refuser'
    }
})(webf);
