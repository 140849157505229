import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - checkOnMousedown:     (bool)      Coche sur l'événement mousedown plutôt que click
 *  - classWrapper          (string)    Classes CSS ajoutées au wrapper
 *
 * Méthodes publiques:
 *  - check:        Coche la case
 *  - uncheck:      Décoche la case
 *  - enable:       Active la case à cocher
 *  - disable:      Désactive la case à cocher
 *  - toggle:       Coche ou décoche la case selon son état au moment de l'appel
 *  - widget:       Retourne le widget de la case à cocher
 *  - style:        Style de la case à cocher : classic, switch, checkmark
 */
$webf('checkbox', {
    options: {
        checkOnMousedown:   false,
        classWrapper:       '',
        style:              'classic'
    },

    _create: function()
    {
        this._draw();
        this._bindEvents();
        this._initState();
    },

    _draw: function()
    {
        this.$widget = this.e.hide()
            .wrap(`<span class='webf-checkboxwrapper ${this.option('style')} ${this.option('classWrapper')}'></span>`)
            .parent().prepend($("<a>").addClass('checkbox'));

        this.option('style') == 'checkmark' && this.widget().find('a').append('<i class="fas fa-check"></i>');
    },

    _initState: function()
    {
        this.enable(!this.e.prop('disabled'));
        this.check(this.e.prop('checked'), false, false);
    },

    _bindEvents: function()
    {
        let $elements = this.$widget;

        if (this.e.prop('id')) {
            const $label = $("label[for='" + this.e.prop('id') + "']");

            if ($label.length) {
                $elements = $elements.add($label);
            }
        }

        if (this.option('checkOnMousedown')) {
            this._on($elements, {
                mousedown: () => {
                    if (!this.e.prop('disabled')) {
                        this.toggle();
                    }
                }
            });
        } else {
            this._on($elements, {
                mousedown: () => {
                    if (!this.e.prop('disabled')) {
                        this.$widget.addClass('mousedown');
                    }
                },
                click: (ev) => {
                    if (ev.target.tagName != 'A' || !$(ev.target).attr('href')) {
                        ev.preventDefault();
                        if (!this.e.prop('disabled')) {
                            this.toggle();
                        }
                    }
                }
            })._on(document, {
                'mouseup mouseleave': (ev) => {
                    this.$widget.removeClass('mousedown');
                }
            });
        }
    },

    check: function(checked, trigger = true, call = true)
    {
        if (checked === false) {
            this.$widget.removeClass('checked').addClass('unchecked');
            this.e.prop('checked', false);
            call && this.e.trigger('uncheck');
        } else {
            this.$widget.removeClass('unchecked').addClass('checked');
            this.e.prop('checked', true);
            call && this.e.trigger('check');
        }

        if (trigger) {
            this.e.trigger('change');
        }
    },

    uncheck: function(trigger, call)
    {
        this.check(false, trigger, call);
    },

    enable: function(enable = true)
    {
        this.e.prop('disabled', !enable);

        if (enable) {
            this.$widget.removeClass('disabled');
            this.e.trigger('enable');
        } else {
            this.$widget.addClass('disabled');
            this.e.trigger('disable');
        }
    },

    disable: function()
    {
        this.enable(false);
    },

    toggle: function(trigger)
    {
        this.check(!this.e.prop('checked'), trigger);
    },

    widget: function()
    {
        return this.$widget;
    },

    _destroy: function()
    {
        this.e.siblings().remove();
        this.e.unwrap().show();
    }
});
