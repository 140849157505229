import webf from '../../utils/core'
import $ from 'jquery'

webf.SelectSimple = webf.Select.extend({

    draw: function()
    {
        var self = this,
            wSelect = webf.round(parseFloat(webf.getStyle(this.e[0], "width"))),
            $selectWrapper = this.e.wrap($('<div>')
                .addClass(['webf-select'].concat(this.plugin.option('selectClass').split(' ')).join(' '))
            ).parent();

        const $wrapperDropdown = $selectWrapper.wrap($(`<div class="webf-select-dropdown ${this.plugin.option('wrapperClass')}">`)
            .css('width', this.plugin.option('width') == 'auto' ? wSelect : this.plugin.option('width'))
        ).parent();

        $selectWrapper.append($("<div>").addClass("select")
            .html("" +
                "<i class='handle-down fas fa-caret-down'></i>" +
                "<a class='selected-area'></a>" +
            "")
        );

        this.$select = $selectWrapper;

        var $options = this._getOptions(),
            $menu = $('<div>')
                .addClass('webf-select-drop')
                .append($options);

        if (this.plugin.option('filter')) {
            $menu.prepend("" +
                "<div class='filter'>" +
                    "<label>" +
                        "<input class='search' type='text' autocomplete='off'>" +
                        "<i class='icon-search fas fa-search'></i>" +
                    "</label>" +
                "</div>" +
            "");
        }

        $wrapperDropdown.append($menu);
        this.$wrapperDropdown = $wrapperDropdown;

        this.menu = $wrapperDropdown.webfDropdown({
            handle: $selectWrapper,
            menu: $menu,
            menuClass: this.plugin.option('dropClass'),
            onFirstOpen: function() {
                var $menu = $(this).webfDropdown('menu'),
                    $input = $menu.find("input[type='text'].search");

                if ($input[0]) {
                    self.input = $input;

                    self.plugin._on($input, {
                        keydown: function(ev) {
                            switch(ev.keyCode || ev.which) {
                                case 38: case 40: case 9: case 13:
                                    break;

                                default:
                                    self._searchDelay(ev);
                            }
                        },
                        'click mousedown': function(ev) {
                            ev.stopPropagation();
                        }
                    });
                }

                $menu.find('.webf-list-item-option').each(function() {
                    var $item = $(this),
                        option = $item.data('value');

                    if (self.noSelectedOption) {
                        self.selectedItem = self.selectedOption;
                    } else if (self.selectedOption.val() == option) {
                        self.val($item.data('value'));
                    }
                });

                self.plugin._call(self.plugin.option('onFirstOpen'));
            },
            onOpen: function() {
                self.$select.addClass('open');
                if (self.input) {
                    self.input.trigger('focus');
                }

                self.plugin._call(self.plugin.option('onOpen'));
            },
            onClose: function() {
                self.$select.removeClass('open');
                self.index = null;
                if (self.input) {
                    self.input.val('');
                }
                self._search('');
                self.showItem();
                self.blurItem();
                self.$select.find('.select').removeClass('open');

                self.plugin._call(self.plugin.option('onClose'));
            }
        }).webfDropdown('menu');

        this.val(this.val());
        this.e.hide();
    },

    bindEvents: function()
    {
        var self = this;

        this.plugin._on(this.menu.add(this.$select), {
            'mousedown mousemove': function(ev) {
                if (ev.target.tagName !== 'INPUT' || !$(ev.target).hasClass('search') || !$(ev.target).closest('.webf-select-drop')[0]) {
                    ev.preventDefault();
                }
            }
        });

        this.plugin._on(this.menu, '.webf-list-item-option.enabled', {
            mouseenter: function() {
                self.index = $(this).prevAll('li.webf-item-option.enabled').length;
                self.focusItem($(this));
            },
            click: function() {
                var $item = $(this);
                self.val($item.data('value'), true);
                self.plugin._call(self.plugin.option('onSelect'), $item, self.e.val());
                self.close();
            }
        })._on(this.menu, '.webf-list-item-option', {
            mouseleave: function() {
                self.index = null;
                self.blurItem($(this));
            }
        });
    },

    val: function(val, trigger)
    {
        if (webf.isUndefined(val)) {
            return this.e.val();
        }

        if (val === null) {
            this.noSelectedOption = true;
        } else {
            var $item = this.menu.find('.webf-list-item-option').findByData('value', val).first();

            if ($item[0]) {
                this.e.val(val);

                this.noSelectedOption = false;
                this.selectedOption = this.e.find("option[value='" + (val + '').htmlsimplequotes() + "']");
                this.select($item);
            }
        }

        if (trigger) {
            this.e.trigger('change');
            this.plugin._call(this.plugin.option('onChange'), $item, this.val());
        }
    },

    select: function($item)
    {
        this.menu.find('.webf-list-item-option').removeClass('webf-active');
        $item.addClass('webf-active');
        this.selectedItem = $item;

        this.display();
    },

    display: function()
    {
        var val = this.val(),
            $item = this.menu.find('.webf-list-item-option').findByData('value', val);

        this.$select.find('a.selected-area').text($item.text());
    }
});

