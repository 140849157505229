import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.dialog = {
        close: 'Fermer'
    };
})(webf);

