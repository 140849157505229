import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.cookieconsent = {
        message: 'Cookies are important to the proper functioning of a site. To improve your experience, We use cookies to remember log-in details and provide secure log-in, collect statistics to optimize site functionality, and deliver content tailored to your interests. Click Agree and Proceed to accept cookies and go directly to the site',
        accept: 'Accept',
        decline: 'Decline'
    }
})(webf);

