import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * Options :
 *  - state:            (string) auto ou accordion, tabs
 *  - tabSelector:      (string) Sélecteur de l'onglet déclenchant l'ouverture des panneaux
 *  - activeTab:        (string|int|string[]|int[]) Onglet(s) ouvert(s) par défaut, peut être soit l'index du panel ou son nom
 *                      ou un tableau de nom ou d'indexes
 *  - orientation:      vertical ou horizontal. Ne s'applique que pour le state 'tabs'.
 *  - event:            (string) click
 *  - webfStyle:        (boolean|string) si string, doit correspondre avec un des styles de webf (primary, dark...)
 *  - animation:        (boolean|string) false ou 'slide'
 *  - animationQueue:   unused
 *  - duration:         (int) Durée de l'animation en ms
 *  - breakPoint:       (int) Largeur en dessous de laquelle un accordéon s'affiche et au dessus de laquelle c'est un
 *                      système d'onglets
 *  - collapsible:      (boolean) Autorise le fait que l'onglet courant soit fermé
 *  - startCollapsed:   (boolean) Tous les panneaux sont fermés à l'ouverture
 *  - multipleExpanded: (boolean) En mode Accordéon, si false : autorise l'ouverture de plusieurs onglets ouvert
 *  - onCreate:         (function($tab, $accordionTab, $panel)) Fonction appellée à la création du plugin
 *  - onOpen:           (function($tab, $accordionTab, $panel)) Fonction appellée à l'ouverture d'un panneau
 *  - onClose:          (function) Fonction appellée à la fermeture d'un panneau
 *  - onChangeState:    (function) Fonction appellée à lorsque le mode (accordéon ou onglets) change
 *
 * Méthodes publiques :
 *  - open:                     (string|int) Ouvre un onglet
 *  - close:                    (string|int) Ferme un onglet
 *  - getCurrentTab             Retourne l'onglet ouvert
 *  - getCurrentTabAccordion    Retourne le ou les onglets des panneaux ouverts
 *  - getCurrentPanel           Retourne le ou les panneaux ouverts
 *  - getTab                    (string|int) Retourne un onglet
 *  - getTabs                   Retourne les onglets
 *  - getTabAccordion           (string|int) Retourne un onglet d'accordéon
 *  - getTabsAccordion          Retourne les onglets d'accordéon
 *  - getPanel                  (string|int) Retourne un panneau
 *  - getPanels                 Retourne les panneaux
 */
$webf('tabs', {
    options: {
        state:              'auto',
        tabSelector:        'li',
        activeTab:          0,
        orientation:        'horizontal',
        event:              'click',
        webfStyle:          'primary',
        animation:          false,
        animationQueue:     false,
        duration:           500,
        breakPoint:         768,
        collapsible:        true,
        startCollapsed:     false,
        multipleExpanded:   false,
        onCreate:           webf.noop,
        onOpen:             webf.noop,
        onClose:            webf.noop,
        onChangeState:      webf.noop
    },

    _create: function()
    {
        this.$ul = null;
        this.$queue = $({});

        this._initTabs();
        this._bindEvents();

        this._call(this.option('onCreate'), this.getCurrentTab(), this.getCurrentTabAccordion(), this.getCurrentPanel());
    },

    _initTabs: function()
    {
        var self = this;
        if (this.e[0].tagName == 'UL') {
            this.$ul = this.e.wrap('<div class="webf-tabs-wrapper"></div>');
        } else {
            this.$ul = this.e
                .addClass('webf-tabs-wrapper')
                .children('ul');
        }
        this.e.addClass('webf-' + this.option('orientation'));
        this.$ul.addClass('webf-tabs');
        this.$ul.children(this.option('tabSelector')).addClass('webf-tab');

        var $panels = this.getPanels().addClass('webf-panel'),
            webfStyle = this.option('webfStyle');

        $panels.wrapAll('<div class="webf-panels"></div>');

        if (webfStyle) {
            $panels.addClass('webf-style webf-panel-' + webfStyle);

            this.$ul
                .addClass('webf-style webf-tabs-' + webfStyle)
                .children(this.option('tabSelector')).addClass('webf-tab webf-tab-' + webfStyle);
        }

        $panels.each(function(i, panel) {
            var $tabAnchor = self.getTab(i).children('a').first(),
                $accordionTab = $('<div>').addClass((webfStyle ? 'webf-style webf-tab-' + webfStyle : '') + ' webf-tab webf-tab-accordion').insertBefore($(panel)),
                $accordionAnchor = $tabAnchor.clone(true);

            $accordionAnchor.appendTo($accordionTab);
        });

        var activeTabs = this.option('activeTab');
        if (!webf.isArray(activeTabs)) {
            activeTabs = [activeTabs];
        }

        if (this.option('multipleExpanded') === false) {
            activeTabs = [activeTabs[0]];
        }

        this.getPanels().hide();

        this._setState();

        if (this.option('startCollapsed') === false) {
            webf.each(activeTabs, function(i, activeTab) {
                var $tab = this.getTab(activeTab),
                    $accordionTab = this.getTabAccordion(activeTab),
                    $panel = this.getPanel(activeTab);

                $tab.add($accordionTab).addClass('webf-tab-active webf-tab-opened');
                $panel.show();
            }, this);
        }
    },

    _bindEvents: function()
    {
        var self = this,
            activateHandler = {};

        activateHandler[this.option('event')] = function(ev) {
            ev.preventDefault();
            var $tab = $(this),
                $a = $tab.children('a');

            if ($tab.hasClass('webf-tab-opened')) {
                if (self.option('collapsible')) {
                    self.close($a.data('tab'), false);
                }
            } else {
                var $currentTab = self.getCurrentTab();

                if (!self.option('state') == 'accordion' || !self.option('multipleExpanded')) {
                    if ($currentTab[0]) {
                        self.close($currentTab.children('a').first().data('tab'));
                    }
                }

                self.open($a.data('tab'));
            }
        };

        this._on(this.$ul, this.option('tabSelector'), activateHandler);
        this._on(this.getTabsAccordion(), activateHandler);

        this._on(window, {
            resize: function(ev) {
                self._setState();
            }
        });
    },

    _setState: function()
    {
        var oldState = this.state;

        this.state = 'tabs';
        var breakPoint = this.option('breakPoint');

        if (this.option('state') == 'auto') {
            if (window.screen.width < breakPoint) {
                this.state = 'accordion';
                this.getTabs().hide();
                this.getTabsAccordion().show();
            } else {
                this.getTabs().show();
                this.getTabsAccordion().hide();
            }

            this._call(this.option('onChangeState'), this.state, oldState);
        } else {
            this.state = this.option('state');

            if (this.state == 'tabs') {
                this.getTabs().show();
                this.getTabsAccordion().hide();
            } else {
                this.getTabs().hide();
                this.getTabsAccordion().show();
            }
        }
    },

    _doTransition: function($tab, method, dequeue, callback)
    {
        var $panel = this.getPanel($tab.children('a').data('tab')),
            animation = this.option('animation'),
            duration = this.option('duration'),
            animOpen, animClose;

        switch (animation) {
            case 'slide':
                animOpen = 'slideDown';
                animClose = 'slideUp';
                break;

            default:
                animOpen = 'show';
                animClose = 'hide';
                duration = 0; break;
        }

        $panel[method == 'open' ? animOpen : animClose](duration, callback);
    },

    open: function(n)
    {
        var self = this,
            $tab = this.getTab(n),
            $panel = this.getPanel(n),
            $accordionTab = this.getTabAccordion(n);

        if ($tab[0]) {
            this._doTransition($tab, 'open', this.option('animationQueue'), function() {
                self._call(self.option('onOpen'), $tab, $accordionTab, $panel);
            });

            $tab.add($accordionTab).addClass('webf-tab-active webf-tab-opened');
        }
    },

    close: function(n, dequeue)
    {
        var self = this,
            $tab = this.getTab(n),
            $panel = this.getPanel(n),
            $accordionTab = this.getTabAccordion(n);

        if ($tab[0]) {
            this._doTransition($tab, 'close', webf.isUndefined(dequeue) ? this.option('animationQueue') : dequeue, function() {
                self._call(self.option('onClose'), $tab, $accordionTab, $panel);
                $accordionTab.removeClass('webf-tab-active webf-tab-opened');
            });

            $tab.removeClass('webf-tab-opened webf-tab-active');
        }
    },

    getCurrentTab: function()
    {
        return this.$ul.children('li.webf-tab-opened');
    },

    getCurrentTabAccordion: function()
    {
        return this.e.children('.webf-panels > .webf-tab-accordion.webf-tab-opened');
    },

    getCurrentPanel: function()
    {
        var $tab = this.getCurrentTab(),
            tabName = $tab.children('a').data('tab');

        return this.getPanel(tabName);
    },

    getTab: function(n)
    {
        var $tabs = this.getTabs(), $tab;

        if (webf.isInteger(n)) {
            $tab = $tabs.eq(n);
        } else {
            $tab = $tabs.filter(function() {
                return ($(this).children('a').first().data('tab') == n);
            });
        }

        return $tab.closest(this.option('tabSelector'));
    },

    getTabs: function()
    {
        return this.$ul.children(this.option('tabSelector'));
    },

    getTabAccordion: function(n)
    {
        return this.getPanel(n).prev();
    },

    getTabsAccordion: function()
    {
        var $tabs = $();

        this.getPanels().each(function() {
            $tabs = $tabs.add($(this).prev());
        });

        return $tabs;
    },

    getPanel: function(n)
    {
        var $panels = this.getPanels(),
            $panel;

        if (webf.isInteger(n)) {
            $panel = $panels.eq(n);
        } else {
            $panel = $panels.filter(function() {
                return ($(this).prop('id') == n);
            });
        }

        return $panel;
    },

    getPanels: function()
    {
        return this.e.find($.makeArray((this.getTabs().map(function(i, tab) {
            return "#" + $(tab).children('a').data('tab');
        }))).join(', '));
    },

    _destroy: function()
    {
        this.$ul.removeClass('webf-tabs');

        var $panels = this.getPanels().removeClass('webf-panel'),
            webfStyle = this.option('webfStyle') || '';

        $panels.removeClass('webf-panel webf-style webf-panel-' + webfStyle);

        this.$ul
            .removeClass('webf-style webf-tabs-' + webfStyle)
            .children('li')
            .removeClass('webf-tab webf-tab-active webf-tab-opened webf-tab-accordion webf-tab-' + webfStyle)
            .show();

        this.getTabsAccordion().filter('.webf-tab-accordion').remove();
    }
});

