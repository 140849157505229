import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'
import Mustache from "mustache"

import tplHandler from './slider/handler.tpl.html';

/**
 * options :
 *  - sliderClass:  (string)        Classes CSS à aplliquer à l'élément
 *  - min:          (int)           Valeur minimale du slider
 *  - max:          (int)           Valeur maximale du slider
 *  - orientation:  (string)        horizontal ou vertical
 *  - value:        (int)           Valeur de départ du slider
 *  - onSlide:      (function())    Fonction appelée lorsque le slider slide
 *  - onStop:       (function())    Fonction appelée lorsque le slider se stoppe
 *  - onChange:     (function())    Fonction appelée lorsque le slider a changé de valeur
 *
 * Méthodes publiques :
 *  - value(int)    Définit la valeur du slide
 */
$webf('slider', {
    options: {
        sliderClass: '',
        min:         0,
        max:         10,
        orientation: 'horizontal',
        value:       0,
        onSlide:     webf.noop,
        onStop:      webf.noop,
        onChange:    webf.noop
    },

    editableOptions: {
        min: function (min) {
            this.value(webf.max([min, this.val]));
        },
        max: function (max) {
            this.value(webf.min([max, this.val]));
        }
    },

    _create: function()
    {
        this.sliding     = false;
        this.focus       = false;
        this.val         = 0;
        this.old_value   = null;

        if (this.option('change')) {
            this.options.onChange = this.option('change');
        }

        if (this.option('slide')) {
            this.options.onSlide = this.option('slide');
        }

        if (this.option('stop')) {
            this.options.onStop = this.option('stop');
        }

        this._draw();
        this._bindEvents();

        this.value(this.option('value'));
        this.old_value = this.val;
    },

    _draw: function()
    {
        this.e.addClass(['webf-slider']
            .concat(this._getOrientation())
            .concat(this.option('sliderClass') || [])
            .join(' ')
        );

        const $handler = this.e.append(Mustache.render(tplHandler)).children();
        this.e.append($handler);

        // this.e.addClass('webf-slider').addClass(this._getOrientation());
        //
        // if (this.option('sliderClass')) {
        //     this.e.addClass(this.option('sliderClass'));
        // }
        //
        // var $handler = $('<a>').addClass('handler').prop('tabIndex', '-1');
        // this.e.append($handler);
    },

    _bindEvents: function()
    {
        var self = this;

        const $handler = this._getHandler();

        this._on($handler, {
            // 'mousedown emulmousedown': function(ev) {
            //     ev.preventDefault();
            //     self.sliding = true;
            //     self._trigger(this, 'focus');
            // },
            // focus: function(ev) {
            //     ev.preventDefault();
            //     self.focus = true;
            // },
            // blur: function() {
            //     self.focus = false;
            // }

            'mousedown emulmousedown': (ev) => {
                ev.preventDefault();
                this.sliding = true;
                this._trigger(ev.target, 'focus');
            },
            focus: (ev) => {
                ev.preventDefault();
                this.focus = true;
                $handler.addClass('webf-slider-focus');
            },
            blur: () => {
                this.focus = false;
                $handler.removeClass('webf-slider-focus');
            }
        })

        ._on(this.e, {
            // mousedown: function(ev) {
            //     ev.preventDefault();
            //     self.value(self._getMouseValue(ev));
            //     self._call(self.option('onSlide'));
            //     self._trigger(self.e.children('.handler'), 'emulmousedown');
            // }

            'mousedown touchstart': (ev) => {
                ev.preventDefault();
                this.value(this._getMouseValue(ev));
                this._call(this.option('onSlide'), this.value());
                this._trigger(this.e.children('.handler'), 'emulmousedown');
            }
        })

        ._on(document, {
            // mousemove: function(ev) {
            //     if (self.sliding) {
            //         ev.preventDefault();
            //
            //         var newValue = self._getMouseValue(ev);
            //
            //         if (self.val !== newValue) {
            //             self.value(newValue);
            //             self._call(self.option('onSlide'));
            //         }
            //     }
            // },
            // mouseup: function(ev) {
            //     self.sliding = false;
            //
            //     if (self.old_value !== self.val) {
            //         self.old_value = self.val;
            //         self._call(self.option('onStop'));
            //     }
            // },
            // blur: function(ev) {
            //     self.focus = false;
            //     self.sliding = false;
            //
            //     if (self.old_value !== self.val) {
            //         self.old_value = self.val;
            //         self._call(self.option('onStop'));
            //     }
            // },
            // keydown: function(ev) {
            //     if (self.focus) {
            //         if (webf.inArray(ev.which, [38, 39])) {
            //             ev.preventDefault();
            //             self.value('+=1');
            //             self._call(self.option('onSlide'));
            //         } else if (webf.inArray(ev.which, [40,37])) {
            //             ev.preventDefault();
            //             self.value('-=1');
            //             self._call(self.option('onSlide'));
            //         }
            //     }
            // },
            // keyup: function(ev) {
            //     if (self.focus) {
            //         if (webf.inArray(ev.which, [37, 38, 39, 40])) {
            //             if (self.old_value !== self.val) {
            //                 self.old_value = self.val;
            //                 self._call(self.option('onStop'));
            //             }
            //         }
            //     }
            // }

            'mousemove touchmove': (ev) => {
                if (this.sliding) {
                    const newValue = this._getMouseValue(ev);

                    if (this.val !== newValue) {
                        this.value(newValue);
                        this._call(this.option('onSlide'), this.value());
                    }
                }
            },
            'mouseup touchend': (ev) => {
                this.sliding = false;

                if (this.old_value !== this.val) {
                    this.old_value = this.val;
                    this._call(this.option('onStop'), this.value());
                }
            },
            blur: (ev) => {
                this.focus = false;
                this.sliding = false;

                if (this.old_value !== this.val) {
                    this.old_value = this.val;
                    this._call(this.option('onStop'), this.value());
                }
            },
            keydown: (ev) => {
                if (this.focus) {
                    if (webf.inArray(ev.which, [38, 39])) {
                        ev.preventDefault();
                        this.value('+=1');
                        this._call(this.option('onSlide'), this.value());
                    } else if (webf.inArray(ev.which, [40, 37])) {
                        ev.preventDefault();
                        this.value('-=1');
                        this._call(this.option('onSlide'), this.value());
                    }
                }
            },
            keyup: (ev) => {
                if (this.focus) {
                    if (webf.inArray(ev.which, [37, 38, 39, 40])) {
                        if (this.old_value !== this.val) {
                            this.old_value = this.val;
                            this._call(this.option('onStop'), this.value());
                        }
                    }
                }
            }
        });
    },

    _getHandler: function()
    {
        return this.e.children('a.handler');
    },

    _getNbSteps: function()
    {
        return this.option('max') - this.option('min');
    },

    _getOrientation: function()
    {
        return this.option('orientation').toLowerCase() === "vertical" ? "vertical" : "horizontal";
    },

    _getMouseValue: function(ev)
    {
        const mp = $webf.getMousePos(ev, this.e);
        const size = this._getSize();
        const sizeStep = this._getSize() / this._getNbSteps();

        var n = this._getOrientation() === 'horizontal'
            ? webf.between(mp.x, 0, size) / sizeStep
            : webf.between(size - mp.y, 0, size) / sizeStep;

        n = Math.round(n) > Math.floor(n) ? Math.ceil(n) : Math.floor(n);

        return this.option('min') + n;
    },

    _getSize: function()
    {
        return this._getOrientation() === 'vertical' ? this.e.outerHeight() : this.e.outerWidth();
    },

    value: function(val)
    {
        if (webf.isUndefined(val)) {
            return this.val;
        }

        var parseValue = function() {
            if (webf.isFloat(this)) {
                return parseFloat(this);
            }

            return null;
        };

        val += '';

        if (val.charAt(1) === '=') {
            var value = this.value();

            if (value === null) {
                value = 0;
            }

            if (val.charAt(0) === '-') {
                val = value - parseValue.call(val.split('=')[1]);
            } else if (val.charAt(0) === "+") {
                val = value + parseValue.call(val.split('=')[1]);
            }
        }

        this.val = webf.between(val, this.option('min'), this.option('max'));
        this._getHandler().css(this._getOrientation() === 'vertical' ? 'bottom' : 'left', (((this.val - this.option('min')) / this._getNbSteps()) * 100) + '%');

        if (this.old_value !== null) {
            this._call(this.option('onChange'), this.val);
        } else {
            this.old_value = this.val;
        }
    },

    _destroy: function()
    {
        var classnames = ['webf-slider', this._getOrientation()];
        if (this.option('sliderClass')) {
            classnames.push(this.option('sliderClass'));
        }

        this.e.removeClass(classnames.join(' '));

        this._getHandler().remove();
    }
});

