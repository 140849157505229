import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Italic = webf.RichTextEditor.Control.extend({
    init: function(editor) {},
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-italic'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('italic');
    },
    refresh: function(editor, $btn) {
        if (editor._queryCommandState('italic')) {
            $btn.addClass('webf-active');
        } else {
            $btn.removeClass('webf-active');
        }
    },
    types: 'action',
    text_tooltip: 'italic',
    shortcut: {
        Ctrl: true,
        key: 'I'
    }
});

