import webf from '../../utils/core'
import $ from 'jquery'

webf.SelectMultiple = webf.Select.extend({

    draw: function()
    {
        const $options = this._getOptions();
        const wSelect = webf.round(parseFloat(webf.getStyle(this.e[0], "width")));
        const $menu = $(`<div class="webf-select-drop multiple ${this.plugin.option('wrapperClass')}">`)
                .css('width', this.plugin.option('width') == 'auto' ? wSelect : this.plugin.option('width'));

        this.e.wrap($menu);
        this.e.after($options);

        this.menu = this.e.parent();

        const vals = [];
        this.e.find('option:selected').each(function() {
            vals.push($(this).val());
        });

        this.val(vals);

        this.e.hide();
    },

    bindEvents: function()
    {
        var self = this;

        this.plugin._on(window, {
            resize: function() {
                if (self.plugin.option('width') == 'auto') {
                    self.e.show();
                    var wSelect = webf.round(parseFloat(webf.getStyle(self.e[0], 'width')));
                    self.e.hide();
                    self.menu.css('width', wSelect);
                }
            }
        });

        this.plugin._on(this.menu, '.webf-list-item-option.enabled', {
            mouseenter: function() {
                self.index = $(this).prevAll('li.webf-item-option.enabled').length;
                self.focusItem($(this));
            },
            click: function() {
                var $item = $(this),
                    value = $item.data('value');

                if ($item.hasClass('webf-active')) {
                    self.removeVal(value);
                } else {
                    self.addVal(value);
                    self.plugin._call(self.plugin.option('onSelect'), $item, $item.data('value'));
                }
            }
        })._on(this.menu, '.webf-list-item-option', {
            mouseleave: function() {
                self.blurItem($(this));
            }
        });
    },

    removeVal: function(val)
    {
        var vals = this.val();

        webf.each(vals, function(i, value) {
            if (value == val) {
                vals.splice(i, 1);
                this.val(vals, true);
                return false;
            }
        }, this);
    },

    addVal: function(val)
    {
        var vals = this.val();

        if (!webf.inArray(val, vals)) {
            vals.push(val);
            this.val(vals, true);
        }
    },

    val: function(vals, trigger)
    {
        if (webf.isUndefined(vals)) {
            return this.e.find('option:selected').map(function(i,v) {
                return this.value;
            }).get();
        }

        this.deselect(this.menu.find('.webf-list-item-option.enabled').filter(function() {
            return !$(this).data('all');
        }));

        webf.each(vals, function(i, val) {
            this.select(this.menu.find('.webf-list-item-option.enabled').findByData('value', val));
        }, this);

        if (!webf.compareArray(vals, this.val())) {
            this.e.val(vals);

            if (trigger) {
                this.e.trigger('change');
                this.plugin._call(this.plugin.option('onChange'), this.val());
            }
        }

        this.display();
    },

    select: function($items)
    {
        $items.addClass('webf-active');
    },

    deselect: function($items)
    {
        $items.removeClass('webf-active');
    },

    display: function()
    {
    }
});
