import webf from './core'

// class that all other classes will inherit from
function Class() { }

// called upon a class to create a subclass
Class.extend = function(members) {
    var superClass = this;
    var subClass;

    members = members || {};

    // ensure a constructor for the subclass, forwarding all arguments to the super-constructor if it doesn't exist
    if (hasOwnProp(members, 'constructor')) {
        subClass = members.constructor;
    }
    if (typeof subClass !== 'function') {
        subClass = members.constructor = function() {
            superClass.apply(this, arguments);
        };
    }

    // build the base prototype for the subclass, which is an new object chained to the superclass's prototype
    subClass.prototype = createObject(superClass.prototype);

    // copy each member variable/method onto the the subclass's prototype
    copyOwnProps(members, subClass.prototype);
    copyNativeMethods(members, subClass.prototype); // hack for IE8

    // copy over all class variables/methods to the subclass, such as `extend` and `mixin`
    copyOwnProps(superClass, subClass);

    return subClass;
};

// adds new member variables/methods to the class's prototype.
// can be called with another class, or a plain object hash containing new members.
Class.mixin = function(members) {
    copyOwnProps(members.prototype || members, this.prototype);
};

function hasOwnProp(obj, name) {
    return hasOwnPropMethod.call(obj, name);
}

var hasOwnPropMethod = {}.hasOwnProperty;

// Create an object that has the given prototype. Just like Object.create
function createObject(proto) {
    var f = function() {};
    f.prototype = proto;
    return new f();
}

function copyOwnProps(src, dest) {
    for (var name in src) {
        if (hasOwnProp(src, name)) {
            dest[name] = src[name];
        }
    }
}

// Copies over certain methods with the same names as Object.prototype methods. Overcomes an IE<=8 bug:
// https://developer.mozilla.org/en-US/docs/ECMAScript_DontEnum_attribute#JScript_DontEnum_Bug
function copyNativeMethods(src, dest) {
    var names = [ 'constructor', 'toString', 'valueOf' ];
    var i, name;

    for (i = 0; i < names.length; i++) {
        name = names[i];

        if (src[name] !== Object.prototype[name]) {
            dest[name] = src[name];
        }
    }
}

webf.Class = Class; // export

export default Class;
