import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.OrderedList = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-list-ol'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('insertOrderedList');
    },
    refresh: function(editor, $btn) {
    },
    types: 'action',
    text_tooltip: 'ordered_list'
});

