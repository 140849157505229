import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - url               (string)        URL du document à télécharger
 *  - cookie            (Object)        Infos sur le cookie servant à déterminer l'état du téléchargement. Doit être en
 *                                      coordination avec le backend
 *  - checkInterval:    (int)           Temps en ms de chaque vérification du cookie
 *  - onLoading         (function())    Fonction appelée lorsque le téléchargement commence
 *  - onComplete        (function())    Fonction appelée lorsque le téléchargement est terminé
 *
 * Méthodes publiques:
 */
$webf('ajaxdownload', {
    options: {
        url:                '',
        cookie: {
            name: 'download',
            value: 1,
            error: 'err',
        },
        checkInterval:      100,
        onLoading:          webf.noop,
        onComplete:         webf.noop,
        onError:            webf.noop
    },

    _create: function()
    {
        $("<iframe>")
            .prop('name', 'ifrm_' + webf.uniqid())
            .prop('src', this.option('url'))
            .hide()
            .appendTo('body');

        this._initCookie();

        var checkDownload = function()
        {
            if (webf.readCookie(this.cookie.name) == this.cookie.value) {
                this._call(this.option('onComplete'));
                this._clean();
                this.e.webfAjaxdownload('destroy');
                return;
            }

            if (webf.readCookie(this.cookie.name) == this.cookie.error) {
                this._call(this.option('onError'));
                this._clean();
                this.e.webfAjaxdownload('destroy');
                return;
            }

            webf.setTimeout(checkDownload, this.option('checkInterval'), this);
        };

        this._call(this.option('onLoading'));

        webf.setTimeout(checkDownload, this.option('checkInterval'), this);
    },

    _initCookie: function()
    {
        this.cookie = this.option('cookie');
        webf.eraseCookie(this.cookie.name);
    },

    _clean: function()
    {
        webf.eraseCookie(this.cookie.name);
    },

    _destroy: function()
    {
        this._clean();
    }
});
