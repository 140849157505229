import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.FontSizePx = webf.RichTextEditor.Control.extend({
    sizes: null,
    zIndex: null,

    init: function(editor, options) {
        this.sizes = options.sizes || [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64, 72, 96];
        this.zIndex = editor.option('zIndex');
    },
    render: function(editor, $btn) {
        var self = this;

        $btn.addClass('webf-select-button fontsizepx')
            .append("<label>" + editor._('fontsizepx') + "</label>")
            .append("<i class='fas fa-caret-down'></i>");

        this.$menu = $(
            "<ul class='webf-list'>" +
                webf.map(this.sizes, function(i, size) {
                    return "" +
                        "<li class='webf-list-item size-" + size + "'>" +
                            "<a data-size='" + size + "'>" + size + "</a>" +
                        "</li>";
                }).join('') +
            "</ul>"
        );

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-fontsizepx',
            onFirstOpen: function() {
                self.$menu.find('a').on('click', function() {
                    var size = $(this).data('size');

                    var selRange = editor.getSelectionRange();
                    var node = selRange.commonAncestorContainer;

                    while (node) {
                        if (node.nodeType == Node.ELEMENT_NODE && node.tagName == 'SPAN') {
                            if (node.style.fontSize) {
                                var $node = $('<div>').append(node);

                                $node.find('span[style*="font-size"]').css('font-size', '');
                                $node.find('span[style=""]').removeAttr('style').contents().unwrap().parent();
                                self.htmlSelection = $node.html();

                                break;
                            }
                        }

                        node = node.parentNode
                    }

                    var $htmlSelection = $('<div>', {
                        html: self.htmlSelection
                    });

                    $htmlSelection.find('span[style*="font-size"]').css('font-size', '');
                    $htmlSelection.find('span[style=""]').removeAttr('style')
                        .contents()
                        .unwrap()
                        .parent();

                    var spanString = $('<span/>', {
                        html: $htmlSelection.html()
                    }).css('font-size', size + 'px').prop('outerHTML');

                    editor.pasteHtml(spanString, true);

                    /*
                     * Code pour supprimer du mieux que j'ai pu les directives fontsize imbriquées
                     */
                    var changesNeeded = false;
                    var html = editor.content();

                    var parser = new DOMParser();
                    var doc = parser.parseFromString(html, "text/html");

                    var elements = doc.getElementsByTagName('*');
                    webf.each(elements, function(i, element) {
                        var curElement = element;

                        if (curElement.style.fontSize) {
                            while (countChildrenWithoutBr(curElement) && curElement.childNodes.length && curElement.childNodes.item(0).nodeType == Node.ELEMENT_NODE) {
                                var child = curElement.childNodes.item(0);

                                if (child.style.fontSize) {
                                    changesNeeded = true;

                                    element.style.fontSize = child.style.fontSize;
                                    child.style.fontSize = '';
                                }

                                curElement = child;
                            }
                        }
                    });

                    if (changesNeeded) {
                        editor.content(doc.body.innerHTML);
                    }

                    webf.setTimeout(function() {
                        self.$btn.find('label').text(size);
                    }, 1, this);
                });
            },
            onOpen: function() {
                self.htmlSelection = editor.getSelectionHtml();
            }
        });
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    refresh: function(editor, $btn) {
        var selRange = editor.getSelectionRange()
        var node = selRange.commonAncestorContainer;
        var fontSize = editor._('fontsizepx');

        while (node) {
            if (node.nodeType == Node.ELEMENT_NODE) {
                if (parseInt($(node).css('font-size'), 10)) {
                    fontSize = parseInt($(node).css('font-size'), 10);
                    break;
                }
            }

            node = node.parentNode
        }

        $btn.find('label').text(fontSize);
    },
    types: 'action',
    text_tooltip: 'fontsizepx',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});

function countChildrenWithoutBr(element) {
    return webf.map(element.childNodes, function(i, node) {
        if (node.nodeType == Node.ELEMENT_NODE && node.tagName == 'BR') {
            return null;
        }
    }).length;
}

