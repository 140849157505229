import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './inputautogrow'
import $ from 'jquery'

/**
 * @options
 *  - separator:            (string) Séparateur des tags dans l'input
 *  - tags:                 ([string]|[Tag]) tags initiaux.
 *  - tagsInputClass:       (string) Classes CSS à ajouter sur la zone de texte handler
 *  - tagClass:             (string) Classes CSS à ajouter aux tags
 *  - MDStyle:              (false|string) Style Material Design
 *  - confirmKeys:          ([int]) KeyCodes des touches servant à valider un tag après saisie
 *  - onTagClick:           (function($tag, tag)) Fonction appelée lorsqu'un clic survient sur un tag
 *  - onBeforeTagAdd:       (function($tag, tag)) Fonction appelée juste avant qu'un tag est ajouté
 *  - onTagAdd:             (function($tag, tag)) Fonction appelée juste après qu'un a été ajouté
 *  - onBeforeTagRemove:    (function($tag, tag)) Fonction appelée juste avant qu'un tag est supprimé
 *  - onTagRemove:          (function()) Fonction appelée juste après qu'un a été supprimé
 *  - onTagFocus:           (function($tag, tag)) Fonction appelée lorsqu'un tag prend le focus
 *
 * @methods
 *  - appendTag(tag)        Ajoute un tag
 *  - removeTag(tag)        Supprime un tag
 *  - removeAllTags()       Supprime tous les tags
 *  - tags()                Retourne les tags
 *  - input()               Retourne la zone d'input
 *  - container()           Retourne l'élément HTML du conteneur des tags
 *
 * Structures:
 *  Tag {
 *   - label (string)
 *   - value (mixed)
 *  }
 */
$webf('tagsinput', {
    options: {
        separator:          ',',
        tags:               [],
        tagsInputClass:     '',
        tagClass:           '',
        confirmKeys:        [13, 'Enter'],
        MDStyle:            false,
        onTagClick:         webf.noop,
        onBeforeTagAdd:     webf.noop,
        onTagAdd:           webf.noop,
        onBeforeTagRemove:  webf.noop,
        onTagRemove:        webf.noop,
        onTagFocus:         webf.noop
    },

    _create: function()
    {
        if (this.option('MDStyle')) {
            this._createMDControl();
        } else {
            this._createClassicControl();
        }

        const $form = this.$input.closest('form');
        const $wrapperInput = this.$input.parent();

        if (!this.$input.closest('form')[0]) {
            this.$input.wrap($('<form>').css({
                display: 'inline'
            }));
        } else {
            this.$input.wrap($('<span>'));
        }

        if (!$form.find('[type="submit"]')[0]) {
            $form.append($("<input>")
                .prop('type', 'submit')
                .css({
                    position: 'absolute',
                    left: '-9999px',
                    top: '-9999px',
                })
            );
        }

        this._initTags();

        if (!this.tags().length) {
            this.$input
                .addClass('empty')
                .prop('placeholder', this.placeholder);
        } else {
            this._enableAutogrow();
        }

        this._bindEvents();
    },

    _createMDControl()
    {
        const id = this.e.attr('id') || webf.uniqid();

        const $tagsInput = $("<div>")
            .prop('tabIndex', -1)
            .addClass('webf-tagsinput md-style ' + this.option('MDStyle') + this.option('tagsInputClass').split(' ').join(' '));

        this.e.wrap($tagsInput).hide();
        this.$tagsInput = this.e.closest('.webf-tagsinput');

        this.$input = $("<input>")
            .addClass('webf-tagsinput-text')
            .prop('enterkeyhint', 'done')
            .prop('autocomplete', 'off')
            .prop('type', 'text');

        let $label = $(`label[for=${id}]`);

        if (!$label[0]) {
            const placeholder = this.e.attr('placeholder') || '';

            if (placeholder.length) {
                $label = $(`<label>${placeholder}</label>`);
            }
        }

        this.$tagsInput.append($("<div>").addClass('container-tags'));
        this.$containerTags = this.$tagsInput.find('.container-tags');
        this.$containerTags.append(this.$input);
        this.$tagsInput.append(this.$containerTags);

        if ($label[0]) {
            this.$tagsInput.prepend($label);
        }

        if (this.e.attr('inputmode')) {
            this.$input.attr('inputmode', this.e.attr('inputmode'))
        }

        this.placeholder = this.e.attr('placeholder') || '';
    },

    _createClassicControl()
    {
        const $tagsInput = $("<div>")
            .prop('tabIndex', -1)
            .addClass('webf-tagsinput ' + this.option('tagsInputClass').split(' ').join(' '));

        this.$input = $("<input>")
            .addClass('webf-tagsinput-text')
            .prop('enterkeyhint', 'done')
            .prop('autocomplete', 'off')
            .prop('type', 'text');

        if (this.e.attr('inputmode')) {
            this.$input.attr('inputmode', this.e.attr('inputmode'))
        }

        this.e.hide().wrap($tagsInput);
        this.$tagsInput = this.e.parent().prepend(this.$input);
        this.placeholder = this.e.attr('placeholder') || '';
        this.$containerTags = this.$tagsInput;
    },

    _enableAutogrow: function()
    {
        this.$input.webfInputautogrow({
            minWidth: 10,
            extraWidth: 10
        });
    },

    _disableAutogrow: function()
    {
        try {
            this.$input.webfInputautogrow('destroy');
        } catch (e) {}
    },

    _bindEvents: function()
    {
        this._on(this.$tagsInput, {
            click: () => {
                this._trigger(this.$input, 'focus');
            }
        })._on(this.$tagsInput, '.webf-tag', {
            click: (ev) => {
                ev.stopPropagation();
                this._call(this.option('onTagClick'), $(ev.currentTarget), $(ev.currentTarget).data('tag'));
            },
            focus: (ev) => {
                ev.stopPropagation();
                this._call(this.option('onTagFocus'), $(ev.currentTarget), $(ev.currentTarget).data('tag'));
            }
        })._on(this.$tagsInput, '.remove', {
            click: (ev) => {
                ev.stopPropagation();
                this.removeTag($(ev.currentTarget).closest('.webf-tag').data('tag'));
            }
        })._on(this.$input, {
            focus: (ev) => {
                if (this.tags().length) {
                    this._enableAutogrow();
                }
            }
        });

        this._on(this.$tagsInput, '.webf-tag', {
            keydown: (ev) => {
                switch (ev.key) {
                    case 'Backspace': // 8
                    case 'Delete': // 46
                        ev.preventDefault();

                        this.removeTag($(ev.currentTarget).data('tag'));
                        const $tag = this.$containerTags.find('.webf-tag').last();

                        if ($tag[0]) {
                            this._focusTag($tag);
                        } else {
                            this.$input.trigger('focus');
                        }
                        break;

                    case 'ArrowLeft': // 37
                        const $prevTags = $(ev.currentTarget).prevAll('.webf-tag');

                        if ($prevTags.length) {
                            this._focusTag($prevTags.first());
                        }
                        break;

                    case 'ArrowRight': // 39
                        var $nextTags = $(ev.currentTarget).nextAll('.webf-tag');

                        if ($nextTags.length) {
                            this._focusTag($nextTags.first());
                        } else {
                            this.$input.trigger('focus');
                        }
                        break;
                }
            }
        });

        this._on(this.$input, {
            keydown: (ev) => {
                if (webf.inArray(ev.which, this.option('confirmKeys') || webf.inArray(ev.key, this.option('confirmKeys')))) {
                    const val = this.$input.val();
                    ev.preventDefault();

                    if (val.length) {
                        this.appendTag(val);
                        this.$input.val('');
                    }

                    this.$input.trigger('focus');
                } else {
                    switch (ev.key) {
                        case 'Backspace': // 8
                            const val = this.$input.val();

                            if (!val.length) {
                                ev.preventDefault();
                                this._focusTag(this.$containerTags.find('.webf-tag').last());
                            }
                            break;

                        case 'ArrowLeft': // 37
                            if (webf.getCaretPosition(this.$input[0]) === 0) {
                                this._focusTag(this.$containerTags.find('.webf-tag').last());
                            }
                            break;
                    }
                }
            }
        });

        document.addEventListener('focus', ev => {
            const $target = $(ev.target);
            if ($target.closest(this.$tagsInput)[0]) {
                this.$tagsInput.addClass('has-label is-focused');
            }
        }, true);

        document.addEventListener('blur', ev => {
            const $target = $(ev.target);
            if ($target.closest(this.$tagsInput)[0]) {
                if (!this._tags.length && !this.$input.val().length) {
                    this.$tagsInput.removeClass('has-label');
                }

                this.$tagsInput.removeClass('is-focused');
            }
        }, true)
    },

    _initTags()
    {
        this._tags = [];

        const tags = this.option('tags').length
            ? this.option('tags')
            : this.e.val().length
                ? this.e.val().split(this.option('separator'))
                : []
        ;

        webf.each(tags, (i, tag) => {
            const $tag = this._createTag(tag);
            this._tags.push($tag.data('tag'));
            this.$input.parent().before($tag);
        });

        if (this._tags.length) {
            if (this.option('MDStyle')) {
                this.$tagsInput.addClass('has-label');
            }
        }
    },

    _createTag: function(tag)
    {
        const textTag = webf.isObject(tag) ? tag.label : tag.trim();
        const valueTag = webf.isObject(tag) ? tag.value : tag.trim();

        if (webf.isUndefined(textTag) || webf.isUndefined(valueTag || !textTag.length)) {
            return;
        }

        tag = {
            label: textTag,
            value: valueTag
        };

        return $('<span>')
            .addClass('webf-tag ' + this.option('tagClass'))
            .prop('tabIndex', -1)
            .html(textTag
                .replace(/\s/g,'&nbsp;')
                .replace(/</g,'&lt;')
                .replace(/>/g,'&gt;')
            )
            .data('tag', tag)
            .data('label', textTag)
            .data('value', valueTag)
            .append($('<span>').addClass('remove')
                .append($("<i class='fas fa-times'>"))
            );
    },

    _focusTag: function($tag)
    {
        this._trigger($tag, 'focus');
    },

    appendTag: function(tag)
    {
        tag = webf.isObject(tag) ? tag : {
            label: tag,
            value: tag
        };

        const textTag = webf.isObject(tag) ? tag.label : tag.trim();
        const valueTag = webf.isObject(tag) ? tag.value : tag.trim();

        if (webf.isUndefined(textTag) || webf.isUndefined(valueTag || !textTag.length)) {
            return;
        }

        if (this._call(this.option('onBeforeTagAdd'), tag) !== false) {
            this._tags.push(tag);
            const $tag = this._createTag(tag);
            // this.$input.before($tag);
            this.$input.parent().before($tag);
            this.$input.prop('placeholder', '').removeClass('empty');
            this._enableAutogrow();
            this._call(this.option('onTagAdd'), $tag, tag);
        }

        if (this.option('MDStyle')) {
            this.$tagsInput.addClass('has-label');
        }
    },

    removeTag: function(tag, all)
    {
        const tagValue = webf.isObject(tag) ? tag.value : tag;
        this._tags.reverse();

        webf.each(this._tags, function(i, aTag) {
            const aTagValue = webf.isObject(aTag) ? aTag.value : aTag;

            if (aTagValue == tagValue) {
                const $tag = this.$containerTags.find('.webf-tag').findByData('value', tagValue).first();

                if (this._call(this.option('onBeforeTagRemove'), $tag, $tag.data('tag')) !== false) {
                    $tag.remove();
                    this._tags.splice(i, 1);

                    if (!this.tags().length) {
                        this._disableAutogrow();
                        this.$input.prop('placeholder', this.placeholder).addClass('empty');
                    }

                    this._call(this.option('onTagRemove'));
                }
                return !!all;
            }
        }, this);

        this._tags.reverse();

        if (!this._tags.length) {
            this.$tagsInput.removeClass('has-label');
        }
    },

    removeAllTags: function()
    {
        webf.each(this.tags(), function(i, tag) {
            this.removeTag(tag);
        }, this);
    },

    tags: function()
    {
        return this._tags;
    },

    input: function()
    {
        return this.$input;
    },

    container: function()
    {
        return this.$tagsInput;
    },

    widget: function()
    {
        return this.$tagsInput;
    },

    _destroy: function()
    {
        this._disableAutogrow();
        this.$containerTags.find('span').remove();
        this.$input.parent().remove();
        this.e.show().unwrap();
    }
});

