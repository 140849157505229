import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.datagrid = {
        search: 'Rechercher',
        refresh: 'Rafraîchir',
        allColumns: 'Toutes les colonnes',
        abbrColumn: '#',
        today: 'Aujourd\'hui',
        noDate: 'Sans date'
    };
})(webf);

