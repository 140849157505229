import webf from '../../utils/core'
import $ from 'jquery'

import Mustache from "mustache";
import cellTemplate from "./templates/cell.tpl.html";

const Footer = webf.Class.extend({
    constructor: function(plugin, footerOptions)
    {
        this.plugin = plugin;
        this.footerOptions = footerOptions;
    },

    render: function()
    {
        var p = this.plugin,
            self = this,
            columns = p.getVisibleColumns(),
            $footer = $('<div>').addClass('footer'),
            totalWidth = 0;

        $footer.append('<table>');

        this.$tableFooter = $footer.find('table');

        var $trGridSize = $('<tr>').addClass('grid-size');
        webf.each(columns, function(i, column) {
            totalWidth += parseInt(column.size);

            $trGridSize.append($('<td>').width(column.size));
        });

        var $tdLastColumn = $('<td>').addClass('last-column');

        $trGridSize.append($tdLastColumn);

        this.$tableFooter.append($trGridSize);

        webf.each(this.footerOptions || [], function(i, record) {
            var $row = self._addRecord(record),
                $tdLastColumn = $('<td>').addClass('last-column');

            $row.append($tdLastColumn);
        });

        return $footer;
    },

    _addRecord: function(record)
    {
        const p = this.plugin;
        const columns = p.getVisibleColumns();

        const $row = $("<tr>").addClass([
            'record',
            record.rowClass].join(' '));
        $row.data('record', record);

        webf.each(columns, function(i, column) {
            const $cell = $('<td>').addClass(column.hasOwnProperty('columnClass') ? column.columnClass : '');

            if (webf.isUndefined(record[column.field])) {
                $row.append($cell.append(''));
                return true; // continue
            }

            const funcRender = webf.isFunction(column.render) ? column.render : function(field) {
                return this[field];
            };

            const $content = $('<div>').addClass('col-wrapper').append($('<div>').addClass('column-name').html(
                funcRender.call(record, column.field, record)
            ));

            $row.append($cell.append($content));
        });

        this.$tableFooter.append($row);

        return $row;
    },

    update: function(records) {
        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            this.remove(record);
            this._addRecord(record);
        }, this);
    },

    remove: function(records)
    {
        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            const $record = this.$tableFooter.find('.record');

            if ($record.data('record').id === record.id) {
                $record.remove();
            }
        }, this);
    },

    adjust: function()
    {
        if (this.plugin.e.find('.grid').hasScrollBarHorizontal()) {
            var wScrollBar = webf.getScrollbarWidth();

            if (wScrollBar) {
                this.plugin.e.find('.footer .last-column').css('width', wScrollBar);
            }
        } else {
            this.plugin.e.find('.footer .last-column').css('width', 'auto');
        }
    }
});

export default Footer
