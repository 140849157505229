import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - minWidth
 *  - maxWidth
 *  - extraWidth
 *
 * Méthodes publiques :
 */
$webf('inputautogrow', {
    options: {
        minWidth:   50,
        maxWidth:   false,
        extraWidth: 10
    },

    _create: function()
    {
        var self = this,
            oldValue = null,
            minWidth = this.option('minWidth') || this.e.width(),
            $mirror = $('<div>').css({
                position: 'absolute',
                top: -9999,
                left: -9999,
                width: 'auto',
                fontSize: this.e.css('fontSize'),
                fontFamily: this.e.css('fontFamily'),
                fontWeight: this.e.css('fontWeight'),
                letterSpacing: this.e.css('letterSpacing'),
                whiteSpace: 'nowrap'
            }).html(this.e.val().escapeHtml().replace(/\s/g, '&nbsp;'));

        $mirror.appendTo('body');

        this.$mirror = $mirror;

        this._on(this.e, {
            'input change paste keydown initautogrow': function() {
                webf.setTimeout(function() {
                    var val = this.e.val();

                    if (oldValue === val) {
                        return;
                    }

                    oldValue = val;

                    $mirror.html(val.escapeHtml().replace(/\s/g, '&nbsp;'));
                    var mirrorWidth = $mirror.width(),
                        newWidth = (mirrorWidth + this.option('extraWidth')) >= minWidth ? mirrorWidth + this.option('extraWidth') : minWidth;

                    if ((newWidth < this.e.width() && newWidth >= minWidth) || (newWidth > minWidth && (!this.option('maxWidth') || newWidth < this.option('maxWidth')))) {
                        this.e.width(newWidth);
                    }
                }, 1, self);
            }
        });

        this._trigger(this.e, 'initautogrow');
    },

    _destroy: function()
    {
        this.e.width('');
        this.$mirror.remove();
    }
});

