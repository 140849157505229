import webf from './core'
import $ from 'jquery'

class Mouse
{
    static getPosition(ev, element) {
        ev = this.#getEvent(ev);
        let rect = { left: 0, top: 0 };

        if (element) {
            const $element = element instanceof $ ? element : $(element);
            const tmpRect = $element.offset();

            if (tmpRect) {
                rect = tmpRect;
            }
        }

        return {
            x: ev.pageX !== undefined ? ev.pageX - rect.left : rect.left,
            y: ev.pageY !== undefined ? ev.pageY - rect.top : rect.top
        };
    }

    static getElement(ev) {
        ev = this.#getEvent(ev);

        return window.document.elementFromPoint(ev.clientX, ev.clientY);
    }

    static #getEvent(ev) {
        if (webf.isTouchDevice()) {
            const orgEvent = ev.originalEvent ? ev.originalEvent : ev;

            if (orgEvent.changedTouches && orgEvent.changedTouches.length) {
                ev = orgEvent.changedTouches[0];
            } else if (orgEvent.touches) {
                ev = orgEvent.touches[0];
            }
        }

        return ev;
    }
}

export default Mouse
