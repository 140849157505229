import webf from '../../utils/core'

webf.lang.en = webf.lang.en || {};

webf.lang.en.confirm = {
    yes: 'Yes',
    cancel: 'Cancel',
    textConfirm: 'Are you sure to do this ?'
};

