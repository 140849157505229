import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - value
 *  - max
 *  - onCreate
 *  - onComplete
 *
 * Méthodes publiques :
 *  - value
 *  - widget
 */
$webf('progressbar', {
    options: {
        value:      0,
        max:        100,
        onCreate:   webf.noop,
        onComplete: webf.noop
    },

    _create: function()
    {
        this.e.addClass('webf-progressbar');
        this.e.append($('<div>').addClass('inner-progress'));

        this.value(this.option('value'));

        this._call(this.option('onCreate'));
    },

    _refresh: function()
    {
        var porcentage = this.value() / this.option('max') * 100;
        this.e.children('.inner-progress').css('width', porcentage + "%");

        if (porcentage >= 100) {
            this._call(this.option('onComplete'));
        }
    },

    value: function(val)
    {
        if (webf.isUndefined(val)) {
            return this.val;
        }

        if (!webf.isFloat(val)) {
            val = 0;
        }

        this.val = webf.between(val, 0, this.option('max'));

        this._refresh();
    },

    widget: function()
    {
        return this.$progressBar;
    },

    _destroy: function()
    {
        this.e.removeClass('webf-progressbar');
    }
});
