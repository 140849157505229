import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - onLoaded:         (function(image, ev))
 *  - onAllLoaded:      (function(image, ev))
 */
$webf('imageloader', {
    options: {
        onLoaded:       webf.noop,
        onAllLoaded:    webf.noop
    },

    _create: function()
    {
        const self = this;
        const $imgs = this.e[0].tagName.toLowerCase() == 'img'
            ? this.e
            : this.e.find('img');
        let loaded = 0;
        let nbImgs = $imgs.length;

        if (!nbImgs) {
            this._call(this.option('onLoaded'));
            this._call(this.option('onAllLoaded'));
        }

        $imgs.each((i, img) => {
            const $img = $(img);
            const image = new Image();
            image.src = $(img).prop('src');

            const onLoad = function(ev) {
                loaded++;

                self._call(self.option('onLoaded'), $img, this, ev);

                if (loaded == nbImgs) {
                    self._call(self.option('onAllLoaded'), $img, this, ev);
                    self.destroy();
                }
            }

            image.onload = onLoad;
            image.onerror = onLoad;
        });
    }
});

