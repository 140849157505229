import webf from './core'

webf.client = {
	os: null,
	browser: {
		name: null,
		version: null
	}
};

if (typeof window !== "undefined") {
	var win = window;

	webf.client.init = function()
	{
		var dataOS = [ {
			pattern: "Win",
			identity: "Windows"
		}, {
			pattern: "Mac",
			identity: "Mac"
		}, {
			pattern: /iPhone|iPad|iPod/,
			identity: "iOS"
		}, {
			pattern: "Android",
			identity: "Androïd"
		}, {
			pattern: "Linux",
			identity: "Linux"
		}, {
			pattern: "FreeBSD",
			identity: "FreeBSD"
		}, {
			pattern: "SunOS",
			identity: "Solaris"
		}, {
			pattern: "BlackBerry",
			identity: "BlackBerry"
		}, {
			pattern: /(Palm|Web)OS/,
			identity: "Palm"
		} ];

		var dataBrowser = [ {
			key: 'userAgent',
			pattern: "Chrome",
			identity: "Chrome"
		}, {
			key: 'userAgent',
			pattern: "OmniWeb",
			versionPattern: "OmniWeb/",
			identity: "OmniWeb"
		}, {
			key: 'vendor',
			pattern: "Apple",
			identity: "Safari",
			versionPattern: "Version"
		}, {
			prop: win.opera,
			identity: "Opera",
			versionPattern: "Version"
		}, {
			key: 'vendor',
			pattern: "iCab",
			identity: "iCab"
		}, {
			key: 'vendor',
			pattern: "KDE",
			identity: "Konqueror"
		}, {
			key: 'userAgent',
			pattern: "Firefox",
			identity: "Firefox"
		}, {
			key: 'vendor',
			pattern: "Camino",
			identity: "Camino"
		}, {
			key: 'userAgent',
			pattern: "Netscape",
			identity: "Netscape"
		}, {
			key: 'userAgent',
			pattern: "MSIE",
			identity: "Explorer",
			versionPattern: "MSIE"
		}, {
			key: 'userAgent',
			pattern: /Trident\/.*rv:([0-9]+[\.0-9])*/,
			identity: "Explorer",
			versionPattern: /Trident\/.*rv:([0-9]+[\.0-9]*)/
		}, {
			key: 'userAgent',
			pattern: "Gecko",
			identity: "Mozilla",
			versionPattern: "rv"
		}, {
			key: 'userAgent',
			pattern: "Mozilla",
			identity: "Netscape",
			versionPattern: "Mozilla"
		} ];

		this.populateOS(dataOS);
		this.populateBrowser(dataBrowser);

		webf.each(this, function(name, prop) {
			if( webf.isFunction(prop) ) {
				delete this[name];
			}
		}, this);
	};

	webf.client.populateOS = function(datas)
	{
		webf.each(datas, function(i, data) {
			if( data.pattern instanceof RegExp
				? data.pattern.test(win.navigator.platform)
				: win.navigator.platform.indexOf(data.pattern) > -1
			) {
				this.os = data.identity;
				return false;
			}
		}, this);
	};

	webf.client.populateBrowser = function(datas)
	{
		webf.each(datas, function(i, data) {
			if( data.prop ) {
				this.browser.name = data.identity;
				this.populateVersionBrowser(data);
				return false;
			}

			var navValue = win.navigator[data.key];

			if( !webf.isUndefined(navValue) ) {
				if( data.pattern instanceof RegExp
					? data.pattern.test(navValue)
					: navValue.indexOf(data.pattern) > -1
				) {
					this.browser.name = data.identity;
					this.populateVersionBrowser(data);
					return false;
				}
			}
		}, this);
	};

	webf.client.populateVersionBrowser = function(datas)
	{
		var ua = win.navigator.userAgent;
		var vp = datas.versionPattern || datas.identity;

		if( vp instanceof RegExp ) {
			var matches = datas.versionPattern.exec(ua);

			if( !matches.length ) {
				return false;
			}

			this.browser.version = matches[1];
		} else {
			var index = ua.indexOf(vp);

			if( index == -1 ) {
				return false;
			}

			this.browser.version = parseFloat(ua.substring(index + vp.length + 1));
		}

		return true;
	};

	webf.client.populateVersionBrowserFromAppVersion = function()
	{
		if( win.navigator.appVersion ) {
			this.browser.version = parseFloat(win.navigator.appVersion);
			return true;
		}

		return false;
	};

	webf.client.init();
}
