import webf from '../../utils/core'
import $ from 'jquery'

webf.SelectMDSimple = webf.SelectSimple.extend(
{
    draw()
    {
        const wSelect = webf.round(parseFloat(webf.getStyle(this.e[0], "width")));
        const $selectWrapper = this.e.wrap($('<div>')
            .addClass(['webf-select-md-text'].concat(this.plugin.option('selectClass').split(' ')).join(' '))
        ).parent();
        const inputMdClass = this.plugin.option('inputMdClass');
        let wrapperClasses = this.plugin.option('wrapperClass');

        if (webf.inArray(inputMdClass, ['filled', 'outlined'])) {
            wrapperClasses += ` ${inputMdClass}`;
        }

        const $wrapperDropdown = $selectWrapper.wrap($(`<div class="webf-md-select ${wrapperClasses}">`)
            .css('width', this.plugin.option('width') == 'auto' ? wSelect : this.plugin.option('width'))
        ).parent();

        let $label = $(`label[for="${this.e.attr('id')}"]`);

        if (!$label[0]) {
            $label = $(`<label>${this.e.find('option').first().text()}</label>`);
        }

        const hasErrorClass = this.e.hasClass('has-error');
        const isValidClass = this.e.hasClass('is-valid');

        $selectWrapper
            .append(`
                <div class="webf-md-text with-right-icon ${inputMdClass} ${hasErrorClass ? 'has-error' : ''} ${isValidClass ? 'is-valid' : ''}">
                    <div class="wrapper">
                        <input type="text" value="${this.val()}" readonly>
                    </div>
                    <div class="icon right-icon">
                        <i class="fas fa-caret-down"></i>
                    </div>
                </div>
            `);

        $selectWrapper.find('.wrapper').prepend($label);
        $selectWrapper.find('.webf-md-text input[type="text"]').trigger('webf-init-md-text');

        this.$select = $selectWrapper;

        var $options = this._getOptions(),
            $menu = $('<div>')
                .addClass('webf-select-drop')
                .append($options);

        if (this.plugin.option('filter')) {
            $menu.prepend("" +
                "<div class='filter'>" +
                    "<label>" +
                        "<input class='search' type='text' autocomplete='off'>" +
                        "<i class='icon-search fas fa-search'></i>" +
                    "</label>" +
                "</div>" +
            "");
        }

        $wrapperDropdown.append($menu);
        this.$wrapperDropdown = $wrapperDropdown;

        this.menu = $wrapperDropdown.webfDropdown({
            handle: $selectWrapper,
            menu: $menu,
            menuClass: this.plugin.option('dropClass'),
            onFirstOpen: ($e) => {
                const $menu = $e.webfDropdown('menu');
                const $input = $menu.find("input[type='text'].search");

                if ($input[0]) {
                    this.input = $input;

                    this.plugin._on($input, {
                        keydown: (ev) => {
                            switch(ev.keyCode || ev.which) {
                                case 38: case 40: case 9: case 13:
                                    break;

                                case 27:
                                    this.close();
                                    break;

                                default:
                                    this._searchDelay(ev);
                            }
                        },
                        'click mousedown': (ev) => {
                            ev.stopPropagation();
                        }
                    });
                }

                $menu.find('.webf-list-item-option').each((i, htmlOption) => {
                    const $item = $(htmlOption);
                    const option = $item.data('value');

                    if (this.noSelectedOption) {
                        this.selectedItem = this.selectedOption;
                    } else if (this.selectedOption.val() == option) {
                        this.val($item.data('value'));
                    }
                });

                this.plugin._call(this.plugin.option('onFirstOpen'));
            },
            onOpen: () => {
                this.$select.addClass('open');
                if (this.input) {
                    this.input.trigger('focus');
                }

                this.$wrapperDropdown.find('.webf-md-text').addClass('is-focused');

                this.plugin._call(this.plugin.option('onOpen'));
            },
            onClose: () => {
                this.$select.removeClass('open');
                // this.index = null;
                if (this.input) {
                    this.input.val('');
                }
                this._search('');
                this.showItem();
                this.blurItem();
                this.$select.find('.select').removeClass('open');
                this.$wrapperDropdown.find('.webf-md-text').removeClass('is-focused');

                this.plugin._call(this.plugin.option('onClose'));
            }
        }).webfDropdown('menu');

        this._resetNavigation();

        this.val(this.val());
        this.e.hide();
    },

    _resetNavigation()
    {
        this.menu.webfNavigation('destroy');
        this.menu.webfNavigation({
            tabIndex: 1,
            selectedClass: 'webf-focus',
            activeClass: 'webf-active',
            keys: {
                active: '13'
            },
            itemsSelector: '.webf-list-item.enabled:not(.webf-item-hidden)',
            mouseEventActive: null,
            loop: true,
            onItemSelect: ($item) => {
                this.focusItem($item);
            },
            onItemActive: ($item) => {
                this.val($item.data('value'), true);
                this.plugin._call(this.plugin.option('onSelect'), $item, this.e.val());
                this.close();
            }
        })
    },

    _getOptions()
    {
        const $options = $("<ul>").addClass('options webf-md-list');

        this.e.find('optgroup, option').each((i, option) => {
            const $opt = $(option);
            const tag = $opt[0].tagName;

            if (tag == 'OPTGROUP') {
                $options.append(
                    this._createGroupItem($opt.prop('label'))
                );
            } else if (tag == 'OPTION') {
                $options.append(
                    this._createOptionItem($opt.val(), $opt.text(), !$opt.prop('disabled'), $opt.prop('selected'), $opt.parent()[0].tagName == 'OPTGROUP')
                );
            }
        });

        return $options;
    },

    _createOptionItem(value, label, enabled = true, selected, group)
    {
        const $option = $("<li>")
            .addClass(['webf-list-item', 'webf-list-item-option', enabled ? 'enabled' : 'disabled', group ? 'group' : ''].join(' '));

        $option.append($('<a>').text(label));

        return $option.data('value', value);
    },

    bindEvents()
    {
        this.plugin._on(this.menu.add(this.$select), {
            'mousedown mousemove': (ev) => {
                if (ev.target.tagName !== 'INPUT' || !$(ev.target).hasClass('search') || !$(ev.target).closest('.webf-select-drop')[0]) {
                    ev.preventDefault();
                }
            }
        });

        this.plugin._on(this.menu, '.webf-list-item-option.enabled', {
            mouseenter: (ev) => {
                // this.index = $(ev.currentTarget).prevAll('li.webf-item-option.enabled').length;
                this.focusItem($(ev.currentTarget));
            },
            click: (ev) => {
                const $item = $(ev.currentTarget);
                this.val($item.data('value'), true);
                this.plugin._call(this.plugin.option('onSelect'), $item, this.e.val());
                this.close();
            }
        })._on(this.menu, '.webf-list-item-option', {
            mouseleave: (ev) => {
                // this.index = null;
                this.blurItem($(ev.currentTarget));
            }
        });
    },

    val(val, trigger)
    {
        if (webf.isUndefined(val)) {
            const value = this.e.val();
            return null === value || webf.isUndefined(value) ? '' : value;
        }

        if (val === null) {
            this.noSelectedOption = true;
        } else {
            const $item = this.menu.find('.webf-list-item-option').findByData('value', val).first();

            if ($item[0]) {
                this.e.val(val);

                this.noSelectedOption = false;
                this.selectedOption = this.e.find("option[value='" + (val + '').htmlsimplequotes() + "']");
                this.select($item);
            }

            if (trigger) {
                this.e.trigger('change');
                this.plugin._call(this.plugin.option('onChange'), $item, this.val());
            }
        }
    },

    select($item)
    {
        this.menu.find('.webf-list-item-option').removeClass('webf-active');
        $item.addClass('webf-active');
        this.selectedItem = $item;

        this.display();
    },

    display()
    {
        const val = this.val();
        const $item = this.menu.find('.webf-list-item-option').findByData('value', val);
        const $inputText = this.$select.find('input[type="text"]');

        $inputText.val($item.text());
        $inputText.trigger('webf-init-md-text');
    },

    close()
    {
        this.$wrapperDropdown.webfDropdown('close');
        this.plugin._call(this.plugin.option('onClose'));
    },

    destroy()
    {
        const $wdMdText = this.$select.children('.webf-md-text');
        const $select = this.$select.find('.select');
        this.e.unwrap().unwrap();

        $select.remove();
        $wdMdText.remove();

        if (this.menu) {
            this.menu.remove();
        }

        if (this.$wrapperDropdown) {
            this.$wrapperDropdown.webfDropdown('destroy');
            this.$wrapperDropdown.remove();
        }

        this.e.show();
    }
});

