import $webf from '../../utils/jquery.webf'
import webf from '../../utils/core'
import WebfDate from '../../utils/date'
import '../../utils/mousewheel'
import $ from 'jquery'
import Footer from './Footer'
import Mustache from 'mustache'

import recordTemplate from '../datagrid/templates/record.tpl.html'
import cellTemplate from '../datagrid/templates/cell.tpl.html'
import eventDispatcher from "../../utils/eventDispatcher";

const throttleDelay = 100;

const Grid = webf.Class.extend({
    constructor: function(plugin)
    {
        this.plugin = plugin;
        this.records = [];
        this.count = 0;
        this.indexesSelected = [];
        this.$tableGrid = null;
        this.lastScrollTop = 0;
        this.keydownTimeout = null;
    },

    render: function()
    {
        const p = this.plugin;
        const displayTopScrollbar = p.option('displayTopScrollbar');
        const $wrapper = $('<div>').addClass('wrapper-grid');
        const $grid = $('<div>').addClass('grid')
                .prop('tabIndex', '-1')
                .append('<table>');
        const $trGridSize = $('<tr>').addClass('grid-size');
        const columns = p.getVisibleColumns();

        this.$tableGrid = $grid.find('table');

        if (!this.observer) {
            this.observer = new MutationObserver(
                (mutations) => {
                    webf.each(mutations, (i, mutation) => {
                        if (mutation.type == 'childList') {
                            if (mutation.addedNodes && mutation.addedNodes.length) {
                                webf.each(mutation.addedNodes, (j, addedNode) => {
                                    const $addedNode = $(addedNode);
                                    if ($addedNode.hasClass('hscroll-grid')) {
                                        this.adjust();
                                    }
                                });
                            }
                        }
                    })
                }
            );

            this.observer.observe(
                $wrapper[0],
                {
                    childList: true,
                    subtree: true
                }
            );
        }

        webf.each(columns, function(i, column) {
            $trGridSize.append($('<td>').width(column.size));
        });
        $trGridSize.append($('<td>'));
        this.$tableGrid.append($trGridSize);

        if (displayTopScrollbar) {
            const $hscrollTop = $("<div class='hscroll-grid'><div></div></div>");
            $wrapper.append($hscrollTop);
        }

        $wrapper
            .append($grid);

        return $wrapper;
    },

    loadRecords: function()
    {
        const p = this.plugin;

        this._load((datas) => {
            this.renderRecords();
            p.loading = false;

            p._call(p.option('onFirstLoad'), this.records, this.count, datas);
            p._call(p.option('onLoad'), this.records, this.count, datas);
        });
    },

    renderRecords: function()
    {
        const p = this.plugin;
        const infosViewport = this.infosViewport();

        this.$tableGrid.find('.record.empty, .padding-records').detach();

        const records = this.records.slice(infosViewport.indexFirstRecord, infosViewport.indexLastRecord + 1);

        this.$tableGrid.find('.record').each((i, elemRecord) => {
            const $record = $(elemRecord);
            const recordInUI = $record.data('record');
            let found = false;

            webf.each(records, (i, record) => {
                if (recordInUI.id == record.id) {
                    found = true;

                    if (!webf.equals(recordInUI, record)) {
                        const $recordUpdated = this.drawRecord(record);
                        $record.replaceWith($recordUpdated);

                        if (this.isSelectedRecord(record)) {
                            $recordUpdated
                                .addClass('selected')
                                .removeClass('pre-selected inactive');
                        }
                    }
                }
            });

            if (!found) {
                $record.detach();
            }
        });

        let toAppend = false;
        const $records = [];
        webf.each(records, (i, record) => {
            if (this._getUIRecord(record)) {
                if (i === 0) {
                    toAppend = true;
                }

                return true;
            }

            const $record = this.drawRecord(record);

            $records.push($record);

            if (this.isSelectedRecord(record)) {
                $record
                    .addClass('selected')
                    .removeClass('pre-selected inactive');
            }
        });

        if (toAppend) {
            this.$tableGrid.append($records);
        } else {
            this.$tableGrid.find('tr.grid-size').after($records);
        }

        const $paddingRecordsTop = $('<tr>').addClass('padding-records').append($('<td>'));
        const $paddingRecordsBottom = $paddingRecordsTop.clone();

        $paddingRecordsTop.addClass('padding-records-top').css('height', infosViewport.heightRecordsOnTop);
        $paddingRecordsBottom.addClass('padding-records-bottom').css('height', infosViewport.heightRecordsOnBottom);

        if (this.records.length < infosViewport.nbRecordsViewport) {
            const nbColumns = p.getVisibleColumns().length;
            const $innerDiv = $('<div>').height(infosViewport.heightRecord);
            const $emptyRecord = $('<tr>').addClass('record empty');

            for (let i=0; i<nbColumns; i++) {
                $emptyRecord.append($('<td>').append($innerDiv.clone()));
            }

            $emptyRecord.append($('<td>').addClass('last-column').append($innerDiv.clone()));

            for (let j=0; j<infosViewport.nbRecordsViewport - this.records.length; j++) {
                this.$tableGrid.append($emptyRecord.clone());
            }

            this.$tableGrid.closest('.grid').addClass('has-empty-records');
        } else {
            this.$tableGrid.closest('.grid').removeClass('has-empty-records');
        }

        this.$tableGrid.find('tr.grid-size').after($paddingRecordsTop);
        this.$tableGrid.append($paddingRecordsBottom);

        const onSelectDate = (date, $cell, $e) => {
            $e.trigger('change');
        }

        const optionsDatepicker = {
            onSelectDate,
            buttons: {
                noDate: {
                    label: p.translate('noDate'),
                    cls: 'small outline-primary',
                    click: (ev, $e) => {
                        $e.val('');
                        $e.trigger('change');
                        $e.webfDatetimepicker('close');
                    }
                }
            }
        }

        webf.each(p.getVisibleColumns(), (i, column) => {
            const format = column.format ? column.format : 'dd/MM/yyyy';

            if (webf.inArray(column.type, ['webfDate', 'webfDatetime'])) {
                webf.each($records, (j, $record) => {
                    $record.find('.webf-datagrid-datepicker input').webfDatetimepicker(
                        webf.extend(true, optionsDatepicker, {
                            format,
                            timepicker: false,
                            buttons: {
                                today: {
                                    label: p.translate('today'),
                                    cls: 'small outline-primary',
                                    click: (ev, $e) => {
                                        $e
                                            .val((new WebfDate()).toString(format))
                                            .trigger('change')
                                            .webfDatetimepicker('close');
                                    }
                                }
                            }
                        })
                    );

                    $record.find('.webf-datagrid-datetimepicker input').webfDatetimepicker(
                        webf.extend(true, optionsDatepicker, {
                            format: column.format ? column.format : 'dd/MM/yyyy',
                            timepicker: true
                        })
                    );
                });
            }
        });

        p._call(p.option('onRender'));
    },

    drawRecord: function(record)
    {
        const columns = this.plugin.getVisibleColumns();
        const cells = [];
        const beginRow = '<tr class="'+['record', record.rowClass].join(' ')+'">';
        const endRow = '</tr>';

        webf.each(columns, function(i, column) {
            const funcRender = webf.isFunction(column.render) ? column.render : function(field) {
                return this[field];
            };

            const classNames = [];
            column.columnClass && classNames.push(column.columnClass);
            column.type == 'checkbox' && classNames.push('cell-checkbox');

            const vars = {
                columnClass: classNames.join(' '),
                value: funcRender.call(record, column.field, record),
                editable: !!column.editable
            };

            if (column.editable) {
                switch (column.type) {
                    case 'select':
                        vars.select = true;

                        let choices = vars.value.choices;
                        if (webf.isArray(choices)) {
                            choices = choices.reduce((obj, key) => {
                                obj[key] = key;
                                return obj;
                            }, {});
                        }

                        vars.options = webf.map(choices, (choiceValue, choiceLabel) => {
                            return {
                                value: choiceValue,
                                label: choiceLabel,
                                selected: vars.value.selected == choiceValue
                            }
                        });
                        break;

                    case 'checkbox':
                        vars.checkbox = true;
                        vars.checked = !!record[column.field];
                        break;

                    case 'date':
                        vars.date = true;
                        break;

                    case 'datetime':
                        vars.datetime = true;
                        break;

                    case 'webfDate':
                        vars.webfDate = true;
                        break;

                    case 'webfDatetime':
                        vars.webfDatetime = true;
                        break;

                    case 'textarea':
                    default:
                        vars.textarea = true;
                }
            }

            const cell = Mustache.render(cellTemplate, vars);

            cells.push(cell);
        });

        const lastCell = Mustache.render(cellTemplate, {
            columnClass: 'last-column',
            value: ''
        });

        return $(beginRow + cells.join('') + lastCell + endRow)
            .data('record', record);
    },

    renderSelectedRecords: function()
    {
        this.$tableGrid.find('.record').removeClass('selected');

        webf.each(this.getSelectedRecords(), (i, record) => {
            const $record = this._getUIRecord(record);

            $record && $record
                .addClass('selected')
                .removeClass('pre-selected inactive');
        });
    },

    adjust: function()
    {
        const $grid = this.$tableGrid.closest('.grid');
        const $hscrollTop = $grid.prev('.hscroll-grid');
        const clientHeightGrid = $grid[0].clientHeight;
        const $records = $grid.find('.record:not(.empty)');

        const heightRecords = ($records.length ? $records.height() * $records.length : 0) +
            $grid.find('.padding-records-top').height() +
            $grid.find('.padding-records-bottom').height();

        const hasScrollbarVertical = heightRecords > clientHeightGrid;

        $hscrollTop.css({
            width: $grid.width() - (hasScrollbarVertical ? webf.getScrollbarWidth(): 0),
            height: $grid.hasScrollBarHorizontal() ? webf.getScrollbarWidth(): 0
        });

        $hscrollTop.children().css({
            width: this.$tableGrid.width()
        });
    },

    bindEvents: function()
    {
        const p = this.plugin;

        // Les événements scroll ne "bubblent" pas donc je ne peux pas les déléguer dynamiquement
        p._off(p.e.find('.hscroll-grid'))
            ._on(p.e.find('.hscroll-grid'), {
                scroll: (ev) => {
                    const $grid = $(ev.currentTarget).next('.grid');

                    $grid.scrollLeft($(ev.currentTarget).scrollLeft());
                },
                mousedown: () => {
                    this.mousedown = true;
                }
            })
            ._off(this.$tableGrid.closest('.grid'))
            ._on(this.$tableGrid.closest('.grid'), {
                scroll: (ev) => {
                    const scrollLeft = $(ev.currentTarget).scrollLeft();

                    const $columns = p.e.find('.columns, .footer');
                    $columns.scrollLeft(scrollLeft);

                    const $hscrollTop = $(ev.currentTarget).prev('.hscroll-grid');
                    $hscrollTop.scrollLeft(scrollLeft);

                    const infosViewport = this.infosViewport();

                    const scrollTop = infosViewport.scrollTopViewport;
                    const gridViewportHeight = infosViewport.heightViewport;

                    const $lastRecord = this.$tableGrid.find('.record').last();
                    const $firstRecord = this.$tableGrid.find('.record').first();

                    // Si on a scrollé vers le bas près du dernier record affiché, on met à jour les records visibles
                    if (
                        (infosViewport.heightPaddingRecordsBottom > 0 && scrollTop + gridViewportHeight > infosViewport.heightPaddingRecordsTop + $lastRecord.height() * ($lastRecord.prevAll('.record').length - 8)) ||
                        (infosViewport.heightPaddingRecordsTop > 0 && scrollTop < infosViewport.heightPaddingRecordsTop + ($firstRecord.height() * 8))
                    ) {
                        this.renderRecords();
                    }

                    // Si on a scrollé vers le bas jusqu'à l'avant-dernier record affiché, on charge les records suivants
                    if (infosViewport.scrollTopViewport + gridViewportHeight > infosViewport.heightRecord * (this.count - 2)) {
                        p._infiniteScroll();
                    }
                },
                focus_in: (ev, scrollTop) => {
                    window.scrollTo(0, scrollTop);
                }
            })
        ;

        if (!this.eventsBinded) {
            this.eventsBinded = true;

            p._on(p.e, '.record .editable :input, .webf-datagrid-checkbox-container', {
                'mousedown click': (ev) => {
                    ev.stopPropagation();

                    const $target = $(ev.currentTarget);

                    if ($target.is(':input')) {
                        p._trigger(ev.target, 'selectRecord');
                    }
                },
                keydown: (ev) => {
                    switch (ev.which) {
                        case 65: // A
                            if (ctrlOrMeta(ev)) {
                                ev.stopPropagation();
                            }
                            break;

                        case 13: // Entrée
                            ev.preventDefault();
                            p._trigger(ev.target, 'edit blur');
                            break;

                        case 9:
                            p._trigger(ev.target, 'edit');
                            break;

                        case 27: // Echap.
                            ev.preventDefault();
                            p._trigger(ev.target, 'cancelEdit blur');
                            break;
                    }
                },
                focusin: (ev) => {
                    const $inputDatepicker = $('.webf-datagrid-datepicker input');
                    if (!$(ev.target).is($inputDatepicker)) {
                        $inputDatepicker.webfDatetimepicker('close');
                    }

                    p._trigger(ev.target, 'selectRecord beginEdit');
                },
                'focusout blur': (ev) => {
                    p._trigger(ev.target, 'cancelEdit');
                },
                change: (ev) => {
                    if (webf.inArray(ev.target.tagName, ['INPUT', 'SELECT'])) {
                        p._trigger(ev.target, 'edit');

                        if (ev.target.tagName === 'SELECT') {
                            p._trigger(ev.target, 'focus');
                        }
                    }
                },
                beginEdit: (ev) => {
                    this.oldValue = this.getEditingValue(ev.target);
                    $(ev.target).closest('.editable').addClass('editing');
                },
                edit: (ev) => {
                    const $cell = $(ev.target).closest('.editable');
                    const value = this.getEditingValue(ev.target);
                    const numColumn = $cell.prevAll('.cell:visible').length;

                    if (value !== this.oldValue) {
                        const record = $cell.closest('.record').data('record');

                        webf.each(p.getVisibleColumns(), (i, column) => {
                            if (numColumn === i) {
                                record[column.field] = value;
                                eventDispatcher.dispatch('datagrid.editRecord.' + p.hash, record, column, value, this.oldValue, $(ev.currentTarget).closest('.record')[0]);
                                this.oldValue = value;

                                return false;
                            }
                        });
                    }

                    p._trigger(ev.target, 'endEdit');
                },
                cancelEdit: (ev) => {
                    this.setEditingValue(ev.target, this.oldValue);
                    p._trigger(ev.target, 'endEdit');
                },
                endEdit: (ev) => {
                    $(ev.target).closest('.editable').removeClass('editing');
                }
            })._on(p.e, '.record:not(.empty)', {
                mousedown: (ev) => {
                    ev.preventDefault(); // Disable selection

                    const scrollTop = window.pageYOffset;

                    this.$tableGrid.closest('.grid')
                        .trigger('focus')
                        .trigger('focus_in', scrollTop);
                },
                'click selectRecord': (ev) => {
                    const $target = $(ev.target);
                    const $record = $target.closest('.record');
                    const record = $record.data('record');
                    const modifiersKey = $webf.getModifiersKey(ev);

                    if ($target.closest('a')[0] || $target.closest('input[type="file"]')[0] || $target.closest('.webf-input-button')[0]) {
                        return;
                    }

                    ev.preventDefault();

                    if (modifiersKey.length) {
                        if (webf.inArray('shift', modifiersKey)) {
                            if (this.indexesSelected.length) {
                                const indexFirst = this.indexesSelected[0];
                                const indexLast = this.indexesSelected[this.indexesSelected.length - 1];
                                const oRecord = this.getRecordById(record.id, true);
                                const newSelection = [];
                                let i;

                                if (indexFirst < oRecord.index) {
                                    for (i=indexFirst; i<=oRecord.index; i++) {
                                        newSelection.push(this.records[i]);
                                    }
                                } else {
                                    for (i=oRecord.index; i<=indexLast; i++) {
                                        newSelection.push(this.records[i]);
                                    }
                                }

                                p.selectRecords(newSelection);
                            } else {
                                p.selectRecords(record);
                            }
                        } else if (ctrlOrMeta(ev)) {
                            if ($record.hasClass('selected')) {
                                this.unSelect(record);
                                p._call(p.option('onSelect'), this.getSelectedRecords());
                            } else {
                                p.selectRecords(record);
                            }
                        }
                    } else {
                        if (this.indexesSelected.length == 1) {
                            if (!$record.hasClass('selected')) {
                                this.unSelectAll();
                                p.selectRecords($record);
                            }
                        } else if (this.indexesSelected.length > 1) {
                            this.unSelectAll();
                            p.selectRecords($record);
                        } else {
                            p.selectRecords($record);
                        }

                        p._call(p.option('onClick'), record, $record, $target);
                    }

                    this.renderSelectedRecords();
                },
                dblclick: (ev) => {
                    const $target = $(ev.target);
                    const $record = $target.closest('.record');
                    const record = $record.data('record');

                    p._call(p.option('onDblClick'), record, $record, $target);
                }
            })._on(p.e, '.grid', {
                keydown: (ev) => {
                    switch (ev.which) {
                        case 65: // A
                            if (ctrlOrMeta(ev)) {
                                ev.preventDefault();
                                p.selectRecords(this.records);
                                this.renderSelectedRecords();
                            }
                    }
                }
            })
            ._on(document, {
                'mouseup blur': () => {
                    this.mousedown = false;
                }
            })._on(document, {
                'keydown.preventDefault': (ev) => {
                    if (this.indexesSelected.length) {
                        switch (ev.which) {
                            case 40: // Down
                            case 38: // Up
                                ev.preventDefault();
                                break;
                        }
                    }
                },
                keydown: webf.throttle((ev) => {
                    if (this.indexesSelected.length) {
                        switch (ev.which) {
                            case 40: // Down
                            case 38: // Up
                                ev.preventDefault();
                                break;
                        }
                    }

                    if (this.indexesSelected.length) {
                        const modifiers = $webf.getModifiersKey(ev);
                        const infosViewport = this.infosViewport();
                        const $editable = $(ev.target).closest('.editable');
                        let newScrollTop;

                        switch (ev.which) {
                            case 40: // Down
                                const indexLastSelectedRecord = this.indexesSelected[this.indexesSelected.length - 1];
                                const indexNextRecord = Math.min(indexLastSelectedRecord + 1, this.records.length - 1);
                                const $nextRecord = this._getUIRecord(this.records[indexNextRecord]);

                                newScrollTop = Math.max(infosViewport.heightRecord * indexNextRecord - infosViewport.heightViewport / 2);
                                this.$tableGrid.closest('.grid').scrollTop(newScrollTop);

                                if (!webf.inArray('shift', modifiers)) {
                                    this.unSelect();
                                }

                                p.selectRecords(this.records[indexNextRecord]);
                                this.renderSelectedRecords();

                                if ($editable[0]) {
                                    p._trigger(ev.target, 'endEdit');
                                    $nextRecord.children('td').eq($editable.prevAll('td').length).find(':input').trigger('focus');
                                }
                                break;

                            case 38: // Up
                                const indexFirstSelectedRecord = this.indexesSelected[0];
                                const indexPrevRecord = Math.max(0, indexFirstSelectedRecord - 1);
                                const $prevRecord = this._getUIRecord(this.records[indexPrevRecord]);

                                newScrollTop = Math.max(infosViewport.heightRecord * indexPrevRecord - infosViewport.heightViewport / 2);
                                this.$tableGrid.closest('.grid').scrollTop(newScrollTop);

                                if (!webf.inArray('shift', modifiers)) {
                                    this.unSelect();
                                }

                                p.selectRecords(this.records[indexPrevRecord]);
                                this.renderSelectedRecords();

                                if ($editable[0]) {
                                    p._trigger(ev.target, 'endEdit');
                                    $prevRecord.children('td').eq($editable.prevAll('td').length).find(':input').trigger('focus');
                                }
                                break;
                        }
                    }
                }, throttleDelay, true)
            })._on(window, {
                resize: () => {
                    this.adjust();
                }
            });
        }
    },

    preselect: function(record)
    {
        const $record = this._getUIRecord(record);

        $record && $record
            .addClass('pre-selected');
    },

    unSelect: function(records)
    {
        if (webf.isUndefined(records)) {
            this.indexesSelected = [];
            return;
        }

        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            const oRecord = this.getRecordById(record.id, true);

            webf.each(this.indexesSelected, function(j, index) {
                if (oRecord.index == index) {
                    this.indexesSelected.splice(j, 1);
                    return false;
                }
            }, this);
        }, this);
    },

    unSelectAll: function()
    {
        this.unSelect();
    },

    getSelectedRecords: function()
    {
        return webf.map(this.indexesSelected, function(i, index) {
            return this.records[index];
        }, this);
    },

    selectRecords: function(records)
    {
        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            if (record instanceof $) {
                record = record.data('record');
            }

            if (record && record.id) {
                const oRecord = this.getRecordById(record.id, true);

                if (oRecord) {
                    this.indexesSelected.push(oRecord.index);
                    this.indexesSelected = webf.cleanIntArray(this.indexesSelected);
                    this.indexesSelected.sort(function(a, b) { return a - b });
                }

                return true;
            }
        }, this);
    },

    sort: function(field, direction)
    {
        const p = this.plugin;
        const columns = p.getVisibleColumns();
        const data = p.option('data');
        let typeField = 'text', usort;

        if (p.option('sortByUrl') && webf.isString(data)) {
            this._load(webf.noop);
        } else {
            webf.each(columns, function(i, column) {
                if (column.field == field) {
                    if (webf.isFunction(column.sort)) {
                        usort = column.sort;
                    } else {
                        typeField = column.type || typeField;

                        usort = (function() {
                            return function(rec1, rec2, field, direction) {
                                const coeff = direction == 'asc' ? 1 : -1;
                                let val1 = rec1[field];
                                let val2 = rec2[field];

                                // if (webf.isString(val1) && !val1.length) {
                                //     return 1;
                                // }
                                //
                                // if (webf.isString(val2) && !val2.length) {
                                //     return -1;
                                // }

                                if (typeField == 'money') {
                                    const formatMoney = column.format || /([\s\d]+,\d{2})\s€/;

                                    val1 = parseFloat(val1.replace(formatMoney, '$1').replace(/\s/g, '').replace(/,/g, '.'));
                                    val2 = parseFloat(val2.replace(formatMoney, '$1').replace(/\s/g, '').replace(/,/g, '.'));

                                    return coeff * (val1 > val2 ? 1 : -1);
                                } else if (webf.inArray(typeField, ['text', 'date'])) {
                                    return coeff * (val1 > val2 ? 1 : -1);
                                } else if (typeField == 'number') {
                                    return coeff * (val1 - val2);
                                }
                            }
                        })();
                    }
                }
            });

            const selectedRecords = this.getSelectedRecords();
            this.unSelectAll();

            this.records.sort(function(rec1, rec2) {
                if (webf.isFunction(usort)) {
                    return usort(rec1, rec2, field, direction);
                }

                return 0;
            });

            this.$tableGrid.find('.record:not(.empty)').detach();
            this.selectRecords(selectedRecords);
            this.renderRecords();
        }
    },

    _getUIRecord: function(record)
    {
        let $record = null;

        if (record instanceof $) {
            if (record.hasClass('record')) {
                $record = record;
            }
        } else if (record) {
            if (!webf.isPlainObject(record)) {
                record = this.getRecordById(record);
            }

            this.$tableGrid.find('.record').each((i, recordElem) => {
                if ($(recordElem).data('record').id == record.id) {
                    $record = $(recordElem);
                    return false;
                }
            });
        }

        return $record;
    },

    _load: function(callback = webf.noop)
    {
        const p = this.plugin;
        const data = p.option('data');
        let nbNewRecords = 0;

        const afterLoadDatas = (datas) => {
            webf.each(datas.records || [], (i, record) => {
                if (this._addRecord(record)) {
                    nbNewRecords++;
                }
            });

            if (nbNewRecords == 0) {
                p.noNewRecord = true;
            }

            if (datas.footer) {
                p.footer = new Footer(p, datas.footer);
                const $footer = p.footer.render();
                p.e.find('.footer').replaceWith($footer);
            }

            this.count = datas.count ? datas.count : this.records.length;

            this.datas = datas;

            callback(datas);
        };

        if (p._call(p.option('onBeforeLoad')) == false) {
            return;
        }

        if (webf.isFunction(data)) {
            p._call(data, (rows) => {
                afterLoadDatas.call(this, rows);
            });
        } else if (webf.isString(data)) {
            $.ajax({
                url: data,
                data: p.option('urlParams') || {},
                type: p.option('method'),
                dataType: 'json'
            }).done(afterLoadDatas);
        } else {
            afterLoadDatas.call(this, data);
        }
    },

    add: function(records)
    {
        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            this._addRecord(record);
            this.renderRecords();
        }, this);
    },

    update: function(records) {
        webf.each(webf.isArray(records) ? records : [records], function(i, record) {
            this._updateRecord(record);
        }, this);

        this.renderRecords();
    },

    remove: function(records)
    {
        if (webf.isUndefined(records)) {
            this.$tableGrid.find('.record:not(.empty)').remove();
            this.records = [];
        } else {
            const indexesDeletedRecords = [];

            webf.each(webf.isArray(records) ? records : [records], (i, record) => {
                if (!webf.isPlainObject(record)) {
                    record = {id: record};
                }

                const $record = this._getUIRecord(record);

                if ($record) {
                    record = $record.data('record');

                    webf.each(this.records, (j, aRecord) => {
                        if (record.id == aRecord.id) {
                            indexesDeletedRecords.push(j);
                        }
                    });

                    $record.remove();
                }
            })

            webf.each(indexesDeletedRecords.reverse(), (i, index) => {
                this.records.splice(index, 1);
            })
        }
    },

    getRecordById: function(id, index = false)
    {
        let record = null;

        webf.each(this.records, function(i, aRecord) {
            if (aRecord.id == id) {
                record = aRecord;
                if (index) {
                    index = i;
                }
                return false;
            }
        }, this);

        if (!webf.isBool(index)) {
            return {
                index: index,
                record: record
            }
        }

        return record;
    },

    /**
     * Retourne les infos du viewport
     *
     * @returns
     *   {  heightRecord: number,
     *      heightViewport: number,
     *      scrollTopViewport: number,
     *      nbRecordsViewport: number,
     *      topFirstRecord: number,
     *      bottomLastRecord: number,
     *      indexFirstRecord: number,
     *      indexLastRecord: number,
     *      firstRecord: *,
     *      lastRecord: * }
     * @private
     */
    infosViewport: function()
    {
        const $grid = this.$tableGrid.closest('.grid');

        const heightRecord = 30;
        const heightViewport = $grid.height();
        const scrollTopViewport = $grid.scrollTop();
        const nbRecordsViewport = Math.floor((heightViewport - 2 * webf.getScrollbarWidth()) / heightRecord);
        const topFirstRecord = heightRecord * Math.max(0, Math.floor(scrollTopViewport / heightRecord) - 25);
        const bottomLastRecord = Math.floor(scrollTopViewport / heightRecord) - 25 + (nbRecordsViewport + 50 + 1) * heightRecord;
        const indexFirstRecord = topFirstRecord / heightRecord;
        const indexLastRecord = heightViewport == 0 ? this.records.length - 1 : Math.floor(scrollTopViewport / heightRecord) - 25 + (nbRecordsViewport + 50) - 1;
        const nbRecordsOnTop = indexFirstRecord;
        const nbRecordsOnBottom = heightViewport == 0 ? 0 : Math.max(0, this.records.length - indexLastRecord + 1);
        const heightRecordsOnTop = nbRecordsOnTop * heightRecord;
        const heightRecordsOnBottom = nbRecordsOnBottom * heightRecord;
        const heightPaddingRecordsTop = this.$tableGrid.find('.padding-records-top').height();
        const heightPaddingRecordsBottom = this.$tableGrid.find('.padding-records-bottom').height();

        return {
            heightRecord: heightRecord,
            heightViewport: heightViewport,
            scrollTopViewport: scrollTopViewport,
            nbRecordsViewport: nbRecordsViewport,
            topFirstRecord: topFirstRecord,
            bottomLastRecord: bottomLastRecord,
            indexFirstRecord: indexFirstRecord,
            indexLastRecord: indexLastRecord,
            nbRecordsOnTop: nbRecordsOnTop,
            nbRecordsOnBottom: nbRecordsOnBottom,
            heightRecordsOnTop: heightRecordsOnTop,
            heightRecordsOnBottom: heightRecordsOnBottom,
            heightPaddingRecordsTop: heightPaddingRecordsTop,
            heightPaddingRecordsBottom: heightPaddingRecordsBottom
        };
    },

    isSelectedRecord: function(record)
    {
        const oRecord = this.getRecordById(record.id, true);

        return !!(oRecord && webf.inArray(oRecord.index, this.indexesSelected));
    },

    _addRecord: function(record)
    {
        if (this.getRecordById(record.id)) {
            return false;
        }

        this.records.push(record);

        return record;
    },

    _updateRecord: function(record)
    {
        const oldRecord = this.getRecordById(record.id, true);

        if (oldRecord) {
            this.records[oldRecord.index] = record;

            const $record = this._getUIRecord(record);
            $record.data('record', {id: record.id});

            return record;
        }

        return false;
    },

    search: function(val)
    {
        if (!val.length) {
            this.razSearch();
        } else {
            val = val.noAccent().toLowerCase();

            this.$tableGrid.find('.record').addClass('row-hidden');

            webf.each(this.records, (i, record) => {
                webf.each(record, (field, value) => {
                    const recordValue = (value + '').noAccent().toLowerCase();

                    if (recordValue.indexOf(val) > -1) {
                        this._getUIRecord(record.id)
                            .removeClass('row-hidden');
                    }
                });
            });
        }
    },

    getRecords: function()
    {
        return this.records;
    },

    razSearch: function()
    {
        this.$tableGrid.find('.record').removeClass('row-hidden');
    },

    getEditingValue: function(element)
    {
        const $element = $(element);
        if ($element.is(':checkbox')) {
            return $element.prop('checked');
        }

        return $element.val();
    },

    setEditingValue: function(element, value)
    {
        const $element = $(element);
        if ($element.is(':checkbox')) {
            $element.prop('checked', !!value);
        } else {
            $element.val(value);
        }
    }
});

function ctrlOrMeta(ev)
{
    const modifiers = $webf.getModifiersKey(ev);

    return /Mac|i(Pod|Phone|Pad)/.test(navigator.platform) && webf.inArray('meta', modifiers) || webf.inArray('ctrl', modifiers);
}

export default Grid
