import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - handles
 *  - minWidth
 *  - minHeight
 *  - maxWidth
 *  - maxHeight
 *  - aspectRatio
 *  - enabled
 *  - onResizeStart
 *  - onResizeEnd
 *  - onResize
 *
 * Méthodes publiques :
 *  - enable
 *  - disable
 */
$webf('resizable', {
    options: {
        handles:                'all',
        minWidth:               50,
        minHeight:              50,
        maxWidth:               null,
        maxHeight:              null,
        aspectRatio:            false,
        enabled:                true,
        onResizeStart:          webf.noop,
        onResizeEnd:            webf.noop,
        onResize:               webf.noop
    },

    _create: function()
    {
        this.resizing = false;

        this._createHandles();
        this._bindHandles();
    },

    _init: function()
    {
        this.option('enabled') && this.enable();
    },

    _createHandles: function()
    {
        var self = this,
            handles = this.option('handles') == 'all' ? 'n e s w nw ne se sw' : this.option('handles');

        handles = webf.map(handles.split(' '), function(i, direction) {
            return direction.length === 1
                ? ['border',/n|s/.test(direction) ? 'horizontal' : 'vertical',direction].join(' ')
                : ['corner',direction].join(' ');
        }).sort(function(a, b) {
            return a.length < b.length;
        });

        webf.each(handles, function(i, handle) {
            self.e.append($('<div>')
                .addClass('resize-handler ' + handle)
                .data('direction', handle.split(' ').pop()));
        });
    },

    _bindHandles: function()
    {
        var self = this;

        this._on(this.e, '.resize-handler', {
            mousedown: function(ev) {
                var $target = $(ev.target),
                    $body = $('body');

                self.resizing = 1;
                ev.preventDefault();

                self.startPoint = $webf.getMousePos(ev);
                self.startElemPoint = $webf.getMousePos(ev, self.e);
                self.direction = $target.data('direction');
                self.startWidth = self.e.width();
                self.startHeight = self.e.height();
                self.maxLeft = parseFloat(self.e.css('left')) + self.e.width() - self.option('minWidth');
                self.minLeft = self.option('maxWidth') === null ? null : parseFloat(self.e.css('left')) + self.e.width() - self.option('maxWidth');
                self.maxTop = parseFloat(self.e.css('top')) + self.e.height() - self.option('minHeight');
                self.minTop = self.option('maxHeight') === null ? null : parseFloat(self.e.css('top')) + self.e.height() - self.option('maxHeight');

                self.origCursorBody = $body.css('cursor');
                $body.css('cursor', $target.css('cursor'));
            }
        })._on(document, {
            mouseup: function() {
                if (self.resizing) {
                    self._call(self.option('onResizeEnd'));
                    $('body').css('cursor', self.origCursorBody);
                }
                self.resizing = false;
            },

            mousemove: function(ev) {
                if (self.resizing === 1) {
                    self.resizing = true;
                    self._call(self.option('onResizeStart'));
                }

                if (self.resizing) {
                    var mp = $webf.getMousePos(ev),
                        mpParent = self.e.css('position') == 'absolute'
                            ? mp
                            : $webf.getMousePos(ev, self.e.parent());

                    if (/w/.test(self.direction)) {
                        self.e.css({
                            left: webf.between(mpParent.x - self.startElemPoint.x, self.minLeft, self.maxLeft),
                            width: webf.between(self.startWidth - (mp.x - self.startPoint.x), self.option('minWidth'), self.option('maxWidth'))
                        });
                    } else if (/e/.test(self.direction)) {
                        self.e.width(webf.between(self.startWidth + (mp.x - self.startPoint.x), self.option('minWidth'), self.option('maxWidth')));
                    }

                    if (/n/.test(self.direction)) {
                       self.e.css({
                            top: webf.between(mpParent.y - self.startElemPoint.y, self.minTop, self.maxTop),
                            height: webf.between(self.startHeight - (mp.y - self.startPoint.y), self.option('minHeight'), self.option('maxHeight'))
                        });
                    } else if (/s/.test(self.direction)) {
                        self.e.height(webf.between(self.startHeight + (mp.y - self.startPoint.y), self.option('minHeight'), self.option('maxHeight')));
                    }

                    ev.preventDefault();
                    self._call(self.option('onResize'));
                }
            }
        });
    },

    enable: function()
    {
        this.e.css('position') == 'absolute' && this.e.css('position', 'absolute');
        this.e.addClass('webf-resizable');
    },

    disable: function()
    {
        this.e.removeClass('webf-resizable');
    },

    _destroy: function()
    {
        this.disable();
        this.e.children('.resize-handler').remove();
    }
});

