import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'
import Mouse from '../utils/Mouse'

/**
 * options :
 *  - selectorSortables:    (string|Element,null) Sélecteur des éléments sortable. Si 'children' est renseigné, les enfants
 *                          directs de l'élément sont utilisés
 *  - selectorHandler:      (string|Element,null) Sélecteur du handler. Si null est renseigné, les descendants de l'élément
 *                          sont utilisés
 *  - ghostClass:           (string) Classe CSS ajoutée à l'élément restant en position pendant le drag
 *  - cloneClass:           (string) Classe CSS ajoutée à l'élément draggé
 *  - droppableClass:       (string) Classe CSS ajoutée à l'élément récepteur du drop
 *  - connectWith:          (string) Selecteur d'autres listes sortables
 *  - tolerance:            (int) Distance en px minimum entre le lieu du mousedown et le déplacement de la souris
 *                          pour que le drag commence
 *  - durationAnimation:    (int) Durée en ms de l'animation de retour de l'item dans la liste une fois le drag terminé
 *  - onSelect:             (function($sortable, index))
 *                          Fonction appelée lorsqu'un élément est sélectionné
 *  - onClone:              (function($clone, $sortable))
 *                          Fonction appelée lorsque le clone de l'item, càd l'élément qui suit la souris pendant le drag,
 *                          est créé.
 *  - onStart:              (function($sortable, $clone, index))
 *                          Fonction appelée lorsqu'un élément commence à être déplacé
 *  - onDrag:               (function($sortable, $clone))
 *                          Fonction appelée lorsque l'item se déplace
 *  - onOver:               (function($sortable, $list))
 *                          Fonction appelée lorsque l'item se déplace dans une liste
 *  - onUpdate:             (function($item, oldIndex, newIndex))
 *                          Fonction appelée lorsque l'item a été draggé à un autre endroit
 *  - onBeforeChange:       (function($item, $target))
 *                          Fonction appelée avant que l'item ne soit déplacé.
 *                          Si cette fonction retourne false, le déplacement est annulé
 *  - onReceive:            (function($item, $list, oldIndex, newIndex))
 *                          Fonction appelée lorsque l'item a été draggé dans une autre liste
 *  - onEnd:                (function($item) Fonction appelée lorsque le drag est terminé
 *
 * Méthodes publiques :
 */
$webf('sortable', {
    options: {
        selectorSortables:  'children',
        selectorHandler:    null,
        ghostClass:         'webf-sortable-ghost',
        cloneClass:         'webf-sortable-clone',
        droppableClass:     'webf-sortable-droppable',
        connectWith:        null,
        tolerance:          5,
        durationAnimation:  300,
        onSelect:           webf.noop,
        onClone:            webf.noop,
        onStart:            webf.noop,
        onDrag:             webf.noop,
        onOver:             webf.noop,
        onEnd:              webf.noop,
        onUpdate:           webf.noop,
        onBeforeChange:     webf.noop,
        onReceive:          webf.noop
    },

    editableOptions: {
    },

    _create: function()
    {
        const selectorSortables = this.option('selectorSortables');
        const selectorHandler = this.option('selectorHandler');
        const connectWith = this.option('connectWith');

        const onMouseEnter = (ev) => {
            if (this.isDragging) {
                const $target = $(ev.currentTarget);

                if (this._call(this.option('onBeforeChange'), this.$selectItem, $target) !== false) {
                    if ($target.prevAll().length > this.$selectItem.prevAll().length) {
                        $target.after(this.$selectItem);
                    } else {
                        $target.before(this.$selectItem);
                    }

                    this._call(this.option('onOver'), this.$selectItem, this.$selectItem.closest(connectWith));
                }

                ev.stopPropagation();
            }
        }

        const onMouseDown = (ev) => {
            if (ev.which == 1 || ev.type == 'touchstart') {
                const $target = $(ev.target);

                if ((!$target.is(':input') && !$target.closest('[contenteditable]')[0])) {
                    if ($target.closest(this.e)[0] || (connectWith && $target.closest(connectWith)[0])) {
                        ev.preventDefault();

                        if (selectorHandler === null || $target.closest(selectorHandler)[0]) {
                            if (!this.isDragging && !this.releasing) {
                                let $item;

                                if (selectorSortables == 'children') {
                                    $item = this.e.children().filter((i, child) => {
                                        const $child = $(child);
                                        return $child.is($target) || $child.find($target)[0];
                                    });

                                    if (!$item[0]) {
                                        $item = $(connectWith).children().filter((i, child) => {
                                            const $child = $(child);
                                            return $child.is($target) || $child.find($target)[0];
                                        });
                                    }

                                    this.oldIndex = $item.prevAll().length;
                                } else {
                                    $item = $target.closest(selectorSortables);
                                    this.oldIndex = $item.prevAll(selectorSortables).length;
                                }

                                this.startPoint = Mouse.getPosition(ev);
                                this.relativeStartPoint = Mouse.getPosition(ev, $item);
                                this.$selectItem = $item;

                                if (true === this._call(this.option('onSelect'), $item, this.oldIndex)) {
                                    ev.stopImmediatePropagation();
                                }
                            }
                        }
                    }
                }
            }
        }

        this._on(this.e, selectorSortables == 'children' ? '> *' : selectorSortables, {
            'mouseenter touchenter': onMouseEnter,
            touchmove: (ev) => $(Mouse.getElement(ev)).trigger('touchenter')
        });

        this._on(this.e, {
            'mousedown touchstart': onMouseDown
        });

        if (connectWith) {
            const onMouseEnterList = (ev) => {
                if (this.isDragging) {
                    const $target = $(ev.currentTarget);

                    if (this._call(this.option('onBeforeChange'), this.$selectItem, $target) !== false) {
                        $target.append(this.$selectItem);

                        this._call(this.option('onOver'), this.$selectItem, this.$selectItem.closest(connectWith));
                    }
                }
            }

            this._on(connectWith, {
                'mouseenter touchenter': onMouseEnterList,
                touchmove: (ev) => $(Mouse.getElement(ev)).trigger('touchenter')
            });

            this._on(this.e, {
                'mouseenter touchenter': onMouseEnterList,
                touchmove: (ev) => $(Mouse.getElement(ev)).trigger('touchenter')
            });

            this._on(connectWith, selectorSortables == 'children' ? '> *' : selectorSortables, {
                'mouseenter touchenter': onMouseEnter,
                touchmove: (ev) => $(Mouse.getElement(ev)).trigger('touchenter')
            });

            this._on(connectWith, {
                'mousedown touchstart': onMouseDown
            });
        }

        this._on(window.document, {
            'mousemove touchmove': (ev) => {
                if (this.$selectItem && this.$selectItem[0]) {
                    const mp = Mouse.getPosition(ev);

                    if (this.startPoint && !this.isDragging) {
                        const sp = this.startPoint;
                        const distance = Math.sqrt(Math.pow(mp.x - sp.x, 2) + Math.pow(mp.y - sp.y, 2));

                        if (distance >= this.option('tolerance')) {
                            this._createPromiseClone(this.$selectItem)
                                .then(($clone) => {
                                    this.$selectItem.addClass(this.option('ghostClass'));
                                    this.isDragging = true;

                                    this.$clone.offset({
                                        top: mp.y - this.relativeStartPoint.y,
                                        left: mp.x - this.relativeStartPoint.x
                                    });

                                    if (connectWith) {
                                        $(connectWith).addClass(this.option('droppableClass'));
                                    }
                                    this.e.addClass(this.option('droppableClass'));

                                    this._call(this.option('onStart'), this.$selectItem, $clone, this.oldIndex);
                                });
                        }
                    }

                    if (this.isDragging) {
                        this.$clone.offset({
                            top: mp.y - this.relativeStartPoint.y,
                            left: mp.x - this.relativeStartPoint.x
                        });

                        this._call(this.option('onDrag'), this.$selectItem, this.$clone);
                    }
                }
            },
            'blur mouseup touchend': (ev) => {
                if (this.isDragging) {
                    ev.preventDefault();
                    let newIndex;

                    if (selectorSortables == 'children') {
                        newIndex = this.$selectItem.prevAll().length;
                    } else {
                        newIndex = this.$selectItem.prevAll(selectorSortables).length;
                    }

                    this._destroyPromiseClone()
                        .then(() => {
                            if (connectWith) {
                                if (!this.e.is(this.$selectItem.closest(connectWith))) {
                                    this._call(this.option('onReceive'), this.$selectItem, this.$selectItem.closest(connectWith), this.oldIndex, newIndex);
                                } else {
                                    if (this.oldIndex != newIndex) {
                                        this._call(this.option('onUpdate'), this.$selectItem, this.oldIndex, newIndex);
                                    }
                                }
                            } else if (this.oldIndex != newIndex) {
                                this._call(this.option('onUpdate'), this.$selectItem, this.oldIndex, newIndex);
                            }

                            if (connectWith) {
                                $(connectWith).removeClass(this.option('droppableClass'));
                            }
                            this.e.removeClass(this.option('droppableClass'));

                            this._call(this.option('onEnd'), this.$selectItem, this.oldIndex, newIndex);

                            this.oldIndex = null;
                        });
                } else {
                    this.startPoint = null;
                }
            },
        });
    },

    _createPromiseClone: function($sortable)
    {
        return new Promise((resolve) => {
            let $clone = $sortable.clone()
                .removeClass(['webf-sortable-ghost'])
                .addClass(['webf-sortable-clone'].concat(this.option('cloneClass').split(' ')).join(' '));

            $clone.css({
                width: $sortable.outerWidth(),
                height: $sortable.outerHeight()
            });

            this._call(this.option('onClone'), $sortable, $clone);

            $clone.appendTo(this.e);

            this.$clone = $clone;

            resolve(this.$clone);
        })
    },

    _destroyPromiseClone: function()
    {
        return new Promise((resolve) => {
            this.isDragging = false;
            this.startPoint = null;
            this.releasing = true;

            this.$clone.animate({
                top: this.$selectItem.offset().top - $(document).scrollTop(),
                left: this.$selectItem.offset().left - $(document).scrollLeft()
            }, {
                duration: this.option('durationAnimation'),
                complete: () => {
                    this.$selectItem.removeClass(this.option('ghostClass'));
                    this.$clone.remove();
                    this.$clone = null;
                    this.releasing = false;

                    resolve();
                }
            });
        });
    }
})
