import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.richtexteditor = {
        undo: 'Undo',
        redo: 'Redo',
        heading: 'Heading',
        font: 'Font',
        fontsize: 'Font size',
        fontsizepx: 'Font size',
        bold: 'Bold',
        italic: 'Italic',
        underline: 'Underline',
        strikethrough: 'Strikethrough',
        subscript: 'Subscript',
        superscript: 'Superscript',
        text_color: 'Text color',
        backcolor: 'Background text color',
        alignleft: 'Left align',
        aligncenter: 'Centrer',
        alignright: 'Right align',
        alignjustify: 'Justify',
        link: 'Add a link',
        unlink: 'Remove a link',
        insertimage: 'Insert a picture',

        'add a link': 'Add a link',
        'text of link': 'Text of the link',
        anchor: 'Anchor',
        invalid_url: 'Invalid URL',
        url_link: 'URL of the link',
        open_in_new_tab: 'Ouvrir dans un nouvel onglet',

        'import': 'import',
        'insert image': 'Insert a picture',
        upload_image: 'Upload a picture',
        from_url: 'import from a URL',
        'drag here': 'Drag an image here.',
        'you can also': 'You can also',
        'select image to import': 'Sélectionner an image to import',
        paste_url_image: 'Paste the URL of the image :',
        overview_image_from_url: 'If the URL is correct, you will see an overview here',
        'no image found': 'No image found at this URL',
        loading: 'Loading...',
        validate: 'Validate',
        cancel: 'Cancel',
        unordered_list: 'Unordered list',
        ordered_list: 'Ordered list',
        indent: 'Indent',
        outdent: 'Outdent',
        hr: 'Horizontal ruler',
        table: 'Table',
        tableProperties: 'Table properties',
        columnSize: 'Column width',
        rowSize: 'Row height',
        verticalAlignment: 'Vertical alignment',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        alignUndefined: 'Undefined'
    };
})(webf);

