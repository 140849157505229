import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.ajaxupload = {
        invExtension: function(filename, allowedExtensions) {
            return "The file " + filename + " is not an extension validation.\nValid extensions : " + allowedExtensions;
        },
        invSize: function(filename, filesize, sizeLimit) {
            return "The file " + filename + " of " + filesize + " exceeds the size limit " + sizeLimit;
        },
        emptyFile: function(filename) {
            return "The file " + filename + " is empty";
        }
    };
})(webf);

