import webf from '../utils/core'
import './imageloader'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - selector:         (string|function())
 *                                  Sélecteur CSS des images
 *                                  Si selector est une fonction, elle doit retourner les items sous forme de table de HTMLElement
 *  - getSrc:           (function(item))
 *                                  Si src est une fonction, elle est appelée pour retrouver la source de l'image de l'item
 *  - margin:           (int)       NOT USED YET. Marge intérieur en px de la lightbox
 *  - preload:          (bool)      Préchargement des images
 *  - delayLoader:      (int)       Délai en ms avant l'affichage d'un loader si l'image n'est pas chargée
 *  - throttleKeyboard  (int)       Délai de prise en compte entre 2 appuis sur le clavier pour passer à un autre item
 *  - swipe             (bool)      Permet de swiper d'un item à l'autre
 *  - deltaSwipe        (int)       Nb. de pixels devant être swiper pour passer à l'item suivant
 *  - durationAnimation (int)       Durée de l'animation de transition entre les items en ms
 *  - buttons           (object)    Liste de boutons caractérisés par les clefs suivantes :
 *                                  - label: Le nom du bouton. Si non renseigné, la clé du bouton sert de label
 *                                  - cls: Classes CSS du bouton
 *                                  - click: fonction appelée lors du clic sur le bouton
 *
 * Méthodes publiques :
 *  - update()                  Si de nouveaux items sont ajoutés, update doit être appelé pour mettre à jour la lightbox.
 *  - preload(cb)               Charge les images de la lightbox, et exécute cb lorsque le chargement est terminé.
 *                              Les images sont passées dans les arguments de cb.
 *  - open()                    Ouvre la lightbox.
 *  - close()                   Ferme la lightbox.
 *  - isOpen()                  Retourne true si la lightbox est ouverte.
 *  - prev()                    Passe à l'item précédent.
 *  - next()                    Passe à l'item suivant.
 *  - getIndexCurrentItem()     Retourne l'index courant de l'item courant.
 *  - getIndexItem($item):      Retourne l'index d'un item.
 */
let loadingTimeout;

$webf('lightbox', {
    options: {
        selector:           'a',
        preload:            false,
        margin:             30,
        delayLoader:        300,
        throttleKeyboard:   550,
        swipe:              true,
        deltaSwipe:         20,
        durationAnimation:  500,
        buttons:            {}
    },

    _create: function()
    {
        this.$curItem = this.getItems().eq(0);
        this.srcImages = [];
        this.firstOpen = true;

        this.$lightbox = $('<div>').addClass('webf-lightbox')
            .append($('<div>').addClass('webf-lightbox-wrapper').append($('<div>').addClass('webf-lightbox-container')))
            // .append($('<a>').append($('<i>').addClass('fas fa-times')).addClass('webf-lightbox-close'))
            .append($('<a>').addClass('webf-lightbox-nav prev'))
            .append($('<a>').addClass('webf-lightbox-nav next'))
            .appendTo('body');

        this.$wrapper = this.$lightbox.children('.webf-lightbox-wrapper');
        this.$container = this.$wrapper.children('.webf-lightbox-container');
        this.$container[0].style.setProperty('--dt', `${this.option('durationAnimation')}ms`);
        this.$container[0].style.setProperty('--tx', '0px');

        this._drawButtons();
        this._drawItems();
        this.option('preload') && this._preload();
        this._bindEvents();
    },

    update: function()
    {
        this._drawItems();
        this.option('preload') && this._preload();
    },

    _drawButtons: function()
    {
        var buttons = this.option('buttons') || {};

        if (webf.isUndefined(buttons.close)) {
            buttons.close = {
                label: '<i class="fa fa-times"></i>',
                click: this.close.bind(this)
            };
        }

        var $buttons = $('<div>').addClass('webf-lightbox-buttons');

        webf.each(buttons, (label, btn) => {
            var name = label, cls, user_func = btn;

            if (btn === false) {
                return true;
            }

            if (!webf.isFunction(user_func)) {
                label = !webf.isUndefined(btn.label) ? btn.label : label;
                cls = !webf.isUndefined(btn.cls) ? btn.cls : '';
                user_func = btn.click;
            }

            var $button = $("<a>")
                .addClass(['webf-lightbox-button'].concat(cls.split(' ')).join(' '))
                .html(label);

            this._on($button, {
                click: (ev) => {
                    if (webf.isFunction(user_func)) {
                        this._call((user_func), ev);
                    }
                }
            });

            if (name == 'close') {
                $buttons.prepend($button);
            } else {
                $buttons.append($button);
            }
        });

        this.$lightbox.append($buttons);

    },

    _drawItems: function()
    {
        const $items = this.getItems();
        this.$container[0].style.setProperty('--n', $items.length);

        $items.each((i, item) => {
            const $item = $(item);
            const srcItem = this._getSrc($item);

            if (!this.$container.children(`[data-src='${srcItem}']`)[0]) {
                const $itemContainer = $('<div>').addClass('item-container');
                if (this.$container.children().length) {
                    this.$container.children().eq(i - 1).after($itemContainer);
                } else {
                    this.$container.append($itemContainer);
                }
                $itemContainer.attr('data-src', srcItem);
            }
        });
    },

    _preload: function ($items = null)
    {
        $items = $items ? $items : this.getItems();

        const promises = [];

        $items.each((i, item) => {
            promises.push(new Promise((resolve) => {
                const $item = $(item);
                const src = this._getSrc($item);

                if (webf.inArray(src, this.srcImages)) {
                    resolve({
                        index: this.getIndexItem($item),
                        src: src
                    });
                } else {
                    const $itemContainer = this.$container.children().findByData('src', src);
                    $itemContainer.data('src', null);

                    if ($itemContainer[0]) {
                        if ($(item).data('type') == 'pdf') {
                            const $iframe = $("<iframe allowfullscreen>");
                            $itemContainer.append($iframe);

                            $iframe
                                .addClass("webf-lightbox-iframe-preloaded")
                                .prop("src", src)

                            $iframe.on('load', (ev) => {
                                !webf.inArray(ev.currentTarget.src, this.srcImages) && this.srcImages.push(ev.currentTarget.src);
                                $iframe.removeClass('webf-lightbox-iframe-preloaded');

                                if (this.isOpen() && this.getIndexCurrentItem() == this.getIndexItem($item)) {
                                    this._destroyLoading();
                                }

                                resolve({
                                    index: this.getIndexItem($item),
                                    src: src
                                });
                            });
                        } else {
                            const $img = $('<img>');
                            $itemContainer.append($img);

                            $img
                                .addClass("webf-lightbox-img-preloaded")
                                .prop("src", src)
                                .webfImageloader({
                                    onLoaded: ($img, image) => {
                                        !webf.inArray(image.src, this.srcImages) && this.srcImages.push(image.src);
                                        $img.removeClass('webf-lightbox-img-preloaded');

                                        if (this.isOpen() && this.getIndexCurrentItem() == this.getIndexItem($item)) {
                                            this._destroyLoading();
                                        }

                                        resolve({
                                            index: this.getIndexItem($item),
                                            src: image.src
                                        });
                                    }
                                });
                        }
                    }
                }
            }));
        });

        return Promise.all(promises);
    },

    _bindEvents: function()
    {
        this._on(this.e, this.getItems(), {
            click: (ev) => {
                ev.preventDefault();
                const $img = $(ev.target).closest(this.option('selector'));
                this.open($img);
            }
        });

        this._on(this.$lightbox, '.webf-lightbox-nav.prev', {
            click: () => this.prev()
        })._on(this.$lightbox, '.webf-lightbox-nav.next', {
            click: () => this.next()
        })/*._on(this.$lightbox, '.webf-lightbox-close', {
            click: () => this.close()
        });*/

        this._on(this.$lightbox, {
            transitionend: () => {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.$lightbox.removeClass('closing');
            }
        });

        this._on(document, {
            keydown: webf.throttle((ev) => {
                if (this.isOpen()) {
                    ev.which === 39 && this.next();
                    ev.which === 37 && this.prev();
                }
            }, this.option('throttleKeyboard'), true),
            'keydown.handleEscape': (ev) => {
                if (this.isOpen() && ev.which === 27) {
                    ev.preventDefault();
                    this.close();
                }
            }
        });

        this._on(this.$lightbox, {
            mousedown: (ev) => ev.preventDefault()
        });

        if (this.option('swipe')) {
            let x0 = null;
            let locked = false;
            const container = this.$container[0];

            this._on(this.$lightbox, {
                'mousedown touchstart': /* lock */ (ev) => {
                    if ((ev.type == 'touchstart' || ev.which == 1) && $(ev.target).closest('.webf-lightbox-container')[0]) {
                        x0 = $webf.getMousePos(ev).x;
                        container.classList.toggle('smooth', !(locked = true))
                    }
                },
                'mousemove touchmove': /* drag */ (ev) => {
                    if (locked) {
                        container.style.setProperty('--tx', `${Math.round($webf.getMousePos(ev).x - x0)}px`)
                    }
                },
                'mouseup touchend': /* move */ (ev) => {
                    if (locked) {
                        ev.preventDefault();

                        container.style.setProperty('--tx', '0px');
                        container.classList.toggle('smooth', !(locked = false));

                        let dx = $webf.getMousePos(ev).x - x0;

                        if (Math.abs(dx) > this.option('deltaSwipe')) {
                            Math.sign(dx) > 0 ? this.prev() : this.next();
                        }
                    }
                }
            });
        }
    },

    _displayItem: function()
    {
        this._preload(this.$curItem)
            .then((images) => {
                const image = images[0];
                const curIndex = this.getIndexCurrentItem();

                if (this.isOpen() && curIndex == image.index) {
                    this._destroyLoading();

                    this.$image = this.$container.find(`[src="${image.src}"]`);
                    this.$image.removeClass('webf-lightbox-img-preloaded webf-lightbox-iframe-preloaded');
                }
            });
    },

    _loading: function()
    {
        this._destroyLoading();

        loadingTimeout = setTimeout(() => {
            const $loading = $('<div>').addClass('webf-lightbox-loading');

            this.$lightbox.append($loading);
        }, this.option('delayLoader') + this.option('durationAnimation'));
    },

    _destroyLoading: function()
    {
        loadingTimeout && clearTimeout(loadingTimeout);
        this.$lightbox.children('.webf-lightbox-loading').remove();
    },

    _setCaption: function()
    {
        const caption = this.$curItem.data('caption');

        setTimeout(() => {
            this.$wrapper.children('.webf-lightbox-caption').remove();

            if (!webf.isUndefined(caption) && caption.length) {
                this.$wrapper.append(
                    $('<div>')
                        .addClass('webf-lightbox-caption')
                        .append(
                            $('<div>').addClass('text')
                                .text(caption)
                        )
                );
            }
        }, this.firstOpen ? 0 : this.option('durationAnimation'));
    },

    _getSrc: function($item)
    {
        let src;

        if (webf.isFunction(this.option('getSrc'))) {
            src = this._call(this.option('getSrc'), $item);
        }

        if (src) return src;

        if ($item[0].tagName == 'IMG') {
            src = $item.prop('src');
        } else if ($item[0].tagName == 'A') {
            src = $item.prop('href');
        }

        src = src ? src : $item.data('src');

        if (!src && !webf.inArray($item[0].tagName, ['IMG'])) {
            src = $item.find('img').first().attr('src');
        }

        return src;
    },

    preload: function(cb = webf.noop)
    {
        this._preload()
            .then((images) => {
                cb(images);
            });
    },

    open: function(item)
    {
        if (webf.isInteger(item)) {
            this.$curItem = this.getItems().eq(item);
            !this.isOpen() && this.$lightbox.addClass('open').removeClass('closing');
            this.$lightbox.children('img').addClass('webf-lightbox-img-preloaded');
            this._setCaption();

            if (!this.firstOpen) {
                this.$container.addClass('smooth');
            } else {
                this.firstOpen = false;
                this.$container.removeClass('smooth');
            }

            this.$container[0].style.setProperty('--i', this.getIndexCurrentItem());

            this._displayItem();
        } else {
            this.open(this.getIndexItem(item));
        }
    },

    isOpen: function()
    {
        return this.$lightbox.hasClass('open');
    },

    prev: function()
    {
        const curIndex = this.getIndexCurrentItem();
        const $items = this.getItems();

        $items.length > 1 && this.open(curIndex <= 0 ? $items.eq($items.length - 1) : $items.eq(curIndex - 1));
    },

    next: function()
    {
        const curIndex = this.getIndexCurrentItem();
        const $items = this.getItems();

        $items.length > 1 && this.open(curIndex + 1 >= $items.length ? $items.eq(0) : $items.eq(curIndex + 1));
    },

    getItems: function()
    {
        const selector = this.option('selector');

        if (webf.isFunction(selector)) {
            return this._call(this.option('selector'));
        }

        return this.e.find(selector);
    },

    getIndexCurrentItem: function()
    {
        let index = 0;

        this.getItems().each((i, innerItem) => {
            if (this.$curItem && this.$curItem[0] === innerItem) {
                index = i;
                return false;
            }
        });

        return parseInt(index);
    },

    getIndexItem: function($item)
    {
        let index = 0;

        this.getItems().each((i, innerItem) => {
            if ($item[0] === innerItem) {
                index = i;
                return false;
            }
        }, this);

        return index;
    },

    close: function()
    {
        this.$lightbox.removeClass('open').addClass('closing');
        this.$curItem = null;
        this.firstOpen = true;

        this.timeout = setTimeout(() => this._trigger(this.$lightbox, 'transitionend'), 300);
    },

    _destroy: function()
    {
        this.$lightbox.remove();
    }
});
