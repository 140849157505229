import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.datetimepicker = {
        mo: 'Lu',
        tu: 'Ma',
        we: 'Me',
        th: 'Je',
        fr: 'Ve',
        sa: 'Sa',
        su: 'Di',

        january: 'Janvier',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Août',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Décembre',

        time: 'Moment',
        hour: 'Heures',
        minute: 'Minutes',
        second: 'Secondes',

        today: "Aujourd'hui",
        close: 'Fermer'
    };
})(webf);

