import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './position'
import $ from 'jquery'

/**
 * options :
 *  - menu
 *  - appendTo
 *  - position
 *  - closeOnClickElement
 *  - autoPositionning
 *  - menuClass
 *  - activeClass
 *  - zIndex
 *  - lazyLoading
 *  - onBeforeFirstOpen
 *  - onBeforeOpen
 *  - onFirstOpen
 *  - onOpen
 *  - onBeforeClose
 *  - onClose
 *
 * Méthodes publiques :
 *  - menu
 *  - open
 *  - close
 *  - refreshPosition
 *  - isOpened
 *  - isClosed
 */
$webf('popover', {
    options: {
        menu:                   '',
        appendTo:               'body',
        position: {
            my:         'left top',
            at:         'left bottom',
            collision:  'flip none'
        },
        closeOnClickElement:    true,
        autoPositionning:       true,
        menuClass:              '',
        activeClass:            'webf-active',
        zIndex:                 1000,
        lazyLoading:            false,
        onBeforeFirstOpen:      webf.noop,
        onBeforeOpen:           webf.noop,
        onFirstOpen:            webf.noop,
        onOpen:                 webf.noop,
        onBeforeClose:          webf.noop,
        onClose:                webf.noop
    },

    _create: function()
    {
        this.init = false;
        this.$menu = this.menu();
        this.beforefirstopen = true;
        this.firstopen = true;
        this.closed = true;

        var origCssMenu = {};
        this.origCssMenu = webf.each(['height', 'overflow-x', 'overflow-y'], function(i, prop) {
            origCssMenu[prop.camelCase()] = webf.getStyle(this, prop);
        }, this.$menu[0]);
        this.origCssMenu = origCssMenu;

        this._bindEvents();
    },

    _bindEvents: function()
    {
        var self = this,
            closeOtherMenus = function() {
            return webf.map(self.getOtherInstances(), function(i, instance) {
                if (instance.isOpened()) {
                    instance.close();
                    return true;
                }

                return null;
            });
        };

        this._on(this.e, {
            click: function(ev) {
                closeOtherMenus();

                if (self.isClosed()) {
                    self.open();
                } else if (self.option('closeOnClickElement') && self.isOpened()) {
                    self.close();
                }

                ev.stopPropagation();
            }
        });

        this._on(document, {
            click: function(ev) {
                if (!$(ev.target).closest(self.menu())[0]) {
                    self.isOpened() && self.close();
                }
            }
        });

        this.option('autoPositionning') && this._on(window, {
            'resize scroll': function() {
                self.isOpened() && self._setMenuPosition();
            }
        });
    },

    _setMenuPosition: function(of)
    {
        var $menu = this.menu(),
            menuVisible = $menu.is(':visible');

        if (!menuVisible) {
            $menu.show();
        }

        $menu.css({
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            zIndex: this.option('zIndex')
        });

        $menu.webfPosition('destroy');
        $menu.webfPosition(webf.extend(true, {}, this.option('position'), {
            of: of || this.e
        }));

        if (!menuVisible) {
            this.menu().hide();
        }
    },

    _removeMenu: function()
    {
        this.$menu.detach();
        this.removed = true;
    },

    _slideOpen: function(callback)
    {
        this.option('autoPositionning') && this._setMenuPosition();

        callback.call(this);
    },

    _slideClose: function(callback)
    {
        this.menu().hide();
    },

    menu: function()
    {
        if (!this.init) {
            this.init = true;
            this.$menu = this.menu();

            if (this.option('lazyLoading')) {
                this.$menu.remove();
                return this.$menu;
            } else {
                this.$menu.appendTo(this.option('appendTo'));
                this.$menu.hide();
            }
        }

        if (this.removed) {
            this.removed = false;
            this.$menu = this.menu();
        }

        return this.$menu
            ? this.$menu
            : $(this.option('menu')
                ? this.option('menu')
                : this.e.data('webf-menu')
                    ? $('<div>').text(this.e.data('webf-menu'))
                    : this.e.next()).addClass(this.option('menuClass'));
    },

    open: function()
    {
        if (!$.contains(document, this.menu())) {
            this.menu().appendTo(this.option('appendTo')).hide();
        }

        if (this.beforefirstopen) {
            this.beforefirstopen = false;
            this._call(this.option('onBeforeFirstOpen'));
        }
        this._call(this.option('onBeforeOpen'));

        if (this.option('activeClass').length) {
            this.e.addClass(this.option('activeClass'));
        }

        this.option('autoPositionning') && this._setMenuPosition();

        this.menu().show();

        this.closed = false;

        if (this.firstopen) {
            this.firstopen = false;
            this._call(this.option('onFirstOpen'));
        }

        this._call(this.option('onOpen'));
    },

    close: function()
    {
        this._call(this.option('onBeforeClose'));

        this.e.removeClass('webf-active');

        this.menu().hide();

        this.closed = true;

        if (this.option('lazyLoading')) {
            this._removeMenu();
        }

        this._call(this.option('onClose'));
    },

    refreshPosition: function(of)
    {
        this._setMenuPosition(of);
    },

    isOpened: function()    { return !this.isClosed(); },
    isClosed: function()    { return this.closed; },

    _destroy: function()
    {
        this.e.removeClass('webf-active');
        this.menu().remove();
    }
});

