import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - min
 *  - max
 *  - loop
 *  - step
 *  - value
 *  - disposition
 *  - onSpin
 *  - onChange
 *  - onComplete
 *
 * Méthodes publiques :
 *  - stepUp
 *  - stepDown
 *  - value
 *  - widget
 */
$webf("spinner", {
    options: {
        min:            null,
        max:            null,
        loop:           false, // if true return to min after reach max
        step:           1,
        value:          null,
        disposition:    {
            down:       'right',
            up:         'right',
            sideBySide: false
        },
        onSpin:         webf.noop,
        onChange:       webf.noop,
        onComplete:     webf.noop
    },

    _create: function()
    {
        this.$spinner = null;
        this.mousedownOnUp = false;
        this.mousedownOnDown = false;
        this.timeout = null;
        this.interval = null;
        this.keydown = null;

        if (webf.isFloat(this.option('value'))) {
            var value = Math.round(this.option('value') * 100000) / 100000;
            this.e.val(value);
        }

        this.oldValue = this.value();

        this._drawSpinner();
        this._bindEvents();

        this._call(this.option('onComplete'), value);
    },

    _drawSpinner: function()
    {
        this.$spinner = this.e.wrap("" +
            "<span class='webf-spinner'></span>" +
        "").addClass('webf-input-spinner').parent();

        var disposition = this.option('disposition');

        if (disposition.down != disposition.up) {
            var $buttonsLeft = $("<div class='webf-spinner-buttons alone left'></div>");
            var $buttonsRight = $("<div class='webf-spinner-buttons alone right'></div>");

            $buttonsLeft.append("<a class='webf-spin-btn down'><i class='fas fa-caret-down'></i></a>");
            $buttonsRight.append("<a class='webf-spin-btn up'><i class='fas fa-caret-up'></i></a>");

            this.$spinner.prepend($buttonsLeft);
            this.$spinner.append($buttonsRight);
        } else {
            var $buttons = $("<div class='webf-spinner-buttons'></div>")

            if (disposition.sideBySide) {
                var $buttonDown = $buttons.clone()
                    .addClass('alone '+disposition.down)
                    .append("<a class='webf-spin-btn down'><i class='fas fa-caret-down'></i></a>");

                var $buttonUp = $buttons.clone()
                    .addClass('alone '+disposition.up)
                    .append("<a class='webf-spin-btn up'><i class='fas fa-caret-up'></i></a>");

                if (disposition.down == 'left') {
                    this.$spinner.prepend($buttonUp);
                    this.$spinner.prepend($buttonDown);
                } else {
                    this.$spinner.append($buttonDown);
                    this.$spinner.append($buttonUp);
                }
            } else {
                $buttons
                    .addClass(disposition.down)
                    .append("" +
                        "<a class='webf-spin-btn up'><i class='fas fa-caret-up'></i></a>" +
                        "<a class='webf-spin-btn down'><i class='fas fa-caret-down'></i></a>" +
                    "");

                if (disposition.down == 'left') {
                    this.$spinner.prepend($buttons);
                } else {
                    this.$spinner.append($buttons);
                }
            }
        }
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.$spinner, 'a.webf-spin-btn', {
            mousedown: function(ev) {
                ev.preventDefault();

                if ($(this).hasClass('up')) {
                    self.mousedownOnUp = true;
                    self._stepUp();
                } else if ($(this).hasClass('down')) {
                    self.mousedownOnDown = true;
                    self._stepDown();
                }
            },
            mouseenter: function(ev) {
                if ($(this).hasClass('up')) {
                    if (self.mousedownOnUp) {
                        self._stepUp();
                    }
                } else if ($(this).hasClass('down')) {
                    if (self.mousedownOnDown) {
                        self._stepDown();
                    }
                }
            },
            mouseleave: function() {
                self._abortSpin();
            },
            stepup: function() {
                self.stepUp(1);
            },
            stepdown: function() {
                self.stepDown(1);
            }
        })._on(this.e, {
            keydown: function(ev) {
                var key = ev.which;

                if (webf.inArray(key, [38, 40])) {
                    ev.preventDefault();
                    if (!self.keydown) {
                        self.keydown = true;

                        if (key == 40) {
                            self._stepDown();
                        } else if (key == 38) {
                            self._stepUp();
                        }
                    }
                }
            },
            keyup: function(ev) {
                self.keydown = false;
                self._abortSpin();
            }
        })._on(document, {
            'mouseup blur': function() {
                self.mousedownOnDown = self.mousedownOnUp = false;
                self._abortSpin();
            }
        });
    },

    _stepUp: function($btn)
    {
        this.stepUp(1);

        this.timeout = webf.setTimeout(function() {
            this.interval = webf.setInterval(function() {
                this._trigger(this.$spinner.find('a.webf-spin-btn.up'), 'stepup');
            }, 20, this);
        }, 500, this);
    },

    _stepDown: function($btn)
    {
        this.stepDown(1);

        this.timeout = webf.setTimeout(function() {
            this.interval = webf.setInterval(function() {
                this._trigger(this.$spinner.find('a.webf-spin-btn.down'), 'stepdown');
            }, 20, this);
        }, 500, this);
    },

    _abortSpin: function()
    {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        var value = this.value();

        if (value != this.oldValue) {
            this._call(this.option('onChange'), value);
        }

        this.oldValue = this.value();
    },

    stepUp: function(steps)
    {
        steps = Math.abs(steps);
        var val = steps * this.option('step');

        if (this.option('min') !== null && this.value() < this.option('min')) {
            this.value(this.option('min'));
        } else if (this.option('max') === null || this.value() + val <= this.option('max')) {
            this.value('+=' + val);
        } else if (this.option('loop') && this.option('min') !== null && this.option('max') !== null){
            this.value(this.option('min'));
        } else {
            this.value(this.option('max'));
        }
    },

    stepDown: function(steps)
    {
        steps = Math.abs(steps);
        var val = steps * this.option('step');

        if (this.option('max') !== null && this.value() > this.option('max')) {
            this.value(this.option('max'));
        } else if (this.option('min') === null || this.value() - val >= this.option('min')) {
            this.value('-=' + val);
        } else if (this.option('loop') && this.option('max') !== null && this.option('min') !== null){
            this.value(this.option('max'));
        } else {
            this.value(this.option('min'));
        }
    },

    value: function(val)
    {
        var parseValue = function() {
            if (webf.isFloat(this)) {
                return parseFloat(this);
            }

            return null;
        };

        if (webf.isUndefined(val)) {
            return parseValue.call(this.e.val());
        }

        val += '';

        if (val.charAt(1) === '=') {
            var value = this.value();
            if (value === null) {
                value = 0;
            }

            if (val.charAt(0) == '-') {
                val = value - parseValue.call(val.split('=')[1]);
            } else if (val.charAt(0) == "+") {
                val = value + parseValue.call(val.split('=')[1]);
            }
        }

        val = webf.round(val, 6);

        this.e.val(val);

        this._call(this.option('onSpin'), val);
    },

    widget: function()
    {
        return this.$spinner;
    },

    _destroy: function()
    {
        this.$spinner.find('a.webf-spin-btn').remove();
        this.e.unwrap();
    }
});

