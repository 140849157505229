import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.date = {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',

        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
        sun: 'Sun',

        mo: 'Mo',
        tu: 'Tu',
        we: 'We',
        th: 'Th',
        fr: 'Fr',
        sa: 'Sa',
        su: 'Su',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',

        jan: 'Jan.',
        feb: 'Feb.',
        mar: 'Mar.',
        apr: 'Apr.',
        'may.': 'May',
        jun: 'Jun.',
        jul: 'Jul.',
        aug: 'Aug.',
        sept: 'Sept.',
        oct: 'Oct.',
        nov: 'Nov.',
        dec: 'Dec.'
    };
})(webf);

