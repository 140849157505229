import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Link = webf.RichTextEditor.Control.extend({
    zIndex: null,

    init: function(editor, options) {
        this.zIndex = editor.option('zIndex');

        this.$contentDialog = $('' +
            '<div style="display:none">' +
            '  <form class="form-horizontal">' +
            '    <div class="form-group ">' +
            '      <label class="col-sm-3 control-label" for="text_link">' +
            '        <span>' + editor._('text of link') + '</span>' +
            '      </label>' +
            '      <div class="col-sm-9">' +
            '        <input id="text_link" class="form-control" type="text" autocomplete="off">' +
            '      </div>' +
            '    </div>' +
            '    <div class="form-group ">' +
            '      <label class="col-sm-3 control-label" for="url_link">' +
            '        <span>' + editor._('url_link') + '</span>' +
            '      </label>' +
            '      <div class="col-sm-9">' +
            '        <input id="url_link" class="form-control" type="text" autocomplete="off">' +
            '      </div>' +
            '    </div>' +
            '    <div class="form-group ">' +
            '      <label class="col-sm-3 control-label" for="chk_open_in_new_tab">' +
            '        <span>' + editor._('open_in_new_tab') + '</span>' +
            '      </label>' +
            '      <div class="col-sm-9">' +
            '        <input type="checkbox" id="chk_open_in_new_tab" class="form-control" autocomplete="off">' +
            '      </div>' +
            '    </div>' +
            '  </form>' +
            '</div>' +
        '');

        this.$contentDialog.webfDialog($.extend({
            autoOpen: false,
            dialogClass: 'webf-richtexteditor-dialog webf-richtexteditor-link',
            box: {
                boxClass: 'primary plain',
                header: {
                    title: editor._('link'),
                    closeButton: false
                },
                footer: {
                    closeButton: false,
                    buttons: {
                        Valider: {
                            cls: 'primary',
                            click: function () {
                                var $widget = $(this).webfDialog('widget'),
                                    textLink = $widget.find('#text_link').val(),
                                    url = $widget.find('#url_link').val(),
                                    openInNewTab = $widget.find('#chk_open_in_new_tab').prop('checked');

                                if (url && /^https?:\/\//.test(url)) {
                                    editor.pasteHtml('<a href="' + url + '"' + (openInNewTab ? 'target="_blank"' : '') + '>' + textLink + '</a>');
                                }

                                $(this).webfDialog('close');
                            }
                        },
                        Annuler: {
                            cls: 'white',
                            click: function () {
                                $(this).webfDialog('close');
                            }
                        }
                    }
                }
            },
            onFirstOpen: function () {
                var $widget = $(this).webfDialog('widget');

                $widget.find("#chk_open_in_new_tab").webfCheckbox();
            },
            onOpen: function () {
                var $widget = $(this).webfDialog('widget'),
                    rangeSelection = editor.getSelectionRange(),
                    htmlSelection = editor.getSelectionHtml(),
                    textSelection = htmlSelection.stripTags(),
                    elemLink,
                    openInNewTab = false,
                    href = 'http://';

                if (elemLink = getLinkInRange(rangeSelection)) {
                    textSelection = elemLink.firstChild.nodeValue;
                    href = elemLink.getAttribute('href');
                    openInNewTab = elemLink.hasAttribute('target') && elemLink.getAttribute('target') == '_blank';
                }

                $widget.find("#chk_open_in_new_tab").webfCheckbox('check', openInNewTab);

                $widget.find('#url_link').val(href);
                $widget.find('#text_link').val(textSelection);
            }
        }, this.zIndex ? {
            zIndex: this.zIndex + 1
        } : {}));
    },
    render: function(editor, $btn) {
        $btn.addClass('link')
            .append("<i class='fas fa-link'></i>");
    },
    exec: function() {
        this.$contentDialog.webfDialog('open');
    },
    blur: function(editor, $btn) {
        this.$contentDialog.webfDialog('close');
    },
    types: 'action',
    text_tooltip: 'link',
    shortcut: {
        Ctrl: true,
        key: 'K'
    },
    destroy: function () {
        this.$contentDialog.webfDialog('destroy');
        this.$contentDialog.remove();
    }
});

function getLinkInRange(rng)
{
    var elemStart = rng.startContainer,
        elemEnd = rng.endContainer;

    while (elemStart = elemStart.parentElement) {
        if( elemStart.tagName == 'A' ) {
            return elemStart;
        }
    }

    while (elemEnd = elemEnd.parentElement) {
        if (elemEnd.tagName == 'A') {
            return elemEnd;
        }
    }

    return false;
}

