import webf from './core'
import WebfClass from './class'
import WebfDate from './date'

const Cookies = WebfClass.extend({
    constructor: function () {},

    get: function (name)
    {
        return webf.readCookie(name);
    },

    set: function (name, val, seconds, path, domain, secure, httpOnly)
    {
        var end;

        path = path || '/';

        if (!name || /^(?:expires|max-age|path|domain|secure)$/i.test(name)) {
            return false;
        }

        if (seconds) {
            var wDate = new WebfDate();
            wDate.addSeconds(seconds);
            end = wDate.toDate().toUTCString();
        }

        var props = {
            expires: end,
            path: path,
            domain: domain,
            secure: secure,
            httpOnly: httpOnly
        };

        document.cookie = [encodeURIComponent(name) + '=' + encodeURIComponent(val)].concat(
            webf.map(props, function(key, value) {
                if (!value) {
                    return null;
                }

                if (webf.inArray(key, ['secure', 'httpOnly'])) {
                    return key;
                }

                return [key, value].join('=');
            })
        ).join('; ');

        return true;
    },

    has: function (name)
    {
        return !!this.get(name);
    },

    remove: function (name) {
        if (!name || !this.has(name)) {
            return false;
        }

        this.set(name, '', -(86400 * 365 * 10));

        return true;
    }
});

webf.Cookies = Cookies

export default Cookies
