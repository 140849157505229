import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './imageloader'
import $ from 'jquery'

/**
 * * non implémenté
 *
 * options:
 *  - *orientation      (string)     horizontal ou vertical
 *  - *useCss             (boolean)     Utilise les transitions CSS quand le navigateur le supporte
 *  - start             (int)         n° de la slide à afficher au chargement.
 *  - autoplay             (boolean)     Fait défiler le carousel automatiquement
 *  - delayAutoplay     (int)         Temps de pause en ms entre chaque slide
 *  - scroll             (int)         Nombre de slides devant bouger lorque l'utilisateur clique sur précédent ou suivant
 *  - infiniteLoop         (boolean)     Si défini à true, cliquer sur Suivant alors que le carousel est au bout fera apparaître
 *                                  le début du carousel et inversement pour le clic sur Précédent.
 *  - *pauseOnHover     (boolean)     met en pause le défilement automatique lors du survol de la souris sur le carousel
 *  - slideWidth         (float)     Largeur d'un slide
 *  - speed             (int)         Durée de l'animation du défilement
 *  - easing             (string)     Type d'animation pour le défilement
 *  - *pagination         (boolean)     Affiche une pagination
 *  - showArrows         (boolean)     Affiche les flèches prédédent et Suivant
 *  - classWrapper         (string)     Classes CSS supplémentaires à appliquer au wrapper du carousel. Utile pour
 *                                  personnaliser l'affichage des flèches
 *  - slideMarginHorizontal (int)     margin horizontal en px entre chaque slide si l'orientation est horizontal
 *  - *slideMarginVertical     (int)     margin vertical en px entre chaque slide si l'orientation est vertical
 *  - *responsive:                     A définir
 *  - onCreate:             (function)     Fonction appelée lorsque le carousel est initialisé
 *  - onClick:             (function($slide))
 *          Fonction appelée lors d'un clic sur une slide
 *  - onMouseover:         (function($slide))
 *          Fonction appelée lors d'un survol de la souris sur une slide
 *  - onMouseleave:     (function($slide))
 *          Fonction appelée lors de la sortie de la souris sur une slide
 *
 * méthodes publques:
 *  - moveTo(int)         Positionne le caousel à l'index du slide indiqué
 */
$webf('carousel', {
    options: {
        orientation:            'horizontal',
        useCss:                 true,
        autoplay:               false,
        delayAutoplay:          2000,
        start:                  0,
        scroll:                 1,
        infiniteLoop:           true,
        pauseOnHover:           true,
        slideWidth:             300,
        slidesToShow:           2,
        speed:                  500,
        easing:                 'swing',
        pagination:             true,
        showArrows:             true,
        classWrapper:           '',
        slideMarginHorizontal:  10,
        slideMarginVertical:    0,
        onCreate:               webf.noop,
        onClick:                webf.noop,
        onMouseover:            webf.noop,
        onMouseleave:           webf.noop,

        responsive:             {}
    },

    _create: function()
    {
        this.slides = this.e.children();
        this.scroll = this._getNbScroll();
        this.curSlide = null;
        this.useCss = this.option('useCss') && this._canIUseCssTransitions();
        this.wrapper = null;
        this.viewport = null;
        this.moving = false;
        this.resize = false;
        this.timeoutPlay = null;
        this.pause = false;

        this.windowWidth = $(window).width();
        this.windowHeight = $(window).height();

        this._drawCarousel();
    },

    _drawCarousel: function()
    {
        var self = this;

        this.e.addClass('webf-carousel');
        this.slides.addClass('webf-slide');

        var $wrapper = $("<div>").addClass(['webf-wrapper-carousel'].concat(this.option('classWrapper').split(' ')).join(' '));
        var $viewport = $("<div>").addClass('webf-viewport-carousel');

        $wrapper.append($viewport);

        this.e.wrap($wrapper);

        $viewport = this.e.closest('.webf-viewport-carousel');
        $wrapper = $viewport.parent();

        if (this.option('showArrows')) {
            var $left = $("<a>").addClass("webf-carousel-nav webf-prev");
            var $right = $("<a>").addClass("webf-carousel-nav webf-next");

            $wrapper.append($left).append($right);
        }

        if (this.option('pagination')) {
//                $viewport.append();
        }

        var numSlides = this._getNbSlides();
        var numSlidesToShow = this._getNbSlidesToShow();

        this.e.webfImageloader( {
            onAllLoaded: function() {
                var sw = self._getSlideWidth();

                for (var i=0; i<self.option('slidesToShow'); i++) {
                    if (self.option('orientation') == 'vertical') {

                    } else {
                        var margin = self.option('slideMarginHorizontal') || 0;

                        self.slides.css( {
                            float: 'left',
                            width: sw - margin,
                            marginRight: margin / 2,
                            marginLeft: margin / 2
                        });
                    }
                }

                var nbClones = self.option('infiniteLoop') ? self._getNbSlides() : 0;

                for (var i=0; i<nbClones; i++) {
                    var $slideAfter = self.slides.eq(i).clone(true).addClass('webf-slide-clone');
                    var $slideBefore = self.slides.eq(-(i + 1)).clone(true).addClass('webf-slide-clone');
                    self.e.append($slideAfter).prepend($slideBefore);
                }

                $wrapper.width(numSlidesToShow * sw);
                $viewport.width((numSlides + (nbClones * 2)) * sw);
                $viewport.height(self._getCarouselHeight());

                self.wrapper = $wrapper;
                self.viewport = $viewport;

                self._setCarouselPosition(self.option('start'));

                if (self.option('autoplay')) {
                    self._play();
                }

                self._bindEvents();

                self._call(self.option('onCreate'), self.slides);
            }
        });
    },

    _bindEvents: function()
    {
        var self = this;

        if (this._isResponsive()) {
            this._on('resize', this._resizeWindow);
        }

        this.slides.each(function(i, slide) {
            var $slide = $(slide);

            self._on($slide, {
                click: function() {
                    self._call(self.option('onClick'), $(this));
                },
                mouseenter: function() {
                    self._call(self.option('onMouseover'), $(this));
                },
                mouseleave: function() {
                    self._call(self.option('onMouseleave'), $(this));
                }
            });
        });

        this._on(this.wrapper, 'a.webf-prev, a.webf-next', {
            click: function() {
                self._move($(this).hasClass('webf-prev') ? -self.scroll : self.scroll);
            }
        });

        if (this.option('autoplay') && this.option('pauseOnHover')) {
            this._on(this.$wrapper, {
                mouseenter: function() {
                    self._stop();
                },
                mouseleave: function() {
                    self.pause = false;
                    self._play();
                }
            });
        }
    },

    _getNbSlides: function()
    {
        return this.slides.length;
    },

    _getSlideWidth: function()
    {
        return this.option('slideWidth') + this.option('slideMarginHorizontal');
    },

    _getNbSlidesToShow: function()
    {
        return this.option('slidesToShow');
    },

    _getNbScroll: function()
    {
        return webf.between(this.option('scroll'), 1, this._getNbSlides() - 1);
    },

    _move: function(delta)
    {
        this.moveTo(this.curSlide + delta);
    },

    _getCarouselHeight: function()
    {
        var height = 0;

        if (this.option('orientation') != 'vertical') {
            height = webf.max(this.slides.map(function() {
                return $(this).outerHeight(false);
            }).get());
        }

        return height;
    },

    _setCarouselPosition: function(position)
    {
        var maxPosition = this._getMaxPosition();
        position = webf.between(webf.isInt(position) ? position : 0, 0, maxPosition);

        this.curSlide = position;

        var left = this.slides.eq(position).prevAll().length * this._getSlideWidth();
        this.viewport.css('left', -left);

        this._adjustSlides();
    },

    _getMaxPosition: function()
    {
        return this.option('infiniteLoop')
            ? this._getNbSlides() - 1
            : this._getNbSlides() - this._getNbSlidesToShow()
        ;
    },

    _translate: function(value)
    {
        var self = this;

        this.viewport.animate( {
            left: value
        }, this.option('speed'), this.option('easing'), function() {
            self.moving = false;

            if (self.resize) {
                self.resize = false;
                self._resizeWindow();
            } else {
                self._adjustSlides();
            }
        });
    },

    _resizeWindow: function()
    {
        if (this.moving) {
            this.resize = true;
        }

        this._setCarouselPosition(this.curSlide);
    },

    moveTo: function(position)
    {
        if (this.moving) {
            return;
        }

        this.moving = true;

        var offset = 0;

        var sw = this._getSlideWidth(),
            nbSlides = this._getNbSlides(),
            maxPosition = this._getMaxPosition()
        ;

        if (this.option('infiniteLoop')) {
            if (position < 0) {
                position = Math.max(position, 1 - nbSlides);
                this.curSlide = nbSlides + position;
            } else {
                this.curSlide = Math.min(position, position % nbSlides);
            }

            offset -= nbSlides * sw;
        } else {
            if (position < 0) {
                position = maxPosition;
            } else if (position > maxPosition) {
                position = 0;
            }

            this.curSlide = position;
        }

        this._translate(offset - position * sw);
    },

    _adjustSlides: function()
    {
        if (this.option('infiniteLoop')) {
            var offset = null;

            var nbSlides = this._getNbSlides();
            var nbClones = nbSlides;
            var sw = this._getSlideWidth();
            var maxPosition = this._getMaxPosition();

            this.e.children().addClass('webf-slide-clone').slice(nbClones, nbSlides + nbClones).removeClass('webf-slide-clone');

            if (this.curSlide == 0) {
                offset = -(nbSlides * sw);
            } else if (this.curSlide <= maxPosition) {
                if (this.e.children().eq(this.curSlide).hasClass('webf-slide-clone')) {
                    offset = -((nbSlides + this.curSlide) * sw);
                }
            }

            if (offset !== null) {
                this.viewport.css(this.option('orientation') == 'vertical' ? 'top' : 'left', offset);
            }
        }
    },

    _play: function()
    {
        if (!this.pause) {
            this.timeoutPlay = webf.setTimeout(function() {
                this._move(this.option('scroll'));
                this._play();
            }, this.option('delayAutoplay'), this);
        }
    },

    _stop: function()
    {
        this.pause = true;
        window.clearTimeout(this.timeoutPlay);
    },

    _isResponsive: function()
    {
        return this.option('slideWidth') === null;
    },

    _canIUseCssTransitions: function()
    {
        var transitions = false;

        webf.each(['Webkit', 'Moz', 'Ms', 'O'], function(i, prefix) {
            if (document.body.style[prefix + 'Transition']) {
                prefix = prefix.toLowercase();

                this.transformType = '-' + prefix + '-transform';
                this.transitionType = prefix + 'Transition';

                transitions = true;
                return false;
            }
        }, this);

        return transitions;
    }
});

// const WebfCarousel = {
//     create(selector, options = {}) {
//         return $(selector).webfCarousel(options);
//     }
// }
//
// export default WebfCarousel

export default class WebfCarousel {
    constructor(selector, options) {
        $(selector).webfCarousel(options);
    }
}
