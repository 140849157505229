import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './position'
import $ from 'jquery'

/**
 * @depends jquery.webf.js
 * @depends jquery.position.js
 * @depends mousewheel.js
 *
 * options:
 *  - handler:              (null|jQuery|Element)
 *                                              Si renseigné, remplace l'élement courant en tant qu'handler afin de pouvoir
 *                                              gérer les handlers ajoutés au DOM après l'instanciation du dropdownmenu
 *                                              NB : Le handler doit être un descendant de l'élément courant.
 *  - menu:                 (string|jQuery|Element)
 *                                              Contenu du menu
 *  - menuWidth             (string|boolean)    width du menu ('auto' par défaut). Soit auto pour width: auto, soit true
 *                                              pour que le plugin détermine la lergeur nécessaire
 *  - behavior:             (string)            Comportement du menu:
 *                                              - menu-toggle: Le menu s'ouvre au clic sur le handler.
 *                                              - menu-hover: Le menu s'ouvre au survol de la souris sur le handler.
 *                                              - custom: Le menu ne s'ouvre que lorsque la méthode open est appelée.
 *  - appendTo:             (string|jQuery|Element)
 *                                              sélecteur ou Element à partir duquel le menu est ajouté.
 *  - groups:               (string)            Liste de noms de groupes avec lesquels les autres dropdownmenus sont reliés
 *                                              Les dropdwonmenus ayant des groupes en commun se ferment mutuellement si
 *                                              l'autre est ouvert.
 *  - closeOnClickElement:  (boolean)           Si true, le click sur le handler ferme le menu
 *  - autoPositionning:     (boolean)           Si true, le menu se positionne automatiquement lors des événement resize, scroll
 *  - menuClass             (string)            Classes CSS du menu
 *  - delayOpen:            (int)               Temps en ms avant l'ouverture du menu
 *  - delayClose:           (int)               Temps en ms avant la fermeturee du menu
 *  - animation:            (string)            Type d'animation lors de l'ouverture ou la fermeture du menu (sliding ou fading)
 *  - durationOpen:         (int)               Durée en ms pour l'ouverture du menu
 *  - durationClose:        (int)               Durée en ms pour la fermeture du menu
 *  - directionOpen:        (string)            Direction de l'ouverture du menu up, down, left, right pour l'animation sliding
 *  - directionClose:       (function(msg))     Direction de la fermeture du menu up, down, left, right pour l'animation sliding
 *  - easingOpen:           (string)            (swing, linear) voir doc jquery pour + de détails
 *  - easingClose:          (string)            (swing, linear) voir doc jquery pour + de détails
 *  - lazyLoading:          (boolean)           Si true, le menu est supprimé du DOM.
 *  - onBeforeFirstOpen:    (function())        Fonction appelée avant la première ouverture du menu.
 *  - onBeforeOpen:         (function($curHandler))
 *                                              Fonction appelée avant l'ouverture du menu.
 *  - onFirstOpen:          (function())        Fonction appelée lors de la première ouverture
 *  - onBeforeOpen:         (function())        Fonction appelée avant l'ouverture du menu. Si la fonction retourne true
 *                                              l'ouverture du menu est annulée
 *  - onOpen:               (function())        Fonction appelée lors l'ouverture du menu.
 *  - onBeforeClose:        (function())        Fonction appelée avant la fermeture du menu.
 *  - onClose:              (function())        Fonction appelée lors de la fermeture du menu.
 *
 * Méthodes publiques:
 *  - menu()                Retourne le menu.
 *  - open()                Ouvre le menu.
 *  - close()               Ferme le menu.
 *  - closeImmediately()    Ferme le menu sans animation.
 *  - refreshPosition()     Met à jour la position du menu.
 *  - getHandler()          Retourne le handler, càd l'élément ayant servi à ouvrir le menu
 *  - isOpened()            Retourne true si le menu est ouvert.
 *  - isClosed()            Retourne true si le menu est fermé.
 *  - isOpening()           Retourne true si le menu est en train de s'ouvrir.
 *  - isClosing()           Retourne true si le menu est en train de se fermer.
 *  - isGoingToOpen()       Retourne true si le menu va s'ouvrir.
 *  - isGoingToClose()      Retourne true si le menu va se fermer.
 */
$webf('dropdownmenu', {
    options: {
        handler:                null,
        menu:                   null,
        menuWidth:              'auto',
        behavior:               'menu-hover',
        appendTo:               'body',
        groups:                 '',
        position: {
            my:         'left top',
            at:         'left bottom',
            collision:  'flip none'
        },
        closeOnClickElement:    true,
        autoPositionning:       true,
        menuClass:              '',
        zIndex:                 1000,
        delayOpen:              0,
        delayClose:             0,
        animation:              'sliding',
        durationOpen:           0,
        durationClose:          0,
        directionOpen:          'down',
        directionClose:         'up',
        easingOpen:             'swing',
        easingClose:            'swing',
        lazyLoading:            false,
        keyboardNavigation:     false,
        onBeforeFirstOpen:      webf.noop,
        onBeforeOpen:           webf.noop,
        onFirstOpen:            webf.noop,
        onOpen:                 webf.noop,
        onBeforeClose:          webf.noop,
        onClose:                webf.noop
    },

    _create: function()
    {
        this.options.groups = this.option('groups').split(' ');

        this.handler = this.option('handler') || this.e;
        this.init = false;
        this.$menu = this.menu();
        this.beforefirstopen = true;
        this.firstopen = true;
        this.closed = true;
        this.opening = false;
        this.closing = false;
        this.timerOpen = null;
        this.timerClose = null;
        this.countMouseEnter = 0;

        var origCssMenu = {};
        this.origCssMenu = webf.each(['height', 'overflow-x', 'overflow-y'], function(i, prop) {
            origCssMenu[prop.camelCase()] = webf.getStyle(this, prop);
        }, this.$menu[0]);
        this.origCssMenu = origCssMenu;

        this._bindEvents();
    },

    closeOtherMenus: function()
    {
        var self = this;

        return webf.map(this.getOtherInstances(), function(i, instance) {
            if (webf.arrayIntersect(self.option('groups'), instance.option('groups')).length) {
                if (instance.isOpened() || instance.isOpening()) {
                    instance.closeImmediately();
                    return true;
                }
            }

            return null;
        });
    },

    _bindEvents: function()
    {
        var self = this,
            cancelClosing = function() {
                self.closing = false;
                clearTimeout(self.timerClose); self.timerClose = null;
            };

        switch (this.option('behavior')) {
            case 'menu-toggle':
                this._on(this.e, this.handler, {
                    'click focus': function(ev) {
                        ev.preventDefault();

                        self.closeOtherMenus();

                        if (self.isClosing() || (!self.isOpening() && self.isClosed())) {
                            self.open();
                        } else if (self.option('closeOnClickElement') && (self.isOpening() || (!self.isClosing() && self.isOpened()))) {
                            if (self.$curHandler && !self.$curHandler.is($(ev.target).closest(self.handler))) {
                                self.closeImmediately();
                                self.open();
                            } else {
                                self.close();
                            }
                        }

                        self.$curHandler = $(ev.target).closest(self.handler);

                        ev.stopPropagation();
                    }
                });

                this._on(document, {
                    click: function(ev) {
                        if (!$(ev.target).closest(self.menu())[0]) {
                            self.isOpened() && !self.isClosing() && self.close();
                        }
                    }
                });
                break;


            case 'menu-hover':
                this._on(this.e.add(this.menu()), this.handler, {
                    mouseenter: function(ev) {
                        ev.stopPropagation();
                        if ($(ev.target).closest(self.handler)[0]) {
                            self.$curHandler = $(ev.target).closest(self.handler);
                        }

                        var nbMenusClosed = self.closeOtherMenus().length,
                            delayOpen = nbMenusClosed ? 0 : self.option('delayOpen');

                        self.countMouseEnter++;

                        if (self.isGoingToClose()) {
                            cancelClosing();
                        } else if (self.isClosed() || self.isClosing()) {
                            self.timerOpen = setTimeout(function() {
                                clearTimeout(self.timerOpen); self.timerOpen = null;
                                self.open();
                            }, delayOpen);
                        }
                    },
                    mouseleave: function(ev) {
                        ev.stopPropagation();

                        self.countMouseEnter--;

                        if (self.isGoingToOpen()) {
                            self._cancelOpening();
                        } else if ((self.isOpened() || self.isOpening()) && !self.isGoingToClose()) {
                            self._scheduleClosing();
                        }
                    }
                });

                if (self.option('closeOnClickElement')) {
                    this._on(this.handler, {
                        click: function() {
                            if (self.option('closeOnClickElement') && (self.isOpening() || (!self.isClosing() && self.isOpened()))) {
                                self.close();
                            }
                        }
                    });
                }
                break;

            case 'custom':
                // custom developer implementation
                break;
        }

        this.option('autoPositionning') && this._on(window, {
            'resize scroll webfMouseWheel': function() {
                (self.isOpened() || self.isOpening()) && self._setMenuPosition();
            }
        });
    },

    _setMenuPosition: function(of)
    {
        var $menu = this.menu(),
            menuVisible = $menu.is(':visible');

        if (!menuVisible) {
            $menu.show();
        }

        if ($menu.parent('.webf-dropdown-slider')[0]) {
            $menu.parent('.webf-dropdown-slider').css({
                position: 'absolute',
                zIndex: this.option('zIndex')
            });
        } else {
            $menu.css({
                position: 'absolute',
                top: 'auto',
                left: 'auto',
                zIndex: this.option('zIndex')
            });
        }

        if ($menu.parent().hasClass('webf-dropdown-slider')) {
            $menu = $menu.parent();
        }

        $menu.webfPosition('destroy');
        $menu.webfPosition(webf.extend(true, {}, this.option('position'), {
            of: of || this.$curHandler || this.e
        }));

        if (!menuVisible) {
            this.menu().hide();
        }
    },

    _scheduleClosing: function()
    {
        var self = this;

        this.timerClose = setTimeout(function() {
            clearTimeout(self.timerClose); self.timerClose = null;
            self.close();
        }, this.option('delayClose'));
    },

    _removeMenu: function()
    {
        this.$menu.detach();
        this.removed = true;
    },

    _slideOpen: function(callback)
    {
        var self = this,
            $menu = this.menu().show(),
            direction = this.option('directionOpen'),
            outerWidth = $menu.outerWidth(),
            outerHeight = $menu.outerHeight(),
            width = $menu.width(),
            height = $menu.height(),
            wrapped = false,
            borderBox = webf.getStyle($menu[0], 'box-sizing'),

            initCssMenu = {
                position: 'relative',
                width: this.option('menuWidth') !== 'auto' ? outerWidth : 'auto',
                height: outerHeight,
                top: 0, left: 0
            },

            finalCssMenu = {};

        if ($menu.parent().hasClass('webf-dropdown-slider')) {
            wrapped = true;
        }

        if (/up/.test(direction)) {
            initCssMenu.top = outerHeight;
            initCssMenu.left = 0;
            finalCssMenu.top = 0;
        } else if (/down/.test(direction)) {
            initCssMenu.top = -outerHeight;
            initCssMenu.left = 0;
            finalCssMenu.top = 0;
        }

        if (/left/.test(direction)) {
            initCssMenu.left = outerWidth;
            finalCssMenu.left = 0;
        } else if (/right/.test(direction)) {
            initCssMenu.left = -outerWidth;
            finalCssMenu.left = 0;
        }

        if (!wrapped) {
            var $wrapper = $('<div>').addClass('webf-dropdown-slider').css({
                height: outerHeight,
                width: outerWidth
            });


            $menu.wrap($wrapper);
            $menu.css(initCssMenu);
        }

        this.option('autoPositionning') && this._setMenuPosition();

        $menu.stop().animate(finalCssMenu, {
            duration: this.option('durationOpen'),
            easing: this.option('easingOpen'),
            complete: function() {
                $(this).css({
                    position: 'absolute',
                    top: $(this).parent().css('top'),
                    left: $(this).parent().css('left')
                }).unwrap();

                callback.call(this);
            }
        });
    },

    _slideClose: function(callback)
    {
        var $menu = this.menu().show(),
            direction = this.option('directionClose'),
            outerWidth = $menu.outerWidth(),
            outerHeight = $menu.outerHeight(),
            width = $menu.width(),
            height = $menu.height(),
            wrapped = false,
            borderBox = webf.getStyle($menu[0], 'box-sizing'),

            initCssMenu = {
                position: 'relative',
                width: borderBox == 'border-box' ? outerWidth : width,
                height: borderBox == 'border-box' ? outerHeight : height,
                top: 0, left: 0
            },

            finalCssMenu = {};

        if ($menu.parent().hasClass('webf-dropdown-slider')) {
            wrapped = true;
        }

        if (/up/.test(direction)) {
            finalCssMenu.top = -outerHeight;
        } else if (/down/.test(direction)) {
            finalCssMenu.top = outerHeight;
        }

        if (/left/.test(direction)) {
            finalCssMenu.left = -outerWidth;
        } else if (/right/.test(direction)) {
            finalCssMenu.left = outerWidth;
        }

        var $wrapper = $('<div>').addClass('webf-dropdown-slider').css({
            height: outerHeight,
            width: outerWidth
        });

        if (!wrapped) {
            $menu.wrap($wrapper).css(initCssMenu);
        }

        this.option('autoPositionning') && this._setMenuPosition();

        $menu.stop().animate(finalCssMenu, {
            duration: this.option('durationClose'),
            easing: this.option('easingClose'),
            complete: function() {
                $(this).css({
                    position: 'absolute'
                }).hide().unwrap();

                callback.call(this);
            }
        });
    },

    _cancelOpening: function() {
        this.opening = false;
        clearTimeout(this.timerOpen); this.timerOpen = null;
    },

    menu: function()
    {
        if (!this.init) {
            this.init = true;
            this.$menu = this.menu();

            if (this.option('lazyLoading')) {
                this.$menu.remove();
                return this.$menu;
            } else {
                this.$menu.appendTo(this.option('appendTo'));
                this.$menu.hide();
            }
        }

        if (this.removed) {
            this.removed = false;
            this.$menu = this.menu();
        }

        var $menu;
        if (this.$menu) {
            $menu = this.$menu;
        } else if (this.option('menu')) {
            $menu = $(this.option('menu'));
        } else if (this.e.data('webf-menu')) {
            $menu = $('<div>').text(this.e.data('webf-menu'));
        } else {
            $menu = this.e.next();
        }

        return $menu.addClass(this.option('menuClass'));
    },

    open: function()
    {
        this.opening = true;
        this.closing = false;

        if (!this.menu().closest('html')[0]) {
            this.menu().appendTo(this.option('appendTo')).hide();
        }

        var self = this,
            complete = function() {
                $(this).css(self.origCssMenu).show();
                self.closed = false;
                self.opening = false;
                self.closing = false;

                if (webf.inArray(self.option('behavior'), ['menu-hover']) && self.countMouseEnter == 0) {
                    self._scheduleClosing();
                }

                if (self.firstopen) {
                    self.firstopen = false;
                    self._call(self.option('onFirstOpen'));
                }

                setTimeout(() => {
                    self.option('autoPositionning') && self._setMenuPosition();
                    self._call(self.option('onOpen'));
                }, 150);
            };

        if (this.beforefirstopen) {
            this.beforefirstopen = false;
            this._call(this.option('onBeforeFirstOpen'));
        }

        if (this._call(this.option('onBeforeOpen'), this.$curHandler) !== false) {
            this.option('autoPositionning') && this._setMenuPosition();

            setTimeout(() => {
                if (this.option('animation') == 'sliding') {
                    this._slideOpen(complete);
                } else if (this.option('animation') == 'fading') {
                    this.option('autoPositionning') && this._setMenuPosition();
                    this.menu().stop().fadeTo(this.option('durationOpen'), 1, complete);
                }
            }, 1);
        }
    },

    close: function()
    {
        if (this.isGoingToOpen()) {
            return this._cancelOpening();
        }

        this.opening = false;
        this.closing = true;

        var self = this,
            complete = function() {
                $(this).css(self.origCssMenu).hide();
                self.closed = true;
                self.opening = false;
                self.closing = false;

                if (self.option('lazyLoading')) {
                    self._removeMenu();
                }

                self._call(self.option('onClose'));
            };

        this._call(this.option('onBeforeClose'));

        this.e.removeClass('webf-active');

        webf.setTimeout(function() {
            if (this.option('animation') == 'sliding') {
                this._slideClose(complete);
            } else if (this.option('animation') == 'fading') {
                this.menu().stop().fadeTo(this.option('durationClose'), 0, complete);
            }
        }, 0, this);
    },

    closeImmediately: function()
    {
        clearTimeout(this.timerClose); this.timerClose = null;
        var durationClose = this.option('durationClose');
        this.options.durationClose = 0;
        this.close();
        webf.setTimeout(function() {
            this.options.durationClose = durationClose;
        }, 0, this);
    },

    refreshPosition: function(of)
    {
        this._setMenuPosition(of);
    },

    getHandler: function()
    {
        return this.$curHandler ? this.$curHandler : this.e;
    },

    isOpened: function()        { return !this.isClosed(); },
    isClosed: function()        { return this.closed; },
    isOpening: function()       { return this.opening; },
    isClosing: function()       { return this.closing; },
    isGoingToOpen: function()   { return !!this.timerOpen; },
    isGoingToClose: function()  { return !!this.timerClose; },

    _destroy: function()
    {
        clearTimeout(this.timerOpen); this.timerOpen = null;
        clearTimeout(this.timerClose); this.timerClose = null;
        this.e.removeClass('webf-active');
        this.menu().remove();
    }
});

