import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - keys:                 Pour la rétrocompatbilité
 *  - fn:                   Pour la rétrocompatbilité
 *  - shortcuts:     ({sequences: fn})
 *                          sequences: (string) Les séquences séparées par des virgules
 *                          fn: (function) La fonction à exécuter lorsqu'un match avec une des séquences
 *
 * Exemples de séquences :
 *  Mod+S : Ctrl + S ou Cmd + S
 *  up down left : Touche Haut puis Touche Bas puis Touche Gauche
 *  Ctrl+A B C : Ctrl + A puis B puis C
 */
var MAP = {
    8: 'backspace',
    9: 'tab',
    13: 'enter',
    20: 'capslock',
    27: 'esc',
    32: 'space',
    33: 'pageup',
    34: 'pagedown',
    35: 'end',
    36: 'home',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    43: 'plus',
    44: 'comma',
    45: 'ins',
    46: 'del'
};

var MODIFIERS = {
    16: 'shift',
    17: 'ctrl',
    18: 'alt',
    224: 'command',
    91: 'meta',
    92: 'meta'
};

$webf('shortcut', {
    options: {
        keys:       '',
        fn:         webf.noop,
        shortcuts:  {}
    },

    _create: function()
    {
        this.sequences = [];
        this.maxNbCombos = 0;
        this.curSequence = [];

        var shortcuts = this.option('shortcuts');

        if (!webf.sizeOf(shortcuts) && this.option('keys') && this.option('fn')) {
            shortcuts[this.option('keys')] = this.option('fn');
        }

        webf.each(shortcuts, function(keys, callback) {
            var sequences = keys
                .replace(/\s{2,}/g, ' ')
                .replace(/\s?\++\s?/, '+')
                .split(',');

            webf.each(sequences, function(i, sequence) {
                var newSeq = sequence.trim().split(' ');

                this.maxNbCombos = Math.max(this.maxNbCombos || 0, newSeq.length);
                var seq = {
                    sequence: normalizeSequence(newSeq),
                    callback: callback
                };

                this.sequences.push(seq);
            }, this);
        }, this);

        var sortedKeys = Object.keys(this.sequences).sort(function(a, b) {
            return this[a].sequence.length < this[b].sequence.length;
        }.bind(this.sequences));

        this.sequences = webf.map(sortedKeys, function(i, key) {
            return this[key];
        }.bind(this.sequences));

        this._bindEvents();
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.e, {
            keydown: function(ev) {
                var combo = self._getCombo(ev);

                if (combo !== false) {
                    self.curCombo = combo;
                    self._pushCombo(combo);

                    var retMatch = self._matchSequence();

                    if (webf.isBool(retMatch)) {
                        self._reset();

                        if (retMatch === false) {
                            ev.preventDefault();
                            ev.stopPropagation();
                        }
                    }
                }
            },
            keyup: function() {
                self.curCombo = null;
            }
        });
    },

    _getCombo: function(ev)
    {
        this.modifiers = getModifiersKey(ev);

        var combo;

        if (ev.type == 'keydown' && !hasModifier(ev)) {
            combo = MAP[ev.which] || String.fromCharCode(ev.which).toLowerCase();
        } else if (MAP[ev.which]) {
            combo = MAP[ev.which];
        } else if (!MODIFIERS[ev.which]) {
            combo = String.fromCharCode(ev.which).toLowerCase();
        }

        if (!webf.isUndefined(combo)) {
            combo = [combo].concat(this.modifiers);
            combo.sort();
            return normalizeCombo(combo.join('+'));
        }

        return false;
    },

    _pushCombo: function(combo)
    {
        if (this.curSequence.length >= this.maxNbCombos) {
            this.curSequence = this.curSequence.slice(1, this.maxNbCombos + 1);
        }

        this.curSequence.push(combo);
    },

    _matchSequence: function()
    {
        var self = this,
            ret;

        webf.each(this.sequences, function(i, seq) {
            var tabSequence = seq.sequence;

            if (tabSequence.length > this.length) {
                return true;
            }

            if (webf.compareArray(tabSequence, this.slice(-tabSequence.length))) {
                ret = self._call(seq.callback, tabSequence);

                if (webf.isUndefined(ret)) {
                    ret = true;
                }
            }
        }, this.curSequence);

        return ret;
    },

    _reset: function()
    {
        this.curSequence = [];
    }
});

function hasModifier(ev)
{
    return !!getModifiersKey(ev).length;
}

function getModifiersKey(ev)
{
    var modifiers = [];

    if (ev.shiftKey)    modifiers.push('shift');
    if (ev.altKey)      modifiers.push('alt');
    if (ev.ctrlKey)     modifiers.push('ctrl');
    if (ev.metaKey)     modifiers.push('meta');

    return modifiers;
}

function normalizeCombo(combo)
{
    return (combo + '').toLowerCase()
        .replace('command', 'meta')
        .replace('mod', /Mac|i(Pod|Phone|Pad)/.test(navigator.platform) ? 'meta' : 'ctrl')
        .replace('option', 'alt')
        .split('+')
        .sort()
        .join('+');
}

function normalizeSequence(seq)
{
    return webf.map(seq, function(i, combo) {
        return normalizeCombo(combo);
    }, this);
}

