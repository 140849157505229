import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/ajaxupload'
import './ajaxform'
import $ from 'jquery'
import PQueue from 'p-queue'

/**
 * options:
 *  - action:               (string)            url où envoyer les fichiers
 *  - key:                  (string)            nom de la clé du ou des fichiers
 *  - keyPath:              (string)            nom de la clé du path du ou des fichiers
 *  - separateUploads       (boolean)           Si true, chaque fichier est envoyé dans une requête séparée
 *  - params:               (Object)            Données en plus à envoyer
 *  - multiple:             (boolean)           Upload de un ou plusieurs fichiers
 *  - droparea:             (string|jQuery|false)
 *                                              sélecteur ou objet jQuery pour la zone de drop
 *                                              Si false, il n'y a pas de zone de drop
 *  - dropareas:            (Object|false)
 *                                              element: selecteur ou object jQuery pour la zone de drop
 *                                              onDrag: fonction appellée lors du dragenter
 *                                              onDrop: fonction appellée lors du drop
 *  - allowedExtensions:    (string|array)      Extensions autorisées séparées par des espaces
 *  - directories:          (boolean)           Autorise l'upload de répertoires
 *  - sizeLimit:            (int)               Taille maximale des fichiers
 *  - dataType:             (string)            Type de retour attendu (json, xml...)
 *  - forceIframe:          (boolean)           Force l'utilisation des iframes pour la soumission des fichiers même si
 *                                              le navigateur peut utiliser l'objet XMLHttpRequest level 2
 *  - autosubmit:           (boolean)           Envoie les fichiers dès que l'input est rempli
 *  - urlProgress:          (string)            Url à appeler pour connaître la progression de l'upload
 *  - progressEntryName:    (string)            Nom de l'entrée pour activer la progression
 *  - progressKeyName:      (string)            Nom de la clé à utiliser pour la progression avec APC ou le système de sessions
 *  - freqProgress:         (int)               Temps en ms entre chaque appel à urlProgress
 *  - renderError:          (function(msg))     Fonction appelée lorsqu'une erreur est détectée. Par défaut alert(msg)
 *  - crossDomain:          (boolean)           Autorise les requêtes sur un domaine différent
 *  - allowOrigin:          string              Valeur du Header Access-Control-Allow-Origin
 *  - withCredentials:      (boolean)           Permet de partager les cookies entre domaines différents
 *  - concurrency:          (int)               Nombre maximum d'uploads concurrents si > 0
 *  - onChange:             (function())        Fonction appelée lorsque l'input file change et que l'upload est prêt à être initié
 *  - onBeforeUpload:       (function(FileList))
 *                                              Fonction appelée avant l'upload.
 *  - onBeforeUploadFile:   (function(i, File, path))
 *                                              Fonction appelée avant l'upload de chq fichier.
 *  - onUploadStart:        (function(i, File, path))
 *                                              Fonction appelée au début de l'upload.
 *  - onProgress:           (function(i, loaded, total))
 *                                              Fonction appelée à intervalle régulier pendant l'upload.
 *  - onComplete:           (function(i, datas, file, path))
 *                                              Fonction appelée lorsqu'un upload est terminé
 *  - onAbort:              (function(i))       Fonction appelée lorsqu'un upload est annulé
 *
 * Méthodes publiques:
 *  - upload([index])       Upload les fichiers si par d'argument ou uploade le fichier de l'index
 *  - abort([index])        Annule l'upload des fichiers si par d'argument ou annule l'upload du fichier de l'index
 *  - retry(index)          Relance l'upload si le fichier de l'index a été annulé avec abort
 */
$webf('ajaxupload', {
    options: {
        action:             typeof window !== "undefined" ? window.location.href : '',
        key:                'upload',
        keyPath:            'path',
        separateUploads:    true,
        params:             {},
        multiple:           true,
        dropareas:          false,
        droparea:           false,
        allowedExtensions:  '*',
        sizeLimit:          0,
        debug:              false,
        forceIframe:        false,
        autosubmit:         true,
        progressEntryName:  'APC_UPLOAD_PROGRESS',
        progressKeyName:    'progress_key',
        urlProgress:        '',
        freqProgress:       1000,
        crossDomain:        false,
        allowOrigin:        '*',
        withCredentials:    false,
        concurrency:        0,
        renderError:        function(msg, type) { alert(msg); },
        onChange:           webf.noop,
        onBeforeUpload:     webf.noop,
        onBeforeUploadFile: webf.noop,
        onUploadStart:      webf.noop,
        onProgress:         webf.noop,
        onComplete:         webf.noop,
        onAbort:            webf.noop,
        onDrag:             webf.noop,
        onDragLeave:        webf.noop,
        onDrop:             webf.noop
    },

    editableOptions: {
        action: function(action) {
            this.options.action = action;
        },
        params: function(params) {
            this.options.params = webf.extend(true, this.options.params, params);
        },
        key: function(newKey) {
            this.options.key = newKey;
        }
    },

    instance: function() {
        return this
    },

    _create: function()
    {
        this.nbUploads = 0
        this._createUploadButton()
        this._createDropAreas()
        this.xhrs = []
        this.indexes = []
        this.abortedUploads = []
        this.indexedFiles = {}
        this.indexedPaths = {}

        if (!this.option('forceIframe') && this._xhr2Support()) {
            this.adapter = new XhrUploader(this)
        } else {
            this.adapter = new FormUploader(this)
        }
    },

    _createUploadButton: function()
    {
        this.input = this._createInput()
        const $form = $('<form>');
        $form.append(this.input);

        const label = $('<label />')
            .addClass('webf-label-input-file')
            .prop('for', this.input.attr('id'))

        this.e
            .addClass('webf-input-button')
            .wrapInner(label)
            .append($form)
    },

    _createDropAreas: function()
    {
        var self = this;
        this.dropareas = []

        if (this.option('dropareas')) {
            this.dropareas = this.option('dropareas')
        } else if (this.option('droparea')) {
            this.dropareas.push({
                element: this.option('droparea'),
                onDrag: webf.noop(),
                // onDrop: function() {
                //     self.option('autosubmit') && self.upload();
                //     return false;
                // }
            });
        }

        webf.each(this.dropareas, function(i, droparea) {
            var handlersDropareras = {
                'drag dragstart': function(ev) {
                    ev.preventDefault()
                    ev.stopPropagation()
                },
                dragenter: function(ev) {
                    ev.stopPropagation()

                    if ($(ev.target).closest(droparea.element).is($(droparea.element))) {
                        droparea.onDrag && self._call(droparea.onDrag, $(droparea.element), ev);
                        self._call(self.option('onDrag'), $(droparea.element), ev)
                    }
                },
                dragover: function(ev) {
                    // Empêche le comportement par défaut afin d'autoriser le drop
                    ev.preventDefault()
                    ev.stopPropagation()

                    if ($(ev.target).closest(droparea.element).is($(droparea.element))) {
                        $(droparea.element).parents('.dragover').removeClass('dragover')
                        $(droparea.element).addClass('dragover')

                        self.$dragover = $(droparea.element)
                    }
                },
                'dragleave dragend': function(ev) {
                    ev.stopPropagation()

                    if ($(ev.target).closest(droparea.element).is($(droparea.element))) {
                        $(droparea.element).removeClass('dragover')
                        self.$dragover && self.$dragover.removeClass('dragover')

                        droparea.onDragLeave && self._call(droparea.onDragLeave, droparea.element, ev)
                        self._call(self.option('onDragLeave'), $(droparea.element), ev);
                    }
                },
                drop: function(ev) {
                    ev.preventDefault()
                    ev.stopPropagation()

                    if ($(ev.target).closest(droparea.element).is($(droparea.element))) {
                        $(droparea.element).removeClass('dragover')

                        var cb = function(files, paths) {
                            self.newFiles = files.sort((f1, f2) => webf.sortMixAlphaDigits(f1.name, f2.name));
                            self.newPaths = paths.sort((p1, p2) => webf.sortMixAlphaDigits(p1, p2));
                            self.files = (self.files || []).concat(files)
                            self.paths = (self.paths || []).concat(paths)

                            if (!droparea.onDrop || self._call(droparea.onDrop, this, files, paths) !== false) {
                                self.option('autosubmit') && self.upload()
                            }

                            self._call(self.option('onDrop'), $(droparea.element), ev)
                        }.bind($(droparea.element));

                        var dt = ev.originalEvent.dataTransfer

                        if (dt.items && dt.items.length && "webkitGetAsEntry" in dt.items[0]) {
                            webkitEntriesApi(dt.items, cb)
                        } else if (dt.files) {
                            inputApi(dt, cb)
                        }
                    }
                }
            }

            self._on(window.document, droparea.element, handlersDropareras)
        });
    },

    _createInput: function()
    {
        const $input = $('<input>')
            .attr('id', webf.uniqid())
            .prop('type', 'file')
            .prop('name', this.option('key'))
            .prop('tabIndex', '-1')

        this.option('multiple') && $input.prop('multiple', 'multiple')

        if (this.option('directories')) {
            webf.each(['mozdirectory', 'allowdirs', 'webkitdirectory'], (i, prop) => {
                $input.prop(prop, true)
            })
        }

        this._on($input, {
            change: (ev) => {
                this._inputChange(ev.currentTarget, ev)
            }
        });

        return $input;
    },

    _inputChange: function(input, ev)
    {
        this._call(this.option('onChange'), input, ev)

        const dt = input;

        const cb = (files, paths) => {
            this.newFiles = files.sort((f1, f2) => webf.sortMixAlphaDigits(f1.name, f2.name));
            this.newPaths = paths.sort((p1, p2) => webf.sortMixAlphaDigits(p1, p2));
            this.files = (this.files || []).concat(files)
            this.paths = (this.paths || []).concat(paths)

            this.option('autosubmit') && this.upload()

            const form = this.input.closest('form');
            form.get(0).reset();
        }

        if (dt.items && dt.items.length && "webkitGetAsEntry" in dt.items[0]) {
            webkitEntriesApi(dt.items, cb)
        } else if (dt.files) {
            inputApi(dt, cb)
        }
    },

    _isValid: function()
    {
        const files = this.newFiles
        let filename, filesize, self = this

        var validate = function(fileName, fileSize) {
            const fileExt = fileName.substringIndex('.', -1).toLowerCase()
            let allowedExts = self.option('allowedExtensions')

            if (webf.isString(allowedExts)) {
                allowedExts = allowedExts.split(' ')
            }

            if (!webf.inArray('*', allowedExts) && !webf.inArray(fileExt, allowedExts)) {
                self._error('invExtension', fileName, allowedExts.join(', '))
                return false
            }

            if (fileSize !== null) {
                if (fileSize === 0) {
                    self._error('emptyFile', fileName)
                    return false
                }

                if (self.option('sizeLimit') && fileSize > self.option('sizeLimit')) {
                    self._error('invSize', fileName, webf.formatSize(fileSize), webf.formatSize(self.option('sizeLimit')))
                    return false
                }
            }

            return true
        };

        if (files && (webf.isObject(files) || webf.isArray(files)) && files.length) {
            for (var i=0; i<files.length; i++) {
                var file = files[i];
                filename = file.fileName ? file.fileName : file.name;
                filesize = file.fileSize ? file.fileSize : file.size;

                if (!validate(filename, filesize)) {
                    return false;
                }
            }
        } else {
            filename = this.input.val().replace(/.*(\/|\\)/, '');

            if (!validate(filename, null)) {
                return false;
            }
        }

        return true;
    },

    _error: function(msg)
    {
        this.option('renderError')(this._.apply(this, [msg].concat(Array.prototype.slice.call(arguments,1))), msg);
    },

    _xhr2Support: function()
    {
        return !webf.isUndefined($("<input type='file'/>")[0].files) &&
            !webf.isUndefined((new XMLHttpRequest()).upload);
    },

    upload: function(index)
    {
        this._isValid() && this.adapter.upload(index)
    },

    abort: function(index)
    {
        if (webf.isUndefined(index)) {
            return webf.each(this.indexes, (i, indexFile) => {
                this.abort(indexFile)
            })
        }

        if (!webf.isUndefined(this.xhrs[index])) {
            return this.xhrs[index].abort()
        } else if (webf.inArray(index, this.indexes)) {
            !webf.inArray(index, this.abortedUploads) && this.abortedUploads.push(index)
            return this._call(this.option('onAbort'), index, this.indexedFiles[index], this.indexedPaths[index])
        }

        throw `unknown ${index}`
    },

    retry: function(index)
    {
        if (!webf.isUndefined(this.xhrs[index])) {
            this.upload(index)
        } else if (webf.inArray(index, this.abortedUploads)) {
            this.abortedUploads.splice(this.abortedUploads.indexOf(index), 1)
        } else if (webf.inArray(index, this.indexes)) {
            this.upload(index)
        }
    },

    destroy: function()
    {
    }
});

function webkitEntriesApi(items, callback) {
    const files = [], paths = [], rootPromises = [];

    function readEntries(entry, reader, oldEntries, cb) {
        var dirReader = reader || entry.createReader();

        dirReader.readEntries(function(entries) {
            var newEntries = oldEntries ? oldEntries.concat(entries) : entries;

            if (entries.length) {
                setTimeout(readEntries.bind(null, entry, dirReader, newEntries, cb), 0);
            } else {
                cb(newEntries);
            }
        });
    }

    function readDirectory(entry, path, resolve) {
        if (!path) path = entry.name;

        readEntries(entry, 0, 0, function(entries) {
            var promises = [];
            webf.each(entries, function(i, entry) {
                promises.push(new Promise(function(resolve) {
                    if (entry.isFile) {
                        entry.file(function(file) {
                            var p = path + "/" + file.name;
                            paths.push(p);
                            files.push(file);
                            resolve();
                        }, resolve.bind());
                    } else {
                        readDirectory(entry, path + "/" + entry.name, resolve);
                    }
                }));
            });

            Promise.all(promises).then(resolve.bind());
        });
    }

    webf.each(items, function(i, entry) {
        entry = entry.webkitGetAsEntry();

        if (entry) {
            rootPromises.push(new Promise(function(resolve) {
                if (entry.isFile) {
                    entry.file(function(file) {
                        paths.push(file.name)
                        files.push(file);
                        resolve();
                    }, resolve.bind());
                } else if (entry.isDirectory) {
                    readDirectory(entry, null, resolve);
                }
            }));
        }
    });

    Promise.all(rootPromises).then(callback.bind(null, files, paths));
}

function inputApi(input, callback)
{
    var files = [], paths = [];

    webf.each(input.files, function(i, file) {
        files.push(file);
        paths.push(file.webkitRelativePath || file.name);
    });

    callback(files, paths);
}


function Uploader(plugin)
{
    if (plugin) {
        this.plugin = plugin;
        this.options = plugin.options;
    }
}

function XhrUploader()
{
    Uploader.apply(this, arguments);

    this.ajaxOptions = {
        processData: false,
        cache: false,
        contentType: false,
        type: 'post',
        dataType: 'json'
    };

    if (this.plugin.option('crossDomain')) {
        this.ajaxOptions.headers = {
            'access-control-allow-origin': this.plugin.option('allowOrigin') || '*'
        };
    }
}

XhrUploader.prototype = new Uploader();
XhrUploader.prototype.constructor = Uploader;

XhrUploader.prototype.upload = async function(index)
{
    var self = this,
        files = this.plugin.newFiles.slice(),
        paths = this.plugin.newPaths.slice(),
        first = this.plugin.nbUploads

    if (!webf.isUndefined(index)) {
        first = parseInt(index.split('-')[1])
        files = this.plugin.files.slice(first, first + 1)
        paths = this.plugin.paths.slice(first, first + 1)
    } else {
        this.plugin.nbUploads += files.length
    }

    function send(formdata, i, resolve = null, reject = null, file = null, path = null, action = null) {
        var xhr = $.ajaxSettings.xhr();

        self.plugin.xhrs[i] = xhr

        var ajax = webf.extend(true, {
            beforeSend: function() {
                return self.plugin._call(self.options.onUploadStart, i, file, path);
            },
            xhrFields: {
                withCredentials: !!self.plugin.option('withCredentials')
            },
            data: formdata,
            xhr: function() {
                xhr.upload.onprogress = function(event) {
                    if (event.lengthComputable) {
                        self.plugin._call(self.options.onProgress, i, event.loaded || event.position, event.total);
                    }
                }

                return xhr;
            },
        }, self.ajaxOptions, {url: action && webf.isString(action) ? action : self.plugin.option('action')});

        $.ajax(ajax)
            .done((datas) => {
                self.plugin._call(self.options.onComplete, i, datas, file, path);
                resolve && resolve()
            })
            .fail(() => {
                self.plugin._call(self.options.onAbort, i, file, path);
                resolve && resolve()
            })
    }

    if (await Promise.resolve(this.plugin._callAsync(this.options.onBeforeUpload, files, paths)) !== false) {
        if (self.options.separateUploads) {
            var queue = new PQueue({
                concurrency: self.options.concurrency || 10
            });

            for (let i=0; i<files.length; i++) {
                (async function (i, file, path) {
                    self.plugin.indexedFiles[i] = file
                    self.plugin.indexedPaths[i] = path

                    self.plugin.indexes.push(i)

                    const action = await Promise.resolve(self.plugin._callAsync(self.options.onBeforeUploadFile, i, file, path));

                    if (action !== false) {
                        queue.add(() => {
                            if (webf.inArray(i, self.plugin.abortedUploads)) {
                                self.plugin.abortedUploads.splice(self.plugin.abortedUploads.indexOf(i), 1)
                                return Promise.resolve()
                            }

                            return new Promise(function (resolve, reject) {
                                var formdata = new FormData();
                                formdata.append(self.options.key, file, path);
                                self.options.keyPath && formdata.append(self.options.keyPath, path);

                                webf.each(self.options.params, function (key, value) {
                                    formdata.append(key, value);
                                });

                                send(formdata, i, resolve, reject, file, path, action);
                            })
                        })
                    }
                })(this.plugin.hash + '-' + first++, files[i], paths[i]);
            }
        } else {
            var formdata = new FormData();

            webf.each(files, function(i, file) {
                formdata.append(self.options.key + '[]', file, paths[i]);
                self.options.keyPath && formdata.append(self.options.keyPath + '[]', paths[i]);
            });

            webf.each(self.options.params, function(key, value) {
                formdata.append(key, value);
            });

            send(formdata, 0);
        }
    }
};

function FormUploader()
{
    Uploader.apply(this, arguments);
}

FormUploader.prototype = new Uploader();
FormUploader.prototype.constructor = Uploader;

FormUploader.prototype.upload = function($input, droppedFiles)
{
    var self = this,
        input = $input[0],
        form = document.createElement('form');

    form.setAttribute('action', this.option('action'));
    form.setAttribute('method', 'post');
    form.setAttribute('enctype', 'multipart/form-data');
    form.style.display = 'none';

    form.appendChild(input);
    document.body.appendChild(form);

    (function(i) {
        $(form).webfAjaxform({
            debug: self.options.debug,
            forceIframe: self.options.forceIframe,
            progressKeyName: self.options.progressKeyName,
            beforeSubmit: function() {
                if (self.plugin._call(self.options.onBeforeUpload, input.files) === false) {
                    return false;
                }

                self.plugin._call(self.options.onUploadStart, i);
                self.resetInput();
            },
            dataType: this.options.dataType,
            progressEntryName: this.options.progressEntryName,
            urlProgress: this.options.urlProgress,
            freqProgress: this.options.freqProgress,
            onProgress: function(event, loaded, total) {
                self.plugin._call(self.options.onProgress, i, loaded, total);
            },
            success: function(datas) {
                self.plugin._call(self.options.onComplete, i, datas);
            }
        }).submit();
    })(this.plugins.nbUploads++);
};

FormUploader.prototype.resetInput = function()
{
    this.plugin.input = this.plugin._createInput();
    this.plugin.button.append(this.plugin.input);
};

