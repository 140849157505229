import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - buttons
 *  - minScroll
 *  - position
 *
 * Méthodes publiques :
 */
$webf('stickybutton', {
    options: {
        buttons:     [{
            label:      'Button 1',
            type:       'submit',
            class:      'webf-button webf-button-primary',
            onClick:    'trigger'
        }],
        minScroll:  0,
        position:   'bottom'
    },

    _create: function()
    {
        this._draw();
        this._bindEvents();
    },

    _draw: function()
    {
        var self = this;
        var buttons = this.option('buttons');
        var $stickyBar = $('<div>').addClass('webf-stickybar');

        if (webf.isDomElement(this.e[0])) {
            if (!this.e[0].tagName) {
                throw new Error('selector must be a form element');
            }
        }

        webf.each(buttons, function(i, button) {
            var $button = $('<button>')
                .prop('type', button.type)
                .text(button.label)
                .addClass(['webf-sticky-button'].concat([button.class].join(' ')))
                .on('click', function() {
                    if (button.onClick == 'trigger') {
                        return self.e.trigger('submit');
                    }

                    if (webf.isFunction(button.onClick)) {
                        self._call(button.onClick, $button[0]);
                    }
                });

            $stickyBar.append($button);
        }, this);

        this.$stickyBar = $stickyBar;

        $('body').append(this.$stickyBar);
    },

    _bindEvents: function()
    {
        var self = this;
        var minScroll = this.option('minScroll');

        $(window).on('scroll webf.init-stickybar', function() {
            if (window.scrollY >= minScroll) {
                self.$stickyBar.addClass('visible');
            } else {
                self.$stickyBar.removeClass('visible');
            }
        }).trigger('webf-init-stickybar');
    },

    _destroy: function()
    {
    }
});

