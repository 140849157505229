import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.useragenda = {
        'pick a date': 'Choisir une date',

        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',

        mon: 'Lun',
        tue: 'Mar',
        wed: 'Mer',
        thu: 'Jeu',
        fri: 'Ven',
        sat: 'Sam',
        sun: 'Dim',

        mo: 'Lu',
        tu: 'Ma',
        we: 'Me',
        th: 'Je',
        fr: 'Ve',
        sa: 'Sa',
        su: 'Di',

        january: 'Janvier',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Août',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Décembre',

        jan: 'Janv.',
        feb: 'Fév.',
        mar: 'Mars',
        apr: 'Avr.',
        'may.': 'Mai',
        jun: 'Juin',
        'jul.': 'Juil.',
        aug: 'Août',
        sept: 'Sept.',
        oct: 'Oct.',
        nov: 'Nov.',
        dec: 'Déc.',

        today: function() { return "Aujourd'hui"; },
        prev_day: 'Jour précédent',
        next_day: 'Jour suivant',
        prev_week: 'Semaine précédente',
        next_week: 'Semaine suivante',
        prev_month: 'Mois précédent',
        next_month: 'Mois suivant',
        week: 'Semaine',
        month: 'Mois',
        day: 'Jour',
        am: 'h',
        pm: 'h',

        new_event: 'Nouvel événement',

        date_title_month: 'MMMM yyyy',
        date_title_week: 'd MMMM yyyy',
        date_title_day: 'EEEE d MMMM yyyy',
        date_col_day: '[<div class="nameWeekDay">]E[</div>][<div class="numMonthDay">]d[</div>]'
    };
})(webf);

