import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.datagrid = {
        search: 'Search',
        refresh: 'Refresh',
        allColumns: 'All columns',
        abbrColumn: '#',
        today: 'Today',
        noDate: 'No date'
    };
})(webf);

