import webf from '../../utils/core'
import '../checkbox'
import $ from 'jquery'

webf.SelectMDCheckable = webf.SelectCheckable.extend({

    draw()
    {
        const wSelect = webf.round(parseFloat(webf.getStyle(this.e[0], "width")));
        const $selectWrapper = this.e.wrap($('<div>')
            .addClass(['webf-select-md-text'].concat(this.plugin.option('selectClass').split(' ')).join(' '))
        ).parent();
        const inputMdClass = this.plugin.option('inputMdClass');
        let wrapperClasses = this.plugin.option('wrapperClass');

        if (webf.inArray(inputMdClass, ['filled', 'outlined'])) {
            wrapperClasses += ` ${inputMdClass}`;
        }

        const $wrapperDropdown = $selectWrapper.wrap($(`<div class="webf-md-select ${wrapperClasses}">`)
            .css('width', this.plugin.option('width') == 'auto' ? wSelect : this.plugin.option('width'))
        ).parent();

        let $label = $(`label[for="${this.e.attr('id')}"]`);

        if (!$label[0]) {
            const placeholder = this.plugin.option('placeholder')
            $label = $(`<label>${placeholder}</label>`);
        }

        const hasErrorClass = this.e.hasClass('has-error');
        const isValidClass = this.e.hasClass('is-valid');

        $selectWrapper
            .append(`
                <div class="webf-md-text with-right-icon ${inputMdClass} ${hasErrorClass ? 'has-error' : ''} ${isValidClass ? 'is-valid' : ''}">
                    <div class="wrapper">
                        <input type="text" value="${this.val().join(', ')}" readonly>
                    </div>
                    <div class="icon right-icon">
                        <i class="fas fa-caret-down"></i>
                    </div>
                </div>
            `);

        $selectWrapper.find('.wrapper').prepend($label);
        $selectWrapper.find('.webf-md-text input[type="text"]').trigger('webf-init-md-text');

        this.$select = $selectWrapper;

        const $options = this._getOptions(),
            $menu = $('<div>')
                .addClass('webf-select-drop checkable')
                .append($options);

        const optCheckAll = this.plugin.option('checkAllItem');
        if (optCheckAll) {
            this.$optionCheckAll = this._createOptionItem(optCheckAll.value || '', this.plugin._(optCheckAll.label));
            this.$optionCheckAll.data('all', true);

            $options.prepend(this.$optionCheckAll);
        }

        if (this.plugin.option('filter')) {
            $menu.prepend(`
                <div class='filter'>
                    <label>
                        <input class='search' type='text' autocomplete='off'>
                        <i class='icon-search fas fa-search'></i>
                    </label>
                </div>
            `);
        }

        $wrapperDropdown.append($menu);
        this.$wrapperDropdown = $wrapperDropdown;

        this.menu = $wrapperDropdown.webfDropdown({
            handle: $selectWrapper,
            menu: $menu,
            closeOnClickMenu: false,
            menuClass: this.plugin.option('dropClass'),
            onFirstOpen: ($e) => {
                const $menu = $e.webfDropdown('menu');
                const $input = $menu.find("input[type='text'].search");

                if ($input.length) {
                    this.input = $input;

                    this.plugin._on($input, {
                        keydown: (ev) => {
                            if (!ev.ctrlKey && !ev.metaKey && !ev.altKey) {
                                switch(ev.keyCode || ev.which) {
                                    case 38: case 40: case 9: case 13:
                                        break;

                                    case 27:
                                        this.close();
                                        break;

                                    default:
                                        this._searchDelay(ev);
                                }
                            }
                        },
                        'click mousedown': (ev) => {
                            ev.stopPropagation();
                        }
                    });
                }

                $("input[type='checkbox']", $menu).webfCheckbox({
                    style: 'checkmark'
                });

                this.plugin._call(this.plugin.option('onFirstOpen'));
            },
            onOpen: () => {
                this.$select.addClass('open');
                if (this.input) {
                    this.input.trigger('focus');
                }

                this.$wrapperDropdown.find('.webf-md-text').addClass('is-focused');

                this.plugin._call(this.plugin.option('onOpen'));
            },
            onClose: () => {
                this.$select.removeClass('open');
                // this.index = null;
                if (this.input) {
                    this.input.val('');
                }
                this._search('');
                this.showItem();
                this.blurItem();
                this.$select.find('.select').removeClass('open');
                this.$wrapperDropdown.find('.webf-md-text').removeClass('is-focused');

                this.plugin._call(this.plugin.option('onClose'));
            }
        }).webfDropdown('menu');

        this._resetNavigation();

        this.val(this.val());
        this.e.hide();
    },

    _getOptions()
    {
        const $options = $("<ul>").addClass('options webf-md-list');

        this.e.find('optgroup, option').each((i, option) => {
            const $opt = $(option);
            const tag = $opt[0].tagName;

            if (tag == 'OPTGROUP') {
                $options.append(
                    this._createGroupItem($opt.prop('label'))
                );
            } else if (tag == 'OPTION') {
                $options.append(
                    this._createOptionItem($opt.val(), $opt.text(), !$opt.prop('disabled'), $opt.prop('selected'), $opt.parent()[0].tagName == 'OPTGROUP')
                );
            }
        });

        return $options;
    },

    display: function()
    {
        let texts = [];

        const $inputText = this.$select.find('input[type="text"]');

        /*if (!this.val().length && this.plugin.placeholder.length) {
            texts = [this.plugin.placeholder];
        } else */if (this.plugin.option('checkAllItem') && this.isAllChecked()) {
            texts = [this.$optionCheckAll.find('label').text()];
            this.select(this.$optionCheckAll);
        } else {
            webf.each(this.val(), function(i, val) {
                texts.push(this.findByData('value', val).children('label').text());
            }, this.menu.find('.webf-list-item-option'));

            this.$optionCheckAll && this.deselect(this.$optionCheckAll);
        }

        $inputText.val(texts.join(', '));
        $inputText.trigger('webf-init-md-text');
    },

    destroy()
    {
        if (this.$select) {
            var $select = this.$select.find('.select');
            this.e.unwrap().unwrap();
            $select.remove();

            if (this.menu) {
                this.menu.prev('.webf-md-text').remove()
                this.menu.remove();
            }

            if (this.$wrapperDropdown) {
                this.$wrapperDropdown.webfDropdown('destroy');
                this.$wrapperDropdown.remove();
            }
        } else if (this.menu) {
            var $options = this.menu.find('.options');
            this.e.unwrap();
            $options.remove();
        }

        this.e.show();
    }
});
