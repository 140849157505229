import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - of
 *  - my
 *  - at
 *  - offset
 *  - collision
 *
 * Méthodes publiques :
 */
$webf('position', {
    options: {
        of:         typeof window !== "undefined" ? window : null,
        my:         'left top',
        at:         'left bottom',
        offset:     { top: 0, left: 0 },
        collision:  'none none'
    },

    _create: function()
    {
        // this.destroy();

        var of = this.option('of'),
            $of = webf.isEvent(of) ? of : $(of),
            posOf = $webf.getOffset($of);

        this.$of = $of;
        this.posOf = posOf;

        this.dimensionsMy = $webf.getDimensions(this.e);
        this.dimensionsOf = $webf.getDimensions($of);
        this.dimensionsWin = $webf.getDimensions($(window));
        this.my = this.option('my').split(' ').slice(0, 2);
        this.at = this.option('at').split(' ').slice(0, 2);
        this.collision = this.option('collision').split(' ').slice(0, 2);
        this.coords = {
            top: posOf.top,
            left: posOf.left
        };

        this._setPosition();
    },

    _setPosition: function()
    {
        var t = this;

        t.coords = {
            top: t.posOf.top,
            left: t.posOf.left
        };

        if (t.at[1] == 'center') {
            t.coords.top += t.dimensionsOf.height / 2;
        } else if (t.at[1] == 'bottom') {
            t.coords.top += t.dimensionsOf.height;
        }

        if (t.at[0] == 'center') {
            t.coords.left += t.dimensionsOf.width / 2;
        } else if (t.at[0] == 'right') {
            t.coords.left += t.dimensionsOf.width;
        }

        if (t.my[1] == 'center') {
            t.coords.top -= t.dimensionsMy.height / 2;
        } else if (t.my[1] == 'bottom') {
            t.coords.top -= t.dimensionsMy.height;
        }

        if (t.my[0] == 'center') {
            t.coords.left -= t.dimensionsMy.width / 2;
        } else if (t.my[0] == 'right') {
            t.coords.left -= t.dimensionsMy.width;
        }

        t.coords.top += t.option('offset').top;
        t.coords.left += t.option('offset').left;

        if (/^flip/.test(t.collision[0])) {
            if (t.coords.left < 0) {
                if (t.my[0] == 'right') {
                    t.my[0] = 'left';
                }

                if (t.at[0] == 'right') {
                    t.at[0] = 'left';
                }
            } else if(t.coords.left + t.dimensionsMy.width > t.dimensionsWin.width) {
                if (t.my[0] == 'left') {
                    t.my[0] = 'right';
                }

                if (t.at[0] == 'left') {
                    t.at[0] = 'right';
                }
            }

            t.collision = [t.collision[0] == 'flipfit' ? 'fit' : 'none', t.collision[1]];

            t._setPosition();
        }

        if (t.collision[0] == 'fit') {
            if (t.coords.left + t.dimensionsMy.width > t.dimensionsWin.width + $(window).scrollLeft()) {
                t.coords.left = t.dimensionsWin.width + $(window).scrollLeft() - t.dimensionsMy.width;
            }

            if (t.coords.left < 0) {
                t.coords.left = 0;
            }
        }

        if (/^flip/.test(t.collision[1])) {
            if (t.coords.top + t.dimensionsMy.height > t.dimensionsWin.height + $(window).scrollTop()) {
                if (t.my[1] == 'top') {
                    t.my[1] = 'bottom';
                }

                if (t.at[1] == 'top') {
                    t.at[1] = 'bottom';
                }
            } else if (t.coords.top < 0) {
                if (t.my[1] == 'bottom') {
                    t.my[1] = 'top';
                }

                if (t.at[1] == 'bottom') {
                    t.at[1] = 'top';
                }
            }

            t.collision = [t.collision[0], t.collision[1] == 'flipfit' ? 'fit' : 'none'];

            this._setPosition();
        }

        if (t.collision[1] == 'fit') {
            if (t.coords.top + t.dimensionsMy.height > t.dimensionsWin.height + $(window).scrollTop()) {
                t.coords.top = t.dimensionsWin.height + $(window).scrollTop() - t.dimensionsMy.height;
            }

            if (t.coords.top < 0) {
                t.coords.top = 0;
            }
        }

        t.e.offset(t.coords);

        // Handle Scrollbars
        if (webf.isWindow(t.$of[0])) {
            t.coords.top -= webf.getScrollbarWidth();
            t.coords.left -= webf.getScrollbarWidth();

            t.e.offset(t.coords);

            if (!$(window).hasScrollBarHorizontal()) {
                t.coords.top += webf.getScrollbarWidth();

                t.e.offset(t.coords);
            }

            if (!$(window).hasScrollBarVertical()) {
                t.coords.left += webf.getScrollbarWidth();

                t.e.offset(t.coords);
            }
        } else {
            var newPosOf = $webf.getOffset(t.$of);
            if (t.posOf.left != newPosOf.left || t.posOf.top != newPosOf.top) {
                t.e.offset({
                    left: t.coords.left + (newPosOf.left - t.posOf.left),
                    top: t.coords.top + (newPosOf.top - t.posOf.top)
                });
            }

            if (/fit/.test(t.collision[0])) {
                if (t.e.offset().left + t.dimensionsMy.width >= $(window).width() + $(window).scrollLeft()) {
                    t.coords.left = $(window).width() + $(window).scrollLeft() - t.dimensionsMy.width;
                    t.e.offset(t.coords);
                }
            }

            if (/fit/.test(t.collision[1])) {
                if (t.e.offset().top + t.dimensionsMy.height >= $(window).height() + $(window).scrollTop()) {
                    t.coords.top = $(window).height() + $(window).scrollTop() - t.dimensionsMy.height;
                    t.e.offset(t.coords);
                }
            }
        }
    }
});

