import webf from '../utils/core'
import '../i18n/confirm'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * Options:
 *  - dialogClass:      (string)        Classes CSS supplémentaires
 *  - textConfirm:      (string)        Texte de confirmation
 *  - confirmButton:    (object button) Bouton de confirmation
 *  - abortButton:      (object button) Bouton d'annulation
 *  - onOpen:           (function())    Fonction appelée lors de chaque ouverture
 *  - onClose:          (function())    Fonction appelée lors de la fermeture
 *
 * Méthodes publiques:
 *  - widget: Retourne l'élément racine de la boite de dialogue
 *  - open: Ouvre la demande de confirmation
 *  - close: Ferme la demande de confirmation
 *
 * Structures:
 * button: {
 *  - className:    (string)    Classes CSS du bouton
 *  - label:        (string)    Label du bouton
 *  - click:        (function)  Fonction appelée lors du click
 * }
 */
$webf('confirm', {
    options: {
        dialogClass:    '',
        textConfirm:    null,
        confirmButton:  {
            className: 'primary',
            label:      null,
            click:      null,
        },
        abortButton:    {
            className: 'secondary',
            label:      null,
            click:      null,
        },
        onAbort:        webf.noop,
        onOpen:         webf.noop,
        onClose:        webf.noop
    },

    _create: function()
    {
        this.$confirmDialog = this.open();

        this.widget()
            .on('animationend', (ev) => {
                const $widget = this.widget();

                if ($widget) {
                    if ($widget.hasClass('confirm-closing')) {
                        $widget.removeClass('confirm-opening');
                        this.$confirmDialog.webfDialog('destroy');
                    } else if ($widget.hasClass('confirm-opening')) {
                        $widget.removeClass('confirm-closing');
                        $widget.removeClass('confirm-opening');
                    }
                }
            });
    },

    open: function()
    {
        const confirmText = this.option('textConfirm')
            || this.e[0].getAttribute('data-text-confirm')
            || this.e.html()
            || this._('textConfirm');

        const confirmButton = this.option('confirmButton');
        const abortButton = this.option('abortButton');

        return $(`<div>${confirmText}</div>`).webfDialog({
            dialogClass: ['webf-confirm confirm-opening'].concat(this.option('dialogClass').split(' ')).join(' '),
            box: {
                boxClass: 'webf-box-confirm',
                header: false,
                footer: {
                    closeButton: false,
                    buttons: {
                        ok: {
                            cls: confirmButton.className,
                            label: confirmButton.label || this.translate('yes'),
                            click: (ev, $e) => {
                                ev.stopPropagation();
                                this._call(confirmButton.click || webf.noop);
                                this.close();
                            }
                        },
                        cancel: {
                            cls: abortButton.className,
                            label: abortButton.label || this.translate('cancel'),
                            click: (ev, $e) => {
                                ev.stopPropagation();
                                this._call(abortButton.click || webf.noop);
                                this.close();
                            }
                        }
                    }
                }
            },
            onOpen: ($e) => {
                this._call(this.option('onOpen'));
            },
            onClose: ($e) => {
                this._call(this.option('onClose'));
            },
            modal: true
        });
    },

    close: function()
    {
        this.widget().addClass('confirm-closing');
        this._call(this.option('onClose'));
    },

    widget: function()
    {
        return this.$confirmDialog.webfDialog('widget');
    }
});
