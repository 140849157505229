import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Redo = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-redo'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('redo');
    },
    types: 'action',
    text_tooltip: 'redo',
    shortcut: {
        Ctrl: true,
        key: 'Y'
    }
});
