import '../utils/mousewheel'
import './imageloader'
import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - wheelSpeed:               (int)           Vitesse de défilement.
 *  - stopScrollingOnEnd:       (boolean)       Stoppe le scroll lorsque l'élément est au bout.
 *  - enableScrollX:            (boolean)       Active le scroll horizontal
 *  - enableScrollY:            (boolean)       Active le scroll vertical
 *  - onInit:                   (function())    Fonction appelée à l'initilisation du plugin
 *  - onUpdate:                 (function())    Fonction appelée à lorsque les scrollbars sont mises à jour
 *
 * Méthodes publiques:
 *  - scrollDeltaY(deltaY)  Scrolle de val px vers le bas.
 *  - scrollDeltaX(deltaX)  Scrolle de val px vers la droite.
 *  - scrollTop(val)        Fixe le scroll à val px du haut
 *  - scrollLeft(val)       Fixe le scroll à val px de la gauche
 *  - scrollXTo(val)        Alias de scrollLeft.
 *  - scrollYTo(val)        Alias de scrollTop.
 *  - scrollTo({x:int, y:int})
 *                          Appelle scrollLeft et scrollTop avec les valeurs des clés x et y.
 *  - update()              Met à jour dans le cas où les dimensions de l'élément ont évolué.
 *  - container()           Retourne le container du plugin.
 */
$webf("scrollbar", {
    options: {
        wheelSpeed:         80,
        stopScrollingOnEnd: false,
        enableScrollX:      true,
        enableScrollY:      true,
        onInit:             webf.noop,
        onUpdate:           webf.noop
    },

    _create: function()
    {
        this.boxWidth = null;
        this.boxHeight = null;
        this.containerWidth = null;
        this.containerHeight = null;
        this.draggingX = false;
        this.draggingY = false;
        this.startPointX = null;
        this.startPointY = null;

        this.touchDrag = false;
        this.startTouchPointX = null;
        this.startTouchPointY = null;
        this.startScrollTop = 0;
        this.startScrollLeft = 0;

        var self = this;

        this.e.addClass('webf-scrollbox')
            .wrapInner("" +
                "<div class='webf-scrollbox-wrapper'>" +
                    "<div class='webf-scrollbox-container'></div>" +
                "</div>" +
            "")
        ;

        if (this.option('enableScrollX')) {
            $('<div>').addClass('webf-scrollbar x')
                .append($('<div>').addClass('handler'))
                .appendTo(this.e.find('.webf-scrollbox-wrapper').first());
        }

        if (this.option('enableScrollY')) {
            $('<div>').addClass('webf-scrollbar y')
                .append($('<div>').addClass('handler'))
                .appendTo(this.e.find('.webf-scrollbox-wrapper').first());
        }

        this.e.webfImageloader({
            onAllLoaded: function() {
                self._initScrollBars();

                self._call(self.option('onInit'));
            }
        });

        this._bindEvents();
    },

    _initScrollBars: function()
    {
        this.option('enableScrollX') && this._initScrollBarX();
        this.option('enableScrollY') && this._initScrollBarY();

        this._call(this.option('onUpdate'));
    },

    _initScrollBarX: function()
    {
        this.containerWidth = this.container().width();
        this.boxWidth = this.e.width();

        if (this.boxWidth < this.containerWidth) {
            var $handler = this.e.find('.webf-scrollbar.x .handler').first();

            $handler.width(Math.pow(this.boxWidth, 2) / this.containerWidth);
            $handler.css('left', this.scrollLeft() * (this.boxWidth / this.containerWidth));

            if ($handler.position().left + $handler.width() >= this.boxWidth) {
                this.scrollLeft('right');
            }

            this.e.find('.webf-scrollbar.x').first().removeClass('webf-invisible');
        } else {
            this.scrollLeft('left');
            this.e.find('.webf-scrollbar.x').first().addClass('webf-invisible');
        }
    },

    _initScrollBarY: function()
    {
        this.containerHeight = this.container().height();
        this.boxHeight = this.e.height();

        if (this.boxHeight < this.containerHeight) {
            var $handler = this.e.find('.webf-scrollbar.y .handler').first();

            $handler.height(Math.pow(this.boxHeight, 2) / this.containerHeight);
            $handler.css('top', this.scrollTop() * (this.boxHeight / this.containerHeight));

            if ($handler.position().top + $handler.height() >= this.boxHeight) {
                this.scrollTop('bottom');
            }

            this.e.find('.webf-scrollbar.y').first().removeClass('webf-invisible');
        } else {
            this.scrollTop('top');
            this.e.find('.webf-scrollbar.y').first().addClass('webf-invisible');
        }
    },

    scrollDeltaX: function(deltaX)
    {
        var $handler = this.e.find('.webf-scrollbar.x .handler').first(),
            $container = this.container(),
            position = $container.position().left,
            max = this.containerWidth - this.boxWidth,
            newPosContainer = webf.between(deltaX - position, 0, max);

        $container.css('left', -newPosContainer);

        if (webf.inArray(newPosContainer, [0, max])) {
            if (newPosContainer == 0) {
                $handler.css('left', 0);
            } else {
                $handler.css('left', this.boxWidth - $handler.width());
            }

            return false;
        } else {
            $handler.css('left', $handler.position().left + deltaX / (this.containerWidth / this.boxWidth));

            return true;
        }
    },

    scrollDeltaY: function(deltaY)
    {
        var $handler = this.e.find('.webf-scrollbar.y .handler').first(),
            $container = this.container(),
            position = $container.position().top,
            max = this.containerHeight - this.boxHeight,
            newPosContainer = webf.between(deltaY - position, 0, max);

        $container.css('top', -newPosContainer);

        // Je sais pas si c'est un bug du navigateur mais parfois ces 2 valeurs sont nulles
        if (this.containerHeight === null || this.boxHeight === null) {
            return true;
        }

        if (webf.inArray(newPosContainer, [0, max])) {
            if (newPosContainer == 0) {
                $handler.css('top', 0);
            } else {
                $handler.css('top', this.boxHeight - $handler.height());
            }

            return false;
        } else {
            $handler.css('top', $handler.position().top + deltaY / (this.containerHeight / this.boxHeight));

            return true;
        }
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(window, {
            resize: function() {
                self.update();
            },
            'mouseup blur': function() {
                $webf.enableSelection();
            }
        });

        this._on(this.e, '> .webf-scrollbar', {
            click: function(ev) {
                var mp = $webf.getMousePos(ev, $(this)),
                    axis = $(ev.target).hasClass('y') ? 'y' : 'x',
                    $handler = $(this).children('.handler'),
                    ratio;

                if (axis == 'x') {
                    ratio = self.containerWidth / self.boxWidth;
                    self.scrollLeft((mp.x - $handler.width()/2) * ratio);
                } else {
                    ratio = self.containerHeight / self.boxHeight;
                    self.scrollTop((mp.y - $handler.height()/2) * ratio);
                }
            }
        })._on(this.e, '> .webf-scrollbox-wrapper .webf-scrollbar .handler', {
            'mousedown touchstart': startDrag,
            click: function(ev) {
                ev.stopPropagation();
            }
        })._on(this.e, '> .webf-scrollbox-wrapper', {
            touchstart: startDrag
        });

        if (webf.isTouchDevice()) {
            this._on(document, {
                touchmove: function(ev) {
                    if (self.touchDrag) {
                        var mp = $webf.getMousePos(ev, self.e.find('.webf-scrollbox-container').first());

                        self.scrollDeltaX(self.startScrollLeft + (self.startTouchPointX - mp.x));
                        self.scrollDeltaY(self.startScrollTop + (self.startTouchPointY - mp.y));
                    }
                }
            });

            this.e.children('.webf-scrollbox-wrapper')[0].addEventListener('touchmove', function(ev) {
                ev.preventDefault();
            }, {
                passive: false
            });
        } else {
            this._on(this.e, '> .webf-scrollbox-wrapper', {
                webfMouseWheel: webf.throttle(function(ev, delta, deltaX, deltaY) {
                    if (self.scrollDeltaX(deltaX * self.option('wheelSpeed'))) {
                        ev.preventDefault();
                        self._trigger($(this), 'show_xscrollbar');
                    }

                    if (self.scrollDeltaY(-deltaY * self.option('wheelSpeed'))) {
                        ev.preventDefault();
                        self._trigger($(this), 'show_yscrollbar');
                    }

                    if (
                        self.option('enableScrollX') && Math.abs(deltaX) > Math.abs(deltaY) ||
                        self.option('enableScrollY') && Math.abs(deltaY) > Math.abs(deltaX)
                    ) {
                        ev.stopPropagation();
                    }
                }, 30),
                'webfMouseWheel.stopScrollingOnEnd': function (ev) {
                    if (self.option('stopScrollingOnEnd')) {
                        ev.preventDefault();
                    }
                }
            });
        }

        this._on(document, {
            mousemove: function(ev) {
                var mp;
                if (self.draggingX) {
                    mp = $webf.getMousePos(ev, self.e.find('.webf-scrollbar.x').first());
                    self.scrollLeft((mp.x - self.startPointX) * (self.containerWidth/self.boxWidth));
                } else if (self.draggingY) {
                    mp = $webf.getMousePos(ev, self.e.find('.webf-scrollbar.y').first());
                    self.scrollTop((mp.y - self.startPointY) * (self.containerHeight/self.boxHeight));
                }
            },
            'mouseup touchend': function() {
                self.e.find('.webf-scrollbox-wrapper').first().removeClass('dragging dragging-x dragging-y');
                self.draggingX = false;
                self.draggingY = false;
                self.touchDrag = false;
            }
        });

        this._on(this.e, {
            webfMouseWheel: function(ev) {
                ev.preventDefault();
            }
        })

        function startDrag(ev) {
            ev.stopPropagation();
            $webf.disableSelection();

            var $handler = $(this),
                $wrapper = $handler.closest('.webf-scrollbox-wrapper').addClass('dragging');

            if ($handler.closest('.webf-scrollbar.x')[0]) {
                self.draggingX = true;
                self.startPointX = $webf.getMousePos(ev, $handler).x;
                self.startScrollLeft = self.scrollLeft();
                $wrapper.addClass('dragging-x');
            } else if ($handler.closest('.webf-scrollbar.y')[0]) {
                self.draggingY = true;
                self.startPointY = $webf.getMousePos(ev, $handler).y;
                self.startScrollTop = self.scrollTop();
                $wrapper.addClass('dragging-y');
            } else if (webf.isTouchDevice()) {
                self.touchDrag = true;
                self.startTouchPointX = $webf.getMousePos(ev, $wrapper).x;
                self.startTouchPointY = $webf.getMousePos(ev, $wrapper).y;
                self.startScrollLeft = self.scrollLeft();
                self.startScrollTop = self.scrollTop();
            }
        }
    },

    scrollLeft: function(val)
    {
        if (webf.isUndefined(val)) {
            return -this.container().position().left;
        }

        var $handler = this.e.find('.webf-scrollbar.x .handler').first(),
            $container = this.container(),
            max = this.containerWidth - this.boxWidth;

        if (val == 'left') {
            val = 0;
        } else if (val == 'right') {
            val = max;
        } else {
            val = webf.between(val, 0, max);
        }

        $container.css('left', -val);

        $handler.css('left', val * (this.boxWidth / this.containerWidth));
    },

    scrollTop: function(val)
    {
        if (webf.isUndefined(val)) {
            var value = parseInt(this.container().position().top);
            return value === 0 ? 0 : -value;
        }

        var $handler = this.e.find('.webf-scrollbar.y .handler').first(),
            $container = this.container(),
            max = this.containerHeight - this.boxHeight;

        if (val == 'top') {
            val = 0;
        } else if (val == 'bottom') {
            val = max;
        } else {
            val = webf.between(val, 0, max);
        }

        $container.css('top', -val);
        $handler.css('top', val * (this.boxHeight / this.containerHeight));
    },

    scrollTo: function(pos)
    {
        var map = {
            x: 'Left',
            y: 'Top'
        };

        webf.each(['x', 'y'], function(i, axis) {
            if (!webf.isUndefined(pos[axis])) {
                this['scroll' + map[axis]](pos[axis]);
            }
        }, this);
    },

    scrollXTo: function(value) {
        this.scrollLeft(value);
    },

    scrollYTo: function(value) {
        this.scrollTop(value);
    },

    update: function()
    {
        this._initScrollBars();
    },

    container: function()
    {
        return this.e.find('.webf-scrollbox-container').first();
    },

    _destroy: function()
    {
        this.e
            .removeClass('webf-scrollbox')
            .children('.webf-scrollbox-wrapper')
                .children('.webf-scrollbar').remove();

        this.container().children().unwrap().unwrap();
    }
});
