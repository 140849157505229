import $webf from '../../utils/jquery.webf'
import webf from '../../utils/core'
import eventDispatcher from '../../utils/eventDispatcher'
import $ from 'jquery'

const Columns = webf.Class.extend({
    constructor: function(plugin, columnsOptions)
    {
        this.plugin = plugin;
        this.columnsOptions = columnsOptions;
        this.numColumnResizing = null;
        this.resizing = false;
    },

    render: function()
    {
        const p = this.plugin;
        const $columns = $('<div>').addClass('columns');
        const allColumns = this.getAllColumns();
        let columns, savedColumns, allFields, savedFields;

        if (p.option('useLocalStorage')) {
            savedColumns = JSON.parse(localStorage.getItem('webf.datagrid.columns.' + p.e.prop('id')));
            allFields = webf.map(allColumns, (i, column) => column.field);
            savedFields = webf.map(savedColumns, (i, column) => column.field);

            if (webf.compareArray(allFields.slice().sort(), savedFields.slice().sort())) {
                webf.each(savedColumns, (i, savedColumn) => {
                    webf.each(allColumns, (j, column) => {
                        if (savedColumn.field == column.field) {
                            if (column.render) {
                                savedColumn.render = column.render;
                            }

                            if (column.sort && webf.isFunction(column.sort)) {
                                savedColumn.sort = column.sort;
                            }
                        }
                    });
                });

                this.columnsOptions = savedColumns;
            }
        }

        columns = this.getVisibleColumns();

        if (webf.isArray(columns) && columns.length) {
            const $tableColumns = $('<table><tbody><tr>');
            const $trColumn = $tableColumns.find('tr');
            let totalWidth = 0;

            webf.each(columns, function(i, column) {
                const $tdColumn = $('<td>').addClass(['column', column.hasOwnProperty('columnClass') ? column.columnClass : ''].join(' '))
                    .data('field', column.field)
                    .data('sortKey', column.sortKey ? column.sortKey : column.field)
                    .width(column.size);
                totalWidth += parseInt(column.size);

                if (i > 0) {
                    $tdColumn.append($('<div>').addClass('resizer'));
                }

                const $columnWrapper = $('<div>').addClass('col-wrapper');
                if (!column.hasOwnProperty('sort') || column.sort) {
                    $columnWrapper.append($('<div>').addClass('sort').append($('<i>').addClass('fas')));
                }
                $columnWrapper.append($('<div class="column-name">').text(column.caption));
                $tdColumn.append($columnWrapper);

                $trColumn.append($tdColumn);
            });

            const $tdLastColumn = $('<td>').addClass('column last-column');
            $tdLastColumn.append($('<div>').addClass('resizer'));

            $trColumn.append($tdLastColumn);

            $columns.append($tableColumns.append($trColumn));
        } else {
            $columns.addClass('column-hidden');
        }

        this.$columns = $columns;

        return this.$columns;
    },

    adjust: function()
    {
        if (this.plugin.e.find('.grid').hasScrollBarHorizontal()) {
            const wScrollBar = webf.getScrollbarWidth();

            if (wScrollBar) {
                this.plugin.e.find('.columns .last-column').css('width', wScrollBar);
            }
        } else {
            this.plugin.e.find('.columns .last-column').css('width', 'auto');
        }
    },

    bindEvents: function()
    {
        const p = this.plugin, self = this;

        p._on(this.plugin.e, '.columns td.column .col-wrapper', {
            click: function(ev) {
                ev.preventDefault();

                const $td = $(this).closest('td');
                const $sort = $td.find('.sort');

                if ($sort[0]) {
                    let direction = 'asc';

                    $td.closest('.columns').find('.sort')
                        .removeClass('sorted')
                        .find('i')
                        .removeClass('fa-caret-up fa-caret-down');

                    $sort.find('i').addClass('fa-caret-down');

                    if ($sort.hasClass('asc')) {
                        direction = 'desc';
                        $sort.find('i').addClass('fa-caret-up');
                    }

                    $sort.removeClass('asc desc').addClass('sorted');
                    $sort.addClass(direction);

                    p.sort($td.data('field'), direction);
                }
            }
        })._on(this.plugin.e, '.columns .resizer', {
            mousedown: function(ev) {
                ev.preventDefault();

                self.numColumnResizing = $(this).closest('td.column').prev().prevAll().length;
                self.resizing = true;
            }
        });

        if (!this.eventsBinded) {
            this.eventsBinded = true;

            p._on(document, {
                mousemove: function (ev) {
                    if (self.resizing) {
                        ev.preventDefault();

                        const $tdColumn = self.$columns.find('td.column').eq(self.numColumnResizing);
                        const mp = $webf.getMousePos(ev, $tdColumn);
                        const widthColumn = Math.max(mp.x, p.option('minWidth'));
                        const $tdGrid = p.e.find('.grid .grid-size td').eq(self.numColumnResizing);
                        const $tdFooter = p.e.find('.footer .grid-size td').eq(self.numColumnResizing);

                        $tdColumn.add($tdGrid).add($tdFooter)
                            .css('width', widthColumn);

                        p._adjust();
                    }
                },
                mouseup: function () {
                    if (self.resizing) {
                        self.resizing = false;

                        webf.each(self.getVisibleColumns(), function(i, column) {
                            if (i == self.numColumnResizing) {
                                column.size = self.$columns.find('td.column').eq(self.numColumnResizing)
                                    .css('width');
                            }
                        });

                        eventDispatcher.dispatch('datagrid.resizeColumns.' + p.hash, self.getAllColumns());
                    }
                }
            });
        }
    },

    setSort: function(field, direction)
    {
        this.$columns.find('td.column .col-wrapper').each(function() {
            const $td = $(this).closest('td');

            if ($td.data('field') == field) {
                var $sort = $td.closest('.columns').find('.sort');

                if ($sort[0]) {
                    if ($sort.hasClass('sorted')) {
                        if ($sort.hasClass('asc') && direction == 'desc') {
                            return;
                        } else if ($sort.hasClass('desc') && direction == 'asc') {
                            return;
                        }
                    }

                    $(this).trigger('click');
                }
            }
        });
    },

    getSort: function()
    {
        let sort =
          { key: 'id',
            direction: 'asc' };
        const $sorted = this.$columns.find('td.column .sort.sorted');

        if ($sorted[0]) {
            sort = {
                key: $sorted.closest('td').data('sortKey'),
                direction: $sorted.hasClass('asc') ? 'asc' : 'desc'
            };
        }

        return sort;
    },

    getAllColumns: function()
    {
        return this.columnsOptions;
    },

    getVisibleColumns: function()
    {
        return webf.map(this.getAllColumns(), (i, column) => {
            return webf.isUndefined(column.visible) || column.visible ? column : null;
        });
    },

    updateVisibleColumns: function(fields = null)
    {
        if (null === fields) {
            return this;
        }

        webf.each(this.columnsOptions, (i, column) => {
            column.visible = webf.inArray(column.field, fields);
        });

        return this;
    },

    updateOrderColumns: function(fields = null)
    {
        if (null === fields) {
            return this;
        }

        this.columnsOptions.sort((a, b) => {
            return fields.indexOf(a.field) - fields.indexOf(b.field);
        });

        return this;
    },
});

export default Columns
