import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - inputClass        (string) Classes CSS à ajouter à l'input text
 *  - textNewElement    (string) Texte apparaissant en tant qu'item dans la liste déroulante pour ajouter un élément
 *  - newElementOnBlur  (boolean) Si true, lorsque le champ d'input perd le focus, l'entrée est prise en compte comme si
 *                      l'utilisateur avait appuyé sur entrée
 *  - onAdd             (function(option)) Fonction appelée lorsqu'une option esrt ajoutée
 *
 * Méthodes publiques:
 *  - isNewElementSelected()
 *                      Retourne true si un élément ajouté avec le combobox est sélectionné
 *  - getNewElements()  Retourne les éléments ajoutés avec le combobox
 */
$webf('combobox', {
    options: {
        inputClass:         '',
        textNewElement:     '{Nouvel élément}',
        newElementOnBlur:   false,
        onAdd:              webf.noop
    },

    _create: function()
    {
        this.$wrapper = null;
        this.$selectedOption = null;

        this.$selectedOption = this.e.find("option[selected='selected']")[0]
            ? this.e.find("option[selected='selected']")
            : this.e.find("option").first();

        this.$selectedOption.prop('selected', true);

        var $option = $("<option data-newelement='1'>" + this.options.textNewElement + "</option>");
        this.e.prepend($option);

        this.e.wrap("<div class='webf-combobox-wrap' />")
            .after("<input type='text' style='display:none;' class='webf-input-text " + this.options.inputClass + "'>");
        this.$wrapper = this.e.parent();

        this._bindEvents();
    },

    _bindEvents: function()
    {
        this._on(this.e, {
            change: (ev) => {
                if ($(ev.currentTarget).find("option:selected").data("newelement")) {
                    const select_width = this.e.outerWidth();
                    this.e.hide();
                    this.$wrapper.find("input.webf-input-text").css("width", select_width).show().trigger("focus");
                } else {
                    this.$selectedOption = this.e.find("option:selected");
                }
            }
        })._on(this.$wrapper, 'input.webf-input-text', {
            blur: (ev) => {
                if ($(ev.currentTarget).is(':visible')) {
                    this.e.find("option[value='" + (this.$selectedOption.val() + '').htmlquotes() + "']").prop("selected", "selected");
                }

                if (this.option('newElementOnBlur')) {
                    const newValue = $(ev.currentTarget).val();

                    if (newValue.length) {
                        this._trigger(ev.currentTarget, 'newElement');
                    }
                } else {
                    $(ev.currentTarget).val('').hide();
                    this.e.show();
                }
            },
            'keydown newElement': (ev) => {
                if (ev.type == 'newElement' || ev.keyCode == 13) {
                    ev.preventDefault();
                    const newValue = $(ev.currentTarget).val();

                    if (newValue.length) {
                        const $option = $("<option value='" + newValue.htmlquotes() + "'>" + newValue + "</option>")
                            .attr('data-newoption', 1)
                            .prop("selected", "selected");
                        this.e.append($option);
                        this._call(this.option('onAdd'), $option[0]);
                        this.e.trigger("change");
                        $(ev.currentTarget).val('').hide();
                        this.e.show();
                    } else {
                        this._trigger(this.$wrapper.find("input.webf-input-text", "blur"));
                    }
                }
            }
        });
    },

    isNewElementSelected: function()
    {
        return !!this.e.find("option:selected").findByData("newoption", 1)[0];
    },

    getNewElements: function()
    {
        return this.e.find("option").findByData("newoption", 1);
    },

    _destroy: function()
    {
        const $newOptions = this.getNewElements();

        $newOptions.remove();
        this.e.find("option").findByData("newelement", 1).remove();

        this.e.next('input[type="text"]').remove();
        this.e.unwrap('.webf-combobox-wrap');
    }
});
