import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - placement:     (string)       Position de l'alert sur la page.
 *                                  Valeurs possibles: center, left, right
 *  - durationOpen   (int)          Durée en ms de l'affichage en fondu de l'alert
 *  - durationClose: (int)          Durée en ms de la disparition en fondu de l'alert
 *  - autoClose:     (boolean)      Si true, l'alert se fermera automatiquement
 *  - delayClose:    (int)          Durée en ms avant que l'alert ne se ferme automatiquement
 *  - alertClass:    (string)       Classes CSS à ajouter à l'alert
 *  - alertType:     (string)       Type d'alert. Correspond aux couleurs: primary, danger, success, etc.
 *  - onCreate:      (function())   Fonction appelée à la création de l'alert
 *  - onBeforeClose: (function())   Fonction appelée avant que l'alert ne se ferme
 *  - onClose:       (function())   Fonction appelée à la fermeture de l'alert
 *
 * Méthodes publiques:
 *  - close:        Ferme l'alert
 */
var offsetTop = 10,
    marginTop = 10;

$webf('alert', {
    options: {
        placement:      'right',
        durationOpen:   0,
        durationClose:  1000,
        autoClose:      false,
        delayClose:     5000,
        alertClass:     '',
        alertType:      'info',
        onCreate:       webf.noop,
        onBeforeClose:  webf.noop,
        onClose:        webf.noop
    },

    _create: function()
    {
        var self = this,
            $wrapper = $('.webf-alert-wrapper'),
            $alert = $("<div>").addClass('webf-alert webf-alert-' + this.option('alertType') + ' ' + this.option('placement')),
            $message = this.e.addClass('message')
                .prepend("<button class='webf-button webf-close webf-button-" + this.option('alertType') + "'><i class='fas fa-times'></i></button>");

        if (!$wrapper[0]) {
            $wrapper = $("<div class='webf-alert-wrapper'></div>").appendTo('body');
        }

        if (this.option('alertClass')) {
            $alert.addClass(this.option('alertClass'));
        }

        this.$alert = $alert;
        this.$wrapper = $wrapper;

        var top = offsetTop;
        var heights = $wrapper.children('.webf-alert').map(function() {
            return $(this).outerHeight();
        }).get();

        if (heights.length == 1) {
            top = heights[0];
        } else if (heights.length > 1) {
            top = heights.reduce(function (a, b) {
                return a + b + 10;
            });
        }

        if (heights.length) {
            top += offsetTop + marginTop;
        }

        $wrapper.append($alert.append($message));

        $alert.css('top', top).hide().fadeIn(this.option('durationOpen'), function() {
            self._bindEvents();
        });

        this._call(this.option('onCreate'));
    },

    _bindEvents: function()
    {
        var self = this,
            close = function(ev) {
                self.close();
            };

        if (this.option('autoClose')) {
            setTimeout(close, this.option('delayClose'));
        }

        this._on(this.$alert.find('.webf-close'), {
            click: close
        });
    },

    close: function()
    {
        var self = this;
        this._call(this.option('onBeforeClose'));

        this.$alert[0] && this.$alert.fadeOut(this.option('durationClose'), function() {
            var $alert = $(this);
            var heightAlert = $alert.outerHeight();

            $alert.nextAll('.webf-alert').animate({top: '-=' + (heightAlert + marginTop)}, 'slow');
            $alert.remove();

            self._call(self.option('onClose'));
        });
    },

    _destroy: function()
    {
        this.$wrapper.remove();
    }
});

