import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Undo = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-undo'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('undo');
    },
    types: 'action',
    text_tooltip: 'undo',
    shortcut: {
        Ctrl: true,
        key: 'Z'
    }
});

