import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Heading = webf.RichTextEditor.Control.extend({
    headings: null,
    zIndex: null,

    init: function(editor, options) {
        this.headings = options.headings || {
            h1: editor._('heading')+' 1',
            h2: editor._('heading')+' 2',
            h3: editor._('heading')+' 3',
            h4: editor._('heading')+' 4',
            h5: editor._('heading')+' 5',
            h6: editor._('heading')+' 6'
        };
        this.zIndex = editor.option('zIndex');
    },
    render: function(editor, $btn) {
        var self = this;

        $btn.addClass('webf-select-button heading')
            .append("<label>" + editor._('heading') + "</label>")
            .append("<i class='fas fa-caret-down'></i>");

        this.$menu = $("" +
            "<ul class='webf-list'>" +
                webf.map(this.headings, function(value, heading) {
                    return "<li class='webf-list-item'><a data-value='" + value + "'>" + heading + "</a></li>";
                }).join('') +
            "</ul>" +
        "");

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            behavior: 'menu-toggle',
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-heading',
            onOpen: function() {
                self.$menu.find('a').on('click', function() {
                    editor._execCommand('formatBlock', $(this).data('value'));
                });
            }
        });
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    refresh: function(editor, $btn) {
        try {
            var value = editor._queryCommandValue('formatBlock');
        } catch(e) {}

        if (/^h[1-6]$/.test(value)) {
            value = value.replace(/^h([1-6]$)/, editor._('heading') + ' $1');
        } else {
            value = editor._('heading');
        }

        $btn.find('label').text(value);
    },
    types: 'action',
    text_tooltip: 'heading',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});

