import webf from '../../utils/core'
import $ from 'jquery'

const Header = webf.Class.extend({
    constructor: function(plugin, headerOptions)
    {
        this.plugin = plugin;
        this.headerOptions = headerOptions;
    },

    render: function()
    {
        var p = this.plugin,
            $header = $('<div>').addClass('header');

        if (this.headerOptions) {
            if (webf.isString(this.headerOptions.title)) {
                $header.text(this.headerOptions.title);
            }
        } else {
            $header.addClass('hidden');
        }

        return $header;
    }
});

export default Header
