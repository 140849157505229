import webf from "../utils/core"
import '../i18n/agenda'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - view:                 (string) month week day
 *  - displayViews:         (boolean|array) affiche les boutons contenus dans le tableau (week, month, day, datetpicker) ou
 *                          n'affiche rien si il est égal à false
 *  - date:                 (Date) La date en javascript à considérer comme la date courante pour l'affichage de
 *                          l'agenda.
 *  - daysToShow:           (array) Tableau indexé précisant les jours à afficher. 0 = lundi, dimanche = 6
 *  - data:                 (array|string|function(start, end)) Charge les événements à afficher.
 *                          Si data est un tableau, alors ce sont les événements à charger.
 *                          Si data est une chaîne de caractères, alors il s'agit de l'url des événements à récupérer
 *                          Si data est une fonction, elle reçoit en premier paramètre la fonction de callback
 *                          à appeler argument avec les événements à charger et la date de début et de fin de
 *                          l'agenda actuellement affiché.
 *  - skins:                (list of skin) chaque skin a un nom
 *  - defaultSkin:          (string) skin à appliquer pour les nouveaux événements
 *  - height:               (integer) La hauteur totale de l'agenda
 *  - timeslotsPerHour:     (integer) Nombre de créneaux par heure
 *  - timeslotHeight:       (integer) la hauteur en px de chaque créneau
 *  - businessHours:        (Object) Détermine les heures à afficher pour chaque jour
 *  - draggable:            (boolean) Rendre les événements déplaçables
 *  - resizable:            (boolean) Rendre les événements redimenionnables
 *  - newEventText:         (string) Texte par défaut à afficher pour un nouvel événement créé par l'utilisateur
 *  - onLoad:               (function(events)) Fonction appelée après le chargement des événements
 *  - onEventClick:         (function($event,event)) Fonction appelée lors d'un clic sur un événement
 *  - onEventMouseover:     (function($event,event)) Fonction appelée lorsque la souris passe ou dessus d'un événement
 *  - onEventMouseout:      (function($event,event)) Fonction appelée lorsque la souris sort d'un événement
 *  - onEventChange:        (function($event,event)) Fonction appelée lorsqu'un événement change soit par
 *                          redimensionnement ou déplacement. Si la fonction retourne false, l'événement retourne à
 *                          son état précédent
 *  - onEventNew:           (function($event,event)) Fonction appelée lors de l'ajout d'un événement. Si la fonction
 *                          retourne false, l'événement est supprimé
 *  - onChangeView:         (function(view)) Fonction appelée lorsque le mode de vue change
 *  - readonly:             (boolean) L'agenda ne peut être que consulté, pas de possibilité de créer, déplacer ou
 *                          redimensionner un événement
 *  - users:                (array of User) division des colonnes des jours
 *  - allowOverlapEvents:   (boolean) Autoriser des événements à la même date
 *  - disableLinks:         (boolean) Rend inactif les liens sur les jours qui amènent à la vue "jour"
 *  - newEventOnClick:      (boolean) Crée l'événement au click sur l'agenda.
 *  - marginOverlap:        (integer) Poucentage de la largeur de la colonne à laisser disponible pour insérer
 *                          un nouvel élément. Par défaut 10
 *  - urlParams:            (object) liste des paramètres à envoyer pour récupérer les événements lorsque data
 *                          est un string, et donc considré comme une URL.
 *  - startParam:           le nom du paramètre de début du calendrier utilisé lorsque data est une URL
 *  - endParam:             le nom du paramètre de fin du calendrier utilisé lorsque data est une URL
 *  - formatDateParam:      Format de la chaîne de caractères représentant les dates de début et de fin à envoyer
 *                          en paramètre à l'URL pour récupérer les événenements.
 *  - scrollToOnInit:       Scroll l'agenda à l'heure spécifié au fomat HH:mm en mode week ou day
 *
 * Méthodes publiques:
 *  - addEvent(Event):          Ajouter un événement
 *  - editEvent(Event):         Modifier un événement
 *  - removeEvent(Event):       Supprime un événement
 *  - clearEvents:              Supprime tous les événements
 *  - displayWeek(Date):        Affiche la semaine de la date spécifiée
 *  - displayMonth(Date):       Affiche le mois de la date spécifiée
 *  - changeView(string,date):  Change le mode en semaine ou mois à la date indiquée. Si la date n'est pas précisé
 *                              la date est déterminée au mieux par le plugin
 *  - getLastEvent(Event):      Retourne le rdv précédent de celui donné
 *  - getNextEvent(Event):      Retourne le rdv suivant de celui donné
 *  - getEventById(string):     Retourne l'événement avec l'id spécifié
 *
 *  Structures:
 *  Event: {
 *    - id:         (mixed) facultatif (attribué par le plugin si indéfini)
 *    - user:       (User|mixed) l'utilisateur lié à l'événement. l'identifiant de l'utilisateur peut être
 *                  fourni à la place de l'objet User.
 *    - start:      (string) la date de début de l'événement correspondant au format
 *    - end:        (string) la date de fin de l'événement correspondant au format
 *    - format:     (string) format compatible avec webf.Date
 *    - content:    (string) Texte à afficher dans la zone prévue à cet effet
 *    - skin:       (string) Nom du skin à utiliser pour l'événement
 *    - readonly:   (boolean) l'événement ne peut être ni déplacé ni redimensionné
 *  }
 *
 *  User: {
 *    - id:         (mixed) requis (l'dentifiant unique de l'utilisateur)
 *    - label:      (string) Texte à afficher comme entête de la colonne de l'utilsiateur
 *    - title:      (string) Attribut title de l'élément
 *    - tooltip:    (string) Contenu HTML de la tooltip affiché au survol de la souris sur le label de
 *                  l'utilisateur. Si la propriété title est renseigné, tooltip est ignoré
 *  }
 *
 *  businessHours: {
 *   - start:       (integer)
 *   - end:         (integer)
 *  }
 *
 *  skin: {
 *   - color:                   couleur du texte
 *   - opacity:                 opacité de l'événement
 *   - backgroundColorTitle:    couleur de fond du titre de l'événement
 *   - backgroundColorContent:  couleur de fond du contenu de l'événement
 *   - borderColor:             Couleur de la bordure
 *  }
 */
webf.Agenda = {};

var dayName = 'sunday monday tuesday wednesday thursday friday saturday'.split(' '),
    monthName = 'january february march april may june july august september october november december'.split(' '),
    shortMonthName = 'jan feb mar apr may. jun jul aug sept oct nov dec'.split(' ');

$webf('agenda', {
    options: {
        firstDay:           1,
        classToday:         'today',
        header: {
            left:       'prev today next',
            center:     'title',
            right:      'month week day, datepicker'
        },
        view:               'week',
        displayViews:       ['month', 'week', 'day', 'datepicker'],
        date:               webf.Date(),
        daysToShow:         [0, 1, 2, 3, 4, 5, 6],
        events:             {},
        height:             500,
        timeslotsPerHour:   4,
        timeslotHeight:     20,
        businessHours:      false /*{
            start: 8,
            end: 18
        }*/,
        draggable:          true,
        resizable:          true,
        newEventText:       "",
        defaultSkin:        "",
        skins:              {},
        marginOverlap:      10,
        urlParams:          {},
        startParam:         'start',
        endParam:           'end',
        formatDateParam:    'yyyy-MM-dd HH:mm:ss',
        scrollToOnInit:     null,
        users:              [],
        readonly:           false,
        allowOverlapEvents: false,
        overlapStyle:       'sidebyside',
        disableLinks:       false,
        newEventOnClick:    false,
        disabledLinks:      false,
        onLoad:             webf.noop,
        onEventClick:       webf.noop,
        onEventMouseover:   webf.noop,
        onEventMouseout:    webf.noop,
        onEventChange:      webf.noop,
        onEventNew:         webf.noop,
        onChangeView:       webf.noop
    },

    _create: function()
    {
        this.options.date = webf.Date(this.option('date'));
        this.daysToShow = this.option('daysToShow');

        this.currentView = this._instantiateView(this.option('view'));
        this.drawAgenda();
    },

    _instantiateView: function(viewType)
    {
        var views = webf.Agenda.Views,
            view = views[viewType] || views[viewType.ucfirst() + 'View'];

        if (webf.isFunction(view)) {
            return new view(this, this.option('date'), this.daysToShow);
        }
    },

    drawAgenda: function()
    {
        this.header = new webf.Agenda.Header(this, this.option('header'));

        var $agenda = $('<div>').addClass('webf-agenda'),
            $header = this.header.render(),
            $tbAgenda = $('<div>').addClass('webf-agenda-view').append(this.currentView.render());

        $agenda
            .append($header)
            .append($tbAgenda);

        this.e.append($agenda);
    },

    setView: function(view)
    {
        if (this.currentView.getName() != view) {
            this.currentView = this._instantiateView(view);
            this.header.refresh();
            this.e.find('.webf-agenda-view')
                .empty()
                .append(this.currentView.render());
        }
    },

    prev: function()
    {

    },

    next: function()
    {

    },

    _formatDate: function(date, format)
    {
        var wd = webf.Date(date);

        format = format.replace(/MMMM(?!((?![\[\]]).)*\])/g, '[' + this._(monthName[wd.month()]) + ']');
        format = format.replace(/MMM(?!((?![\[\]]).)*\])/g, '[' + this._(shortMonthName[wd.month()]) + ']');
        format = format.replace(/EEEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,2).ucfirst() + ']');
        format = format.replace(/E(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,1).ucfirst() + ']');

        return wd.toString(format);
    },

    _destroy: function()
    {
        this.e.children('.webf-agenda').remove();
    }
});

