import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import WebfDate from '../utils/date'
import '../i18n/useragenda'
import './scrollbar'
import './dropdownmenu'
import './datetimepicker'
import './tooltip'
import $ from 'jquery'

/**
 * options:
 *  - view:                     (string) month week day
 *  - displayViews:             (boolean|array) affiche les boutons contenus dans le tableau (week, month, day, datetpicker) ou
 *                              n'affiche rien si il est égal à false
 *  - date:                     (Date) La date en javascript à considérer comme la date courante pour l'affichage de
 *                              l'agenda.
 *  - daysToShow:               (string|array) suite de chiffre de 0 à 6 séparé par des virgules représentant les jours à afficher. 0 = lundi, dimanche = 6
 *  - data:                     (array|string|function(start, end)) Charge les événements à afficher.
 *                              Si data est un tableau, alors ce sont les événements à charger.
 *                              Si data est une chaîne de caractères, alors il s'agit de l'url des événements à récupérer
 *                              Si data est une fonction, elle reçoit en premier paramètre la fonction de callback
 *                              à appeler argument avec les événements à charger et la date de début et de fin de
 *                              l'agenda actuellement affiché.
 *  - skins:                    (list of skin) chaque skin a un nom
 *  - defaultSkin:              (string) skin à appliquer pour les nouveaux événements
 *  - height:                   (integer|string) La hauteur totale de l'agenda
 *                              fitbottom : l'agenda se fixe sur le bas de la fenêtre
 *  - timeslotsPerHour:         (integer) Nombre de créneaux par heure
 *  - timeslotHeight:           (integer) la hauteur en px de chaque créneau
 *  - businessHours:            (Object) Détermine les heures à afficher pour chaque jour
 *  - draggable:                (boolean) Rendre les événements déplaçables
 *  - resizable:                (boolean) Rendre les événements redimenionnables
 *  - newEventText:             (string) Texte par défaut à afficher pour un nouvel événement créé par l'utilisateur
 *  - onBeforeLoad:             (function()) Fonction appelée avant le chargement des événements
 *  - onLoad:                   (function(events)) Fonction appelée après le chargement des événements
 *  - onBeforeLoadAnnotations:  (function()) Fonction appelée avant le chargement des annotations
 *  - onLoadAnnotations:        (function(annotations)) Fonction appelée après le chargement des annotations
 *  - eventClick:               (function($event,event)) Fonction appelée lors d'un clic sur un événement
 *  - eventMouseover:           (function($event,event)) Fonction appelée lorsque la souris passe ou dessus d'un événement
 *  - eventMouseout:            (function($event,event)) Fonction appelée lorsque la souris sort d'un événement
 *  - eventChange:              (function($event,event)) Fonction appelée lorsqu'un événement change soit par
 *                              redimensionnement ou déplacement. Si la fonction retourne false, l'événement retourne à
 *                              son état précédent
 *  - eventNew:                 (function($event,event)) Fonction appelée lors de l'ajout d'un événement. Si la fonction
 *                              retourne false, l'événement est supprimé
 *  - annotationClick:          (function($event,event)) Fonction appelée lors d'un clic sur une annotation
 *  - annotationNew:            (function(day)) Fonction appelée lors de l'ajout d'une annotation en cliquant sur la zone
 *                              prévue à cet effet
 *  - onChangeView:             (function(view, start, end)) Fonction appelée lorsque le mode de vue change
 *  - readonly:                 (boolean) L'agenda ne peut être que consulté, pas de possibilité de créer, déplacer ou
 *                              redimensionner un événement
 *  - users:                    (array of User) division des colonnes des jours
 *  - usersAtBottom             (boolean) affiche la barre des utilisateurs en bas de l'agenda
 *  - allowOverlapEvents:       (boolean) Autoriser des événements à la même date
 *  - disabledLinks:            (boolean) Rend inactif les liens sur les jours qui amènent à la vue "jour"
 *  - newEventOnClick:          (boolean) Crée l'événement au click sur l'agenda.
 *  - marginOverlap:            (integer) Poucentage de la largeur de la colonne à laisser disponible pour insérer
 *                              un nouvel élément. Par défaut 10
 *  - urlParams:                (object) liste des paramètres à envoyer pour récupérer les événements lorsque data
 *                              est un string, et donc considré comme une URL.
 *  - startParam:               le nom du paramètre de début du calendrier utilisé lorsque data est une URL
 *  - endParam:                 le nom du paramètre de fin du calendrier utilisé lorsque data est une URL
 *  - formatDateParam:          Format de la chaîne de caractères représentant les dates de début et de fin à envoyer
 *                              en paramètre à l'URL pour récupérer les événenements.
 *  - scrollToOnInit:           Scroll l'agenda à l'heure spécifié au fomat HH:mm en mode week ou day
 *  - tolerance:                Tolérance (en pixels) à partir de laquelle le mousemove se déclenche
 *  - hourMarker:               Affiche le marqueur de l'heure actuelle en mode jour ou semaine
 *  - dayAnnotations:           (boolean) Affiche des annotations pour la journée (ignoré si userAnnnotation est à true)
 *  - userAnnotations:          (boolean) Affiche des annotations pour la journée pour chaque user
 *
 * Méthodes publiques:
 *  - addEvent(Event):              Ajouter un événement
 *  - editEvent(Event):             Modifier un événement
 *  - removeEvent(Event):           Supprimer un événement
 *  - clearEvents:                  Supprimer tous les événements
 *  - clearAnnotations:             Supprimer toutes les annotations
 *  - addAnnotation(annotation):    Ajouter une annotation
 *  - editAnnotation(annotation):   Modifier une annotation
 *  - removeAnnotation(annotation): Supprimer une annotation
 *  - displayWeek(Date):            Afficher la semaine de la date spécifiée
 *  - displayMonth(Date):           Afficher le mois de la date spécifiée
 *  - changeView(string,date):      Changer le mode en semaine ou mois à la date indiquée. Si la date n'est pas précisé
 *                                  la date est déterminée au mieux par le plugin
 *  - getLastEvent(Event):          Retourner le rdv précédent de celui donné
 *  - getNextEvent(Event):          Retourner le rdv suivant de celui donné
 *  - getEventById(string):         Retourner l'événement avec l'id spécifié
 *  - getRenderedEvent(Event):      Retourner l'événement du DOM. (Il peut être en plusieurs segements)
 *  - setDefaultSkin(string):       Modifier le skin à appliquer pour les nouveaux événements
 *  - open(Event|int):              Déclenche l'événement eventClick
 *  - triggerClick(Event|int):      Alias de open
 *
 *  Structures:
 *  Event: {
 *    - id:         (mixed) facultatif (attribué par le plugin si indéfini)
 *    - user:       (User|mixed) l'utilisateur lié à l'événement. l'identifiant de l'utilisateur peut être
 *                  fourni à la place de l'objet User.
 *    - start:      (string) la date de début de l'événement correspondant au format
 *    - end:        (string) la date de fin de l'événement correspondant au format
 *    - format:     (string) format compatible avec new WebfDate
 *    - content:    (string) Texte à afficher dans la zone prévue à cet effet
 *    - skin:       (string) Nom du skin à utiliser pour l'événement
 *    - readonly:   (boolean) l'événement ne peut être ni déplacé ni redimensionné
 *    - classname:  (string) classes CSS ajoutées sur l'événement rendu
 *  }
 *
 *  User: {
 *    - id:         (mixed) requis (l'dentifiant unique de l'utilisateur)
 *    - label:      (string) Texte à afficher comme entête de la colonne de l'utilsiateur
 *    - title:      (string) Attribut title de l'élément
 *    - color:      (string) Couleur du label
 *    - tooltip:    (string) Contenu HTML de la tooltip affiché au survol de la souris sur le label de
 *                  l'utilisateur. Si la propriété title est renseignée, tooltip est ignoré
 *  }
 *
 *  Annotation: {
 *    - id:         (mixed) facultatif (attribué par le plugin si indéfini)
 *    - user:       (mixed) User ou id du User
 *    - date:       (string) la date de de l'annotation
 *    - format:     (string) format compatible avec new WebfDate
 *    - comment:    (string) Texte à afficher dans la zone prévue à cet effet
 *    - skin:       (string) Nom du skin à utiliser pour l'événement
 *    - classname:  (string) classes CSS ajoutées sur l'annotation rendue
 *  }
 *
 *  businessHours: {
 *   - start:   (integer)
 *   - end:     (integer)
 *  }
 *
 *  skin: {
 *   - color:                   couleur du texte
 *   - opacity:                 opacité de l'événement
 *   - backgroundColorTitle:    couleur de fond du titre de l'événement
 *   - backgroundColorContent:  couleur de fond du contenu de l'événement
 *   - borderColor:             Couleur de la bordure
 *  }
 */
const dayName = 'sunday monday tuesday wednesday thursday friday saturday'.split(' ');
const monthName = 'january february march april may june july august september october november december'.split(' ');
const shortMonthName = 'jan feb mar apr may. jun jul aug sept oct nov dec'.split(' ');

$webf("useragenda", {
    options: {
        view:                       'week',
        displayViews:               ['month','week','day','datepicker'],
        date:                       new WebfDate(),
        daysToShow:                 '0,1,2,3,4,5,6',
        data:                       {},
        height:                     500,
        timeslotsPerHour:           4,
        timeslotHeight:             20,
        businessHours: {
            start: 8,
            end: 18
        },
        draggable:                  true,
        resizable:                  true,
        newEventText:               "",
        defaultSkin:                "",
        skins:                      {},
        marginOverlap:              10,
        urlParams:                  {},
        startParam:                 'start',
        endParam:                   'end',
        formatDateParam:            'yyyy-MM-dd HH:mm:ss',
        scrollToOnInit:             null,
        tolerance:                  0,
        hourMarker:                 true,
        usersAtBottom:              false,
        readonly:                   false,
        allowOverlapEvents:         false,
        disabledLinks:              false,
        newEventOnClick:            false,
        dayAnnotations:             false,
        userAnnotations:            false,
        onBeforeLoad:               webf.noop,
        onLoad:                     webf.noop,
        onLoadAnnotations:          webf.noop,
        onBeforeLoadAnnotations:    webf.noop,
        eventClick:                 webf.noop,
        eventMouseover:             webf.noop,
        eventMouseout:              webf.noop,
        eventChange:                webf.noop,
        eventNew:                   webf.noop,
        onChangeView:               webf.noop,
        annotationClick:            webf.noop,
        annotationNew:              webf.noop,
        users:                      []
    },

    _create: function()
    {
        this.view = null;
        this.dragging = false;
        this.resizing = false;
        this.creating = false;
        this.startPoint = null;
        this.start = null;
        this.end = null;
        this.events = [];
        this.annotations = [];
        this.oldEvent = null;
        this.moving = false;
        this.curMonth = new WebfDate(this.option('date')).month();
        this.curYear = new WebfDate(this.option('date')).year();
        this.widthMonthDay = null;
        this.heightMonthDay = null;
        this.daysToShow = this._getDaysToShow();
        this.prevView = null;
        this.creatingOnClick = false;

        if (this.options.displayViews == false || (webf.inArray("day", this.options.displayViews) == -1)) {
            this.options.disabledLinks = true;
        }

        if (this.options.view == 'month') {
            this._initStartEndOfMonth();
            this.view = 'month';
            this._drawMonthAgenda();
        } else if (this.options.view == 'week') {
            this._initStartEndOfWeek();
            this.view = 'week';
            this._drawWeekAgenda();
        } else if (this.options.view == 'day') {
            this._initStartEndOfDay();
            this.view = 'day';
            this.daysToShow = [(this.start.clone().day() + 6) % 7];
            this._drawDayAgenda();
        }

        if (this.options.scrollToOnInit) {
            if (webf.inArray(this.view, ['week','day'])) {
                var hm = this.options.scrollToOnInit.split(':');
                var scrollTop = (hm[0] * this.options.timeslotsPerHour + (parseInt(hm[1]) / 60) * this.options.timeslotsPerHour) * this.options.timeslotHeight - this.options.businessHours.start * (this.options.timeslotsPerHour * this.options.timeslotHeight);
                this.e.find('.grid').webfScrollbar('scrollYTo',scrollTop);
            }
        }

        this._loadEvents();
        this._loadAnnotations();
        this._bindEvents();
        this._bindButtons();
    },

    editableOptions: {
        height: function(height) {
            this._setHeight(height);
        },
        data: function(data) {
            this.options.data = data;
        },
        users: function(users) {
            this.options.users = users;
        },
        defaultSkin: function(skin) {
            this.setDefaultSkin(skin);
        }
    },

    _initStartEndOfWeek: function(date)
    {
        var firstDay = Math.min.apply(null, this._getDaysToShow());
        var lastDay = Math.max.apply(null, this._getDaysToShow());

        var now = new WebfDate(date || this.option('date'));
        var dayNow = (now.day() + 6) % 7;

        var firstDayOfWeek = now.clone().subDays(dayNow - firstDay);
        var lastDayOfWeek = now.clone().addDays(lastDay - dayNow);

        this.start = firstDayOfWeek.clone().hours(this.options.businessHours.start).startOfHour();
        this.end = lastDayOfWeek.clone().hours(this.options.businessHours.end).startOfHour();
    },

    _initStartEndOfMonth: function(date)
    {
        var now = new WebfDate(date || this.option('date'));

        this.curMonth = now.month();
        this.curYear = now.year();
        this.start = now.clone().startOfMonth();
        this.start.subDays((this.start.day() + 6) % 7);
        this.end = this.start.clone().addDays(42);
    },

    _initStartEndOfDay: function(date)
    {
        var now = new WebfDate(date || this.option('date'));

        this.start = now.clone().hours(this.option('businessHours').start).startOfHour();
        this.end = now.clone().hours(this.option('businessHours').end).startOfHour();
    },

    _drawWeekAgenda: function()
    {
        var self = this;
        var now = new WebfDate();

        if (webf.inArray("datepicker", this.option('displayViews'))) {
            this._removeDatePicker();
        }

        var agenda_toolbar = "" +
            "<div class='wrap-toolbar'>" +
                "<div class='sm'>" +
                    "<div class='title " + (this.view == 'day' && this.start.isToday() ? 'today' : '') + "'>" +
                        (this.view == 'week'
                            ? this.start.toString('MMMM yyyy').ucfirst()
                            : this.start.toString('EEEE d MMMM yyyy').ucfirst()
                        ) +
                    "</div>" +
                "</div>" +
                "<div class='toolbar'>" +
                    (this.view == 'week' ?
                        "<div class='webf-buttons-group buttons'>" +
                            "<a title='" + this._('prev_week') + "' class='webf-button white prev-week' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
                            "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
                            "<a title='" + this._('next_week') + "' class='webf-button white next-week' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
                        "</div>" :
                        "<div class='webf-buttons-group buttons'>" +
                            "<a title='" + this._('prev_day') + "' class='webf-button white prev-day' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
                            "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
                            "<a title='" + this._('next_day') + "' class='webf-button white next-day' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
                        "</div>"
                    ) +
                    "<div class='lg'>" +
                        "<div class='title " + (this.view == 'day' && this.start.isToday() ? 'today' : '') + "'>" + (this.view == 'week' ? this._formatDate(this.start,this._('date_title_week')) + " - " + this._formatDate(this.end.subSecond(),this._('date_title_week')) :
                            this._formatDate(this.start,this._('date_title_day'))) + "</div>" +
                        ((this.options.displayViews !== false ) ?
                    "</div>" +
                    "<div class='views'>" +
                        "<div class='webf-buttons-group buttons'>" +
                            (webf.inArray("month", this.options.displayViews) ? "<a class='webf-button month white' href='javascript:void(0)'>" + this._('month') + "</a>" : "") +
                            (webf.inArray("week", this.options.displayViews) ? "<a class='webf-button week white " + (this.view == 'week' ? 'selected' : '') + "' href='javascript:void(0)'>" + this._('week') + "</a>" : "") +
                            (webf.inArray("day", this.options.displayViews) ? "<a class='webf-button day white " + (this.view == 'day' ? 'selected' : '') + "' href='javascript:void(0)'>" + this._('day') + "</a>" : "") +
                            (webf.inArray("datepicker", this.options.displayViews) ? "<a class='webf-button datepicker white' href='javascript:void(0)'>" +
                                "<i class='far fa-calendar'></i>" +
                                "<span>" + this._('pick a date') + "</span>" +
                            "</a>" : "") +
                        "</div>" +
                    "</div>" : "") +
                "</div>" +
            "</div>" +
        "";

        let dayAnnotations = '';
        if (this.option('users').length) {
            if (this.option('userAnnotations')) {
                dayAnnotations = "" +
                    "<div class='allday'>" +
                    "   <table class='tb-grid'>" +
                    "   <tr>" +
                    "       <td class='td-first'></td>" +
                    "       <td class='td-col-days'>" +
                    "           <table class='tb-days'>" +
                    "               <tr>" +
                    "                   <td class='td-col-allday'></td>".repeat(
                                            (this.end.clone().subSeconds().startOfDay().diff(this.start.clone().startOfDay(), 'days') + 1)
                                            * this.option('users').length
                                        ) +
                    "               </tr>" +
                    "               <tr>" +
                    "                   <td class='td-col-padding-allday'></td>".repeat(
                                            (this.end.clone().subSeconds().startOfDay().diff(this.start.clone().startOfDay(), 'days') + 1)
                                            * this.option('users').length
                                        ) +
                    "               </tr>" +
                    "           </table>" +
                    // "           <table class='tb-events'>" +
                    // "           </table>" +
                    "       </td>" +
                    "   </tr>" +
                    "   </table>" +
                    "</div>" +
                    "";
            } else if (this.option('dayAnnotations')) {
                dayAnnotations = "" +
                    "<div class='allday'>" +
                    "   <table class='tb-grid'>" +
                    "   <tr>" +
                    "       <td class='td-first'></td>" +
                    "       <td class='td-col-days'>" +
                    "           <table class='tb-days'>" +
                    "               <tr>" +
                    "                   <td class='td-col-allday'><!-- --<div class='annotation'></div --></td>".repeat(this.end.clone().startOfDay().diff(this.start.clone().startOfDay(), 'days')) +
                    "               </tr>" +
                    "               <tr>" +
                    "                   <td class='td-col-padding-allday'></td>".repeat(this.end.clone().subSeconds().startOfDay().diff(this.start.clone().startOfDay(), 'days') + 1) +
                    "               </tr>" +
                    "           </table>" +
                    // "           <table class='tb-events'>" +
                    // "           </table>" +
                    "       </td>" +
                    "   </tr>" +
                    "   </table>" +
                    "</div>" +
                    "";
            }
        }
        var td_days = "";
        var td_agenda = "";
        var tb_users = "";

        if (this.option('users').length) {
            tb_users = "" +
                "<table class='tb-users'>" +
                    "<tr>" +
                        webf.map(this.options.users, function(i, user) {
                            return "" +
                                "<td class='td-user user-" + user.id + "' data-user='" + user.id + "'>" +
                                    "<div class='col-user'></div>" +
                                "</td>" +
                            "";
                        }).join('') +
                    "</tr>" +
                "</table>" +
            "";
        }

        webf.each(this._getDaysToShow(), function(i, value) {
            var date = self.start.clone();
            var is_today = false;
            const day = date.clone().addDays(value);

            td_days += `
                <td class='td-day'>
                    <a class='link-days ${day.isToday() ? 'today' : ''} ${(self.options.disabledLinks ? ' disabled' : '')}'>
                        ${(self.view == 'week' ? self._formatDate(day, self.translate('date_col_day')) : '&nbsp;')}
                    </a>
                </td>
            `;

            if (date.equals(now, 'day')) {
                is_today = true;
            }

            td_agenda += "" +
                "<td data-col='" + value + "' style='height:" + (((self.options.businessHours.end - self.options.businessHours.start) * self.options.timeslotsPerHour) * (self.options.timeslotHeight)) + "px' class='td-col-day col-day-" + value + (is_today ? " today" : "") + "'>" +
                    "<div class='col-day-full'>" +
                        tb_users +
                        "<div class='hour-marker'></div>" +
                    "</div>" +
                "</td>" +
            "";
        });

        var users = "";
        if (this.options.users.length) {
            var td_users = "";

            webf.each(this._getDaysToShow(), function() {
                webf.each(self.options.users, function(i, user) {
                    var user_color = user.color ? user.color : null;
                    td_users += "" +
                        "<td>" +
                            "<div data-user='" + user.id + "' class='user'>" +
                                "<label" + ( user_color ? ' style="color:' + user_color + '"' : '') + ">" + user.label + "</label>" +
                            "</div>" +
                        "</td>" +
                    "";
                });
            });

            users = "" +
                "<div class='users'>" +
                    "<table class='tb-users'>" +
                        "<tr>" +
                            "<td class='td-hours'></td>" +
                            td_users +
                        "</tr>" +
                    "</table>" +
                "</div>" +
            "";
        }

        var agenda_days = "" +
            "<div class='days'>" +
                "<table class='tb-days'>" +
                    "<tr>" +
                        "<td class='td-hours'></td>" +
                        td_days +
                    "</tr>" +
                "</table>" +
            "</div>" +
        "";

        var td_grid = "";
        for( var i=this.options.businessHours.start; i<this.options.businessHours.end; i++) {
            for( var j=0; j<this.options.timeslotsPerHour; j++) {
                td_grid += "" +
                    "<tr>" +
                        (j==0 ? "<td rowspan='" + this.options.timeslotsPerHour + "' class='td-hours'>" +
                             "<span class='hour'>" + (i > this.options.businessHours.start ? (i + this._(i <= 12 ? "am" : "pm")) : '') + "</span>" +
                             "<div class='webf-border-bottom'></div>" +
                        "</td>" : "") +
                        "<td class='td-grid'>" +
                            "<div style='height:" + (this.options.timeslotHeight - 1) + "px;' class='timeslot "+ ((j==this.options.timeslotsPerHour-1) ? "hour" : "") + "'>" +
                                "" +
                            "</div>" +
                        "</td>" +
                    "</tr>" +
                "";
            }
        }

        var agenda_grid = "" +
            // "<div class='grid' style='height: " + this.options.height + "px'>" +
            "<div class='grid'>" +
                "<table class='tb-grid'>" +
                    td_grid +
                "</table>" +
                "<div class='agenda'>" +
                    "<table class='tb-agenda'>" +
                        "<tr>" +
                            "<td class='td-hours'>" +
                            "<td>" +
                                "<table class='tb-agenda-days'>" +
                                    "<tr>" +
                                        td_agenda +
                                    "</tr>" +
                                "</table>" +
                            "</td>" +
                        "</tr>" +
                    "</table>" +
                "</div>" +
            "</div>" +
        "";

        var agenda = "<div class='webf-user-agenda " + (this.view == "week" ? "agenda-week" : "agenda-day") + "'>" +
            agenda_toolbar +
            agenda_days +
            users +
            dayAnnotations +
            agenda_grid + ( this.options.usersAtBottom ? users : '' ) + "</div>";

        this.e.empty().append(agenda);
        this._setHeight(this.option('height'));

        if (this.options.users.length) {
            $('.webf-user-agenda-tooltip-user').remove();

            this.e.find('.webf-user-agenda .user label').each(function() {
                var id_user = $(this).closest('.user').data('user');

                var user = self._getUserById(id_user, '_drawWeekAgenda');

                if (user.title) {
                    $(this).prop('title',user.title);
                } else if (user.tooltip) {
                    $(this).webfTooltip({
                        content: $("" +
                            "<div class='webf-user-agenda-tooltip-user'>" +
                                "<div class='triangle'>" +
                                    "<div class='inner-triangle'></div>" +
                                "</div>" +
                                "<div class='content'>" +
                                    user.tooltip +
                                "</div>" +
                            "</div>" +
                        ""),
                        position: {
                            my: 'center top',
                            at: 'center bottom',
                            offset: {
                                top: 6,
                                left: 0
                            }
                        },
                        zIndex: 2000
                    });
                }
            });
        }

        this.e.find('.grid').webfScrollbar();

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._initDatePicker();
        }
    },

    _drawDayAgenda: function()
    {
        this._drawWeekAgenda();
    },

    _drawMonthAgenda: function()
    {
        var self = this;
        var startmonth = this.start.clone().startOfMonth();

        var start = this.start.equals(startmonth,'day') ? startmonth.clone() : startmonth.addMonth().clone();

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._removeDatePicker();
        }

        var agenda_toolbar = "" +
            "<div class='wrap-toolbar'>" +
                "<div class='toolbar'>" +
                    "<div class='webf-buttons-group buttons'>" +
                        "<a title='" + this._('prev_month') + "' class='webf-button white prev-month' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
                        "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
                        "<a title='" + this._('next_month') + "' class='webf-button white next-month' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
                    "</div>" +
                    ((this.options.displayViews !== false) ?
                    "<div class='views'>" +
                        "<div class='webf-buttons-group buttons'>" +
                            (webf.inArray("month", this.options.displayViews) ? "<a class='webf-button month white selected' href='javascript:void(0)'>" + this._('month') + "</a>" : "") +
                            (webf.inArray("week", this.options.displayViews) ? "<a class='webf-button week white' href='javascript:void(0)'>" + this._('week') + "</a>" : "") +
                            (webf.inArray("day", this.options.displayViews) ? "<a class='webf-button day white' href='javascript:void(0)'>" + this._('day') + "</a>" : "") +
                            (webf.inArray("datepicker", this.options.displayViews) ? "<a class='webf-button datepicker white' href='javascript:void(0)'>" + this._('pick a date') + "</a>" : "") +
                        "</div>" +
                    "</div>" : "") +
                    "<div class='title'>" + this._formatDate(start,this._('date_title_month')) + "</div>" +
                "</div>" +
            "</div>" +
        "";

        var td_days = "";

        webf.each(this._getDaysToShow(), function(i, value) {
            td_days += `
                <td>
                    ${new WebfDate([2013, 8, 1]).addDays(value).toString('EEE').ucfirst()}
                </td>
            `;
        });

        var agenda_days = "" +
            "<div class='days'>" +
                "<table class='tb-days'>" +
                    "<tr>" +
                        td_days +
                    "</tr>" +
                "</table>" +
            "</div>" +
        "";

        var td_grid = "",
            td_agenda = "";
        start = this.start.clone();

        this.widthMonthDay = Math.round((self.e.width() / self._getDaysToShow().length)*10000)/10000 - 1;
        this.heightMonthDay = Math.round(((self.options.height/6) - 1)*10000)/10000;

        var n = 0;
        for( var i=0; i<6; i++) {
            var td_day = "";
            webf.each(this._getDaysToShow(), function(j, value) {
                var day = start.clone().addDays(i*7 + value);
                td_day += "" +
                    "<td style='width:" + self.widthMonthDay + "px; height:" + self.heightMonthDay + "px;' class='td-day-month td-day-month-" + j + "-" + i + " " + (i == 5 ? 'last-row' : '') + " " + (i == 0 ? 'first-row' : '') + " " + (j == 0 ? 'first-col' : '') + " " + (j == (self._getDaysToShow().length-1) ? 'last-col' : '') + "'>" +
                        "<div class='cell-day date-" + day.toString('yyyy-MM-dd') + "' data-date='" + day.toString('yyyy-MM-dd') + "'>" +
                            "<div class='num-day " + (day.month() == self.curMonth ? "current" : "" ) + "'>" +
                                "<span><a href='javascript:void(0);' class='link-to-day " + ( self.options.disabledLinks ? ' disabled' : '') + "'>" + day.toString('d') + "</a></span>" +
                            "</div>" +
                            "<div style='height:" + (((self.options.height/6) - 1) - 18) + "px;' class='events'>" +
                                "" +
                            "</div>" +
                        "</div>" +
                    "</td>" +
                "";
                n++;
            });

            td_grid += "<tr>" + td_day + "</tr>";
        }

        var agenda_grid = "" +
            "<div class='grid' style='height: " + this.options.height + "px'>" +
                "<table class='tb-grid'>" +
                    td_grid +
                "</table>" +
            "</div>" +
        "";

        var agenda = "<div class='webf-user-agenda agenda-month'>" + agenda_toolbar + agenda_days + agenda_grid + "</div>";

        this.e.empty().append(agenda);

        self.e.find('.cell-day .events').webfScrollbar({
            wheelSpeed: 5
        });

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._initDatePicker();
        }
    },

    _loadEvents: function()
    {
        var data = this.options.data;

        var afterLoadEvents = (events) => {
            webf.each(events || [], (i, event) => {
                this.addEvent(event, true);
            });

            if (this.options.allowOverlapEvents) {
                if (this.view == 'week') {
                    webf.each(this._getDaysToShow(), (i, value) => {
                        var day = this.start.clone().addDays(value);
                        if (this.options.users.length) {
                            webf.each(this.option('users'), (i, user) => {
                                this._setConfOverlapEvents(day, user);
                            });
                        } else {
                            this._setConfOverlapEvents(day);
                        }
                    });
                } else if (this.view == 'day') {
                    if (this.options.users.length) {
                        webf.each(this.option('users'), (i, user) => {
                            this._setConfOverlapEvents(this.start.clone(), user);
                        });
                    } else {
                        this._setConfOverlapEvents(this.start);
                    }
                }
            }

            this._renderEvents(this.events);

            this._call(this.option('onLoad'), this.events);
        };

        this._call(this.option('onBeforeLoad'), this.events);

        if (webf.isFunction(data)) {
            this._call(data, function(events) {
                afterLoadEvents(events);
            }, this.start.clone(), this.end.clone());
        } else if (webf.isString(data)) {
            var params = this.options.urlParams || {};

            params[this.options.startParam] = this.start.toString(this.options.formatDateParam);
            params[this.options.endParam] = this.end.toString(this.options.formatDateParam);

            $.ajax({
                url: data,
                data: params,
                success: afterLoadEvents,
                type: 'post',
                dataType: 'json'
            });
        } else {
            afterLoadEvents(data);
        }
    },

    _loadAnnotations: function()
    {
        const dataAnnotations = this.option('annotations');

        const afterLoadAnnotations = (annotations) => {
            webf.each(annotations || [], (i, annotation) => {
                this.addAnnotation(annotation);
            });

            this._call(this.option('onLoadAnnotations'), this.annotations);
        };

        this._call(this.option('onBeforeLoadAnnotations'), this.annotations);

        if (webf.isFunction(dataAnnotations)) {
            this._call(dataAnnotations, function(annotations) {
                afterLoadAnnotations(annotations);
            }, this.start.clone(), this.end.clone());
        } else if (webf.isString(dataAnnotations)) {
            const params = this.options.urlParams || {};

            params[this.options.startParam] = this.start.toString(this.options.formatDateParam);
            params[this.options.endParam] = this.end.toString(this.options.formatDateParam);

            $.ajax({
                url: dataAnnotations,
                data: params,
                success: afterLoadAnnotations,
                type: 'post',
                dataType: 'json'
            });
        } else {
            afterLoadAnnotations(dataAnnotations);
        }
    },

    _renderEvents: function(events, temporary)
    {
        webf.each(events, (i, event) => {
            var start = new WebfDate(event.start, event.format);
            var end = new WebfDate(event.end, event.format);

            if (!end.isLater(start, true)) {
                return true;
            }

            if (!(start.isEarlier(this.end) && end.isLater(this.start))) {
                return true;
            }

            if (end.isLater(start, 'day', true)) {
                if (end.diff(start, 'day', true) <= 1 && end.hours() == 0 && end.minutes() == 0) {
                    // le lendemain à minuit => ok
                } else {
                    return true;
                }
            }

            var num_day = (start.clone().day() + 6) % 7;

            var skin = event.skin ? event.skin : this.options.defaultSkin;
            var cssEvent = this._getCssSkin(skin, 'event');
            var cssTitle = this._getCssSkin(skin, 'title');
            var cssContent = this._getCssSkin(skin, 'content');

            if (webf.inArray(this.view,['week','day'])) {
                var widthEvent = 100,
                    leftEvent = 0;

                var top = (start.hours() - this.options.businessHours.start) * (this.options.timeslotHeight * this.options.timeslotsPerHour) + (start.minutes() / 60) * (this.options.timeslotHeight * this.options.timeslotsPerHour),
                    height = (end.hours() + (end.isLater(start, 'day', true) ? 24 : 0) - this.options.businessHours.start) * (this.options.timeslotHeight * this.options.timeslotsPerHour) + (end.minutes() / 60) * (this.options.timeslotHeight * this.options.timeslotsPerHour) - top;

                if (event._nbCols) {
                    widthEvent = (100 - this.options.marginOverlap) / event._nbCols;
                    leftEvent = widthEvent * event._col;

                    delete event._col;
                    delete event._group;
                    delete event._nbCols;
                }

                var $blockEvent = $("" +
                    "<div style='left:" + leftEvent + "%; width:" + widthEvent + "%;" + cssEvent + "top:" + top + "px; height: " + height + "px' class='event event-" + (event.id + '').toCssClassName() + (temporary ? " temporary" : "") + "'>" +
                        "<div style='" + cssTitle + "' class='title" + (!this.options.readonly && this.options.draggable && !event.readonly ? " draggable" : "") + "'>" + start.toString("H:mm") + " - " + end.toString("H:mm") + "</div>" +
                        "<div style='" + cssContent + " 'class='content'>" + event.content + "</div>" +
                        (!this.options.readonly && this.options.resizable && !event.readonly ? "<div class='handle-resizable'>=</div>" : "" ) +
                    "</div>" +
                "").data('event',event).data('col', num_day);

                if (event.classname) {
                    $blockEvent.addClass(event.classname);
                }

                var $colFullDay = this.e.find('.col-day-' + num_day + ' .col-day-full');
                if (this.options.users.length && !webf.isUndefined(event.user)) {
                    $blockEvent.data('user', event.user.id);
                    $colFullDay.find('.td-user.user-' + event.user.id + " .col-user").append($blockEvent);
                } else {
                    $colFullDay.append($blockEvent);
                }
            } else if (this.view == 'month') {
                var $blockEvent = $("" +
                    "<div style='" + cssEvent + "' class='event event-" + (event.id + '').toCssClassName() + "'>" +
                        "<div style='" + cssTitle + "' class='title " + (!this.options.readonly && this.options.draggable && !event.readonly ? " draggable" : "") + "'><b>" + start.toString("H:mm") + " - " + end.toString("H:mm") + "</b> " + event.content + "</div>" +
                    "</div>" +
                "").data('event',event).data('start',start);

                if (event.classname) {
                    $blockEvent.addClass(event.classname);
                }

                var $events = this.e.find(".cell-day.date-" + start.toString('yyyy-MM-dd') + " .events");

                if ($events.length) {
                    var event_inserted = false;
                    $events.find('.event').each(function(i,event) {
                        var $event = $(event);
                        if ($event.data('start').isLater(start)) {
                            $event.before($blockEvent);
                            event_inserted = true;
                            return false;
                        }
                    });

                    if (!event_inserted) {
                        $events.webfScrollbar('container').append($blockEvent);
                    }

                    $events.webfScrollbar('update');
                }
            }
        });
    },

    getRenderedEvent: function(event)
    {
        return this.e.find('.event-' + (event.id + '').toCssClassName());
    },

    getRenderedAnnotation: function(annotation)
    {
        return this.e.find('.annotation-' + (annotation.id + ''));
    },

    _setConfOverlapEvents: function(day, user)
    {
        var webfDay = new WebfDate(day);

        var events = webf.map(this.events, function(i, event) {
            if (new WebfDate(event.start, event.format).equals(webfDay, 'day')) {
                if (webf.isUndefined(user) || user.id == event.user.id) {
                    return event;
                }
            }

            return null;
        });

        if (!events.length) {
            return;
        }

        events = events.sort(function(a, b) {
            return new WebfDate(a.start, a.format).unix() - new WebfDate(b.start, b.format).unix();
        });

        var tmpEvents = [],
            max_ends = [],
            nb_cols = [],
            prevEvent,
            max_group_end_date = [];

        webf.each(events, function(i, event) {
            var start_cur_ev = new WebfDate(event.start, event.format).unix(),
                end_cur_ev = new WebfDate(event.end, event.format).unix();

            if (!tmpEvents.length) {
                event._group = 0;
                event._col = 0;
                prevEvent = event;
            } else {
                if (start_cur_ev < max_group_end_date[prevEvent._group]) {
                    event._group = prevEvent._group;
                } else {
                    event._group = prevEvent._group + 1;
                }

                prevEvent = event;

                webf.each( tmpEvents, function(j, ev) {
                    var end_ev = new WebfDate(ev.end, ev.format).unix();

                    if (start_cur_ev >= end_ev) {
                        if (webf.isUndefined(max_ends[ev._col]) || start_cur_ev >= max_ends[ev._col]) {
                            event._col = ev._col;
                            return false;
                        }
                    } else {
                        event._col = webf.isUndefined(event._col) ? 1 : event._col + 1;
                    }
                });
            }

            max_group_end_date[event._group] = Math.max(!isNaN(max_group_end_date[event._group]) ? max_group_end_date[event._group] : 0, new WebfDate(event.end,event.format).unix());
            nb_cols[event._group] = Math.max(!isNaN(nb_cols[event._group]) ? nb_cols[event._group] : 1, event._col + 1);
            max_ends[event._col] = Math.max(!isNaN(max_ends[event._col]) ? max_ends[event._col] : 1, end_cur_ev);
            tmpEvents.push(event);
        });

        webf.each(events, function(i, event) {
            event._nbCols = nb_cols[event._group];
        });

        return events;
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.e, '.event', {
            mousedown: (ev) => {
                this.mousedownonagenda = true;
                this.moving = false;
                this.creatingOnClick = false;
                ev.stopPropagation();
                this.startPointMouse = $webf.getMousePos(ev);
            },
            mouseup: (ev) => {
                ev.preventDefault();

                if (this.mousedownonagenda) {
                    this.mousedownonagenda = false;
                    var $event = $(ev.currentTarget);
                    var event = $event.data('event');

                    if (!this.moving && !this.creating && !this.creatingOnClick) {
                        this._call(self.options.eventClick, event, $event);
                    }
                }
            },
            mouseenter: (ev) => {
                if (!this.moving && !this.creating && !this.draggingMonthEvent && !this.dragging && !this.resizing) {
                    ev.preventDefault();
                    this.mouseEnterOnEvent = true;

                    const $event = $(ev.currentTarget);
                    const event = $event.data('event');

                    this._call(this.option('eventMouseover'), event, $event);
                }
            },
            mouseleave: (ev) => {
                if (this.mouseEnterOnEvent && !this.moving && !this.creating && !this.draggingMonthEvent && !this.dragging && !this.resizing) {
                    ev.preventDefault();
                    this.mouseEnterOnEvent = false;

                    const $event = $(ev.currentTarget);
                    const event = $event.data('event');

                    this._call(this.option('eventMouseout'), event, $event);
                }
            },
        });

        this._on(this.e, '.event .title.draggable, .event .handle-resizable', {
            mousedown: (ev) => {
                ev.preventDefault();

                const $event = $(ev.currentTarget).closest('.event');

                this.oldEvent = webf.extend(true, {}, $event.data('event'));
                this.startPoint = getMousePos(ev, $event);

                if ($(ev.target).hasClass('handle-resizable')) {
                    this.resizing = $event;
                } else if ($(ev.target).hasClass('title') || $(ev.target).parent().hasClass('title')) {
                    this.dragging = $event;
                }
            }
        })

        this._on(this.e, (this.option('users').length ? '.td-user' : '.td-col-day') + ', .td-day-month', {
            mousemove: (ev) => {
                ev.preventDefault();

                if (this.creatingOnClick && this.options.newEventOnClick) {
                    return;
                }

                if (this.mousedownonagenda && (Math.abs(this.startPointMouse.x - $webf.getMousePos(ev).x) > this.options.tolerance || Math.abs(this.startPointMouse.y - $webf.getMousePos(ev).y) > this.options.tolerance)) {
                    this.moving = true;
                }

                if (this.options.allowOverlapEvents && (this.dragging || this.resizing)) {
                    var $tmpEvent = this.dragging || this.resizing;

                    if (!$tmpEvent.hasClass('temporary')) {
                        $tmpEvent.addClass('temporary');

                        var $cloneEvent = $tmpEvent.clone(true);
                        $cloneEvent.appendTo($tmpEvent.parent());
                        $tmpEvent.hide();

                        if (this.dragging) {
                            this.dragging = $cloneEvent;
                        } else if (this.resizing) {
                            this.resizing = $cloneEvent;
                        }
                    }
                }

                if (this.dragging) {
                    var $event = this.dragging;

                    if (webf.inArray(this.view, ['week','day'])) {
                        var user_col = $event.data('user') ? $event.data('user').id : null;
                        var event_col = $event.data('col');
                        var num_user_col, num_col;

                        if ($(ev.target).closest('td').hasClass('td-user')) {
                            num_user_col = $(ev.currentTarget).data('user');
                            num_col = $(ev.currentTarget).closest('.td-col-day').data('col');
                        } else {
                            num_col = $(ev.currentTarget).data('col');
                        }

                        var mp = getMousePos(ev, $(ev.currentTarget));

                        if (!webf.isUndefined(num_user_col)) {
                            if (num_user_col != user_col) {
                                var user = this._getUserById(num_user_col, '_bindEvents');
                                $event.data('user', user);
                                $event.data('col', num_col);

                                var event = $event.data('event');
                                event.user = user;
                                $event.data('event',event);

                                var $clone = $event.clone(true);

                                this.e.find(".col-day-" + num_col + " .col-day-full .td-user.user-" + num_user_col + " .col-user").append($clone);

                                $event.remove();

                                $event = $clone;
                                this.dragging = $event;
                            }
                        } else {
                            if (num_col != event_col) {
                                $event.data('col', num_col);
                                var $clone = $event.clone(true);

                                this.e.find('.col-day-' + num_col + ' .col-day-full').append($clone);

                                $event.remove();

                                $event = $clone;
                                this.dragging = $event;
                            }
                        }

                        $event.css('top', webf.between(Math.ceil((mp.y - this.startPoint.y) / this.options.timeslotHeight) * this.options.timeslotHeight, 0, $(ev.currentTarget).height() - $event.height()));
                    } else if (this.view == 'month') {
                        if (!this.draggingMonthEvent) {
                            var event = $event.data('event');
                            var $cloneEvent = $event.clone(true);
                            $('body').append($cloneEvent.addClass('webf-user-agenda-dragging-event').css({
                                position: 'absolute',
                                width: $event.innerWidth(),
                                height: $event.innerHeight()
                            }));

                            this.draggingMonthEvent = $cloneEvent;
                            this.removeEvent(event);
                        }
                    }
                } else if (this.resizing) {
                    var $event = this.resizing;

                    var mp = getMousePos(ev, $(ev.currentTarget));

                    $event.height(Math.max(this.options.timeslotHeight,Math.ceil((mp.y - $event.position().top) / this.options.timeslotHeight) * this.options.timeslotHeight));
                }
            }
        });

        this._on(this.e, '.tb-agenda-days, .td-day-month', {
            mousedown: (ev) => {
                if (this.option('readonly')) {
                    return;
                }

                ev.preventDefault();

                self.startPointMouse = $webf.getMousePos(ev);
                self.mousedownonagenda = true;

                const fmt = 'yyyy-MM-dd HH:mm';
                let start, end, dayDate, mp;
                let $colDay, colDay, $colUser, $event;

                const event = {
                    id: new WebfDate().time() + webf.uniqid(),
                    content: self.options.newEventText,
                    format: fmt
                };

                if (webf.inArray(self.view,['week','day'])) {
                    $colUser = $(ev.target).closest('td');
                    if ($colUser.hasClass('td-user')) {
                        event.user = self._getUserById($colUser.data('user'));
                    }

                    $colDay = $(ev.target).closest('.td-col-day');
                    colDay = $colDay.data('col');

                    mp = getMousePos(ev, $colDay);

                    start = self.start.clone().addDays((self.view == 'week' ? colDay : 0))
                        .addMinutes(
                            Math.floor(mp.y / self.options.timeslotHeight) *
                            (60 / self.options.timeslotsPerHour)
                        );

                    event.start = start.toString(fmt);
                    event.end = start.clone().addMinutes((60 / self.options.timeslotsPerHour)).toString(fmt);

                    $event = self._createEvent(event);

                    self.resizing = $event;
                } else if (self.view == 'month') {
                    dayDate = $(this).find('.cell-day').data('date');

                    start = new WebfDate(dayDate,'yyyy-MM-dd').hours(self.options.businessHours.start);
                    end = start;

                    event.start = start.toString(fmt);
                    event.end = end.toString(fmt);

                    $event = self.addEvent(event);

                    if (false == self._call(self.options.eventNew, event, $event)) {
                        self.removeEvent(event);
                    }
                }

                if (self.options.newEventOnClick) {
                    self.creatingOnClick = true;
                    self._dragend.call(self, $event);
                } else {
                    self.creating = true;
                }
            }
        });

        this._on(this.e, '.tb-days .td-day .link-days', {
            click: (ev) => {
                if (this.options.disabledLinks) {
                    return false;
                }
                var num_day = $(ev.currentTarget).closest('.td-day').prevAll('.td-day').length;
                var wd = this.start.clone().addDays(num_day);
                this.changeView('day', wd);
            }
        })._on('.td-day-month .cell-day .num-day a.link-to-day', {
            mousedown: (ev) => {
                if (!this.options.disabledLinks) {
                    ev.stopPropagation();
                }
            }
        })._on('.td-day-month .cell-day .num-day a.link-to-day', {
            click: (ev) => {
                if (this.options.disabledLinks) {
                    return false;
                }
                this.changeView('day', new WebfDate($(ev.currentTarget).closest('.cell-day').data('date'),'yyyy-MM-dd'));
            }
        })

        this._on(document, {
            'mouseup blur': (ev) => {
                if (this.moving || this.creating) {
                    if (this.draggingMonthEvent) {
                        this._dragend.call(this, this.draggingMonthEvent);
                        this.e.find('.td-day-month').removeClass('drop');
                        this.draggingMonthEvent.remove();
                    } else if (this.dragging) {
                        this._dragend.call(this, this.dragging);
                    } else if (this.resizing) {
                        this._dragend.call(this, this.resizing);
                    }
                }

                this.moving = false;
                this.creating = false;
                this.draggingMonthEvent = false;
                this.dragging = false;
                this.resizing = false;
            },
            mousemove: (ev) => {
                if (this.dragging || this.resizing) {
                    if (this.draggingMonthEvent) {
                        var $grid = this.e.find('.webf-user-agenda .grid');
                        var mp = getMousePos(ev);
                        var mp_grid = getMousePos(ev, $grid);
                        var width_grid = $grid.outerWidth();
                        var height_grid = $grid.outerHeight();

                        this.e.find('.td-day-month').removeClass('drop');
                        if (mp_grid.x <= 0 || mp_grid.y <= 0 || mp_grid.x >= width_grid || mp_grid.y >= height_grid) {
                            this.draggingMonthEvent.removeData('day');
                        } else {
                            var abc = Math.floor(mp_grid.x / this.widthMonthDay);
                            var ord = Math.floor(mp_grid.y / this.heightMonthDay);
                            var $td = this.e.find('.td-day-month-' + abc + '-' + ord);
                            $td.addClass('drop');
                            this.draggingMonthEvent.data('day',$td.find('.cell-day').data('date'));
                        }

                        this.draggingMonthEvent.css({
                            top: mp.y - this.startPoint.y,
                            left: mp.x - this.startPoint.x
                        });
                    }

                    ev.preventDefault();
                }
            }
        });

        this._on(this.e, '.allday .annotation', {
            click: (ev) => {
                ev.stopPropagation();
                const $annotation = $(ev.currentTarget);
                const annotation = $annotation.data('annotation');

                this._call(this.option('annotationClick'), annotation, $annotation);
            }
        })._on(this.e, '.allday .tb-days tr', {
            click: (ev) => {
                ev.stopPropagation();
                const $td = $(ev.target);
                const numDay = $td.prevAll().length;

                this._call(self.option('annotationNew'), this.start.clone().addDays(numDay));
            }
        });

        this._on(window, {
            resize: webf.throttle((ev) => {
                this._setHeight(this.option('height'));
            }, 150, false, true)
        });

        if (this.option('hourMarker')) {
            webf.setInterval(function() {
                var now = new WebfDate();

                self.e.find('.hour-marker').hide();
                var $hourMarker = self.e.find('.td-col-day.today .hour-marker');

                var heightPerHour = self.option('timeslotHeight') * self.option('timeslotsPerHour');
                var top = ((now.hours() + (now.minutes() / 60)) * heightPerHour)
                    - (self.option('businessHours').start * heightPerHour)
                    - ($hourMarker.height() / 2);

                $hourMarker.css('top', top).show();
            }, 1000);
        }
    },

    _dragend: function($event)
    {
        var self = this;

        if (webf.inArray(this.view,['week','day'])) {
            var newEvent = $event.data('event');

            var px_per_hour = this.options.timeslotsPerHour * this.options.timeslotHeight;
            var start_newEvent = new WebfDate(this.start.clone().addDays((self.view == 'week' ? $event.data('col') : 0))).addMinutes(webf.round(($event.position().top / px_per_hour) * 60));
            var end_newEvent = start_newEvent.clone().addMinutes(($event.height() / px_per_hour) * 60);

            newEvent.start = start_newEvent.toString(newEvent.format);
            newEvent.end = end_newEvent.toString(newEvent.format);

            if (this.options.allowOverlapEvents || this._isFree(newEvent)) {
                $event.find('.title').text(start_newEvent.toString("H:mm") + " - " + end_newEvent.toString("H:mm"));
                $event.data('event',newEvent);
                $event.removeClass('temporary');

                if (this.creating || this.creatingOnClick) {
                    if (false === this._call(this.options.eventNew, newEvent, $event)) {
                        this.removeEvent(newEvent);
                    }
                } else {
                    if (false === this._call(this.options.eventChange, newEvent, $event)) {
                        this.removeEvent(newEvent);
                        this.addEvent(this.oldEvent);
                    }
                }

                if (this.options.allowOverlapEvents) {
                    if (!this.creating && this.oldEvent) {
                        this._renderDayEvents(new WebfDate(this.oldEvent.start, this.oldEvent.format));
                    }
                    this._renderDayEvents(new WebfDate(newEvent.start, newEvent.format));
                }
            } else {
                if (this.creating) {
                    this.removeEvent($event.data('event'));
                } else {
                    this.removeEvent(newEvent);
                    this.addEvent(this.oldEvent);
                }

                $event.remove();
            }
        } else if (this.view == 'month') {
            var newEvent = $event.data('event');

            var newDay = new WebfDate($event.data('day'),'yyyy-MM-dd');
            var oldStartDate = new WebfDate(this.oldEvent.start,this.oldEvent.format);
            var oldEndDate = new WebfDate(this.oldEvent.end,this.oldEvent.format);

            newEvent.start = newDay.hours(oldStartDate.hours()).minutes(oldStartDate.minutes()).seconds(0).toString(this.oldEvent.format);
            newEvent.end = newDay.hours(oldEndDate.hours()).minutes(oldEndDate.minutes()).seconds(0).toString(this.oldEvent.format);

            if (this._isFree(newEvent)) {
                this.removeEvent(this.oldEvent);
                $event = this.addEvent(newEvent);

                if (this.creating) {
                    if (false === this._call(this.options.eventNew, newEvent, $event)) {
                        this.removeEvent(newEvent);
                    }
                } else {
                    if (false === this._call(this.options.eventChange, newEvent, $event)) {
                        this.removeEvent(newEvent);
                        this.addEvent(this.oldEvent);
                    }
                }
            } else {
                if (this.creating) {
                    this.removeEvent(newEvent);
                } else {
                    this.removeEvent(newEvent);
                    this.addEvent(this.oldEvent);
                }
            }
        }
    },

    _bindButtons: function()
    {
        this._on(this.e, '.webf-button.next-week', {
            click: (ev) => {
                this.displayWeek(this.start.clone().addWeek());
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.prev-week', {
            click: (ev) => {
                this.displayWeek(this.start.clone().subWeek());
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.next-day', {
            click: (ev) => {
                const newDate = this.start.clone().addDay();
                this.daysToShow = [(newDate.day() + 6) % 7];
                this.displayDay(newDate);
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.prev-day', {
            click: (ev) => {
                const newDate = this.start.clone().subDay();
                this.daysToShow = [(newDate.day() + 6) % 7];
                this.displayDay(newDate);
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.prev-month', {
            click: (ev) => {
                var startmonth = this.start.clone().startOfMonth();
                var start = this.start.equals(startmonth,'day') ? startmonth : startmonth.addMonth();
                this.displayMonth(start.subMonth());
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.next-month', {
            click: (ev) => {
                this.displayMonth(this.start.clone().month(this.curMonth + 1).startOfMonth());
                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.webf-button.today', {
            click: (ev) => {
                var today = new WebfDate();
                if (this.view == 'month') {
                    this.displayMonth(today);
                } else if (this.view == 'week') {
                    this.displayWeek(today);
                } else if (this.view == 'day') {
                    this.daysToShow = [(today.day() + 6) % 7];
                    this.displayDay(today);
                }

                this._call(this.options.onChangeView, this.getView(), this.start, this.end);
            }
        })._on(this.e, '.views .webf-button', {
            click: (ev) => {
                if ($(ev.currentTarget).hasClass('datepicker')) {
                    ev.preventDefault();
                    return;
                }

                $(ev.currentTarget).closest('.views').find('.webf-button').removeClass('selected');
                $(ev.currentTarget).addClass('selected');

                this.prevView = this.view;
                if ($(ev.currentTarget).hasClass('week')) {
                    this.changeView('week');
                } else if ($(ev.currentTarget).hasClass('month')) {
                    this.changeView('month');
                } else if ($(ev.currentTarget).hasClass('day')) {
                    this.changeView('day');
                }
            }
        });
    },

    _isFree: function(newEvent)
    {
        var free = true;

        var start_newEvent = new WebfDate(newEvent.start,newEvent.format);
        var end_newEvent = new WebfDate(newEvent.end,newEvent.format);

        webf.each(this.events, function(i, event) {
            if (event.id != newEvent.id && (webf.isUndefined(event.user) || event.user.id == newEvent.user.id)) {
                var start_event = new WebfDate(event.start,event.format),
                    end_event = new WebfDate(event.end,event.format);

                if (!(start_event.isLater(end_newEvent) || start_event.equals(end_newEvent,'minute'))
                    && !(end_event.isEarlier(start_newEvent) || end_event.equals(start_newEvent,'minute')) )
                {
                    free = false;
                    return false;
                }
            }
        });

        return free;
    },

    _getCssSkin: function(skin, elem)
    {
        if (!skin) {
            return "";
        }

        skin = this.options.skins[skin];

        if (!skin) {
            return "";
        }

        return webf.map(skin, function(index, value) {
            if (elem == 'event') {
                if (index == 'color') {
                    return 'color:' + value;
                } else if (index == 'opacity') {
                    return 'opacity: ' + value + '; filter:alpha(opacty=' + parseInt(value * 100) + ')';
                }
            } else if (elem == 'title') {
                if (index == 'backgroundColorTitle') {
                    return 'background-color:' + value;
                } else if (index == 'borderColor') {
                    return 'border-color:' + value;
                }
            } else if (elem == 'content') {
                if (index == 'backgroundColorContent') {
                    return 'background-color:' + value;
                } else if (index == 'borderColorContent') {
                    return 'border-color:' + value;
                }
            }
        }).join(';') + ";";
    },

    _viewChange: function(newView, date)
    {
        if (date) {
            return new WebfDate(date);
        }

        var now = new WebfDate();

        if (this.start.clone().startOfDay().isEarlier(now) && this.end.clone().endOfDay().isLater(now)) {
            return now;
        }

        if (newView == 'day') {
            if (this.prevView == 'month') {
                date = new WebfDate([this.curYear, this.curMonth, 1]);
            } else if (this.prevView == 'week') {
                date = this.start.clone().day(now.day());
            }
        }

        return (date ? date : this.start.clone());
    },

    _createEvent: function(event)
    {
        this.addEvent(event, true);
        this._renderEvents([event], true);
        return this.getRenderedEvent(event);
    },

    _renderDayEvents: function(day, user)
    {
        var self = this;

        if (webf.isUndefined(user) && this.options.users.length) {
            webf.each(this.options.users, (i, user) => {
                this._renderDayEvents(day, user);
            });
        } else {
            var _day = day.clone();
            var eventsDay = this.getEvents(_day.startOfDay(), _day.clone().addDay(), user);

            webf.each(eventsDay, function(j, eventDay) {
                var $event = self.getRenderedEvent(eventDay);
                if ($event.length) {
                    $event.remove();
                }
            });

            if (this.options.allowOverlapEvents) {
                this._setConfOverlapEvents(_day, user);
            }

            this._renderEvents(eventsDay);
        }
    },

    _renderDayAnnotations: function(day)
    {
        const $allDay = this.e.find('.allday');
        let $td;

        const annotationsDay = webf.map(this.annotations, (i, annotation) => {
            if (new WebfDate(annotation.date, annotation.format).equals(day, 'day')) {
                return annotation;
            }

            return null;
        });

        webf.each(annotationsDay, (i, annotation) => {
            if (this.option('userAnnotations')) {
                $td = $allDay.find('.tb-days .td-col-allday').eq(day.diff(this.start.clone().startOfDay(), 'day') * this.option('users').length);
            } else {
                $td = $allDay.find('.tb-days .td-col-allday').eq(day.diff(this.start.clone().startOfDay(), 'day'));
            }

            if (this.option('userAnnotations')) {
                if (annotation.user) {
                    const user = this._getUserById(annotation.user);

                    webf.each(this.option('users'), (i, optUser) => {
                        if (optUser.id == user.id) {
                            return false;
                        }

                        $td = $td.next();
                    });
                }
            }

            let $annotation = this.getRenderedAnnotation(annotation);
            $annotation[0] && $annotation.remove();

            const skin = annotation.skin ? annotation.skin : this.option('defaultSkin');
            const cssEvent = this._getCssSkin(skin, 'event');
            const cssContent = this._getCssSkin(skin, 'content');

            $annotation = $(`
                <div class="annotation annotation-${annotation.id}" style="${cssEvent} ${cssContent}">
                    ${annotation.content}
                </div>
            `)
                .addClass(annotation.classname)
                .data('annotation', annotation);

            $td.append($annotation);
        });

        this._setHeight(this.option('height'));
    },

    _formatDate: function(date, format)
    {
        var wd = new WebfDate(date);

        format = format.replace(/MMMM(?!((?![\[\]]).)*\])/g, '[' + this._(monthName[wd.month()]) + ']');
        format = format.replace(/MMM(?!((?![\[\]]).)*\])/g, '[' + this._(shortMonthName[wd.month()]) + ']');
        format = format.replace(/EEEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,3).ucfirst() + ']');
        format = format.replace(/E(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,1).ucfirst() + ']');

        return wd.toString(format);
    },

    _getUserById: function(id_user)
    {
        let user = null;

        webf.each(this.option('users'), (i, aUser) => {
            if (aUser.id == id_user) {
                user = webf.clone(aUser);
            }
        });

        if (!user) {
            throw `id_user introuvable : ${id_user}`;
        }

        return user;
    },

    _initDatePicker: function()
    {
        var $btnDatePicker = this.e.find('.toolbar .datepicker');

        $btnDatePicker.webfDatetimepicker({
            position: {
                my:         'right top',
                at:         'right bottom',
                collision:  'flipfit none'
            },
            timepicker: false,
            durationAnimation: 400,
            date: this.start.clone(),
            buttons: {
                today: {
                    cls: 'outline-primary',
                    label: this._('today'),
                    click: () => {
                        this.changeView('day', new WebfDate);
                    }
                }
            },
            showMonthYear: true,
            onSelectDate: (date) => {
                this.changeView('day', date);
            }
        }).data('datepicker', 1);
    },

    _removeDatePicker: function()
    {
        var $btnDatePicker = this.e.find('.toolbar .datepicker');
        if ($btnDatePicker.data('datepicker')) {
            $btnDatePicker.webfDatetimepicker('destroy');
        }
    },

    getEvents: function(start, end, user)
    {
        var self = this;

        start = new WebfDate(start);
        end = new WebfDate(end);

        var events = [];

        webf.each(this.events, function(i, event) {
            var startEvent = new WebfDate(event.start, event.format);

            if ((!self.options.users.length || event.user.id == user.id) &&
                (startEvent.isLater(start,false) && end.isLater(startEvent,false))
           ) {
                events.push(event);
            }
        });

        return events;
    },

    refresh: function()
    {
        this.clearEvents();
        this.clearAnnotations();
        const curScrollTop = this.e.find('.grid').webfScrollbar('scrollTop');

        if (this.view == 'month') {
            this._drawMonthAgenda();
        } else if (webf.inArray(this.view, ['week', 'day'])) {
            this._drawWeekAgenda();
        }

        this.e.find('.grid').webfScrollbar('scrollYTo', curScrollTop);

        this._loadEvents();
        this._loadAnnotations();
    },

    addEvent: function(event, norender)
    {
        if (webf.isUndefined(event.id)) {
            event.id = new WebfDate().time() + webf.uniqid();
        }

        if (this.getEventById(event.id)) {
            this.removeEvent(event);
            this.addEvent(event);
        }

        if (this.options.users.length) {
            let id_user;

            if (webf.isUndefined(event.user)) {
                event.user = this.option('users')[0];
            } else {
                if (webf.isObject(event.user)) {
                    if (event.user.id) {
                        id_user = event.user.id;
                    } else {
                        id_user = this.option('users')[0].id;
                    }
                } else {
                    id_user = event.user;
                }

                try {
                    event.user = this._getUserById(id_user);
                } catch {
                    return;
                }
            }
        }

        if (this.options.allowOverlapEvents || this._isFree(event)) {
            this.events.push(event);

            if (!norender) {
                this._renderDayEvents(new WebfDate(event.start, event.format));
                return this.getRenderedEvent(event);
            }
        }

        return false;
    },

    editEvent: function(editEvent)
    {
        webf.each(this.events, (i, event) => {
            if (editEvent.id == event.id) {
                this.removeEvent(editEvent);

                if (this.view == 'month') {
                    this.addEvent(editEvent);
                } else if (webf.inArray(this.view,['week','day'])) {
                    this.addEvent(editEvent, true);
                    this._renderDayEvents(new WebfDate(editEvent.start, editEvent.format));
                }

                return false;
            }
        });
    },

    removeEvent: function(event)
    {
        webf.each(this.events, (i, aevent) => {
            if (aevent.id == event.id) {
                const copyEvent = webf.clone(event);
                const $event = this.getRenderedEvent(event);
                let $events;

                if (this.view == 'month') {
                    $events = $event.closest('.events');
                }

                this.events.splice(i,1);
                $event.remove();

                if (this.options.allowOverlapEvents) {
                    this._renderDayEvents(new WebfDate(copyEvent.start, copyEvent.format), event.user);
                }

                if (this.view == 'month') {
                    $events.webfScrollbar('update');
                }

                return false;
            }
        });
    },

    clearEvents: function()
    {
        webf.each(this.events, (i, event) => {
            this.getRenderedEvent(event).remove();
        });

        this.events = [];
    },

    addAnnotation: function(annotation)
    {
        if (webf.isUndefined(annotation.id)) {
            annotation.id = new WebfDate().time() + webf.uniqid();
        }

        if (this.getAnnotationById(annotation.id)) {
            this.removeAnnotation(annotation);
            this.addAnnotation(annotation);
        }

        this.annotations.push(annotation);

        this._renderDayAnnotations(new WebfDate(annotation.date, annotation.format));
        return this.getRenderedAnnotation(annotation);
    },

    editAnnotation: function(editAnnotation)
    {
        webf.each(this.annotations, (i, annotation) => {
            if (editAnnotation.id == annotation.id) {
                this.removeAnnotation(editAnnotation);

                if (webf.inArray(this.view,['week','day'])) {
                    this.addAnnotation(editAnnotation);
                    this._renderDayAnnotations(new WebfDate(editAnnotation.date, editAnnotation.format));
                }

                return false;
            }
        });
    },

    removeAnnotation: function(annotation)
    {
        webf.each(this.annotations, (i, aAnnotation) => {
            if (aAnnotation.id == annotation.id) {
                const copyAnnotation = webf.clone(annotation);
                const $annotation = this.getRenderedAnnotation(annotation);

                this.annotations.splice(i, 1);
                $annotation.remove();

                this._renderDayAnnotations(new WebfDate(copyAnnotation.date, copyAnnotation.format));

                return false;
            }
        });
    },

    clearAnnotations: function()
    {
        webf.each(this.annotations, (i, annotation) => {
            this.getRenderedAnnotation(annotation).remove();
        });

        this.annotations = [];
    },

    changeView: function(newView, given_date)
    {
        var getDate = () => this._viewChange(newView, given_date);

        if (newView == 'month') {
            this.daysToShow = this.options.daysToShow;
            this.displayMonth(getDate());
        } else if (newView == 'week') {
            this.daysToShow = this.options.daysToShow;
            this.displayWeek(getDate());
        } else if (newView == 'day') {
            var date = getDate();

            this.daysToShow = [(date.day() + 6) % 7];
            this.displayDay(date);
        }

        this._call(this.options.onChangeView, this.getView(), this.start, this.end);
    },

    displayWeek: function(date)
    {
        this.view = 'week';

        var scrollTop = this.e.find('.grid').webfScrollbar('scrollTop');

        this._initStartEndOfWeek(date);
        this.refresh();

        this.e.find('.grid').webfScrollbar('scrollTo', {y: scrollTop});
    },

    displayMonth: function(date)
    {
        this.view = 'month';
        this._initStartEndOfMonth(date);
        this.refresh();
    },

    displayDay: function(date)
    {
        this.view = 'day';
        this.daysToShow = [(date.day() + 6) % 7];

        var scrollTop = this.e.find('.grid').webfScrollbar('scrollTop');

        this._initStartEndOfDay(date);
        this.refresh();

        this.e.find('.grid').webfScrollbar('scrollTo', {y: scrollTop});
    },

    getStart: function()
    {
        return this.start;
    },

    getEnd: function()
    {
        return this.end;
    },

    isFree: function(event)
    {
        return this._isFree(event);
    },

    getLastEvent: function(event)
    {
        var id_user = webf.isUndefined(event.user) ? null : event.user.id;
        var lastEvent = null;
        var wdEventStart = new WebfDate(event.start, event.format);

        webf.each(this.events, function(i, ev) {
            if (event.id != ev.id && (id_user === null || ev.user.id == id_user)) {
                var evStart = new WebfDate(ev.start, ev.format);
                if (wdEventStart.isLater(evStart,false)) {
                    if (!lastEvent) {
                        lastEvent = ev;
                        return true;
                    }

                    if (new WebfDate(lastEvent.start, lastEvent.format).isEarlier(evStart)) {
                        lastEvent = ev;
                    }
                }
            }
        });

        return lastEvent;
    },

    getNextEvent: function(event)
    {
        var id_user = webf.isUndefined(event.user) ? null : event.user.id;
        var nextEvent = null;
        var wdEventStart = new WebfDate(event.start, event.format);

        webf.each(this.events, function(i, ev) {
            if (event.id != ev.id && (id_user === null || ev.user.id == id_user)) {
                var evStart = new WebfDate(ev.start, ev.format);
                if (evStart.isLater(wdEventStart,false)) {
                    if (!nextEvent) {
                        nextEvent = ev;
                        return true;
                    }

                    if (evStart.isEarlier(new WebfDate(nextEvent.start, nextEvent.format))) {
                        nextEvent = ev;
                    }
                }
            }
        });

        return nextEvent;
    },

    getEventById: function(id)
    {
        var retEvent = null;

        webf.each(this.events, function(i, event) {
            if (event.id == id) {
                retEvent = webf.clone(event);
                return true;
            }
        });

        return retEvent;
    },

    getAnnotationById: function(id)
    {
        var retAnnotation = null;

        webf.each(this.annotations, function(i, annotation) {
            if (annotation.id == id) {
                retAnnotation = webf.clone(annotation);
                return true;
            }
        });

        return retAnnotation;
    },

    getView: function()
    {
        return this.view;
    },

    getUIEvent: function(event)
    {
        const id_event = webf.isObject(event) ? event.id : event;
        const oEvent = this.getEventById(id_event);

        return this.getRenderedEvent(oEvent);
    },

    open: function(event)
    {
        const id_event = webf.isObject(event) ? event.id : event;
        const _event = this.getEventById(id_event);
        const $event = this.getRenderedEvent(_event);

        this._call(this.options.eventClick, _event, $event);
    },

    // Backward compatibility
    triggerClick: function(event)
    {
        this.open(event);
    },

    setDefaultSkin: function(skin)
    {
        this.options.defaultSkin = skin;
    },

    _getDaysToShow: function()
    {
        return webf.isArray(this.daysToShow)
            ? this.daysToShow
            : (
                webf.isArray(this.option('daysToShow'))
                ? this.option('daysToShow')
                : this.option('daysToShow').split(',')
            )
        ;
    },

    _setHeight: function(height)
    {
        let heightGrid = height;

        if (height == 'fitbottom') {
            const wh = window.innerHeight;
            const topGrid = this.e.find('.grid').offset().top;

            heightGrid = wh - topGrid - 1;
        }

        this.e.find('.grid')
            .css('height', heightGrid)
            .webfScrollbar('update');
    },

    _destroy: function()
    {
        this.e.find('.toolbar .datepicker').webfDatetimepicker('destroy');
        this.e.find('.grid').webfScrollbar('destroy');
        this.e.empty();
    }
});

function getMousePos(evt, $elem)
{
    var mp = $webf.getMousePos(evt, $elem);

    return {
        x: webf.between(mp.x, 0, $elem.width()),
        y: webf.between(mp.y, 0, $elem.height())
    };
};
