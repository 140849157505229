import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import '../i18n/autocomplete'
import './dropdown'
import './tagsinput'
import $ from 'jquery'

/**
 * options :
 *  - behavior:          (string) Comportement de l'autocomplete.
 *                        - single : Un seul élément peut être sélectionné à la fois
 *                        - tags : L'input sera transformé en Webf/Tagsinput
 *  - delay:             (int) Temps en ms à attendre avant que la recherche sur la source ne soit effectuée
 *  - minLength:         (int) Nombre de caractères minimum pour lancer la recherche
 *                       Si minLength = 0 et que la source est un Array, alors l'autocomplete se comportera comme un combobox
 *  - position:          (string) left ou right
 *  - source:            (object[]|string|function|object) Source des données
 *                        - object[] : Les objets doivent contenir au moins les clés "label" et "value".
 *                        - string : la source est considéré comme une URL, elle sera appellé en ajax. Cette URL doit retourner
 *                       la même chose que dans le cas où la source est un tableau d'objets.
 *                       3 paramètres peuvent être passés à cette URL :
 *                         * term: chaine de caractères saisis par l'utilisateur
 *                         * offset : page courante (si more est à true)
 *                         * limit : valeur de l'option limit (si more est à true)
 *                        - function(request, response) : la fonction response passée en 2nd argument doit être appelée par l'implémenteur
 *                       avec les résultats suggérés en paramètre
 *                       ex : response([{
 *                           label: 'test 1',
 *                           value: 'valeur 1'
 *                       }, {
 *                           label: 'test 2',
 *                           value: 'valeur 2'
 *                       }]);
 *                        - object : la clé source doit exister et être un des types présenté ci dessus.
 *  - useKeyboard:       (boolean) Autorise l'utilisation du clavier pour naviguer dans la liste de suggestions
 *  - renderLabel:       (string|function(request, result)) Permet de personnaliser le rendu des items de la liste de suggestions
 *                        - string : highlight, simple
 *  - renderNoResult:    (boolean|string|function(request)) Permet de personnaliser le rendu lorsqu'aucun résultat n'a été trouvé
 *                        - boolean :
 *                         Si false, aucun message n'apparaît (Comportement par défaut).
 *                         Si true, un message traduit "No result found" apparaît
 *                        - string : Chaîne HTML founies pour apparaître dans la liste des suggestions
 *                        - function(request) : Fonction appelée devant retournée une chaine pour apparaître dans la liste des suggestions
 *  - separator:         (str<ing) Laisser cette option vide
 *  - more:              (boolean) Affiche un caret down poru passer à la page suivante dans la liste des suggestions
 *  - textMore:          (string) Contenu HTML du caret down
 *  - ajaxType:          (string) Verbe HTTP à utiliser si la source est une URL (GET ou POST)
 *  - limit:             (int) Nombre maximum d'items à afficher dans une page de la liste de suggestion.
 *                       Transmis en paramètre à l'URL
 *  - tagsOptions:       (object) Options supplémentaires passées aux plugin Webf/Tagsinput
 *  - givenInput:        (object) Permet à l'utilisateur de visualiser et sélectionner dans liste de suggestions la
 *                       valeur qu'il saisit dans le champ
 *                        - (boolean|string) show :
 *                         * false : Désactivé
 *                         * always : Toujours affiché
 *                         * onNoSuggestion : Affichée si aucune suggestion ne matche
 *                        - (string) position : Position de l'élément dans la liste de suggestions
 *                         * first : En premier
 *                         * last : en dernier
 *  - allowSelectGroup:  (boolean) Autorise la sélection des groupes
 *  - params:            (array) Paramètres supplémentaires fourni à al source lorsqu'il s'agit d'une url ou d'une fonction
 *  - onSelect:          (function($item, suggest, request)) : Fonction appelée lorsqu'un item est sélectionné
 *  - onOpen:            (function($listeSuggestions)) : Fonction appelée lorsque la liste de suggestion s'ouvre
 *  - onFocus:           (function($item, suggest)) : Fonction appelée lorsqu'un item prend le focus
 *  - onEmpty:           (function()) : Fonction appelée lorsque l'input est vidé
 *
 * Méthodes publiques:
 *  - search(term)      Lance une recherche sur les termes
 *  - isOpen:           Retourne true si la liste de suggestions est ouverte
 *  - open:             Force l'ouverture de la liste de suggestions
 *  - empty:            Vide l'input et force la fermeture de la liste de suggestions
 *  - close:            Force la fermeture de la liste de suggestions
 *  - widget:           Permet de récupérer la liste de suggestions
 */
$webf('autocomplete', {
    options: {
        behavior:           'single',
        delay:              200,
        minLength:          2,
        position:           'left',
        source:             [],
        useKeyboard:        true,
        zIndex:             1100,
        inputClass:         '',
        suggestClass:       '',
        renderLabel:        'highlight',
        renderNoResult:     false,
        separator:          '',
        more:               false,
        textMore:           "<i class='fas fa-caret-down'><i>",
        ajaxType:           'get',
        limit:              10, // ne prend pas en compte l'entrée donnée (option givenInput) si celle-ci s'affiche
        tagsOptions:        {},
        givenInput: {
            show: false,
            position: 'first'
        },
        allowSelectGroup:   false,
        params:             {},
        onSelect:           webf.noop,
        onOpen:             webf.noop,
        onFocus:            webf.noop,
        onEmpty:            webf.noop
    },

    editableOptions: {
        params: function(params) {
            this.options.params = params;
        }
    },

    _create: function()
    {
        var self = this;

        this.menu = null;
        this.timeoutSearch = null;
        this.source = null;
        this.list = [];
        this.curItem = null;
        this.cancelBlur = false;
        this.index = 0;
        this.offsetMore = 0;
        this.options.minLength = Math.max(this.option('minLength'), 0);

        this.e.prop('autocomplete', 'off');

        if (this.option('behavior') == 'single') {
            this.container = this.input = this.e;
            this.e.addClass(['webf-input-autocomplete'].concat(this.option('inputClass').split(' ')).join(' '));
        } else if (this.option('behavior') == 'tags') {
            this.container = this.e.webfTagsinput(
                webf.extend(true, {
                    tagsInputClass: this.option('inputClass')
                }, this.option('tagsOptions'), {
                    confirmKeys: [null],
                    onTagRemove: function() {
                        if (!$(this).webfTagsinput('tags').length) {
                            self.empty();
                        }

                        var tagsOptions = self.option('tagsOptions');
                        if (tagsOptions && webf.isFunction(tagsOptions.onTagRemove)) {
                            tagsOptions.onTagRemove.call($(this));
                        }
                    }
                })
            ).webfTagsinput('widget');
            this.input = this.e.webfTagsinput('input');
        }

        this.oldterm = this._getInputValue();

        var methodRender = this.option('renderLabel');

        if (webf.isString(methodRender)) {
            switch (methodRender) {
                case 'highlight':
                    methodRender = this._renderHighlight;
                    break;

                case 'simple':
                    methodRender = this._simpleRender;
            }
        }

        this.methodRender = methodRender;

        var renderNoResult = this.option('renderNoResult');

        if (renderNoResult === true) {
            this.methodRenderNoResult = this._renderNoResult;
        } else if (renderNoResult === false) {
            this.methodRenderNoResult = false;
        } else if (webf.isString(renderNoResult)) {
            this.methodRenderNoResult = function() {
                return renderNoResult;
            };
        } else if (webf.isFunction(renderNoResult)) {
            this.methodRenderNoResult = renderNoResult;
        }

        this._initMenu();
        this._bindEvents();
        this._initSource();
    },

    _initSource: function()
    {
        var self = this,
            src = this.option('source');

        if (webf.isArray(src)) {
            this.source = function(request, response) {
                var matcher = new RegExp(request.term.toLowerCase().noAccent().escapeRegex(), "i"),
                    sliced = webf.isInt(request.limit) && request.limit > 0;

                var results = $.grep(sliced ? src.slice(request.offset * request.limit, (request.offset + 1) * request.limit) : src, function(value) {
                    if (!value.group) {
                        return matcher.test((value.label || value).toLowerCase().noAccent());
                    }

                    return true;
                });

                response(results);
            };
        } else if (webf.isString(src)) {
            var url = src;

            this.source = function(request, response) {
                self.xhr && self.xhr.abort();

                !webf.isUndefined(request.index) && delete request.index;

                self.xhr = $.ajax({
                    url: url,
                    data: request,
                    success: function(data) {
                        response(data);
                    },
                    type: self.option('ajaxType'),
                    dataType: 'json',
                    error: function() {
                        response([]);
                    }
                });
            };
        } else if (webf.isFunction(src)) {
            this.source = src;
        } else if (webf.isPlainObject(src)) {
            this.options.source = src.source;
            this._initSource();
        }
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.input, {
            focus: function(ev) {
                var src = self.option('source');

                if (!self.isOpen()) {
                    if (self.option('minLength') == 0) {
                        if (!self._getInputValue().length && webf.isArray(src)) {
                            self._suggest({term: ''}, src);

                            self._call(self.option('onEmpty'));
                            return;
                        }
                    }

                    self._searchDelay(ev);
                }
            },
            blur: function() {
                if (self.cancelBlur) {
                    self.cancelBlur = false;
                    if (self.isOpen()) {
                        self.close();
                    }
                }
            },
            keydown: function(ev) {
                switch (ev.which) {
                    case 40: // Down
                        if (self.isOpen()) {
                            ev.preventDefault();
                            self._move(1);
                        } else {
                            self._searchDelay(ev);
                        }
                        break;

                    case 38: // Up
                        if (self.isOpen()) {
                            ev.preventDefault();
                            self._move(-1);
                        } else {
                            self._searchDelay(ev);
                        }
                        break;

                    case 37:
                    case 39:
                        break;

                    case 9: // Tab
                        if (self.isOpen()) {
                            if (self.curItem) {
                                ev.preventDefault();
                                self._select(self.curItem);
                            } else {
                                self.close();
                            }
                        }
                        break;

                    case 13: // Enter
                        if (self.isOpen()) {
                            ev.preventDefault();

                            if (self.curItem === null) {
                                self.curItem = self.menu.children('.webf-list-item-option').first();
                            }

                            self._select(self.curItem);
                        }
                        break;

                    case 27: // Escape
                        if (self.isOpen()) {
                            ev.preventDefault();
                            self.close();
                        }
                        break;

                    default:
                        self._searchDelay(ev);
                }
            }
        });

        this._on(document, {
            click: function() {
                self.close();
            }
        });
    },

    _initMenu: function()
    {
        let webfMenuListClass = 'webf-list';
        if (this.e.closest('.webf-md-text')[0] || this.e.closest('.md-style')[0]) {
            webfMenuListClass = 'webf-md-list';
        }

        const $menu = $("<ul>").addClass(webfMenuListClass + ' webf-list-autocomplete'.split(' ').concat(this.option('suggestClass').split(' ')).join(' '));

        this.$wrapperDropdown = this.container.wrap($('<div>').addClass('clearfix wrapper-webf-aucocomplete')).parent();
        this.$wrapperDropdown.append($menu);

        this.menu = this.$wrapperDropdown.webfDropdown({
            handle: this.container,
            menu: $menu,
            auto: false,
            position: this.option('position'),
            onFirstOpen: ($e) => {
                const $menu = $e.webfDropdown('menu');

                this._on($menu, '.webf-list-item-option', {
                    mouseenter: (ev) => {
                        this._focusItem($(ev.currentTarget));
                    },
                    mouseleave: (ev) => {
                        this.index = null;
                        this._blurItem($(ev.currentTarget));
                    },
                    mousedown: (ev) => {
                        ev.preventDefault();

                        setTimeout(() => {
                            this.cancelBlur = true;
                        }, 0);
                    },
                    click: (ev) => {
                        this._select($(ev.currentTarget));
                    }
                });

                this._on($menu, '.webf-list-item-more', {
                    click: () => {
                        var request = webf.extend({
                            term: this.oldterm,
                            offset: ++this.offsetMore,
                            limit: this.option('limit')
                        }, this.option('params'));

                        this._search(request);
                    }
                });

                this._on($menu.add(this.container), {
                    click: (ev) => {
                        ev.stopPropagation();
                    }
                });
            },
            onOpen: ($e) => {
                var $menu = $e.webfDropdown('menu');

                this._call(this.option('onOpen'), $menu);
            }
        }).webfDropdown('menu');
    },

    _searchDelay: function(event)
    {
        clearTimeout(this.timeoutSearch); this.timeoutSearch = null;
        this.offsetMore = 0;

        this.timeoutSearch = webf.setTimeout(function() {
            var value = this._getInputValue(),
                numEntry = null,
                isSameValue = this.oldterm === value,
                modifierKey = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

            if (this.option('minLength') == 0) {
                var src = this.option('source');

                if (!value.length && webf.isArray(src)) {
                    this._suggest({term: ''}, src);
                    return;
                }
            }

            if (this.option('separator')) {
                var regSeparator = new RegExp(this.option('separator')[0].escapeRegex(), 'ig'),
                    newValues = value.split(regSeparator),
                    caretPos = webf.getInputSelection(this.e[0]).start,
                    len = 0, s = false;

                webf.each(newValues, function(i, val) {
                    len += val.length + 1;

                    if (!s && caretPos <= len) {
                        numEntry = i;
                        s = true;
                    }

                    newValues[i] = this.trim();
                });

                value = newValues[numEntry];
            }

            this.oldterm = value;

            if (this.option('behavior') == 'single' && !isSameValue && !value.length) {
                this.empty();
            } else if (this.option('minLength') > value.length) {
                this.close();
            } else if (!isSameValue || (isSameValue && !this.isOpen() && !modifierKey)) {
                this._search(webf.extend({
                    term: value,
                    offset: this.offsetMore,
                    index: numEntry,
                    limit: webf.isArray(this.option('source')) ? 0 : this.option('limit')
                }, this.option('params')), event);
            }
        }, this.option('delay') || 1, this);
    },

    _focusItem: function($item)
    {
        this.index = $item.prevAll('.webf-list-item-option').length;
        this._blurItem();
        this.curItem = $item;
        $item.addClass('webf-focus');
        this._call(this.option('onFocus'), $item, $item.data('webf-autocomplete-suggest'));
    },

    _blurItem: function($item)
    {
        this.curItem = null;

        if (!$item) {
            this.menu.children('.webf-list-item-option').removeClass('webf-focus');
        } else {
            $item.removeClass('webf-focus');
        }
    },

    search: function(term)
    {
        this._search({
            term,
            offset: 0,
            limit: this.option('limit')
        });
    },

    _search: function(request, event)
    {
        var self = this,
            gi   = self.option('givenInput');

        this.index = null;
        this.curItem = null;

        if (gi.show == 'always') {
            var inputValue = self._getInputValue();
            self._suggest(request, [{
                label: inputValue,
                value: inputValue,
                fromSource: false
            }]);
        }

        this.source.call(this, request, function(results) {
            var inputValue = self._getInputValue(),
                inputTag = {
                    label: inputValue,
                    value: inputValue,
                    fromSource: false
                };

            if (gi.show == 'always') {
                if (gi.position == 'first') {
                    results.unshift(inputTag);
                } else if (gi.position == 'last') {
                    results.push(inputTag);
                }
            } else if (gi.show == 'onNoSuggestion' && results.length == 0) {
                results.push(inputTag);
            }

            self._suggest(request, results);
        });
    },

    _getInputValue: function()
    {
        if (this.option('behavior') == 'tags') {
            return this.input.val();
        }

        return webf.inArray(this.e[0].tagName, ['INPUT', 'TEXTAREA'])
            ? this.e.val()
            : this.e.text()
        ;
    },

    _setSuggestValue: function(suggest)
    {
        if (this.option('behavior') == 'tags') {
            if (!webf.isObject(suggest.value) && (suggest.value + '').length) {
                this.e.webfTagsinput('appendTag', suggest);
            }
            this.input.val('');
            this.input.trigger('initautogrow');
        } else {
            webf.inArray(this.e[0].tagName, ['INPUT', 'TEXTAREA'])
                ? this.e.val(suggest.label.decodeHtml())
                : this.e.text(suggest.label.decodeHtml())
            ;
        }
    },

    _suggest: function(request, results)
    {
        var self = this;

        this.menu.children('.webf-list-item-option').remove();
        this.menu.children('.webf-list-item-more').remove();

        webf.each(results, function(i, data) {
            data = webf.isString(data)
                ? { label: data, value: data }
                : data
            ;

            if (self.option('givenInput').show !== false && data.fromSource !== false) {
                data.fromSource = true;
            }

            var itemClasses = 'webf-list-item webf-list-item-option enabled'.split(' '),
                methodRender = this.methodRender,
                $item = $("<li><a><span></span></a></li>");

            if (data.group) {
                $item = $("<li><label><span></span></label></li>");
                itemClasses.push('webf-list-item-group');
                methodRender = this._renderGroup;
            }

            $item.find('span').append(
                methodRender.call(this, request, data)
            );

            this.menu.append(
                $item
                    .addClass(itemClasses.join(' '))
                    .data('webf-autocomplete-request', request)
                    .data('webf-autocomplete-suggest', data)
            );
        }, this);

        if (results.length) {
            this.open();

            if (this.option('more')) {
                var $more = $("<li>").addClass('webf-list-item-more');
                this.menu.append($more.html("<a><span>" + this.option('textMore') + "</span></a>"));
            }
        } else if (this.methodRenderNoResult === false) {
            this.close();
        } else {
            this.open();

            var itemClasses = 'webf-list-item webf-list-item-option enabled'.split(' '),
                methodRenderNoResult = this.methodRenderNoResult,
                $item = $("<li class='webf-noresult'></li>");

            this.menu.append(
                $item.append(
                    methodRenderNoResult.call(this, request)
                )
                    .addClass(itemClasses.join(' '))
                    .data('webf-autocomplete-request', request)
            );
        }

        this.menu.scrollTop(0);
    },

    _simpleRender: function(request, result)
    {
        return result.label;
    },

    _renderHighlight: function(request, result)
    {
        return webf.hilite(result.label, request.term.split(' '), 'strong')
            .replace(/<\/strong>(\s+)<strong>/ig, '$1')
            .replace(/\s/g, '&nbsp;');
    },

    _renderGroup: function(request, result)
    {
        return result.group;
    },

    _renderNoResult: function(request)
    {
        return '<span class="webf-noresult">' + this.translate('noResultFound') + '</span>';
    },

    _select: function($item)
    {
        if ($item.hasClass('webf-list-item-group') && !this.option('allowSelectGroup')) {
            return;
        }

        var request = $item.data('webf-autocomplete-request'),
            suggest = $item.data('webf-autocomplete-suggest');

        if (webf.isUndefined(suggest)) {
            return;
        }

        this.oldterm = suggest.value;
        this.offsetMore = 0;

        if (this._call(this.option('onSelect'), $item, suggest, request) !== false) {
            this.xhr && this.xhr.abort();
            this._setSuggestValue(suggest);
            this.close();
        }
    },

    _move: function(delta)
    {
        if (this.isOpen()) {
            var $items = this.menu.children('.webf-list-item-option.enabled'),
                nbItems = $items.length;

            if (this.index === null) {
                this.index = delta < 0 ? nbItems - 1 : 0;
            } else {
                this._blurItem($items.eq(this.index));
                this.index = (this.index + delta) % nbItems;
            }

            var $item = $items.eq(this.index),
                scrollTop = this.menu.scrollTop(),
                topItem = scrollTop + $item.position().top,
                heightItem = $item.outerHeight(),
                heightMenu = this.menu.outerHeight(),
                newScrollTop = scrollTop;

            this._focusItem($item);

            if (topItem + heightItem > scrollTop + heightMenu) {
                newScrollTop = (topItem + heightItem) - heightMenu;
            } else if (topItem < scrollTop) {
                newScrollTop = topItem;
            }

            this.menu.scrollTop(newScrollTop);
        }
    },

    isOpen: function()
    {
        return this.container.parent().webfDropdown('isOpened');
    },

    open: function()
    {
        !this.isOpen() && this.container.parent().webfDropdown('open');

        return this.e;
    },

    empty: function()
    {
        if (this.option('behavior') == 'tags') {
            this.e.webfTagsinput('removeAllTags');
        }

        this.e.val('');
        this.close();
        this._call(this.option('onEmpty'));
    },

    close: function()
    {
        this.isOpen() && this.container.parent().webfDropdown('close');

        return this.e;
    },

    widget: function()
    {
        return this.$menu ? this.$menu : this.container.parent().webfDropdown('menu');
    },

    _destroy: function()
    {
        this.$wrapperDropdown.webfDropdown('destroy');

        if (this.option('behavior') == 'single') {
            this.e.removeClass('webf-input-autocomplete');

            if (this.option('inputClass')) {
                this.e.removeClass(this.option('inputClass'));
            }
        } else if (this.option('behavior') == 'tags') {
            this.e.webfTagsinput('destroy');
        }

        this.e.unwrap();
    }
});
