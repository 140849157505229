import webf from '../../utils/core'
import '../checkbox'
import $ from 'jquery'

webf.SelectCheckable = webf.SelectMultiple.extend({

    _createOptionItem: function(value, label, enabled, selected, group)
    {
        enabled = webf.isUndefined(enabled) ? true : enabled;

        const $option = $("<li>")
            .addClass([
                'webf-list-item',
                'webf-list-item-option',
                enabled ? 'enabled' : 'disabled',
                group ? 'group' : '',
                selected ? 'selected' : ''
            ].join(' '));

        const id = webf.uniqid();
        const $label = $('<label>').prop('for', id).text(label);

        $option.prepend($("<input>")
            .prop('type', 'checkbox')
            .prop('id', id)
            .prop('disabled', !enabled)
            .prop('checked', !!selected));
        $option.append($label);

        return $option.data('value', value);
    },

    draw: function()
    {
        const wSelect = webf.round(parseFloat(webf.getStyle(this.e[0], "width")));
        const $selectWrapper = this.e.wrap($("<div>")
            .addClass(['webf-select'].concat(this.plugin.option('selectClass').split(' ')).join(' '))
        ).parent();
        const $wrapperDropdown = $selectWrapper.wrap($(`<div class="webf-select-dropdown ${this.plugin.option('wrapperClass')}">`)
            .css('width', this.plugin.option('width') == 'auto' ? wSelect : this.plugin.option('width'))
        ).parent();

        $selectWrapper.append($("<div>").addClass("select")
            .html("" +
                "<i class='handle-down fas fa-caret-down'></i>" +
                "<a class='selected-area'>" + this.plugin.option('placeholder') + "</a>" +
            "")
        );

        this.$select = $selectWrapper;

        const $options = this._getOptions(),
            $menu = $("<div>")
                .addClass('webf-select-drop checkable')
                .append($options);

        const optCheckAll = this.plugin.option('checkAllItem');
        if (optCheckAll) {
            this.$optionCheckAll = this._createOptionItem(optCheckAll.value || '', this.plugin._(optCheckAll.label));
            this.$optionCheckAll.data('all', true);

            $options.prepend(this.$optionCheckAll);
        }

        if (this.plugin.option('filter')) {
            $menu.prepend(`
                <div class='filter'>
                    <label>
                        <input class='search' type='text' autocomplete='off'>
                        <i class='icon-search fas fa-search'></i>
                    </label>
                </div>
            `);
        }

        $wrapperDropdown.append($menu);

        this.menu = $wrapperDropdown.webfDropdown({
            handle: $selectWrapper,
            menu: $menu,
            closeOnClickMenu: false,
            menuClass: this.plugin.option('dropClass'),
            onFirstOpen: ($e) => {
                const $menu = $e.webfDropdown('menu');
                const $input = $menu.find("input[type='text'].search");

                if ($input.length) {
                    this.input = $input;

                    this.plugin._on($input, {
                        keydown: (ev) => {
                            if (!ev.ctrlKey && !ev.metaKey && !ev.altKey) {
                                switch(ev.keyCode || ev.which) {
                                    case 38: case 40: case 9: case 13:
                                        break;

                                    default:
                                        this._searchDelay(ev);
                                }
                            }
                        }
                    });
                }

                $("input[type='checkbox']", this.menu).webfCheckbox({
                    style: 'checkmark'
                });

                this.plugin._call(this.plugin.option('onFirstOpen'));
            },
            onOpen: () => {
                this.$select.addClass('open');
                if (this.input) {
                    this.input.trigger('focus');
                }

                this.plugin._call(this.plugin.option('onOpen'));
            },
            onClose: () => {
                this.$select.removeClass('open');
                this.index = null;
                if (this.input) {
                    this.input.val('');
                }
                this._search('');
                this.showItem();
                this.blurItem();
                this.$select.find('.select').removeClass('open');

                this.plugin._call(this.plugin.option('onClose'));
            }
        }).webfDropdown('menu');

        this.display();

        this.e.hide();
    },

    bindEvents: function()
    {
        this.plugin._on(this.menu.add(this.$select), {
            'mousedown mousemove': (ev) => {
                if (ev.target.tagName !== 'INPUT' || !$(ev.target).hasClass('search') || !$(ev.target).closest('.webf-select-drop')[0]) {
                    ev.preventDefault();
                }
            }
        });

        this.plugin._on(this.menu, '.webf-list-item-option.enabled', {
            mouseenter: (ev) => {
                this.index = $(ev.currentTarget).prevAll('li.webf-item-option.enabled').length;
                this.focusItem($(ev.currentTarget));
            }
        })._on(this.menu, '.webf-list-item-option', {
            mouseleave: (ev) => {
                this.index = null;
                this.blurItem($(ev.currentTarget));
            }
        })._on(this.menu, '.webf-list-item-group', {
            click: (ev) => {
                this.index = null;
                const $optGroup = this.e.find('optgroup[label="' + $(ev.currentTarget).text() + '"]');
                const $options = $optGroup.children();
                const $items = $(ev.currentTarget).nextAll('.webf-list-item-option').slice(0, $options.length);

                const vals = $options.map(function(i, option) {
                    return $(option).val();
                }).get();

                if (this.ctrlKey) {
                    $items.siblings('.webf-list-item-option.disabled').each(function() {
                        if ($(ev.currentTarget).find('input[type="checkbox"]').prop('checked')) {
                            vals.push($(ev.currentTarget).data('value'));
                        }
                    });
                }

                this.plugin._call(this.plugin.option('onSelect'), $optGroup, vals);
                this.val(vals, true);
            }
        })._on(this.menu, ".webf-list-item-option.enabled input[type='checkbox']", {
            check: (ev) => {
                const $item = $(ev.currentTarget).closest('.webf-list-item-option');
                const vals = [];

                if ($item.data('all')) {
                    $item.nextAll('.webf-list-item-option').each((i, item) => {
                        vals.push($(item).data('value'));
                    });

                    this.plugin._call(this.plugin.option('onSelect'), $item, $item.data('value'));
                    this.val(vals, true);
                } else if (this.ctrlKey) {
                    vals.push($item.data('value'));

                    $item.siblings('.webf-list-item-option.disabled').each((i, item) => {
                        if ($(item).find('input[type="checkbox"]').prop('checked')) {
                            vals.push($(item).data('value'));
                        }
                    });

                    this.plugin._call(this.plugin.option('onSelect'), $item, $item.data('value'));
                    this.val(vals, true);
                } else {
                    this.plugin._call(this.plugin.option('onSelect'), $item, $item.data('value'));
                    this.addVal($item.data('value'));
                }
            },
            uncheck: (ev) => {
                const $item = $(ev.currentTarget).closest('.webf-list-item-option');
                const vals = [];

                if ($item.data('all')) {
                    $item.nextAll('.webf-list-item-option.disabled').each((i, item) => {
                        if ($(item).find('input[type="checkbox"]').prop('checked')) {
                            vals.push($(item).data('value'));
                        }
                    });

                    this.val(vals, true);
                } else if (this.ctrlKey) {
                    vals.push($item.data('value'));

                    $item.siblings('.webf-list-item-option.disabled').each((i, item) => {
                        if ($(item).find('input[type="checkbox"]').prop('checked')) {
                            vals.push($(item).data('value'));
                        }
                    });

                    this.val(vals, true);
                } else {
                    this.removeVal($item.data('value'));
                }
            }
        });

        this.plugin._on(window, {
            keydown: (ev) => {
                if (ev.ctrlKey || ev.metaKey) {
                    this.ctrlKey = true;
                }
            },
            keyup: (ev) => {
				if (ev.ctrlKey || ev.metaKey || webf.inArray(ev.which, [17, 91, 224])) {
                    this.ctrlKey = false;
                }
            },
            blur: () => {
                this.ctrlKey = false;
            }
        });
    },

    select: function($items)
    {
        $items.addClass('selected');
        $items.find("input[type='checkbox']").webfCheckbox('check', true, false, false);
        $items.find("input[type='checkbox']").prop('checked', 'checked');
    },

    deselect: function($items)
    {
        $items.removeClass('selected');
        $items.find("input[type='checkbox']").webfCheckbox('uncheck', false, false);
        $items.find("input[type='checkbox']").prop('checked', false);
    },

    isAllChecked: function()
    {
        return this.menu.find('.webf-list-item-option').map(function(i, item) {
            return !$(item).data('all') ? this : null;
        }).length == this.val().length;
    },

    display: function()
    {
        let texts = [];

        if (!this.val().length && this.plugin.placeholder.length) {
            texts = [this.plugin.placeholder];
        } else if (this.plugin.option('checkAllItem') && this.isAllChecked()) {
            texts = [this.$optionCheckAll.find('label').text()];
            this.select(this.$optionCheckAll);
        } else {
            webf.each(this.val(), function(i, val) {
                texts.push(this.findByData('value', val).children('label').text());
            }, this.menu.find('.webf-list-item-option'));

            this.$optionCheckAll && this.deselect(this.$optionCheckAll);
        }

        this.$select.find('a.selected-area').text(texts.join(', '));
    }
});
