import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import './position'
import $ from 'jquery'

/**
 * options:
 *  - message
 *  - duration
 *  - promise
 *  - dismissible
 *  - dismissibleBtn
 *  - closeOnReject
 *  - position
 *  - modal
 *  - onResolve
 *  - onReject
 *  - onClose
 *
 * Méthodes publiques:
 *  - setMessage
 *  - resolve
 *  - reject
 *  - refreshPosition
 */
$webf('toast', {
    options: {
        message:        'Toast',
        duration:       3000,
        promise:        null,
        dismissible:    false,
        dismissibleBtn: '&times',
        closeOnReject:  true,
        position: {
            // of: this.e, <-- valeur par défaut, utilisée plus loin car this.e est inaccessible ici
            my: "left bottom",
            at: "left bottom",
            offset: {
                top: -10,
                left: 10
            },
        },
        modal:     false,
        onResolve: webf.noop,
        onReject:  webf.noop,
        onClose:   webf.noop
    },

    _create: function () {
        var promise  = $.Deferred(),
            $overlay = null,
            $toast   = $('<div>').addClass('webf-toast').append(
                $('<span>').addClass('message').html(this.option('message'))
            );

        if (this.option('dismissible')) {
            $toast
                .addClass('dismissible')
                .prepend($('<button>').addClass('webf-button xsmall').html(this.option('dismissibleBtn')))
            ;
        }

        if (this.option('modal')) {
            $overlay = $('<div>').addClass('webf-toast-overlay');
            $overlay.appendTo(this.e);
        }

        this.promise  = promise;
        this.$overlay = $overlay;
        this.$toast   = $toast;

        this.$toast.appendTo(this.e);
        this.refreshPosition();
        this.$toast.addClass('webf-enter');

        this._bindEvents();

        setTimeout(function () {
            $toast.removeClass('webf-enter');
        }, 20); // Hack pour forcer la transition entre 'avec' et 'sans' la classe webf-enter
    },

    _bindEvents: function () {
        var self = this;

        var duration = this.option('duration');
        if (webf.isInt(duration) && duration > 0) {
            setTimeout(function () {
                self.resolve();
            }, duration);
        }

        var promise = this.option('promise');
        if (promise !== null) {
            promise.done(function () {
                self.resolve();
            }).fail(function () {
                self.reject();
            });
        }

        this.promise.done(function () {
            self._call(self.option('onResolve'));
            self._close();
        }).fail(function () {
            self._call(self.option('onReject'));
            if (self.option('closeOnReject')) {
                self._close();
            }
        });

        this._on(this.$toast.find('button'), {
            click: function (ev) {
                self.reject();
            }
        })
    },

    setMessage: function (message) {
        this.$toast.find('> .message').html(message);
        this.refreshPosition();
    },

    resolve: function () {
        if (this.promise.state() !== 'pending') {
            return;
        }

        this.promise.resolve();
    },

    reject: function () {
        if (this.promise.state() !== 'pending') {
            return;
        }

        this.promise.reject();
    },

    refreshPosition: function () {
        this.$toast.webfPosition('destroy');
        this.$toast.webfPosition(webf.extend({ of: this.e }, this.option('position')));
    },

    _close: function () {
        this._call(this.option('onClose'));
        this.destroy();
    },

    _destroy: function () {
        this._on(this.$toast.addClass('webf-leave'), {
            'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd': function () {
                $(this).remove();
            }
        });

        if (this.option('modal')) {
            this._on(this.$overlay.addClass('webf-leave'), {
                'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd': function () {
                    $(this).remove();
                }
            });
        }
    }
});
