import webf from './core'
import eventDispatcher from './eventDispatcher'
import jQuery from 'jquery'
const $ = jQuery

var prefix = 'webf';
var instances = {};

const $webf = function(name, plugin)
{
    var prefixedName = prefix + name.ucfirst();

    var $plugin = function(elem)
    {
        this.binds = [];
        this.hash = webf.uniqid();
        this.pluginName = name;
        this.namespace = this.pluginName + this.hash;
        this.e = $(elem);
    };

    var webfPlugin = new WebfPlugin();
    webf.extend($plugin.prototype, webfPlugin, plugin);

    if ($.fn) {
        $.fn[prefixedName] = function(settings)
        {
            if (webf.isString(settings)) {
                if (settings.charAt(0) === '_') {
                    return this;
                }

                var args = Array.prototype.slice.call(arguments, 1),
                    applyMethod = function(instance) {
                        if (!webf.isUndefined(instance)) {
                            if (webf.isFunction(instance[settings])) {
                                return instance[settings].apply(instance, args);
                            }

                            throw "method " + settings + " not found in " + prefixedName;
                        }
                    };

                if (this.length == 1) {
                    return applyMethod($(this).data(prefixedName));
                }

                return this.each(function() {
                    applyMethod($(this).data(prefixedName));
                });
            } else {
                return this.each(function() {
                    var $this = $(this),
                        instance = $this.data(prefixedName);

                    if (!instance) {
                        instance = new $plugin($this);
                        instance.options = jQuery.extend(true, {
                            lang: webf.config.LANG
                        }, plugin.options, settings);
                        instance.editableOptions = webf.extend(true, {}, plugin.editableOptions);
                        instances[instance.hash] = instance;
                        $this.data(prefixedName, instance);

                        instance._create();
                    }

                    instance._init();
                });
            }
        };
    }
};

var WebfPlugin = function() {};

WebfPlugin.prototype = {
    _init: function() {},

    getOptions: function()
    {
        return this.options;
    },

    option: function(option, val)
    {
        if (webf.isUndefined(val)) {
            return this.options[option];
        } else if (webf.isFunction(this.editableOptions[option])) {
            this.options[option] = val;
            this.editableOptions[option].apply(this.getInstance(), [val]);

            return this.e;
        } else {
            throw 'Option "' + option + '" is not editable for the "' + this.pluginName + '" plugin';
        }
    },

    element: function()
    {
        return this.e;
    },

    getInstance: function()
    {
        return this;
    },

    getOtherInstances: function(andSelf)
    {
        andSelf = webf.isUndefined(andSelf) ? false : andSelf;

        return webf.map($webf.getInstances(this.pluginName), function(i, instance) {
            return andSelf ? instance : (this != instance.hash ? instance : null);
        }, this.hash);
    },

    _on: function(element, selector, handlers)
    {
        var $element = element instanceof jQuery ? element : $(element),
            self = this;

        if (!handlers) {
            handlers = selector;
            selector = null;
        }

        webf.each(handlers, function(events, handler) {
            webf.each(events.split(' '), function(i, event) {
                var nsEvent = event + '.' + self.namespace;
                if (!selector) {
                    $element.on(nsEvent, handler);
                } else {
                    $element.on(nsEvent, selector, handler);
                }

                $element.data(nsEvent, true);
                self.binds.push($element);
            });
        });

        return this;
    },

    _off: function(element, events)
    {
        if (!element) {

            webf.each(this.binds, function(i, $binded) {
                this._off($binded, events);
            }, this);

            return this;
        }

        var $element = element instanceof jQuery ? element : $(element);

        if (!events) {
            webf.each($element.data(), function(name) {
                if (webf.inArray(this.namespace, name.split('.'))) {
                    $element.unbind('.' + this.namespace).off('.' + this.namespace).removeData(name);
                }
            }, this);
        } else {
            webf.each(events.split(' '), function(i, event) {
                var nsEvent = event + '.' + this.namespace;

                if ($element.data(nsEvent)) {
                    $element.unbind(nsEvent).off(nsEvent).removeData(nsEvent);
                }
            }, this);
        }

        return this;
    },

    _trigger: function(element, events)
    {
        const $element = element instanceof jQuery ? element : $(element);
        let _events;

        if (webf.isObject(events)) {
            events.type += '.' + this.namespace;
            _events = [events];
        } else if (webf.isString(events)) {
            _events = webf.map((events || '').split(' '), (i, event) => {
                return $.Event(`${event}.${this.namespace}`);
            });
        }

        webf.each(_events, (i, event) => {
            $element.trigger(event);
        });
    },

    _has: function($element, plugin)
    {
        var prefixedName = plugin;

        if (plugin.charAt(0) == ':') {
            prefixedName = prefix + plugin.substring(1).ucfirst();
        }

        return !!$element.data(prefixedName);
    },

    _call: function(func, ...args)
    {
        return func.apply(this.e, args.concat(this.e));
    },

    _callAsync: function(func, ...args)
    {
        return new Promise((resolve, reject) => {
            const result = func.apply(this.e, args.concat(this.e));

            if (result instanceof Promise) {
                result.then(resolve).catch(reject);
            } else {
                resolve(result);
            }
        });
    },

    translate: function(label)
    {
        return webf.translate.apply(webf, [this.options.lang, this.pluginName, label].concat(Array.prototype.slice.call(arguments, 1)));
    },

    _: function()
    {
        return this.translate.apply(this, arguments);
    },

    destroy: function()
    {
        this._off();

        webf.isFunction(this._destroy) && this._destroy();

        this.e.removeData(prefix + this.pluginName.ucfirst());
        delete instances[this.hash];
    }
};

$webf.getInstances = function(plugin)
{
    return webf.map(instances, function(hash, instance) {
        return instance.pluginName == plugin ? instance : null;
    });
};

$webf.getMousePos = function(e, $elem)
{
    var rect = { left: 0, top: 0 };

    if ($elem) {
        rect = $elem.offset();
    }

    if (webf.isTouchDevice()) {
        var orgEvent = e.originalEvent;

        if (orgEvent.changedTouches && orgEvent.changedTouches.length) {
            e = orgEvent.changedTouches[0];
        } else if (orgEvent.touches) {
            e = orgEvent.touches[0];
        }
    }

    return {
        x: !webf.isUndefined(e.pageX) ? e.pageX - rect.left :  rect.left,
        y: !webf.isUndefined(e.pageY) ? e.pageY - rect.top : rect.top
    };
};

$webf.getDimensions = function($elem)
{
    if (webf.isEvent($elem)) {
        return {
            width: 0,
            height: 0
        };
    }

    return {
        width: $elem.outerWidth(),
        height: $elem.outerHeight(),
    };
};

$webf.disableSelection = function()
{
    $("body").bind("onselectstart" in document.createElement("div") ? 'selectstart.webf-disableselection' : 'mousedown.webf-disableselection', function(ev) {
        ev.preventDefault();
    });
};

$webf.enableSelection = function()
{
    $("body").unbind('.webf-disableselection');
};

$webf.getOffset = function($elem)
{
    if (webf.isWindow($elem[0]) || webf.isDocument($elem[0])) {
        return {
            top: $elem.scrollTop(),
            left: $elem.scrollLeft()
        };
    } else if (webf.isEvent($elem)) {
        return {
            top: $elem.pageY,
            left: $elem.pageX
        };
    }

    return $elem.offset();
};

if ($.fn) {
    $.fn.findByData = function (prop, val) {
        return this.filter(function () {
            if (webf.isArray(val)) {
                return webf.inArray($(this).data(prop), val);
            }

            return $(this).data(prop) == val;
        });
    };

    $.fn.fixListScrollbar = function () {
        var width = this.width(),
            innerWidth = this.children('li').first().innerWidth();

        this.width(2 * width - innerWidth);
    };

    $.fn.hasScrollBarVertical = function (stopRecursion) {
        var elem = this[0];

        if (webf.isWindow(elem) || webf.isDocument(elem)) {
            elem = document.documentElement;
        }

        // var hasScrollBar = elem.scrollHeight > this.height();
        var hasScrollBar = elem.scrollHeight > elem.clientHeight;

        if (stopRecursion || !hasScrollBar || !this.hasScrollBarHorizontal(true)) {
            return hasScrollBar;
        }

        return elem.scrollHeight >= this.height() - webf.getScrollbarWidth();
    };

    $.fn.hasScrollBarHorizontal = function (stopRecursion) {
        var elem = this[0];

        if (webf.isWindow(elem) || webf.isDocument(elem)) {
            elem = document.documentElement;
        }

        // var hasScrollBar = elem.scrollWidth > this.width();
        var hasScrollBar = elem.scrollWidth > elem.clientWidth;

        if (stopRecursion || !hasScrollBar || !this.hasScrollBarVertical(true)) {
            return hasScrollBar;
        }

        return elem.scrollWidth >= this.width() - webf.getScrollbarWidth();
    };

    $.fn.toggleRequired = function (required = true) {
        this.each((i, input) => {
            const $input = $(input);
            const idInput = $input.attr('id');
            let $controlLabel;

            $input.prop('required', required);

            if ($input.attr('id')) {
                $controlLabel = $('label').filter(`[for="${idInput}"]`);
            }

            if (!$controlLabel || !$controlLabel[0]) {
                $controlLabel = $input.closest('.form-group, .row').find('.control-label');

                if ($controlLabel.length > 1 && idInput) {
                    $controlLabel = $controlLabel.filter(`[for="${idInput}"]`);
                }
            }

            $controlLabel.toggleClass('required', !!required);
        });

        return this;
    };
}

$webf.getModifiersKey = function(ev)
{
    var modifiers = [];

    if (ev.shiftKey)    modifiers.push('shift');
    if (ev.altKey)      modifiers.push('alt');
    if (ev.ctrlKey)     modifiers.push('ctrl');
    if (ev.metaKey)     modifiers.push('meta');

    return modifiers;
};

if (typeof window !== "undefined") {
    $(function () {
        $(document).on('click', '.webf-button', function () {
            var $btn = $(this);

            if ($btn.hasClass('webf-state')) {
                if ($btn.parent('.webf-buttons-group')[0]) {
                    $btn.parent().children('.webf-state').removeClass('webf-state-active');
                    $btn.addClass('webf-state-active');
                }
            }
        });

        $('.webf-list.fix-scrollbar').fixListScrollbar();

        $(document).on('click', '.webf-button.drop, .webf-box > .box-header.drop', function (ev) {
            var $webfBox = $(this).closest('.webf-box'),
                $iIcon = $webfBox.children('.box-header.drop').children('.drop').children('i');

            var durationOpen = !webf.isUndefined($webfBox.data('duration-open')) ? $webfBox.data('duration-open') : (!webf.isUndefined($webfBox.data('duration')) ? $webfBox.data('duration') : 400);
            var durationClose = !webf.isUndefined($webfBox.data('duration-close')) ? $webfBox.data('duration-close') : (!webf.isUndefined($webfBox.data('duration')) ? $webfBox.data('duration') : 400);

            if ($webfBox.hasClass('fold')) {
                $webfBox.children('.box-body').slideDown(durationOpen, function () {
                    $webfBox.removeClass('fold');
                    $iIcon
                        .removeClass('fa-caret-down')
                        .addClass('fa-caret-up');

                    $webfBox.trigger('webf.box-open');
                    eventDispatcher.dispatch('webf.box-open', $webfBox);
                });
            } else {
                $webfBox.children('.box-body').slideUp(durationClose, function () {
                    $webfBox.addClass('fold');
                    $iIcon
                        .removeClass('fa-caret-up')
                        .addClass('fa-caret-down');

                    $webfBox.trigger('webf.box-close');
                    eventDispatcher.dispatch('webf.box-close', $webfBox);
                });
            }
        });

        $(document).on('click', '.webf-box > .box-header > a', function (ev) {
            ev.stopPropagation();
        })


        $(document).on('click', '.webf-list-item.disabled', function (ev) {
            ev.preventDefault();
        })


        $(document).on('click', '.webf-entity > .webf-header > .toggle-details, .webf-entity.unfoldable > .webf-header', function (ev) {
            ev.stopPropagation();

            var $webfEntity = $(this).closest('.webf-entity');
            var durationOpen = !webf.isUndefined($webfEntity.data('duration-open')) ? $webfEntity.data('duration-open') : (!webf.isUndefined($webfEntity.data('duration')) ? $webfEntity.data('duration') : 300);
            var durationClose = !webf.isUndefined($webfEntity.data('duration-close')) ? $webfEntity.data('duration-close') : (!webf.isUndefined($webfEntity.data('duration')) ? $webfEntity.data('duration') : 300);
            var $toggleDetails = $webfEntity.children('.webf-header').children('.toggle-details');

            if ($webfEntity.hasClass('unfolded')) {
                $toggleDetails.addClass('folding');
                $webfEntity.children('.webf-body').slideUp(durationClose, function () {
                    $toggleDetails.removeClass('folding')
                    $webfEntity.removeClass('unfolded');
                    $webfEntity.trigger('webf.entity-close');
                    eventDispatcher.dispatch('webf.entity-close', $webfEntity);
                });
            } else {
                $toggleDetails.addClass('unfolding');
                $webfEntity.children('.webf-body').slideDown(durationOpen, function () {
                    $toggleDetails.removeClass('unfolding')
                    $webfEntity.addClass('unfolded');
                    $webfEntity.removeClass('folded').addClass('unfolded');
                    $webfEntity.trigger('webf.entity-open');
                    eventDispatcher.dispatch('webf.entity-open', $webfEntity);
                });
            }
        });

        var addTooltips;
        (addTooltips = function () {
            if (!webf.isTouchDevice()) {
                $('.webf-tooltip:visible').each(function (i, btnTooltip) {
                    const $btnTooltip = $(btnTooltip);
                    const dataWidth = $btnTooltip.data('widthTooltip');

                    if ($btnTooltip.find('.tooltiptext')[0]) {
                        return;
                    }

                    const $tooltipText = $('<div>').addClass('tooltiptext').html(
                        $btnTooltip.attr('title') || '&nbsp;'
                    );
                    const $triangle = $('<div>').addClass('triangle');
                    $tooltipText.append($triangle);

                    $btnTooltip.append($tooltipText);
                    $btnTooltip.removeAttr('title');

                    $tooltipText.css({
                        whiteSpace: 'nowrap'
                    })

                    let width;
                    if (dataWidth) {
                        width = dataWidth;
                    } else {
                        width = Math.min($tooltipText.outerWidth() + 1, 200);
                    }
                    const height = $tooltipText.outerHeight();
                    $tooltipText.css({
                        whiteSpace: 'initial'
                    });

                    const widthTriangle = $triangle.outerWidth();
                    const widthBtnTooltip = $btnTooltip.outerWidth();
                    const heightBtnTooltip = $btnTooltip.outerHeight();

                    $tooltipText.css({
                        width: width
                    });

                    if (
                        $btnTooltip.hasClass('webf-tooltip-top-left') ||
                        $btnTooltip.hasClass('webf-tooltip-bottom-left')
                    ) {
                        $triangle.css('right', (widthBtnTooltip - widthTriangle) / 2 - 2);
                    } else if (
                        $btnTooltip.hasClass('webf-tooltip-top-right') ||
                        $btnTooltip.hasClass('webf-tooltip-bottom-right')
                    ) {
                        $triangle.css('left', widthBtnTooltip / 2);
                    } else if (
                        $btnTooltip.hasClass('webf-tooltip-left') ||
                        $btnTooltip.hasClass('webf-tooltip-right')
                    ) {
                        $tooltipText.css({
                            marginTop: -($tooltipText.outerHeight() - height) / 2
                        });
                    } else if (
                        $btnTooltip.hasClass('webf-tooltip-right-bottom') ||
                        $btnTooltip.hasClass('webf-tooltip-left-bottom')
                    ) {
                        $triangle.css('top', (heightBtnTooltip - widthTriangle) / 2 - 2);
                    } else if (
                        $btnTooltip.hasClass('webf-tooltip-right-top') ||
                        $btnTooltip.hasClass('webf-tooltip-left-top')
                    ) {
                        $triangle.css('bottom', (heightBtnTooltip - widthTriangle) / 2);
                    } else if ($btnTooltip.hasClass('webf-tooltip-bottom')) {
                        $tooltipText.css({
                            marginLeft: -width / 2
                        });
                    } else {
                        $tooltipText.css({
                            marginLeft: -width / 2
                        });
                    }

                    $tooltipText.addClass('hidden-tooltip');
                })
            }
        })()

        eventDispatcher.addListener('webf.entity-open, webf.box-open, webf.entities-update, webf.tooltips-update', addTooltips);

        $(document).on('click', '.webf-entity > .webf-header a, .webf-entity > .webf-header > .webf-buttons-group', function (ev) {
            ev.stopPropagation();
        })

        // MD Text
        const selectorMDInputText = '.webf-md-textarea textarea, .webf-md-text input[type="text"], .webf-md-text.select-native select, .webf-md-text input[type="email"], .webf-md-text input[type="password"], .webf-md-text input[type="date"], .webf-md-text input[type="number"], .webf-md-text input[type="tel"]';
        $(document).on('focus', selectorMDInputText, (ev) => {
            const $input = $(ev.currentTarget);
            const $parent = $input.closest('.webf-md-text')[0] ? $input.closest('.webf-md-text') : $input.closest('.webf-md-textarea');

            $parent.addClass('is-focused has-label');
        })

        $(document).on('webf-init-md-text change blur', selectorMDInputText, (ev) => {
            const $input = $(ev.currentTarget);
            const $parent = $input.closest('.webf-md-text')[0] ? $input.closest('.webf-md-text') : $input.closest('.webf-md-textarea');

            if ($input.is('select')) {
                $parent
                    .toggleClass('has-label', !!$input.find(':selected').text().length)
                    .removeClass('is-focused');
            } else {
                $parent
                    .toggleClass('has-label', !!$input.val().length)
                    .removeClass('is-focused');
            }
        })

        $(selectorMDInputText).trigger('webf-init-md-text');
    });
}

export default $webf
