import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import WebfDate from '../utils/date'
import '../i18n/timepicker'
import './dialog'
import './popover'
import $ from 'jquery'

/**
 * options:
 *  - mode:                     (string) dropdown ou dialog
 *  - defaultTime:              (Date) temps par default. Si l'élément déclencheur est un input text, la valeur est interprétée
 *                              comme une date avec le format spécifié dans l'option format
 *  - format:                   (string) Format d'affichage du temps, par defaut HH:mm
 *  - title:                    (string) Titre de la fenêtre de la boite de dialogue
 *  - minutesStep:              (integer) incrémentation des minutes
 *  - showSeconds:              (integer) Affiche l'input des secondes
 *  - secondsStep:              (integer) incrémentation des secondes
 *  - showMeridian:             (boolean) Affiche l'heure en mode 12h si il est égal à true ou en mode 24h si il est à false
 *  - position:                 (object) Position de la fenêtre par rapport à l'élément déclencheur
 *  - onTimeChange:             (function(Date)) Fonction appelée lorsque le temps est modifié
 *  - onOpen:                   (function()) Fonction appelée lorsque le timepicker s'ouvre
 *  - onClose:                  (function()) Fonction appelée lorsque le timepicker se ferme
 *  - zIndex:                   (integer) z-index fourni au plugin webf|Popover ou webf|Dialog
 *
 * Méthodes publiques:
 *  - setTime(Date):            Définie le temps du picker
 *  - getTime():                Retourne la date définie dans le picker
 *  - close():                  Provoque la fermuture du Timepciker
 */
$webf("timepicker", {
    options: {
        mode:               'dropdown',
        defaultTime:        null,
        title:              '',
        format:             'HH:mm',
        minutesStep:        5,
        showSeconds:        true,
        secondsStep:        15,
        showMeridian:       false,
        position:           {
            my:         'left top',
            at:         'left bottom',
            collision:  'flipfit none'
        },
        onTimeChange:       webf.noop,
        onOpen:             webf.noop,
        onClose:            webf.noop
    },

    _create: function()
    {
        this.time = this._getTimeFromInput() ? this._getTimeFromInput() : new WebfDate(this.option('defaultTime'));

        this._drawTimepicker();
        this._bindEvents();
        this._renderTime(false);
    },

    _drawTimepicker: function()
    {
        var self = this;

        var $timepicker = $("" +
            "<div class='webf-timepicker " + (this.option('showSeconds') ? 'show-seconds' : '') + ' ' + (this.option('showMeridian') ? 'show-meridian' : '') +"'>" +
            "    <table>" +
            "        <tr>" +
            "            <td class='td-hours'><a class='hours up'><i class='fas fa-caret-up'></i></a></td>" +
            "            <td class='time-separator hours-minutes'></td>" +
            "            <td class='td-minutes'><a class='minutes up'><i class='fas fa-caret-up'></i></a></td>" +
            "            <td class='time-separator minutes-seconds'></td>" +
            "            <td class='td-seconds'><a class='seconds up'><i class='fas fa-caret-up'></i></a></td>" +
            "            <td class='time-separator meridian'></td>" +
            "            <td class='td-meridian'><a class='meridian up'><i class='fas fa-caret-up'></i></a></td>" +
            "        </tr>" +
            "        <tr>" +
            "            <td class='td-hours'><input readonly class='hours' type='text' maxlength='2'></td>" +
            "            <td class='time-separator hours-minutes'>:</td>" +
            "            <td class='td-minutes'><input readonly class='minutes' type='text' maxlength='2'></td>" +
            "            <td class='time-separator minutes-seconds'>:</td>" +
            "            <td class='td-seconds'><input readonly class='seconds' type='text' maxlength='2'></td>" +
            "            <td class='time-separator meridian'>&nbsp;</td>" +
            "            <td class='td-meridian'><input readonly class='meridian' type='text' maxlength='2'></td>" +
            "        </tr>" +
            "        <tr>" +
            "            <td class='td-hours'><a class='hours down'><i class='fas fa-caret-down'></i></a></td>" +
            "            <td class='time-separator hours-minutes'></td>" +
            "            <td class='td-minutes'><a class='minutes down'><i class='fas fa-caret-down'></i></a></td>" +
            "            <td class='time-separator minutes-seconds'></td>" +
            "            <td class='td-seconds'><a class='seconds down'><i class='fas fa-caret-down'></i></a></td>" +
            "            <td class='time-separator meridian'></td>" +
            "            <td class='td-meridian'><a class='meridian down'><i class='fas fa-caret-down'></i></a></td>" +
            "        </tr>" +
            "    </table>" +
            "</div>" +
        "")
            .hide()
            .appendTo($('body'));

        var mode = this.option('mode');

        if (mode == 'dropdown') {
            this.$widget = this.e.webfPopover({
                menu: $timepicker,
                closeOnClickElement: !(this.e[0].tagName == 'INPUT' || this.e[0].tagName == 'TEXTAREA'),
                zIndex: this.option('zIndex'),
                onClose: function() {
                    self._call(self.option('onClose'), self.time);
                },
                onOpen: function() {
                    self._call(self.option('onOpen'), self.time);
                }
            }).webfPopover('menu');
        } else if (mode == 'dialog') {
            this.$widget = $timepicker.webfDialog({
                autoOpen: false,
                dialogClass: 'webf-dialog-timepicker',
                box: {
                    boxClass: 'dark plain',
                    header: {
                        title: self.option('title') || self._('Pick a time')
                    },
                    footer: {
                        closeButton: false,
                        buttons: {
                            OK: function() {
                                $(this).webfDialog('close');
                            }
                        }
                    }
                },
                zIndex: this.option('zIndex'),
                onClose: function() {
                    self._call(self.option('onClose'), self.time);
                },
                onOpen: function() {
                    self._call(self.option('onOpen'), self.time);
                }
            }).webfDialog('widget');
        }

        this.$timepicker = $timepicker;
    },

    _bindEvents: function()
    {
        var self = this,
            minutesStep = this.option('minutesStep'),
            secondsStep = this.option('secondsStep');

        if (this.option('mode') == 'dialog') {
            this._on(this.e, {
                click: function() {
                    self.$timepicker.webfDialog('open');
                }
            });
        }

        this._on(this.$widget, 'a', {
            mousedown: function(ev) {
                ev.preventDefault();
            },
            'click up down': function() {
                if ($(this).hasClass('up')) {
                    if ($(this).hasClass('hours')) {
                        self.time.addHour();
                    } else if ($(this).hasClass('minutes')) {
                        self.time.addMinutes(minutesStep - self.time.minutes() % minutesStep);
                    } else if ($(this).hasClass('seconds')) {
                        self.time.addSeconds(secondsStep - self.time.seconds() % secondsStep);
                    } else if ($(this).hasClass('meridian')) {
                        self.time.subHours(12);
                    }
                } else {
                    if ($(this).hasClass('hours')) {
                        self.time.subHour();
                    } else if ($(this).hasClass('minutes')) {
                        self.time.subMinutes(minutesStep - self.time.minutes() % minutesStep);
                    } else if ($(this).hasClass('seconds')) {
                        self.time.subSeconds(secondsStep - self.time.seconds() % secondsStep);
                    } else if ($(this).hasClass('meridian')) {
                        self.time.subHours(12);
                    }
                }

                self._renderTime();
                self._call(self.option('onTimeChange'), self.time);
            }
        });

        this._on(this.$widget, 'input', {
            keydown: function(ev) {
                if (!self.keydownTimeout) {
                    var $input = $(this);

                    self.keydownTimeout = setTimeout(function() {
                        self.keydownTimeout = null;

                        var composant = 'hours';
                        if ($input.hasClass('minutes')) {
                            composant = 'minutes';
                        } else if ($input.hasClass('seconds')) {
                            composant = 'seconds';
                        } else if ($input.hasClass('meridian')) {
                            composant = 'meridian';
                        }

                        switch (ev.which) {
                            case 38:
                                self._trigger(self.$widget.find('a.'+composant+'.up'), 'up');
                                break;

                            case 40:
                                self._trigger(self.$widget.find('a.'+composant+'.down'), 'down');
                                break;
                        }
                    }, 100);
                }
            }
        });
    },

    _getTimeFromInput: function()
    {
        if (/text|date/.test(this.e.prop('type')) && this.option('format') && this.e.val()) {
            return new WebfDate(this.e.val(), this.option('format'));
        }

        return null;
    },

    _renderTime: function(feedInput)
    {
        feedInput = webf.isUndefined(feedInput) ? true : feedInput;

        this.$widget.find('input.hours').val(this.time.toString(this.option('showMeridian') ? 'hh' : 'HH'));
        this.$widget.find('input.minutes').val(this.time.toString('mm'));
        this.$widget.find('input.seconds').val(this.time.toString('ss'));
        this.$widget.find('input.meridian').val(this.time.toString('A'));

        if (feedInput && /text|date/.test(this.e.prop('type')) && this.option('format')) {
            this.e.val(this.time.toString(this.option('format')));
        }
    },

    setTime: function(time)
    {
        this.time = new WebfDate(time);

        this._renderTime();
    },

    getTime: function()
    {
        return this.time;
    },

    widget: function()
    {
        return this.$widget;
    },

    close: function()
    {
        if (this.option('mode') == 'dropdown') {
            this.e.webfPopover('close');
        } else if (this.option('mode') == 'dialog') {
            this.e.webfDialog('close');
        }
    },

    _destroy: function()
    {
        this.$widget.remove();
    }
});

