import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options:
 *  - selectorDraggable:    (string|Element)    Sélecteur des éléments draggable
 *  - selectorDroppable:    (string|Element)    Sélecteur des éléments droppable
 *  - selectorHandler:      (string|Element)    Sélecteur du handler des éléments draggable. Les handlers doivent être des
 *                                              enfants des élements draggable
 *  - selectorSelected:     (string)            Sélecteur des items sélectionnés prêt être draggé
 *  - textDragHandler:      (string)            Texte du drag handler lors du déplacement des éléments draggable
 *  - tolerance:            (int)               Distance en px minimum entre le lieu du mousedown et le déplacement de la souris
 *                                              pour que le drag commence
 *  - onMousedown:          (function($draggable, ev))
 *                                              Fonction appelée sur l'event mousedown. Si la fonction retourne true,
 *                                              la propagation de l'event mousedown est stoppée
 *  - onMouseup:            (function($draggable, ev))
 *                                              Fonction appelée sur l'event mouseup si le drag n'a pas commencé.
 *  - onDragStart:          (function($draggables, $droppables))
 *                                              Fonction appelée lorsque le drag commence
 *  - onDrop:               (function($droppable, $draggables))
 *                                              Fonction appelée lorsque le drop a lieu
 *
 * Méthodes publiques:
 *  - cancelDragging()      Annule le drag et détruit le draghandler
 */
$webf('dragndrop', {
    options: {
        selectorDraggable:      '.webf-draggable',
        selectorDroppable:      '.webf-droppable',
        selectedSelector:       '.webf-dragndrop-selected',
        selectorHandler:        null,
        textDragHandler:        null,
        onMousedown:            webf.noop,
        onMouseup:              webf.noop,
        onDragStart:            webf.noop,
        onDrop:                 webf.noop,
        tolerance:              7
    },

    editableOptions: {
        selectorHandler: function(selectorHandler) {
            this.options.selectorHandler = selectorHandler
        }
    },

    _create: function()
    {
        this._bindEvents()
    },

    _bindEvents: function()
    {
        const selectorDraggable = this.option('selectorDraggable');
        const selectorSelected = this.option('selectorSelected');

        this._on(this.e, this.option('selectorHandler') || selectorDraggable, {
            mousedown: (ev) => {
                if (ev.which == 1) {
                    const selectorHandler = this.option('selectorHandler')

                    if (selectorHandler) {
                        if ($(ev.currentTarget).closest(selectorHandler)[0]) {
                            ev.preventDefault()
                        } else return
                    } else ev.preventDefault()

                    const $item = $(ev.currentTarget).closest(selectorDraggable)

                    if ($item[0]) {
                        if (true === this._call(this.option('onMousedown'), $item, ev)) {
                            ev.stopImmediatePropagation()
                        }

                        this.readyToDrag = true
                        this.startPoint = $webf.getMousePos(ev)
                        this.$draggables = this.e.find(selectorDraggable)
                            .filter(selectorSelected)
                        this.$droppables = this.e.find(this.option('selectorDroppable'))
                            .not(this.$draggables)
                            .addClass('webf-droppable')
                    }
                }
            }
        })

        ._on(this.e, selectorDraggable, {
            mouseup: (ev) => {
                const $item = $(ev.currentTarget)

                if (!this.isDragging) {
                    this._call(this.option('onMouseup'), $item, ev)
                }

                if (!$item.hasClass('dragging')) {
                    this.readyToDrag = false;
                    this.isDragging = false;
                }
            }
        })

        ._on(window.document, {
            mousemove: (ev) => {
                if (this.$draggables && this.$draggables.length && this.readyToDrag) {
                    if (this.startPoint) {
                        this.endPositioning = false
                        const sp = this.startPoint
                        const mp = $webf.getMousePos(ev)
                        const distance = Math.sqrt(Math.pow(mp.x - sp.x, 2) + Math.pow(mp.y - sp.y, 2))

                        if (distance >= this.option('tolerance')) {
                            this.isDragging = true
                            this.startPoint = null

                            this._call(this.option('onDragStart'), this.$draggables, this.$droppables)

                            if (this.$draggables) {
                                this.$draggables.each((i, draggable) => {
                                    const $draggable = $(draggable)

                                    if (!$draggable.hasClass('dragging')) {
                                        $draggable.addClass('webf-draggable dragging')

                                        this._createDragHandler($draggable)

                                        setTimeout(() => {
                                            this.$dragHandlers.addClass('dragging')
                                        }, 1)

                                        setTimeout(() => {
                                            this.$dragHandlers.addClass('fast')
                                        }, 200)

                                        setTimeout(() => {
                                            if (!this.endPositioning) {
                                                this.$dragHandlers.removeClass('positionning fast')
                                                this.endPositioning = true

                                                const $handler = this.$dragHandlers.first();
                                                const countDraggables = this.$dragHandlers.length
                                                this.$dragHandlers.not($handler).remove()

                                                // if (countDraggables > 1) {
                                                //     $handler.before($('<div>').addClass('webf-draghandlers-count').text(countDraggables))
                                                // }
                                            }

                                        }, 500)
                                    }
                                })
                            }
                        }
                    }

                    this.$dragHandlers && this.$dragHandlers.offset({
                        top: ev.pageY,
                        left: ev.pageX
                    })
                }
            },
            'blur mouseup': (ev) => {
                this.cancelDragging()
            },
        })

        ._on(this.e, '.webf-droppable', {
            mouseenter: (ev) => {
                if (this.isDragging) {
                    $(ev.currentTarget).closest('.webf-droppable').addClass('hover')
                }
            },
            mouseleave: (ev) => {
                if (this.isDragging) {
                    $(ev.currentTarget).closest('.webf-droppable').removeClass('hover')
                }
            },
            mouseup: (ev) => {
                if (this.isDragging) {
                    ev.preventDefault()

                    const $dropElement = $(ev.currentTarget)
                    this.readyToDrag = false
                    this.isDragging = false
                    this._destroyDragHandler()

                    this._call(this.option('onDrop'), this.$draggables, $dropElement)
                }
            }
        })
    },

    cancelDragging: function()
    {
        const selectorDroppable = this.option('selectorDroppable')

        this.$draggables && this.$draggables.removeClass('dragging')
        this.$droppables && this.$droppables.removeClass('webf-droppable')
        this.$draggables = null
        this.isDragging = false
        this.readyToDrag = false
        this._destroyDragHandler()
        this.e.find(selectorDroppable).removeClass('hover')
    },

    _createDragHandler: function($draggable)
    {
        let textDragHandler = this.option('textDragHandler')

        if (!this.textDragHandlers) {
            if (webf.isFunction(textDragHandler)) {
                textDragHandler = textDragHandler.call(null, this.$draggables)
            }
        }

        const $handler = $('<div>')
            .addClass('webf-draghandler positionning')
            .append(`<span>${textDragHandler}</span>`)
            .css({
                top: $draggable.offset().top + ($draggable.outerHeight() - 42) / 2,
                left: $draggable.offset().left,
            })

        $handler.appendTo('body')

        if (this.$dragHandlers) {
            this.$dragHandlers = this.$dragHandlers.add($handler)
        } else {
            this.$dragHandlers = $handler
        }

        return $handler
    },

    _destroyDragHandler: function()
    {
        if (this.$dragHandlers) {
            this.$dragHandlers.remove()
            this.$dragHandlers = null
            this.textDragHandlers = null
        }
    }
});

