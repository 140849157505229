import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import WebfDate from '../utils/date'
import './scrollbar'
import './datetimepicker'
import '../i18n/fulldayeventagenda'
import $ from 'jquery'
import Mustache from "mustache"

/**
 * options:
 *  - view:                 (string) month week day
 *  - displayViews:         (boolean|array) affiche les boutons contenus dans le tableau (week, month, day, datetpicker) ou
 *                          n'affiche rien si il est égal à false
 *  - displayAllDay:        (boolean) Affiche les événements de toute la journée
 *  - date:                 (Date) La date en javascript à considérer comme la date courante pour l'affichage de
 *                          l'agenda.
 *  - daysToShow:           (array) Tableau indexé précisant les jours à afficher. 0 = lundi, dimanche = 6
 *  - data:                 (array|string|function(start, end)) Charge les événements à afficher.
 *                          Si data est un tableau, alors ce sont les événements à charger.
 *                          Si data est une chaîne de caractères, alors il s'agit de l'url des événements à récupérer
 *                          Si data est une fonction, elle reçoit en premier paramètre la fonction de callback
 *                          à appeler argument avec les événements à charger et la date de début et de fin de
 *                          l'agenda actuellement affiché.
 *  - skins:                (list of skin) chaque skin a un nom
 *  - defaultSkin:          (string) skin à appliquer pour les nouveaux événements
 *  - height:               (integer|string) La hauteur totale de l'agenda
 *                          fitbottom : l'agenda se fixe sur le bas de la fenêtre
 *  - timeslotsPerHour:     (integer) Nombre de créneaux par heure
 *  - timeslotHeight:       (integer) la hauteur en px de chaque créneau
 *  - businessHours:        (Object) Détermine les heures à afficher pour chaque jour
 *  - draggable:            (boolean) Rendre les événements déplaçables
 *  - resizable:            (boolean) Rendre les événements redimenionnables
 *  - newEventText:         (string) Texte par défaut à afficher pour un nouvel événement créé par l'utilisateur
 *  - onLoad:               (function(events)) Fonction appelée après le chargement des événements
 *  - eventClick:           (function(event,$event)) Fonction appelée lors d'un clic sur un événement
 *  - eventMouseover:       (function(event,$event)) Fonction appelée lorsque la souris passe ou dessus d'un événement
 *  - eventMouseout:        (function(event,$event)) Fonction appelée lorsque la souris sort d'un événement
 *  - eventChange:          (function(event,$event)) Fonction appelée lorsqu'un événement change soit par
 *                          redimensionnement ou déplacement. Si la fonction retourne false, l'événement retourne à
 *                          son état précédent
 *  - beforeCreateEvent:    (function()) Fonction appelée lorsque l'utilisateur commence à créer un événement. Si false est
 *                          retourné, l'événement ne sera pas créé
 *  - eventNew:             (function(event,$event,allday)) Fonction appelée lors de l'ajout d'un événement. Si la fonction
 *                          retourne false, l'événement est supprimé
 *                          si allday est à true, l'événement crée est un événement de toute une journée
 *  - onChangeView:         (function(view, start, end)) Fonction appelée lorsque le mode de vue change
 *  - readonly:             (boolean) L'agenda ne peut être que consulté, pas de possibilité de créer, déplacer ou
 *                          redimensionner un événement
 *  - users:                (array of User) division des colonnes des jours
 *  - allowOverlapEvents:   (boolean) Autoriser des événements à la même date
 *  - disabledLinks:        (boolean) Rend inactif les liens sur les jours qui amènent à la vue "jour"
 *  - newEventOnClick:      (boolean) Crée l'événement au click sur l'agenda.
 *  - marginOverlap:        (integer) Poucentage de la largeur de la colonne à laisser disponible pour insérer
 *                          un nouvel élément. Par défaut 10
 *  - urlParams:            (object) liste des paramètres à envoyer pour récupérer les événements lorsque data
 *                          est un string, et donc considré comme une URL.
 *  - startParam:           le nom du paramètre de début du calendrier utilisé lorsque data est une URL
 *  - endParam:             le nom du paramètre de fin du calendrier utilisé lorsque data est une URL
 *  - formatDateParam:      Format de la chaîne de caractères représentant les dates de début et de fin à envoyer
 *                          en paramètre à l'URL pour récupérer les événenements.
 *  - scrollToOnInit:       Scroll l'agenda à l'heure spécifié au fomat HH:mm en mode week ou day
 *  - hourMarker:           (boolean) Affiche le marqueur de l'heure actuelle en mode jour ou semaine
 *  - minHeightEvent:       (integer) Hauteur minimale d'affichage d'un événement
 *
 * Méthodes publiques:
 *  - addEvent(Event):          Ajouter un événement
 *  - editEvent(Event):         Modifier un événement
 *  - removeEvent(Event):       Supprime un événement
 *  - clearEvents:              Supprime tous les événements
 *  - displayWeek(Date):        Affiche la semaine de la date spécifiée
 *  - displayDay(Date):         Affiche le jour de la date spécifiée
 *  - displayMonth(Date):       Affiche le mois de la date spécifiée
 *  - changeView(string,date):  Change le mode en semaine ou mois à la date indiquée. Si la date n'est pas précisé
 *                              la date est déterminée au mieux par le plugin
 *  - getLastEvent(Event):      Retourne le rdv précédent de celui donné
 *  - getNextEvent(Event):      Retourne le rdv suivant de celui donné
 *  - getEventById(string):     Retourne l'événement avec l'id spécifié
 *  - setDefaultSkin:           Modifie le skin à appliquer pour les nouveaux événements
 *  - getView:                  Retourne la vue courante ('week', 'month', 'day')
 *  - triggerClick(Event):      Simule un clic sur l'événement
 *  - getRenderedEvent(Event):  Retourne l'événement du DOM. (Il peut être en plusieurs segements)
 *
 *  Structures:
 *  Event: {
 *    - id:         (mixed) facultatif (attribué par le plugin si indéfini)
 *    - user:       (User|mixed) l'utilisateur lié à l'événement. l'identifiant de l'utilisateur peut être
 *                  fourni à la place de l'objet User.
 *    - start:      (string) la date de début de l'événement correspondant au format
 *    - end:        (string) la date de fin de l'événement correspondant au format
 *    - format:     (string) format compatible avec WebfDate
 *    - content:    (string) Texte à afficher dans la zone prévue à cet effet
 *    - skin:       (string) Nom du skin à utiliser pour l'événement
 *    - readonly:   (boolean) l'événement ne peut être ni déplacé ni redimensionné
 *    - invisible:  (boolean) n'affiche pas l'événement sur l'agenda
 *  }
 *
 *  User: {
 *    - id:         (mixed) requis (l'dentifiant unique de l'utilisateur)
 *    - label:      (string) Texte à afficher comme entête de la colonne de l'utilisateur
 *    - title:      (string) Attribut title de l'élément
 *    - tooltip:    (string) Contenu HTML de la tooltip affiché au survol de la souris sur le label de
 *                  l'utilisateur. Si la propriété title est renseigné, tooltip est ignoré
 *  }
 *
 *  businessHours: {
 *   - start:       (integer)
 *   - end:         (integer)
 *  }
 *
 *  skin: {
 *   - color:                   couleur du texte
 *   - opacity:                 opacité de l'événement
 *   - backgroundColorTitle:    couleur de fond du titre de l'événement
 *   - backgroundColorContent:  couleur de fond du contenu de l'événement
 *   - borderColor:             Couleur de la bordure
 *   - borderColorContent:      Couleur de la bordure du contenu
 *  }
 */
var dayName = 'sunday monday tuesday wednesday thursday friday saturday'.split(' '),
    monthName = 'january february march april may june july august september october november december'.split(' '),
    shortMonthName = 'jan feb mar apr may. jun jul aug sept oct nov dec'.split(' ');

$webf("fulldayeventagenda", {
    options: {
        view:               'week',
        displayViews:       ['week', 'day', 'datepicker'],
        displayAllDay:      true,
        date:               new WebfDate(),
        daysToShow:         [0,1,2,3,4,5,6],
        data:               {},
        height:             500,
        timeslotsPerHour:   4,
        timeslotHeight:     20,
        businessHours:      {
            start: 0,
            end: 24
        },
        draggable:          true,
        resizable:          true,
        newEventText:       "",
        defaultSkin:        "",
        skins:              {},
        marginOverlap:      10,
        urlParams:          {},
        startParam:         'start',
        endParam:           'end',
        formatDateParam:    'yyyy-MM-dd HH:mm:ss',
        scrollToOnInit:     null,
        hourMarker:         true,
        minHeightEvent:     15,
        onLoad:             function() {},
        eventClick:         function() {},
        eventMouseover:     function() {},
        eventMouseout:      function() {},
        eventChange:        function() {},
        eventNew:           function() {},
        beforeCreateEvent:  function() {},
        onChangeView:       function() {},
        users:              [],
        readonly:           false,
        allowOverlapEvents: false,
        disabledLinks:      false,
        newEventOnClick:    false
    },

    _create: function()
    {
        this.view = null;
        this.dragging = false;
        this.resizing = false;
        this.creating = false;
        this.invertResizing = false;
        this.mousedownonagenda = false;
        this.mousedownonalldays = false;
        this.startPoint = null;
        this.start = null;
        this.end = null;
        this.events = [];
        this.allDayEvents = [];
        this.oldEvent = null;
        this.moving = false;
        this.curMonth = new WebfDate(this.options.date).month();
        this.curYear = new WebfDate(this.options.date).year();
        this.draggingMonthEvent = null;
        this.widthMonthDay = null;
        this.heightMonthDay = null;
        this.daysToShow = this.options.daysToShow;
        this.prevView = null;
        this.creatingOnClick = false;
        this.mouseEnterOnEvent = false;
        this.colDayStart = null;
        this.colDayEnd = null;
        this.shiftKey = false;
        this.altKey = false;

        if (this.options.displayViews == false || (!webf.inArray("day", this.options.displayViews))) {
            this.options.disabledLinks = true;
        }

        if (this.options.view == 'month') {
            this._initStartEndOfMonth();
            this.view = 'month';
            this._drawMonthAgenda();
        } else if (this.options.view == 'week') {
            this._initStartEndOfWeek();
            this.view = 'week';
            this._drawWeekAgenda();
        } else if (this.options.view == 'day') {
            this._initStartEndOfDay();
            this.view = 'day';
            this.daysToShow = [(this.start.clone().day() + 6) % 7];
            this._drawDayAgenda();
        }

        if (this.options.scrollToOnInit) {
            if (webf.inArray(this.view, ['week','day'])) {
                var hm = this.options.scrollToOnInit.split(':');
                var scrollTop = (hm[0] * this.options.timeslotsPerHour + (parseInt(hm[1]) / 60) * this.options.timeslotsPerHour) * this.options.timeslotHeight - this.options.businessHours.start * (this.options.timeslotsPerHour * this.options.timeslotHeight);
                this.e.find('.grid').webfScrollbar('scrollTo', {y: scrollTop});
            }
        }

        this._loadEvents();
        this._bindEvents();
        this._bindButtons();
    },

    editableOptions: {
        height: function(height) {
            this._setHeight(height);
        },
        // height: function(heightValue) {
        //     this.e.find('.grid').css('height', heightValue);
        // },
        data: function(data) {
            this.options.data = data;
        },
        urlParams: function(urlParams) {
            webf.each(urlParams, function(key, value) {
                this.addUrlParams(key, value);
            }, this);
        }
    },

    _initStartEndOfWeek: function(date)
    {
        var firstDay = Math.min.apply(null, this.daysToShow);
        var lastDay = Math.max.apply(null, this.daysToShow);

        var now = new WebfDate(date || this.options.date);
        var dayNow = (now.day() + 6) % 7;

        var firstDayOfWeek = now.clone().subDays(dayNow - firstDay);
        var lastDayOfWeek = now.clone().addDays(lastDay - dayNow);

        this.start = firstDayOfWeek.clone().hours(this.options.businessHours.start).startOfHour();
        this.end = lastDayOfWeek.clone().hours(this.options.businessHours.end).startOfHour();
    },

    _initStartEndOfMonth: function(date)
    {
        const now = new WebfDate(date || this.option('date'));

        this.curMonth = now.month();
        this.curYear = now.year();
        this.start = now.clone().startOfMonth().prevMonday(true);
        this.end = now.clone().addMonth().startOfMonth().nextMonday().subSecond();
    },

    _initStartEndOfDay: function(date)
    {
        var now = new WebfDate(date || this.options.date);

        this.start = now.clone().hours(this.options.businessHours.start).startOfHour();
        this.end = now.clone().hours(this.options.businessHours.end).startOfHour();
    },

    _drawWeekAgenda: function()
    {
        var self = this;
        var now = new WebfDate();

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._removeDatePicker();
        }

        var agenda_toolbar = "" +
            "<div class='toolbar'>" +
            (this.view == 'week' ?
                "<div class='webf-buttons-group buttons'>" +
                    "<a title='" + this._('prev_week') + "' class='webf-button prev-week' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
                    "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
                    "<a title='" + this._('prev_week') + "' class='webf-button next-week' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
                "</div>" :
                "<div class='webf-buttons-group buttons'>" +
                    "<a title='" + this._('prev_day') + "' class='webf-button prev-day' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
                    "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
                    "<a title='" + this._('next_day') + "' class='webf-button next-day' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
                "</div>"
            ) +
            "<div class='title'>" + (this.view == 'week' ? this._formatDate(this.start,this._('date_title_week')) + " - " + this._formatDate(this.end.clone().subSecond(),this._('date_title_week')) :
                this._formatDate(this.start,this._('date_title_day'))) + "</div>" +
            ((this.options.displayViews !== false ) ?
                "<div class='views'>" +
                    "<div class='webf-buttons-group buttons'>" +
                        (webf.inArray("week", this.options.displayViews) ? "<a class='webf-button webf-state week " + (this.view == 'week' ? 'webf-state-active selected' : '') + "' href='javascript:void(0)'>" + this._('week') + "</a>" : "") +
                        (webf.inArray("day", this.options.displayViews) ? "<a class='webf-button webf-state day " + (this.view == 'day' ? 'webf-state-active selected' : '') + "' href='javascript:void(0)'>" + this._('day') + "</a>" : "") +
                        (webf.inArray("datepicker", this.options.displayViews) ? "<a class='webf-button datepicker' href='javascript:void(0)'>" +
                            "<i class='far fa-calendar'></i>" +
                            "<span>" + this._('pick a date') + "</span>" +
                        "</a>" : "") +
                    "</div>" +
                "</div>" : "") +
            // "<br style='clear: both;'>" +
            "</div>" +
            "";

        var td_days = "";
        var td_agenda = "";
        var tb_users = "";

        if (this.options.users.length) {
            tb_users = "" +
                "<table class='tb-users'>" +
                    "<tr>" +
                        webf.map(this.options.users, (i, user) => {
                            return "" +
                                "<td class='td-user user-" + user.id + "' data-user='" + user.id + "'>" +
                                "<div class='col-user'></div>" +
                                "</td>" +
                                "";
                        }).join('') +
                    "</tr>" +
                "</table>" +
            "";
        }

        webf.each(this.daysToShow, (i, value) => {
            var date = self.start.clone();
            var is_today = false;
            const day = date.clone().addDays(value);
            td_days += `
                <td class='td-day'>
                    <a class='link-days ${day.isToday() ? 'today' : ''} ${(self.options.disabledLinks ? ' disabled' : '')}'>
                        ${(this.view == 'week' ? this._formatDate(day, this.translate('date_col_day')) : '&nbsp;')}
                    </a>
                </td>
            `;

            if (date.equals(now, 'day')) {
                is_today = true;
            }

            td_agenda += "" +
                "<td data-col='" + value + "' style='height:" + (((self.options.businessHours.end - self.options.businessHours.start) * self.options.timeslotsPerHour) * (self.options.timeslotHeight)) + "px' class='td-col-day col-day-" + value + (is_today ? " today" : "") + "'>" +
                "<div class='col-day-full'>" +
                tb_users +
                "<div class='hour-marker'></div>" +
                "</div>" +
                "</td>" +
                "";
        });

        var users = "";
        if (this.options.users.length) {
            var td_users = "";

            webf.each(this.daysToShow, () => {
                webf.each(this.options.users, function(i, user) {
                    td_users += "" +
                        "<td>" +
                        "<div data-user='" + user.id + "' class='user'>" +
                        "<label>" + user.label + "</label>" +
                        "</div>" +
                        "</td>" +
                        "";
                });
            });

            users = "" +
                "<div class='users'>" +
                "<table class='tb-users'>" +
                "<tr>" +
                "<td class='td-hours'></td>" +
                td_users +
                "</tr>" +
                "</table>" +
                "</div>" +
                "";
        }

        var agenda_days = "" +
            "<div class='days'>" +
            "<table class='tb-days'>" +
            "<tr>" +
            "<td class='td-hours'></td>" +
            td_days +
            "</tr>" +
            "</table>" +
            "</div>" +
            "";

        var td_grid = "";
        for( var i=this.options.businessHours.start; i<this.options.businessHours.end; i++) {
            for( var j=0; j<this.options.timeslotsPerHour; j++) {
                td_grid += "" +
                    "<tr>" +
                    (j==0 ? "<td rowspan='" + this.options.timeslotsPerHour + "' class='td-hours'>" +
                        "<span class='hour'>" + i + this._(i <= 12 ? "am" : "pm") + "</span>" +
                        "<div class='border-bottom'></div>" +
                        "</td>" : "") +
                    "<td class='td-grid'>" +
                    "<div style='height:" + (this.options.timeslotHeight - 1) + "px;' class='timeslot "+ ((j==this.options.timeslotsPerHour-1) ? "hour" : "") + "'>" +
                    "" +
                    "</div>" +
                    "</td>" +
                    "</tr>" +
                    "";
            }
        }

        var allday_events = "";
        if (this.options.displayAllDay) {
            allday_events = "" +
                "<div class='allday'>" +
                "<table class='tb-grid'>" +
                "<tr>" +
                "<td class='td-first'></td>" +
                "<td class='td-col-days'>" +
                "<table class='tb-days'>" +
                "<tr>" +
                "<td class='td-col-allday'></td>".repeat(this.end.diff(this.start, 'days')) +
                "</tr>" +
                "</table>" +
                "<table class='tb-events'>" +
                "</table>" +
                "</td>" +
                "</tr>" +
                "</table>" +
                "</div>" +
                "";
        }

        var agenda_grid = "" +
            "<div class='grid' style='height: " + this.options.height + "px'>" +
            "<table class='tb-grid'>" +
            td_grid +
            "</table>" +
            "<div class='agenda'>" +
            "<table class='tb-agenda'>" +
            "<tr>" +
            "<td class='td-hours'>" +
            "<td>" +
            "<table class='tb-agenda-days'>" +
            "<tr>" +
            td_agenda +
            "</tr>" +
            "</table>" +
            "</td>" +
            "</tr>" +
            "</table>" +
            "</div>" +
            "</div>" +
            "";

        var agenda = "<div class='webf-agenda " + (this.view == "week" ? "agenda-week" : "agenda-day") + "'>" + agenda_toolbar + agenda_days + users + allday_events + agenda_grid + "</div>";

        this.e.empty().append(agenda);
        this._setHeight(this.option('height'));

        if (this.options.users.length) {
            $('.webf-agenda-tooltip-user').remove();

            this.e.find('.webf-agenda .user label').each(function() {
                var id_user = $(this).closest('.user').data('user');

                var user = self._getUserById(id_user);

                if (user.title) {
                    $(this).prop('title',user.title);
                } else if (user.tooltip) {
                    $(this).webfTooltip( {
                        content: $("" +
                            "<div class='webf-user-agenda-tooltip-user'>" +
                            "<div class='triangle'>" +
                            "<div class='inner-triangle'></div>" +
                            "</div>" +
                            "<div class='content'>" +
                            user.tooltip +
                            "</div>" +
                            "</div>" +
                            ""),
                        position: {
                            my: 'center top',
                            at: 'center bottom',
                            offset: {
                                top: 6,
                                left: 0
                            }
                        },
                    } );
                }
            });
        }

        this.e.find('.grid').webfScrollbar();

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._initDatePicker();
        }
    },

    _drawDayAgenda: function()
    {
        this._drawWeekAgenda();
    },

    _drawMonthAgenda: function()
    {
        const startmonth = this.start.clone().startOfMonth();
        let start = this.start.equals(startmonth,'day') ? startmonth.clone() : startmonth.addMonth().clone();

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._removeDatePicker();
        }

        var agenda_toolbar = "" +
            "<div class='toolbar'>" +
            "<div class='webf-buttons-group buttons'>" +
            "<a title='" + this._('prev_month') + "' class='webf-button white prev-month' href='javascript:void(0)'><i class='fas fa-caret-left'></i></a>" +
            "<a class='webf-button white today' href='javascript:void(0)'>" + this._('today') + "</a>" +
            "<a title='" + this._('next_month') + "' class='webf-button white next-month' href='javascript:void(0)'><i class='fas fa-caret-right'></i></a>" +
            "</div>" +
            ((this.options.displayViews !== false) ?
                "<div class='views'>" +
                "<div class='webf-buttons-group buttons'>" +
                (webf.inArray("month", this.options.displayViews) ? "<a class='webf-button month white selected' href='javascript:void(0)'>" + this._('month') + "</a>" : "") +
                (webf.inArray("week", this.options.displayViews) ? "<a class='webf-button week white' href='javascript:void(0)'>" + this._('week') + "</a>" : "") +
                (webf.inArray("day", this.options.displayViews) ? "<a class='webf-button day white' href='javascript:void(0)'>" + this._('day') + "</a>" : "") +
                (webf.inArray("datepicker", this.options.displayViews) ? "<a class='webf-button datepicker white' href='javascript:void(0)'>" + this._('pick a date') + "</a>" : "") +
                "</div>" +
                "</div>" : "") +
            "<div class='title'>" + this._formatDate(start,this._('date_title_month')) + "</div>" +
            "</div>" +
            "";

        var td_days = "";

        webf.each(this.daysToShow, function(i, value) {
            td_days += "" +
                "<td>" +
                    new WebfDate([2013,8,2]).addDays(value).toString('EEE').ucfirst() +
                "</td>" +
            "";
        });

        var agenda_days = "" +
            "<div class='days'>" +
            "<table class='tb-days'>" +
            "<tr>" +
            td_days +
            "</tr>" +
            "</table>" +
            "</div>" +
            "";

        var td_grid = "",
            td_agenda = "";
        start = this.start.clone();

        this.widthMonthDay = Math.round((this.e.width() / this.daysToShow.length)*10000)/10000 - 1;
        this.heightMonthDay = Math.round(((this.options.height/6) - 1)*10000)/10000;

        var n = 0;
        for (var i=0; i<6; i++) {
            var td_day = "";
            webf.each(this.daysToShow, (j, value) => {
                var day = start.clone().addDays(i*7 + value);
                td_day += "" +
                    "<td style='width:" + this.widthMonthDay + "px; height:" + this.heightMonthDay + "px;' class='td-day-month td-day-month-" + j + "-" + i + " " + (i == 5 ? 'last-row' : '') + " " + (i == 0 ? 'first-row' : '') + " " + (j == 0 ? 'first-col' : '') + " " + (j == (this.daysToShow.length-1) ? 'last-col' : '') + "'>" +
                    "<div class='cell-day date-" + day.toString('yyyy-MM-dd') + "' data-date='" + day.toString('yyyy-MM-dd') + "'>" +
                    "<div class='num-day " + (day.month() == this.curMonth ? "current" : "" ) + "'>" +
                    "<span><a href='javascript:void(0);' class='link-to-day " + ( this.options.disabledLinks ? ' disabled' : '') + "'>" + day.toString('d') + "</a></span>" +
                    "</div>" +
                    "<div style='height:" + (((this.options.height/6) - 1) - 18) + "px;' class='events'>" +
                    "" +
                    "</div>" +
                    "</div>" +
                    "</td>" +
                    "";
                n++;
            });

            td_grid += "<tr>" + td_day + "</tr>";
        }

        var agenda_grid = "" +
            "<div class='grid' style='height: " + this.options.height + "px'>" +
            "<table class='tb-grid'>" +
            td_grid +
            "</table>" +
            "</div>" +
            "";

        var agenda = "<div class='webf-agenda agenda-month'>" + agenda_toolbar + agenda_days + agenda_grid + "</div>";

        this.e.empty().append(agenda);

        this.e.find('.cell-day .events').webfScrollbar( {
            wheelSpeed: 5
        });

        if (webf.inArray("datepicker", this.options.displayViews)) {
            this._initDatePicker();
        }
    },

    _loadEvents: function(callback)
    {
        var data = this.options.data;

        var afterLoadEvents = events => {
            webf.each(events || [], (i, event) => {
                this._addEvent(event, true);
            });

            if (this.options.allowOverlapEvents) {
                if (this.view == 'week') {
                    webf.each(this.daysToShow, (i, value) => {
                        var day = this.start.clone().addDays(value);

                        if (this.options.users.length) {
                            for (var j in this.options.users) {
                                this._setConfOverlapEvents(day, this.options.users[j]);
                            }
                        } else {
                            this._setConfOverlapEvents(day, undefined, true);
                        }
                    });
                } else if (this.view == 'day') {
                    if (this.options.users.length) {
                        for (var i in this.options.users) {
                            this._setConfOverlapEvents(this.start.clone(), this.options.users[i]);
                        }
                    } else {
                        this._setConfOverlapEvents(this.start);
                    }
                }
            }

            this._setConfAllDayEvents();

            this._renderEvents(webf.merge(this.events, this.allDayEvents));

            this._call(callback || this.options.onLoad, this.events);
        };


        if (webf.isFunction(data)) {
            this._call(data, function(events) {
                afterLoadEvents(events);
            }, this.start.clone(), this.end.clone());
        } else if (webf.isString(data)) {
            var params = this.options.urlParams || {};

            params[this.options.startParam] = this.start.toString(this.options.formatDateParam);
            params[this.options.endParam] = this.end.toString(this.options.formatDateParam);

            $.ajax( {
                url: data,
                data: params,
                success: afterLoadEvents,
                type: 'post',
                dataType: 'json'
            });
        } else {
            afterLoadEvents.call(null, data);
        }
    },

    _renderEvents: function(events)
    {
        webf.each(events, (i, event) => {
            if (event.invisible) {
                return true;
            }

            var start = new WebfDate(event.start, event.format);
            var end = new WebfDate(event.end, event.format);

            if (!(start.isEarlier(this.end) && end.isLater(this.start))) {
                return true;
            }

            if (!end.isLater(start, false) || !(start.isEarlier(this.end, false) && end.isLater(this.start, false))) {
                return true;
            }

            event.skin = event.skin ? event.skin : this.options.defaultSkin;
            var skin = event.skin;
            var cssEvent = this._getCssSkin(skin, 'event');
            var cssTitle = this._getCssSkin(skin, 'title');
            var cssContent = this._getCssSkin(skin, 'content');

            var $blockEvent, n;

            if (webf.inArray(this.view,['week','day'])) {
                if (event._allDay) {
                    var nbDays = this.view == 'day' ? 1 : 7;
                    var allday = event._allDay;
                    var colspanEvent = allday.nbCols;
                    var col = allday.col;

                    if (allday.col < 0) {
                        col = 0;
                        colspanEvent = Math.min(nbDays, allday.nbCols + allday.col);
                    } else if (allday.col + allday.nbCols > nbDays) {
                        colspanEvent = nbDays - allday.col;
                    }

                    $blockEvent = $("" +
                        "<td class='td-event' colspan='" + colspanEvent + "'>" +
                        "<div class='event' style='" + cssEvent + ";" + cssContent + "'>" +
                        event.content.htmlquotes() +
                        "</div>" +
                        "</td>" +
                        "");

                    $blockEvent.find('.event').data('event', event);

                    if (this.e.find('.allday .tb-events tr').length - 1 < allday.row) {
                        var nbRowsNeeded = this.e.find('.allday .tb-events tr').length - allday.row;

                        for (var j=0; j<=nbRowsNeeded; j++) {
                            $('.allday .tb-events').append("<tr></tr>");
                        }
                    }

                    var $row = this.e.find('.allday .tb-events tr').eq(allday.row);
                    if ($row.find('.td-event').length == 0) {
                        $row.append("<td colspan='1'></td>".repeat(col));
                        $row.append($blockEvent);
                        $row.append("<td colspan='1'></td>".repeat(nbDays - (colspanEvent + col)));
                    } else {
                        if (col == 0) {
                            $row.find('td').eq(0).replaceWith($blockEvent);
                        } else {
                            n = -1;
                            $row.find('td').each(function(i, td) {
                                n += parseInt($(td).attr('colspan'));
                                if (n == col) {
                                    $(td).replaceWith($blockEvent);
                                    return true;
                                }
                            });
                        }
                    }

                    n = 0;
                    $row.find('td').each(function(i, td) {
                        n += parseInt($(td).attr('colspan'));
                        if (n > nbDays) {
                            $(td).remove();
                        }
                    });

                    this.e.find('.allday .td-col-days').css('height', (this.e.find('.allday .tb-events tr').length + 1) * 18);

                } else {
                    var segments = this._getSegments(event);

                    webf.each(segments, (i, segment) => {
                        var widthSegment = 100,
                            leftSegment = 0,
                            startSegment = new WebfDate(segment.start, segment.format),
                            endSegment = new WebfDate(segment.end, segment.format);
                        var segmentDay;

                        var top = (startSegment.hours() - this.options.businessHours.start) * (this.options.timeslotHeight * this.options.timeslotsPerHour) + (startSegment.minutes() / 60) * (this.options.timeslotHeight * this.options.timeslotsPerHour);
                        var height = (endSegment.hours() + (!endSegment.equals(startSegment, 'day') ? 24 : 0) - this.options.businessHours.start) * (this.options.timeslotHeight * this.options.timeslotsPerHour) + (end.minutes() / 60) * (this.options.timeslotHeight * this.options.timeslotsPerHour) - top;
                        height = this.options.minHeightEvent > 0 ? Math.max(height, this.options.minHeightEvent) : height;

                        if (segment._segments) {
                            var strDay = startSegment.toString('yyyy_MM_dd');
                            segmentDay = segment._segments[strDay];

                            widthSegment = (100 - this.options.marginOverlap) / segmentDay._nbCols;
                            leftSegment = widthSegment * segmentDay._col;
                        }

                        var num_day = (startSegment.clone().day() + 6) % 7;

                        $blockEvent = $("" +
                            "<div style='left:" + leftSegment + "%; width:" + widthSegment + "%;" + cssEvent + "top:" + top + "px; height: " + height + "px;" + (this.options.skins[skin] && this.options.skins[skin].borderColorContent ? "border-color: " + this.options.skins[skin].borderColorContent : "") + "' class='event event-" + (event.id + '').toCssClassName() + (!this.options.readonly && this.options.draggable && !event.readonly ? " draggable" : "") + "'>" +
                            (segment._numSegment == 0 ? "<div style='" + cssTitle + "' class='title'>" + startSegment.toString("H:mm") + "</div>" : '') +
                            "<div style='" + cssContent + " 'class='content'>" + segment.content + "</div>" +
                            (segment._numSegment + 1 == segment._nbSegments && !this.options.readonly && this.options.resizable && !event.readonly ? "<div class='handle-resizable'>=</div>" : "" ) +
                            "</div>" +
                            "")
                            .data('col', num_day)
                            .data('segment',segment)
                            .data('event',event)
                        ;

                        var $colFullDay = this.e.find('.col-day-' + num_day + ' .col-day-full');
                        if (this.options.users.length && !webf.isUndefined(event.user)) {
                            $blockEvent.data('user', event.user.id);
                            $colFullDay.find('.td-user.user-' + event.user.id + " .col-user").append($blockEvent);
                        } else {
                            $colFullDay.append($blockEvent);
                        }
                    });
                }


            } else if (this.view == 'month') {
                $blockEvent = $("" +
                    "<div style='" + cssEvent + "' class='event event-" + (event.id + '').toCssClassName() + (!this.options.readonly && this.options.draggable && !event.readonly ? " draggable" : "") + "'>" +
                    "<div style='" + cssTitle + "' class='title'><b>" + start.toString("H:mm") + " - " + end.toString("H:mm") + "</b> " + event.content.htmlquotes() + "</div>" +
                    "</div>" +
                    "").data('event',event).data('start',start);

                var $events = this.e.find(".cell-day.date-" + start.toString('yyyy-MM-dd') + " .events");

                if ($events.length) {
                    var event_inserted = false;
                    $events.find('.event').each(function(i,event) {
                        var $event = $(event);
                        if ($event.data('start').isLater(start)) {
                            $event.before($blockEvent);
                            event_inserted = true;
                            return false;
                        }
                    });

                    if (!event_inserted) {
                        $events.webfScrollbar('container').append($blockEvent);
                    }

                    $events.webfScrollbar('update');
                }
            }
        });
    },

    getRenderedEvent: function(event)
    {
        return this.e.find('.event-' + (event.id + '').toCssClassName());
    },

    _setConfOverlapEvents: function(day, user)
    {
        var self = this;
        var wd = new WebfDate(day);
        var strWd = wd.toString('yyyy_MM_dd');

        var events = this.getEvents(wd.startOfDay(), wd.clone().addDay(), user, false);

        if (!events.length) {
            return;
        }

        events = events.sort(function(a, b) {
            return new WebfDate(a.start, a.format).compare(new WebfDate(b.start, b.format));
        });

        var tmpSegments = [],
            max_ends = [],
            nb_cols = [],
            prevSegment,
            max_group_end_date = []
        ;

        webf.each(events, function(i, event) {
            var segments = self._getSegments(event);

            webf.each(segments, function(j, segment) {
                var segStart = new WebfDate(segment.start, segment.format),
                    segEnd = new WebfDate(segment.end, segment.format);

                if (!segStart.equals(wd, 'day')) {
                    return true;
                }

                var start_cur_seg = segStart.unix(),
                    end_cur_seg = segEnd.unix();

                if (!tmpSegments.length) {
                    segment._group = 0;
                    segment._col = 0;
                    prevSegment = segment;
                } else {
                    if (start_cur_seg < max_group_end_date[prevSegment._group]) {
                        segment._group = prevSegment._group;
                    } else {
                        segment._group = prevSegment._group + 1;
                    }

                    prevSegment = segment;

                    webf.each(tmpSegments, function(j, seg) {
                        var end_seg = new WebfDate(seg.end, seg.format).unix();

                        if (start_cur_seg >= end_seg) {
                            if (webf.isUndefined(max_ends[seg._col]) || start_cur_seg >= max_ends[seg._col]) {
                                segment._col = seg._col;
                                return false;
                            }
                        } else {
                            segment._col = webf.isUndefined(segment._col) ? 1 : segment._col + 1;
                        }
                    });
                }

                max_group_end_date[segment._group] = Math.max(!webf.isUndefined(max_group_end_date[segment._group]) ? max_group_end_date[segment._group] : 0, new WebfDate(segment.end,segment.format).unix());
                nb_cols[segment._group] = Math.max(!webf.isUndefined(nb_cols[segment._group]) ? nb_cols[segment._group] : 1, segment._col + 1);
                max_ends[segment._col] = Math.max(!webf.isUndefined(max_ends[segment._group]) ? max_ends[segment._group] : 1, end_cur_seg);
                tmpSegments.push(segment);

                if (!event._segments) {
                    event._segments = {};
                }

                event._segments[strWd] = {
                    _group: segment._group,
                    _col: segment._col
                };
            });
        });

        webf.each(events, function(i, event) {
            event._segments[strWd]._nbCols = nb_cols[event._segments[strWd]._group];
        });
    },

    _setConfAllDayEvents: function()
    {
        var self = this;

        if (!this.allDayEvents.length) {
            return;
        }

        var tmpEvents = [];

        this.allDayEvents = this.allDayEvents.sort(function(a, b) {
            return new WebfDate(a.start, a.format).compare(new WebfDate(b.start, b.format));
        });

        var maxRow = 0;
        webf.each(this.allDayEvents, function(n, event) {
            var start = new WebfDate(event.start, event.format);
            var end = new WebfDate(event.end, event.format);

            if (!(start.isEarlier(self.end) && end.isLater(self.start))) {
                return null;
            }

            var allday = {};

            allday.row = 0;
            allday.col = start.diff(self.start, 'days');
            allday.nbCols = end.diff(start, 'days');

            for( var i=0; i<=maxRow+1; i++) {
                var evsRow = webf.map(tmpEvents, function(j, evRow) {
                    return evRow._allDay.row == i ? evRow : null;
                });

                var rowAvailable = true;
                webf.each(evsRow, function(j, evRow) {
                    var startTmpEv = new WebfDate(evRow.start, evRow.format);
                    var endTmpEv = new WebfDate(evRow.end, evRow.format);

                    if (!(end.isEarlier(startTmpEv, false) || start.isLater(endTmpEv, false))) {
                        rowAvailable = false;
                    }
                });

                if (rowAvailable) {
                    allday.row = i;
                    break;
                }
            }

            maxRow = Math.max(maxRow, allday.row);
            event._allDay = allday;

            tmpEvents.push(event);
        });
    },

    _bindEvents: function()
    {
        var self = this;

        this._on(this.e, '.event', {
            'mousedown click' : function(ev) {
                self.mousedownonagenda = true;
                self.moving = false;
                self.creatingOnClick = false;
                ev.stopPropagation();
            }
        })._on(this.e, '.event', {
            'mouseup' : function(ev) {
                ev.preventDefault();

                if (self.mousedownonagenda) {
                    self.mousedownonagenda = false;
                    var $event = $(this);
                    var event = $event.data('event');

                    if (!self.moving && !self.creating && !self.creatingOnClick) {
                        self._call(self.options.eventClick, event, $event, self._isAllDayEvent(event));
                    }
                }
            }
        })._on(this.e, '.event.draggable, .event .handle-resizable', {
            mousedown: function(ev) {
                ev.preventDefault();

                var $eventMouseDown = $(this).closest('.event');
                var event = $eventMouseDown.data('event');
                var $event = self.getRenderedEvent(event);

                var startEvent = new WebfDate(event.start, event.format);

                self.oldEvent = webf.clone(event);
                self.startPoint = {
                    offsetPage: $webf.getMousePos(ev),
                    numSegment: $eventMouseDown.data('segment')._numSegment,
                    ySegment: $webf.getMousePos(ev, $eventMouseDown).y,
                    yColEvent: (startEvent.diff(startEvent.clone().startOfDay(), 'minutes')) * ((self.options.timeslotHeight * self.options.timeslotsPerHour) / 60),
                    numColEvent: $eventMouseDown.closest('.td-col-day').data('col') - $eventMouseDown.data('segment')._numSegment,
                    numColSegment: $eventMouseDown.closest('.td-col-day').data('col')
                };

                if ($(ev.target).hasClass('handle-resizable')) {
                    self.resizing = $event;
                } else {
                    self.dragging = $event;
                }
            }
        })._on(this.e, '.event', {
            'mouseenter' : function(ev) {
                if (!self.moving && !self.creating && !self.draggingMonthEvent && !self.dragging && !self.resizing) {
                    ev.preventDefault();
                    self.mouseEnterOnEvent = true;

                    var $event = $(this);
                    var event = $event.data('event');

                    self._call(self.options.eventMouseover, event, $event);
                }
            }
        })._on(this.e, '.event', {
            'mouseleave' : function(ev) {
                if (self.mouseEnterOnEvent && !self.moving && !self.creating && !self.draggingMonthEvent && !self.dragging && !self.resizing) {
                    ev.preventDefault();
                    self.mouseEnterOnEvent = false;

                    var $event = $(this);
                    var event = $event.data('event');

                    self._call(self.options.eventMouseout, event, $event);
                }
            }
        })._on(this.e, (this.options.users.length ? '.td-user' : '.td-col-day') + ', .td-day-month', {
            mousemove: function(ev) {
                var $event, event, mp, num_col, newStart, newEnd;

                ev.preventDefault();

                if (self.creatingOnClick && self.options.newEventOnClick) {
                    return;
                }

                if (self.mousedownonagenda) {
                    self.moving = true;

                    if (self.temporaryEvent) {
                        $event = self._createEvent(self.temporaryEvent, true);
                        self.resizing = $event;
                        self.temporaryEvent = null;
                    }
                }

                if (self.dragging || self.resizing) {
                    var $tmpEvent = self.dragging || self.resizing;

                    if (!$tmpEvent.hasClass('temporary')) {
                        var $cloneEvent = $tmpEvent.clone(true);
                        $tmpEvent.remove();

                        if (self.dragging) {
                            self.dragging = $cloneEvent;
                        } else if (self.resizing) {
                            self.resizing = $cloneEvent;
                        }
                    }
                }

                if (self.dragging) {
                    $event = self.dragging;

                    if (webf.inArray(self.view, ['week','day'])) {
                        var user_col = $event.data('user') ? $event.data('user').id : null;
                        var num_user_col;

                        if ($(ev.target).closest('td').hasClass('td-user')) {
                            num_user_col = $(this).data('user');
                            num_col = $(this).closest('.td-col-day').data('col');
                        } else {
                            num_col = $(this).data('col');
                        }

                        mp = $webf.getMousePos(ev, $(this));
                        event = $event.data('event');

                        newStart = self.start.clone()
                            .addDays(num_col - self.startPoint.numSegment);

                        if (self.shiftKey) {
                            // Le drag n'est pas contraint par les slots
                            newStart.addMinutes(
                                (Math.ceil(mp.y - (self.startPoint.numSegment > 0 ? self.startPoint.ySegment - self.startPoint.yColEvent : self.startPoint.ySegment))) *
                                (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                            );
                        } else {
                            // Le drag est contraint par le slot
                            newStart.addMinutes(
                                (Math.ceil((mp.y - (self.startPoint.numSegment > 0 ? self.startPoint.ySegment - self.startPoint.yColEvent : self.startPoint.ySegment)) / self.options.timeslotHeight) * self.options.timeslotHeight) *
                                (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                            )
                        }

                        var durationEventSeconds = new WebfDate(event.end, event.format).diff(new WebfDate(event.start, event.format), 'seconds');
                        newEnd = newStart.clone().addSeconds(durationEventSeconds);

                        if (self.altKey) {
                            // Si un event se trouve dans le même slot, l'event draggé va se coller à lui
                            var eventsStartInSlot = self.getStartEvents(newEnd, newEnd.clone().addMinutes(60 / self.options.timeslotsPerHour), null, false);
                            var eventsEndInSlot = self.getEndEvents(newStart, newStart.clone().addMinutes(60 / self.options.timeslotsPerHour), null, false);

                            webf.each(eventsStartInSlot.sort(function(a, b) {
                                return new WebfDate(a.start, a.format).isLater(new WebfDate(b.end, b.format));
                            }), function(i, eventSlot) {
                                var dateDebut = new WebfDate(eventSlot.start, eventSlot.format);

                                if (eventSlot.id != event.id) {
                                    newEnd
                                        .hours(dateDebut.hours())
                                        .minutes(dateDebut.minutes())
                                        .seconds(dateDebut.seconds());

                                    newStart = newEnd.clone().subSeconds(durationEventSeconds);
                                }
                            });

                            webf.each(eventsEndInSlot.sort(function(a, b) {
                                return new WebfDate(a.start, a.format).isEarlier(new WebfDate(b.end, b.format));
                            }), function(i, eventSlot) {
                                var dateFin = new WebfDate(eventSlot.end, eventSlot.format);

                                if (eventSlot.id != event.id) {
                                    newStart
                                        .hours(dateFin.hours())
                                        .minutes(dateFin.minutes())
                                        .seconds(dateFin.seconds());

                                    newEnd = newStart.clone().addSeconds(durationEventSeconds);
                                }
                            });
                        }

                        event.start = newStart.toString(event.format);
                        event.end = newEnd.toString(event.format);

                        var $clone = $event.clone(true);
                        $event.remove();
                        self.dragging = $clone;

                        if (!webf.isUndefined(num_user_col)) {
                            if (num_user_col != user_col) {
                                var user = self._getUserById(num_user_col);
                                $event.data('user', user);

                                event = $event.data('event');
                                event.user = user;
                                $event.data('event',event);
                            }
                        }
                    }

                    self._renderMovingEvent(event);
                } else if (self.resizing) {
                    $event = self.resizing;
                    event = $event.data('event');

                    if (webf.inArray(self.view, ['week','day'])) {
                        mp = $webf.getMousePos(ev, $(this));
                        num_col = $(this).data('col');

                        var startEvent = new WebfDate(event.start, event.format);
                        var endEvent = new WebfDate(event.end, event.format);

                        if (self.invertResizing) {
                            if (self.shiftKey) {
                                // Le drag n'est pas contraint par les slots
                                newStart = self.start.clone().addDays(self.view == 'week' ? num_col : 0)
                                    .addMinutes(
                                        Math.floor(mp.y) *
                                        (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                                    )
                                ;
                            } else {
                                newStart = self.start.clone().addDays(self.view == 'week' ? num_col : 0)
                                    .addMinutes(
                                        (Math.floor(mp.y / self.options.timeslotHeight) * self.options.timeslotHeight) *
                                        (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                                    )
                                ;
                            }

                            if (newStart.isLater(endEvent)) {
                                event.start = event.end;
                                event.end = newStart.toString(event.format);

                                self.invertResizing = false;
                            } else {
                                event.start = newStart.toString(event.format);
                            }
                        } else {
                            if (self.shiftKey) {
                                // Le drag n'est pas contraint par les slots
                                newEnd = self.start.clone().addDays(self.view == 'week' ? num_col : 0)
                                    .addMinutes(
                                        Math.ceil(mp.y) *
                                        (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                                    )
                                ;
                            } else {
                                // Le resize est contraint par le slot
                                newEnd = self.start.clone().addDays(self.view == 'week' ? num_col : 0)
                                    .addMinutes(
                                        (Math.ceil(mp.y / self.options.timeslotHeight) * self.options.timeslotHeight) *
                                        (1 / ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60))
                                    )
                                ;
                            }

                            if (self.altKey) {
                                // Si un event se trouve dans le même slot, l'event draggé va se coller à lui
                                var eventsStartInSlot = self.getStartEvents(newEnd, newEnd.clone().addMinutes(60 / self.options.timeslotsPerHour), null, false);

                                webf.each(eventsStartInSlot.sort(function (a, b) {
                                    return new WebfDate(a.start, a.format).isLater(new WebfDate(b.end, b.format));
                                }), function (i, eventSlot) {
                                    var dateDebut = new WebfDate(eventSlot.start, eventSlot.format);

                                    if (eventSlot.id != event.id) {
                                        newEnd
                                            .hours(dateDebut.hours())
                                            .minutes(dateDebut.minutes())
                                            .seconds(dateDebut.seconds());

                                        newStart = newEnd.clone().subSeconds(durationEventSeconds);
                                    }
                                });
                            }

                            if (newEnd.isEarlier(startEvent)) {
                                event.end = event.start;
                                event.start = newEnd.toString(event.format);

                                self.invertResizing = true;
                            } else {
                                event.end = newEnd.toString(event.format);
                            }
                        }
                    }

                    self._renderMovingEvent(event);
                }

            }
        })._on(this.e, '.allday .tb-events td', {
            mousedown: function(ev) {
                var col = 0;

                $(this).prevAll('td').each(function(i, td) {
                    col += parseInt($(td).attr('colspan'));
                });

                self.e.find('.allday .td-col-allday').eq(col).trigger(ev.type);
            }
        })._on(this.e, '.allday .td-col-allday', {
            mousedown: function(ev) {
                if (self.options.readonly) {
                    return;
                }

                ev.preventDefault();

                self.mousedownonalldays = true;

                var colDay = $(this).prevAll('td').length;

                self.colDayStart = colDay;
                self.colDayEnd = colDay;
            }
        })._on(this.e, '.tb-agenda-days, .td-day-month', {
            mousedown: function(ev) {
                var start, $event, event, end;

                if (self.options.readonly) {
                    return;
                }

                ev.preventDefault();

                if (self._call(self.options.beforeCreateEvent) === false) {
                    return;
                }

                self.mousedownonagenda = true;

                var fmt = 'yyyy-MM-dd HH:mm';
                event = {
                    id: new WebfDate().time() + webf.uniqid(),
                    content: self.options.newEventText,
                    format: fmt
                };

                if (webf.inArray(self.view,['week','day'])) {
                    var $colUser = $(ev.target).closest('td');
                    if ($colUser.hasClass('td-user')) {
                        event.user = self._getUserById($colUser.data('user'));
                    }

                    var $colDay = $(ev.target).closest('.td-col-day');
                    var colDay = $colDay.data('col');

                    var mp = $webf.getMousePos(ev, $colDay);

                    start = self.start.clone().addDays((self.view == 'week' ? colDay : 0))
                        .addMinutes(
                            Math.floor(mp.y / self.options.timeslotHeight) *
                            (60 / self.options.timeslotsPerHour)
                        );

                    event.start = start.toString(fmt);
                    event.end = start.clone().addMinutes((60 / self.options.timeslotsPerHour)).toString(fmt);

                    self.temporaryEvent = event;
                    $event = self._createEvent(self.temporaryEvent, true);
                    self.resizing = $event;
                } else if (self.view == 'month') {
                    var dayDate = $(this).find('.cell-day').data('date');

                    start = new WebfDate(dayDate,'yyyy-MM-dd').hours(self.options.businessHours.start);
                    end = start;

                    event.start = start.toString(fmt);
                    event.end = end.toString(fmt);

                    $event = self._addEvent(event);

                    if (false == self._call(self.options.eventNew, event, $event)) {
                        self.removeEvent(event);
                    }
                }

                if (self.options.newEventOnClick) {
                    self.creatingOnClick = true;
                    self._dragend.call(self, $event);
                } else {
                    self.creating = true;
                }
            }
        })._on(this.e, '.tb-days .td-day .link-days', {
            'click': function() {
                if (self.options.disabledLinks) {
                    return false;
                }
                var num_day = $(this).closest('.td-day').prevAll('.td-day').length;
                var wd = self.start.clone().addDays(num_day);
                self.changeView('day', wd);
            }
        })._on(this.e, '.td-day-month .cell-day .num-day a.link-to-day', {
            mousedown: function(ev) {
                if (!self.options.disabledLinks) {
                    ev.stopPropagation();
                }
            }
        })._on(this.e, '.td-day-month .cell-day .num-day a.link-to-day', {
            'click': function(ev) {
                if (self.options.disabledLinks) {
                    return false;
                }
                self.changeView('day', new WebfDate($(this).closest('.cell-day').data('date'),'yyyy-MM-dd'));
            }
        })._on(this.e, {
            'blur mouseup mouseleave': function(ev) {
                self.temporaryEvent = null;
                self.shiftKey = false;

                if (self.mousedownonalldays) {
                    self.e.find('.allday .td-col-allday').removeClass('hover');

                    var fmt = 'yyyy-MM-dd HH:mm';
                    var colStart = self.colDayStart;
                    var colEnd = self.colDayEnd;

                    if (self.colDayStart > self.colDayEnd) {
                        var tmp = colStart;
                        colStart = colEnd;
                        colEnd = tmp;
                    }

                    var event = {
                        id: new WebfDate().time() + webf.uniqid(),
                        content: self.options.newEventText,
                        start: self.start.clone().addDays(colStart).toString(fmt),
                        end: self.start.clone().addDays(colEnd + 1).toString(fmt),
                        format: fmt
                    };

                    self.addEvent(event);

                    var $event = self.getRenderedEvent(event);

                    if (false == self._call(self.options.eventNew, event, $event, true)) {
                        self.removeEvent(event);
                    }
                } else if (self.moving || self.creating) {
                    if (self.draggingMonthEvent) {
                        self._dragend.call(self, self.draggingMonthEvent, ev);
                        self.e.find('.td-day-month').removeClass('drop');
                        self.draggingMonthEvent.remove();
                    } else if (self.dragging) {
                        self._dragend.call(self, self.dragging, ev);
                    } else if (self.resizing) {
                        self._dragend.call(self, self.resizing, ev);
                    }
                }

                self.moving = false;
                self.creating = false;
                self.draggingMonthEvent = false;
                self.dragging = false;
                self.resizing = false;
                self.invertResizing = false;
                self.mousedownonalldays = false;
            }
        })._on(this.e, {
            mousemove: function(ev) {
                if (self.mousedownonalldays) {
                    ev.preventDefault();

                    var $tbAgenda = self.e.find('.tb-agenda-days');
                    var leftAgenda = $tbAgenda.offset().left;
                    var widthAgenda = $tbAgenda.width();
                    var mp = $webf.getMousePos(ev);
                    self.colDayEnd = webf.between(Math.floor(((mp.x - leftAgenda) / widthAgenda) * 7), 0, 6);

                    self.e.find('.allday .td-col-allday').each(function(i) {
                        if (self.colDayStart < self.colDayEnd) {
                            if (i < self.colDayStart || i > self.colDayEnd) {
                                $(this).removeClass('hover');
                            } else {
                                $(this).addClass('hover');
                            }
                        } else {
                            if (i > self.colDayStart || i < self.colDayEnd) {
                                $(this).removeClass('hover');
                            } else {
                                $(this).addClass('hover');
                            }
                        }
                    });
                }
            }
        })._on(document, {
            keydown: function(ev) {
                switch (ev.which) {
                    case 18: // Alt:
                        self.altKey = true;
                        break;

                    case 16: // Shift
                        self.shiftKey = true;
                        break;
                }
            },
            keyup: function(ev) {
                switch (ev.which) {
                    case 18: // Alt:
                        self.altKey = false;
                        break;

                    case 16: // Shift
                        self.shiftKey = false;
                        break;
                }
            }
        });

        if (this.option('hourMarker')) {
            webf.setInterval(function() {
                var now = new WebfDate();

                self.e.find('.hour-marker').hide();
                var $hourMarker = self.e.find('.td-col-day.today .hour-marker');

                var heightPerHour = self.option('timeslotHeight') * self.option('timeslotsPerHour');
                var top = ((now.hours() + (now.minutes() / 60)) * heightPerHour)
                    - (self.option('businessHours').start * heightPerHour)
                    - ($hourMarker.height() / 2);

                $hourMarker.css('top', top).show();
            }, 1000);
        }

        this._on(window, {
            resize: webf.throttle((ev) => {
                this._setHeight(this.option('height'));
            }, 150, false, true)
        });
    },

    _renderMovingEvent: function(event)
    {
        var $event = this.getRenderedEvent(event);
        $event.detach();

        var self = this;
        var evSegments = this._getSegments(event);

        webf.each(evSegments, function(i, evSegment) {
            var start = new WebfDate(evSegment.start, evSegment.format);
            var end = new WebfDate(evSegment.end, evSegment.format);
            var durationEventMinutes = end.diff(start, 'minutes');

            var num_day = (start.clone().day() + 6) % 7;

            var heightMin = (self.options.timeslotHeight * self.options.timeslotsPerHour) / 60;
            var top = heightMin * ((start.hours() * 60) + start.minutes() - (self.option('businessHours').start) * 60);
            var height = Math.max(durationEventMinutes * ((self.options.timeslotsPerHour * self.options.timeslotHeight) / 60), self.options.timeslotsPerHour);

            event.skin = event.skin ? event.skin : self.options.defaultSkin;
            var skin = event.skin;
            var cssEvent = self._getCssSkin(skin, 'event');
            var cssTitle = self._getCssSkin(skin, 'title');
            var cssContent = self._getCssSkin(skin, 'content');

            var $blockEvent = $("" +
                "<div style='left:0%; width:100%;" + cssEvent + "top:" + top + "px; height: " + height + "px;" + (self.options.skins[skin] && self.options.skins[skin].borderColorContent ? "border-color: " + self.options.skins[skin].borderColorContent : '') + "' class='event temporary event-" + (event.id + '').toCssClassName() + (!self.options.readonly && self.options.draggable && !event.readonly ? " draggable" : "") + "'>" +
                (evSegment._numSegment == 0 ? "<div style='" + cssTitle + "' class='title'>" + start.toString("H:mm") + "</div>" : '') +
                "<div style='" + cssContent + " 'class='content'>" + evSegment.content + "</div>" +
                (evSegment._numSegment + 1 == evSegment._nbSegments && !self.options.readonly && self.options.resizable && !event.readonly ? "<div class='handle-resizable'>=</div>" : "" ) +
                "</div>" +
                "")
                .data('col', num_day)
                .data('segment',evSegment)
                .data('event',event)
            ;

            var $colFullDay = self.e.find('.col-day-' + num_day + ' .col-day-full');
            if (self.options.users.length && !webf.isUndefined(event.user)) {
                $blockEvent.data('user', event.user.id);
                $colFullDay.find('.td-user.user-' + event.user.id + " .col-user").append($blockEvent);
            } else {
                $colFullDay.append($blockEvent);
            }
        });
    },

    _dragend: function($event, ev)
    {
        var self = this, newEvent;

        if (webf.inArray(this.view,['week', 'day'])) {
            newEvent = $event.data('event');

            if (this.options.allowOverlapEvents || this._isFree(newEvent)) {
                $event.data('event', newEvent);

                self.e.find('.event.temporary').remove();

                if (this.creating || this.creatingOnClick) {
                    if (false === this._call(this.options.eventNew, newEvent, $event)) {
                        this.removeEvent(newEvent);
                    }
                } else {
                    if (!webf.equals(newEvent, this.oldEvent)) {
                        if (false === this._call(this.options.eventChange, newEvent, $event)) {
                            this.removeEvent(newEvent);
                            this._addEvent(this.oldEvent);
                        }
                    } else {
                        this.triggerClick(this.oldEvent);
                    }
                }

                if (this.options.allowOverlapEvents) {
                    var daysToRender = {};

                    if (!this.options.newEventOnClick && !self.creating) {
                        webf.each(this._getDaysEvent(this.oldEvent), function(i, day) {
                            daysToRender[day.toString('yyyy-MM-dd')] = day;
                        });
                    }

                    this._addEvent(newEvent, true);

                    webf.each(this._getDaysEvent(newEvent), function(i, day) {
                        daysToRender[day.toString('yyyy-MM-dd')] = day;
                    });

                    webf.each(daysToRender, function(i, day) {
                        self._setConfOverlapEvents(day);
                    });

                    webf.each(daysToRender, function(i, day) {
                        self._renderDayEvents(day);
                    });
                }
            } else {
                if (this.creating) {
                    this.removeEvent($event.data('event'));
                } else {
                    this.removeEvent(newEvent);
                    this._addEvent(this.oldEvent);
                }

                $event.remove();
            }
        } else if (this.view == 'month') {
            newEvent = $event.data('event');

            var newDay = new WebfDate($event.data('day'),'yyyy-MM-dd');
            var oldStartDate = new WebfDate(this.oldEvent.start,this.oldEvent.format);
            var oldEndDate = new WebfDate(this.oldEvent.end,this.oldEvent.format);

            newEvent.start = newDay.hours(oldStartDate.hours()).minutes(oldStartDate.minutes()).seconds(0).toString(this.oldEvent.format);
            newEvent.end = newDay.hours(oldEndDate.hours()).minutes(oldEndDate.minutes()).seconds(0).toString(this.oldEvent.format);

            if (this._isFree(newEvent)) {
                this.removeEvent(this.oldEvent);
                $event = this._addEvent(newEvent);

                if (this.creating) {
                    if (false === this._call(this.options.eventNew, newEvent, $event)) {
                        this.removeEvent(newEvent);
                    }
                } else {
                    if (false === this._call(this.options.eventChange, newEvent, $event)) {
                        this.removeEvent(newEvent);
                        this._addEvent(this.oldEvent);
                    }
                }
            } else {
                if (this.creating) {
                    this.removeEvent(newEvent);
                } else {
                    this.removeEvent(newEvent);
                    this._addEvent(this.oldEvent);
                }
            }
        }
    },

    _bindButtons: function()
    {
        var self = this;

        this._on(this.e, '.webf-button.next-week', {
            'click' : function() {
                self.displayWeek(self.start.clone().addWeek());
            }
        })._on(this.e, '.webf-button.prev-week', {
            'click' : function() {
                self.displayWeek(self.start.clone().subWeek());
            }
        })._on(this.e, '.webf-button.next-day', {
            'click' : function() {
                var newDate = self.start.clone().addDay();
                self.daysToShow = [(newDate.day() + 6) % 7];
                self.displayDay(newDate);
            }
        })._on(this.e, '.webf-button.prev-day', {
            'click' : function() {
                var newDate = self.start.clone().subDay();
                self.daysToShow = [(newDate.day() + 6) % 7];
                self.displayDay(newDate);
            }
        })._on(this.e, '.webf-button.prev-month', {
            'click' : function() {
                var startmonth = self.start.clone().startOfMonth();
                var start = self.start.equals(startmonth,'day') ? startmonth : startmonth.addMonth();
                self.displayMonth(start.subMonth());
            }
        })._on(this.e, '.webf-button.next-month', {
            'click' : function() {
                self.displayMonth(self.start.clone().month(self.curMonth + 1).startOfMonth());
            }
        })._on(this.e, '.webf-button.today', {
            'click' : function() {
                var today = new WebfDate();
                if (self.view == 'month') {
                    self.displayMonth(today);
                } else if (self.view == 'week') {
                    self.displayWeek(today);
                } else if (self.view == 'day') {
                    self.daysToShow = [(today.day() + 6) % 7];
                    self.displayDay(today);
                }
            }
        })._on(this.e, '.views .webf-button', {
            'click' : function(ev) {
                if ($(this).hasClass('datepicker')) {
                    ev.preventDefault();
                    return;
                }

                $(this).closest('.buttons').find('.webf-state').each(function() {
                    $(this).removeClass('webf-state-active');
                });

                $(this).addClass('webf-state-active');

                $(this).closest('.views').find('.webf-button').removeClass('selected');
                $(this).addClass('selected');

                self.prevView = self.view;
                if ($(this).hasClass('week')) {
                    self.changeView('week');
                } else if ($(this).hasClass('month')) {
                    self.changeView('month');
                } else if ($(this).hasClass('day')) {
                    self.changeView('day');
                }
            }
        });
    },

    _isFree: function(newEvent)
    {
        var free = true;

        var start_newEvent = new WebfDate(newEvent.start,newEvent.format);
        var end_newEvent = new WebfDate(newEvent.end,newEvent.format);

        webf.each(this.events, function(i, event) {
            if (event.id != newEvent.id && (webf.isUndefined(event.user) || event.user.id == newEvent.user.id)) {
                var start_event = new WebfDate(event.start,event.format),
                    end_event = new WebfDate(event.end,event.format);

                if (!(start_event.isLater(end_newEvent) || start_event.equals(end_newEvent,'minute'))
                    && !(end_event.isEarlier(start_newEvent) || end_event.equals(start_newEvent,'minute')) )
                {
                    free = false;
                    return false;
                }
            }
        });

        return free;
    },

    _getCssSkin: function(skin, elem)
    {
        if (!skin) {
            return "";
        }

        skin = this.options.skins[skin];

        if (!skin) {
            return "";
        }

        return webf.map(skin, function(index, value) {
            if (elem == 'event') {
                if (index == 'color') {
                    return 'color:' + value;
                } else if (index == 'opacity') {
                    return 'opacity: ' + value + '; filter:alpha(opacty=' + parseInt(value * 100) + ')';
                }
            } else if (elem == 'title') {
                if (index == 'backgroundColorTitle') {
                    return 'background-color:' + value;
                } else if (index == 'borderColor') {
                    return 'border-color:' + value;
                }
            } else if (elem == 'content') {
                if (index == 'backgroundColorContent') {
                    return 'background-color:' + value;
                } else if (index == 'borderColorContent') {
                    return 'border-color:' + value;
                }
            }
        }).join(';') + ";";
    },

    _viewChange: function(newView, date)
    {
        if (date) {
            return new WebfDate(date);
        }

        var now = new WebfDate();

        if (this.start.clone().startOfDay().isEarlier(now) && this.end.clone().endOfDay().isLater(now)) {
            return now;
        }

        var date;

        if (newView == 'day') {
            if (this.prevView == 'month') {
                date = new WebfDate([this.curYear, this.curMonth, 1]);
            } else if (this.prevView == 'week') {
                date = this.start.clone().day(now.day());
            }
        }

        return (date ? date : this.start.clone());
    },

    _createEvent: function(event, temporary)
    {
        if (temporary) {
            this._renderMovingEvent(event);
        } else {
            this.addEvent(event);
        }

        return this.getRenderedEvent(event);
    },

    _renderDayEvents: function(day, user)
    {
        var self = this;

        if (webf.isUndefined(user) && this.options.users.length) {
            for( var i in this.options.users) {
                this._renderDayEvents(day, this.options.users[i]);
            }
        } else {
            var _day = day.clone();
            var eventsDay = this.getEvents(_day.startOfDay(), _day.clone().addDay(), user, false);

            webf.each(eventsDay, function(j, eventDay) {
                var $event = self.getRenderedEvent(eventDay);
                if ($event.length) {
                    $event.remove();
                }
            });

            this._renderEvents(eventsDay);
        }
    },

    _formatDate: function(date, format)
    {
        var wd = new WebfDate(date);

        format = format.replace(/MMMM(?!((?![\[\]]).)*\])/g, '[' + this._(monthName[wd.month()]) + ']');
        format = format.replace(/MMM(?!((?![\[\]]).)*\])/g, '[' + this._(shortMonthName[wd.month()]) + ']');
        format = format.replace(/EEEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EEE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]) + ']');
        format = format.replace(/EE(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,2).ucfirst() + ']');
        format = format.replace(/E(?!((?![\[\]]).)*\])/g, '[' + this._(dayName[wd.day()]).substr(0,1).ucfirst() + ']');

        return wd.toString(format);
    },

    _getUserById: function(id_user)
    {
        var users = this.options.users;

        for( var i in users) {
            if (users[i].id == id_user) {
                return webf.clone(users[i]);
            }
        }

        return null;
    },

    _initDatePicker: function()
    {
        var self = this,
            $btnDatePicker = this.e.find('.toolbar .datepicker');

        $btnDatePicker.webfDatetimepicker( {
            onCreate: function() {
                $(this).parent().addClass('webf-buttons-group');
            },
            position: {
                my:         'left top',
                at:         'left bottom',
                collision:  'flipfit none'
            },
            timepicker: false,
            durationAnimation: 400,
            date: self.start.clone(),
            buttons: {
                today: {
                    label: self._('today'),
                    click: 'today'
                }
            },
            showMonthYear: true,
            onSelectDate: function(date) {
                self.changeView('day', date);
            }
        }).data('datepicker', 1);
    },

    _removeDatePicker: function()
    {
        var $btnDatePicker = this.e.find('.toolbar .datepicker');
        if ($btnDatePicker.data('datepicker')) {
            $btnDatePicker.webfDatetimepicker('destroy');
        }
    },

    _getDaysEvent: function(event)
    {
        var days = [];

        var start = new WebfDate(event.start, event.format);
        var end = new WebfDate(event.end, event.format);

//          if (!end.isLater(start, true) || !(start.isEarlier(this.end) && end.isLater(this.start))) {
//              return days;
//          }

        if (!end.isLater(start, false) || !(start.isEarlier(this.end) && end.isLater(this.start))) {
            return days;
        }

        if (start.isEarlier(this.start)) {
            var diffStart = Math.ceil(this.start.diff(start, 'days', true));
            start.addDays(diffStart);
        }

        if (end.isLater(this.end, true)) {
            var diffEnd = Math.ceil(end.diff(this.end, 'days', true));
            end.subDays(diffEnd);
        }

        if (end.hours() == 0 && end.minutes() == 0) {
            end.subDay().endOfDay();
        }

        var nbDays = Math.ceil(end.diff(start.startOfDay(), 'days', true));

        for( var i=0; i<nbDays; i++) {
            days.push(start.clone().addDays(i));
        }

        return days;
    },

    _getSegments: function(event)
    {
        var self = this;
        var segments = [];

        var start = new WebfDate(event.start, event.format),
            end = new WebfDate(event.end, event.format);

        event._nbSegments = 1;
        event._numSegment = 0;

        var multiday = false;

        if (end.isLater(start, 'day', true)) {
            if (end.diff(start, 'day', true) <= 1 && end.hours() == 0 && end.minutes() == 0) {
                // do nothing
            } else {
                multiday = true;
                var firstSegment = 0,
                    lastSegment,
                    nbSegments;

                nbSegments = Math.ceil(end.diff(start.clone().startOfDay(), 'days', true));
                lastSegment = nbSegments - 1;

                if (start.isEarlier(self.start)) {
                    firstSegment += Math.ceil(self.start.diff(start, 'days', true));
                }

                if (end.isLater(self.end)) {
                    if (end.equals(self.end, 'minute')) {
                        lastSegment = nbSegments - (Math.ceil(end.diff(self.end, 'days', true)) + 2);
                    } else {
                        lastSegment = nbSegments - (Math.ceil(end.diff(self.end, 'days', true)) + 1);
                    }
                }
            }

            if (multiday) {
                var segment,
                    segStart;

                for (var i=firstSegment; i<=lastSegment; i++) {
                    segment = webf.clone(event);

                    segment._nbSegments = nbSegments;
                    segment._numSegment = i;

                    if (i == 0) {
                        segment.end = start.clone().addDay().startOfDay().toString(event.format);
                    } else if (i == lastSegment) {
                        segStart = start.clone().addDays(i).startOfDay();
                        segment.start = segStart.toString(event.format);

                        if (end.equals(end.clone().startOfDay(), 'seconds')) {
                            segment.end = segStart.clone().addDay().toString(event.format);
                        } else {
                            segment.end = segStart.clone()
                                .hours(end.hours())
                                .minutes(end.minutes())
                                .seconds(end.seconds())
                                .toString(event.format);
                        }
                    } else if (i == (nbSegments - 1)) {
                        if (end.equals(self.end, 'minute')) {
                            segment.start = end.clone().subDay().toString(event.format);
                        } else {
                            segment.start = end.clone().startOfDay().toString(event.format);
                            segment.end = end.clone().toString(event.format);
                        }
                    } else if (i == firstSegment) {
                        segStart = start.clone().addDays(i).startOfDay();
                        segment.start = segStart.toString(event.format);
                        segment.end = segStart.addDay().startOfDay().toString(event.format);
                    } else {
                        segStart = start.clone().addDays(i).startOfDay();
                        segment.start = segStart.toString(event.format);
                        segment.end = segStart.addDay().toString(event.format);
                    }

                    segments.push(segment);
                }
            } else {
                segments.push(webf.clone(event));
            }
        } else {
            segments.push(webf.clone(event));
        }

        return segments;
    },

    _isAllDayEvent: function(event)
    {
        var start = new WebfDate(event.start, event.format);

        if (start.equals(start.clone().startOfDay(), 'minute')) {
            var end = new WebfDate(event.end, event.format);

            if (end.equals(end.clone().startOfDay(), 'minute')) {
                return true;
            }
        }

        return false;
    },

    _clearAllDayEvents: function()
    {
        this.e.find('.allday .tb-events').empty();
        this.allDayEvents = [];

        this.e.find('.all-day .td-col-days').css('height', 24);
    },

    _addEvent: function(event, norender)
    {
        var self = this;

        if (webf.isUndefined(event.id)) {
            event.id = new WebfDate().time() + webf.uniqid();
        }

        if (this.getEventById(event.id)) {
            return;
        }

        if (this.options.users.length) {
            var id_user;

            if (webf.isUndefined(event.user)) {
                event.user = this.options.users[0];
            } else {
                if (webf.isObject(event.user)) {
                    if (event.user.id) {
                        id_user = event.user.id;
                    } else {
                        id_user = this.options.users[0].id;
                    }
                } else {
                    id_user = event.user;
                }

                event.user = this._getUserById(id_user);
            }
        }

        if (this._isAllDayEvent(event)) {
            this.allDayEvents.push(event);

            if (!norender) {
                var remainingAllDayEvents = self.allDayEvents.slice();
                self._clearAllDayEvents();
                self.allDayEvents = remainingAllDayEvents;

                self._setConfAllDayEvents();
                self._renderEvents(this.allDayEvents);
            }
        } else if (this.options.allowOverlapEvents || this._isFree(event)) {
            if (event._allDay) {
                delete event._allDay;
            }

            this.events.push(event);

            if (!norender) {
                var days = this._getDaysEvent(event);

                webf.each(days, function(i, day) {
                    self._renderDayEvents(day);
                });

                return this.getRenderedEvent(event);
            }
        }

        return false;
    },

    _setHeight: function(height)
    {
        let heightGrid = height;

        if (height == 'fitbottom') {
            const wh = window.innerHeight;
            const topGrid = this.e.find('.grid').offset().top;

            heightGrid = wh - topGrid - 1;
        }

        this.e.find('.grid')
            .css('height', heightGrid)
            .webfScrollbar('update');
    },

    addEvent: function(event)
    {
        var self = this;

        if (this._isAllDayEvent(event)) {
            this._addEvent(event);
        } else {
            this._addEvent(event, true);

            var days = this._getDaysEvent(event);

            webf.each(days, function(i, day) {
                self._setConfOverlapEvents(day, event.user);
            });

            webf.each(days, function(i, day) {
                self._renderDayEvents(day, event.user);
            });

            return this.getRenderedEvent(event);
        }
    },

    getEvents: function(start, end, user, allday)
    {
        allday = webf.isUndefined(allday) ? true : allday;

        start = new WebfDate(start);
        end = new WebfDate(end);

        return webf.map(webf.merge(this.events, allday ? this.allDayEvents : []), function(i, event) {
            var startEvent = new WebfDate(event.start, event.format);
            var endEvent = new WebfDate(event.end, event.format);

            if (startEvent.isEarlier(end) && endEvent.isLater(start)) {
                if (!user || event.user.id == user.id) {
                    return event;
                }
            }

            return null;
        });
    },

    getStartEvents: function(start, end, user, allday)
    {
        start = new WebfDate(start);
        end = new WebfDate(end);

        return webf.map(webf.merge(this.events, allday ? this.allDayEvents : []), function(i, event) {
            var startEvent = new WebfDate(event.start, event.format);

            if (startEvent.isEarlier(end) && startEvent.isLater(start)) {
                if (!user || event.user.id == user.id) {
                    return event;
                }
            }

            return null;
        });
    },

    getEndEvents: function(start, end, user, allday)
    {
        start = new WebfDate(start);
        end = new WebfDate(end);

        return webf.map(webf.merge(this.events, allday ? this.allDayEvents : []), function(i, event) {
            var endEvent = new WebfDate(event.end, event.format);

            if (endEvent.isEarlier(end) && endEvent.isLater(start)) {
                if (!user || event.user.id == user.id) {
                    return event;
                }
            }

            return null;
        });
    },

    refresh: function(callback)
    {
        var scrollTop = this.e.find('.grid').webfScrollbar('scrollTop');

        this.clearEvents();

        if (this.view == 'month') {
            this._drawMonthAgenda();
        } else if (webf.inArray(this.view,['week','day'])) {
            this._drawWeekAgenda();
        }

        this._loadEvents(callback);

        this.e.find('.grid').webfScrollbar('scrollTo', {y: scrollTop});
    },

    editEvent: function(editEvent)
    {
        webf.each(webf.merge(this.events, this.allDayEvents), (i, event) => {
            if (editEvent.id == event.id) {
                this.removeEvent(editEvent);
                this.addEvent(editEvent);
            }
        })
    },

    removeEvent: function(event)
    {
        var self = this;

        webf.each(self.events, (i, aevent) => {
            if (aevent.id == event.id) {
                this.events.splice(i,1);

                var $event = self.getRenderedEvent(event);
                if (this.view == 'month') {
                    var $events = $event.closest('.events');
                }

                $event.remove();

                if (this.option('allowOverlapEvents')) {
                    var day = this.start.clone();
                    for( var j=0; j<7; j++) {
                        this._setConfOverlapEvents(day, event.user);
                        this._renderDayEvents(day, event.user);
                        day.addDay();
                    }
                }

                if (this.view == 'month') {
                    $events.webfScrollbar('update');
                }

                return false;
            }
        });

        webf.each(this.allDayEvents, (i, aevent) => {
            if (aevent.id == event.id) {
                this.allDayEvents.splice(i,1);
                var remainingAllDayEvents = self.allDayEvents.slice();

                this._clearAllDayEvents();
                this.allDayEvents = remainingAllDayEvents;
                this._setConfAllDayEvents();

                this._renderEvents(remainingAllDayEvents);
                return false;
            }
        });
    },

    clearEvents: function()
    {
        var self = this;

        webf.each(this.events, function(i, event) {
            var $event = self.getRenderedEvent(event);
            $event.remove();
        });

        self.events = [];

        self._clearAllDayEvents();
    },

    changeView: function(newView, given_date)
    {
        var getDate = () => this._viewChange(newView, given_date);

        if (newView == 'month') {
            this.daysToShow = this.options.daysToShow;
            this.displayMonth(getDate());
        } else if (newView == 'week') {
            this.daysToShow = this.options.daysToShow;
            this.displayWeek(getDate());
        } else if (newView == 'day') {
            var date = getDate();
            this.daysToShow = [(date.day() + 6) % 7];
            this.displayDay(date);
        }

        this._call(this.options.onChangeView, this.getView(), this.start, this.end);
    },

    displayWeek: function(date, cb)
    {
        this.view = 'week';

        var scrollTop = this.e.find('.grid').webfScrollbar('scrollTop');

        this._initStartEndOfWeek(date);
        this.refresh(cb);

        this.e.find('.grid').webfScrollbar('scrollTo', {y: scrollTop});
    },

    displayMonth: function(date, cb)
    {
        this.view = 'month';
        this._initStartEndOfMonth(date);
        this.refresh(cb);
    },

    displayDay: function(date, cb)
    {
        if (this.view != 'day') {
            this.view = 'day';
        }

        this._initStartEndOfDay(date);
        this.refresh(cb);
    },

    getStart: function()
    {
        return this.start;
    },

    getEnd: function()
    {
        return this.end;
    },

    isFree: function(event)
    {
        return this._isFree(event);
    },

    getLastEvent: function(event)
    {
        var id_user = webf.isUndefined(event.user) ? null : event.user.id;
        var lastEvent = null;
        var wdEventStart = new WebfDate(event.start, event.format);

        webf.each(this.events, function(i, ev) {
            if (event.id != ev.id && (id_user === null || ev.user.id == id_user)) {
                var evStart = new WebfDate(ev.start, ev.format);
                if (wdEventStart.isLater(evStart,false)) {
                    if (!lastEvent) {
                        lastEvent = ev;
                        return true;
                    }

                    if (new WebfDate(lastEvent.start, lastEvent.format).isEarlier(evStart)) {
                        lastEvent = ev;
                    }
                }
            }
        });

        return lastEvent;
    },

    getNextEvent: function(event)
    {
        var id_user = webf.isUndefined(event.user) ? null : event.user.id;
        var nextEvent = null;
        var wdEventStart = new WebfDate(event.start, event.format);

        webf.each(this.events, function(i, ev) {
            if (event.id != ev.id && (id_user === null || ev.user.id == id_user)) {
                var evStart = new WebfDate(ev.start, ev.format);
                if (evStart.isLater(wdEventStart,false)) {
                    if (!nextEvent) {
                        nextEvent = ev;
                        return true;
                    }

                    if (evStart.isEarlier(new WebfDate(nextEvent.start, nextEvent.format))) {
                        nextEvent = ev;
                    }
                }
            }
        });

        return nextEvent;
    },

    getEventById: function(id)
    {
        var retEvent = null;

        webf.each(webf.merge(this.events, this.allDayEvents), function(i, event) {
            if (event.id == id) {
                retEvent = webf.clone(event);
                return true;
            }
        });

        return retEvent;
    },

    setDefaultSkin: function(skin)
    {
        this.options.defaultSkin = skin;
    },

    addUrlParams: function(key, value)
    {
        this.options.urlParams[key] = value;
    },

    getView: function()
    {
        return this.view;
    },

    triggerClick: function(event)
    {
        var id_event = webf.isObject(event) ? event.id : event;
        var _event = this.getEventById(id_event);
        var $event = this.getRenderedEvent(_event);

        this._call(this.options.eventClick, _event, $event, this._isAllDayEvent(_event));
    },
});

