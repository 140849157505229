import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.FontSize = webf.RichTextEditor.Control.extend({
    sizes: null,
    zIndex: null,

    init: function(editor, options) {
        this.sizes = options.sizes || {1:8, 2:10, 3:12, 4:14, 5:18, 6:24, 7:36};
        this.zIndex = editor.option('zIndex');
    },
    render: function(editor, $btn) {
        var self = this;

        $btn.addClass('webf-select-button fontsize')
            .append("<label>" + editor._('fontsize') + "</label>")
            .append("<i class='fas fa-caret-down'></i>");

        this.$menu = $(
            "<ul class='webf-list'>" +
                webf.map(this.sizes, function(name, fontsize) {
                    return "<li class='webf-list-item'><a data-fontsize='" + name + "'>" + fontsize + "</a></li>";
                }).join('') +
            "</ul>"
        );

        var $wrapper = $btn.closest('.webf-buttons-group').wrap($('<div>'));
        this.$btn = $wrapper.parent();
        this.$btn.append(this.$menu);

        this.$btn.webfDropdown({
            handle: $btn,
            menu: this.$menu,
            menuClass: 'webf-richtexteditor-list webf-richtexteditor-fontsize',
            onOpen: function() {
                self.$menu.find('a').on('click', function() {
                    editor._execCommand('fontsize', $(this).data('fontsize'));
                });
            }
        });
    },
    blur: function(editor, $btn) {
        $btn.webfDropdown('close');
    },
    refresh: function(editor, $btn) {
        try {
            var value = editor._queryCommandValue('fontsize');
        } catch(e) {}

        value = value ? this.sizes[value] + '' : editor._('fontsize');

        $btn.find('label').text(value.replace(/^'/,"").replace(/'$/,""));
    },
    types: 'action',
    text_tooltip: 'fontsize',
    destroy: function () {
        this.$btn.webfDropdown('destroy');
        this.$menu.remove();
    }
});
