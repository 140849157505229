import webf from '../../utils/core'

(function(webf) {
    webf.lang.en = webf.lang.en || {};

    webf.lang.en.ajaxform = {
        required: function(name) {
            return "the field " + name + " is required";
        }
    };
})(webf);

