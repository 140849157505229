import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - checkOnMousedown
 *  - classWrapper
 *
 * Méthodes publiques :
 *  - check
 *  - uncheck
 *  - toggle
 *  - enable
 *  - disable
 */
$webf('radio', {
    options: {
        checkOnMousedown:     false,
        classWrapper:         ''
    },

    _create: function()
    {
        this.$webfRadio = null;
        this.$group = this.e.prop('name') ? $("input[name='"+this.e.prop('name')+"']") : this.e;

        this._draw();
        this._bindEvents();
        this._initState();
    },

    _draw: function()
    {
        this.$webfRadio = this.e.hide()
            .wrap("<span class='webf-radiowrapper'></span>")
            .parent().prepend("<a class='radio'></a>")
            .addClass(this.option('classWrapper'))
        ;
    },

    _initState: function()
    {
        this.enable(!this.e.prop('disabled'));
        this.check(this.e.prop('checked'), false);
    },

    _bindEvents: function()
    {
        var self = this,
            $elements = this.$webfRadio;

        if (this.e.prop('id')) {
            var $label = $("label[for='" + this.e.prop('id') + "']");

            if ($label.length) {
                $elements = $elements.add($label);
            }
        }

        if (this.options.checkOnMousedown) {
             this._on($elements, {
                 mousedown: function(ev) {
                    if (!self.e.prop('disabled') && !self.e.prop('checked')) {
                        self.check();
                    }
                 }
             });
        } else {
            this._on($elements, {
                mousedown: function(ev) {
                    if (!self.e.prop('disabled')) {
                        self.$webfRadio.addClass('mousedown');
                    }
                },
                click: function(ev) {
                    ev.preventDefault();
                    if (!self.e.prop('disabled') && !self.e.prop('checked')) {
                        self.check();
                    }
                }
            })._on(document, {
                'mouseup mouseleave': function(ev) {
                    self.$webfRadio.removeClass('mousedown');
                }
            });
        }
    },

    check: function(checked, trigger)
    {
        trigger = webf.isUndefined(trigger) ? true : trigger;

        var self = this;

        if (checked === false) {
            this.$webfRadio.removeClass("checked").addClass("unchecked");
            this.e.prop('checked',false);
            this.e.trigger('uncheck');
            if (trigger) {
                this.e.trigger('uncheck').trigger('change');
            }
        } else {
            this.$group.not(this.e).each(function() {
                if (self._has($(this), ':radio')) {
                    $(this).webfRadio('uncheck',false);
                }
            });

            this.$webfRadio.removeClass("unchecked").addClass("checked");
            this.e.prop('checked',true);
            this.e.trigger('check');
            if (trigger) {
                this.e.trigger('change');
            }
        }
    },

    uncheck: function(trigger)
    {
        this.check(false, trigger);
    },

    toggle: function(trigger)
    {
        this.check(!this.e.prop('checked'), trigger);
    },

    enable: function(enable)
    {
        enable = webf.isUndefined(enable) ? true : enable;

        this.e.prop('disabled', !enable);

        if (enable) {
            this.$webfRadio.removeClass('disabled');
        } else {
            this.$webfRadio.addClass('disabled');
        }
    },

    disable: function()
    {
        this.enable(false);
    },

    _destroy: function()
    {
        this.e.siblings().remove();
        this.e.unwrap().show();
    }
});

