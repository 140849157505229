import webf from '../utils/core'
import WebfDate from '../utils/date'
import $webf from '../utils/jquery.webf'
import '../i18n/datetimepicker'
import './select'
import './popover'
import './dialog'
import './slider'
import './spinner'
import $ from 'jquery'
import Mustache from 'mustache'

import dtpTemplate from './datetimepicker/templates/datetimepicker.tpl.html';
import tplTimepickerSelect from './datetimepicker/templates/timepickerSelect.tpl.html';
import tplTimepickerSpinner from './datetimepicker/templates/timepickerSpinner.tpl.html';
import tplTimepickerSlider from './datetimepicker/templates/timepickerSlider.tpl.html';
import tplCalendar from './datetimepicker/templates/calendar.tpl.html';
import tplMonthYear from './datetimepicker/templates/monthyear.tpl.html';

/**
 * options:
 *  - style                 (string)        auto, dialog, popover
 *                                          auto: le datetimepicker est en dialog sur mobiles et tablettes et en
 *                                          popover sur desktop
 *  - date                  (date|string)   Date courante du calendrier
 *  - autoOpen              (boolean)       Ouvre le datetimpicker à l'instanciation
 *  - timepicker            (boolean)       Affiche ou non les contrôles concernant l'heure
 *  - position              (Object)        Fixe la position du calendrier par rapport à l'input (v. plugin poisition)
 *  - showMonthYear         (boolean)       Affiche ou nom les mois et l'année dans une liste déroulante
 *  - yearRange             (string)        Plage des années à afficher dans la liste déroulante
 *                                          Pour afficher les 10 années avant et les 5 années après la date courante : c-10:c+5
 *  - buttons               (object)        Liste d'objets rendu sous forme de webfButton
 *                                          Chaque bouton peut être écrit sous la forme "label: function() {...}"
 *                                           - cls:   Classes CSS
 *                                           - label: Le label du bouton
 *                                           - click: Fonction fournie pour l'événement click
 *  - animation                             Not used
 *  - durationSlide         (int)           Durée de l'animation de slide entre 2 mois
 *  - fillInput             (Element|jQuery)
 *                                          Element input text pouvant remplacer l'input de base
 *  - format                (string)        Format de la date (v. webf Date)
 *  - showOtherMonths       (boolean)       Affiche ou non les jours des mois précédents dans le calendrier
 *  - groups                (string)        v. webfDropdownmenu
 *  - closeOnClickElement   (boolean)       Ferme le calendrier sur le clic
 *  - selectRange           (boolean)       Si true, active la sélection d'une plage de dates
 *  - onCreate              (function(d1))  Fonction appelée dès la création du calendrier
 *  - onBeginSelectRange    (function(d1))  Fonction appelée lorsque la sélection d'une plage commence
 *  - onEndSelectRange      (function(d1, d2))
 *                                          Fonction appelée lorsque la sélection d'une plage est terminée
 *  - disableDate           (function(date))
 *                                          Fonction appelée pour déterminer si la date peut être sélectionnée. Si la fonction
 *                                          retourne true, la date est désactivée
 *  - onOpen                (function())    Fonction appelée lors de l'ouverture du calendrier
 *  - onClose               (function())    Fonction appelée lors de la fermeture du calendrier
 *  - onSelectDate          (function(date, $cell))
                                            Fonction appelée lorsqu'une date est sélectionnée
 *  - onTimeChange          (function(date))
 *                                          Fonction appelée lorsque l'heure change
 *  - onDateChange          (function(date))
 *                                          Fonction appelée lorsque la date change
 *
 * Méthodes publiques:
 *  - gotoDate(date)            Anime le calendrier jusqu'à la date spécifiée
 *  - setDate(date)             Fixe la date courante du calendrier
 *  - setTime(date)             Fixe l'heure courante du calendrier
 *  - getTime(h, m, s)          Retour l'heure courante du calendrier
 *  - selectRange(d1, d2)       Sélectionne une plage de dates entre d1 et d2
 *  - getRange                  Retourne la plage courante du calendrier
 *  - getDate                   Retourne le date courante du calendrier
 *  - widget                    Retourne l'élement racine du calendrier
 *  - open                      Ouvre le calendrier
 *  - close                     Ferme le calendrier
 */

const dayName = 'sunday monday tuesday wednesday thursday friday saturday'.split(' ');
const monthName = 'january february march april may june july august september october november december'.split(' ');

$webf('datetimepicker', {
    options: {
        style:                  'auto',
        date:                   null,
        autoOpen:               false,
        timepicker:             'select',
        position:           {
            my: 'left top',
            at: 'left bottom',
            collision: 'flipfit none'
        },
        showMonthYear:          false,
        yearRange:              'c-10:c+10',
        buttons:                {},
        animation:              'fading',
        durationSlide:          0,
        fillInput:              null,
        format:                 null,
        showOtherMonths:        true,
        groups:                 '',
        closeOnClickElement:    false,
        selectRange:            false,
        onCreate:               webf.noop,
        onBeginSelectRange:     webf.noop,
        onEndSelectRange:       webf.noop,
        disableDate:            webf.noop,
        onOpen:                 webf.noop,
        onClose:                webf.noop,
        onSelectDate:           webf.noop,
        onTimeChange:           webf.noop,
        onDateChange:           webf.noop,
        onDateHover:            webf.noop,
        onDateLeave:            webf.noop
    },

    _create: function()
    {
        if (this.option('date')) {
            if (webf.isObject(this.option('date'))) {
                this.setDate(this.option('date'));
            } else {
                this.setDate(new WebfDate(this.option('date'), this.option('format')));
            }
        } else if (/text|date/.test(this.e.prop('type')) && this.option('format') && this.e.val()) {
            this.setDate(new WebfDate(this.e.val(), this.option('format')));
        } else if (webf.isDomElement(this.e[0])) {
            let $inputDates = this.e.find('input');
            let d1 = new WebfDate(), d2;

            if ($inputDates.length == 2) {
                d1 = new WebfDate($($inputDates[0]).val(), this.option('format'));
                d2 = new WebfDate($($inputDates[1]).val(), this.option('format'));

                this.setRange(d1, d2);
            }

            this.date = d1;
        } else {
            this.setDate(new WebfDate());
        }

        this.currentDate = this.date && !this.d1 && !this.d2 ? this.date : null;

        this._drawDateTimePicker();
        this._bindEvents();

        this._call(this.option('onCreate'));

        this.option('autoOpen') && this.open();
    },

    _getHtmlCalendar: function(date)
    {
        date = new WebfDate(date || this.date);
        const now = new WebfDate();
        const firstDay = new WebfDate(date).startOfMonth();
        const numFirstDay = (firstDay.day() + 6) % 7;
        const rowsWeek = [];
        let curDay, endMonth = false;
        let classNames = [];

        if (numFirstDay > 0) {
            rowsWeek[0] = {cellsDay: []};

            for (let j=0; j<7; j++) {
                if (numFirstDay - j <= 0) {
                    curDay = firstDay.clone().subDays(numFirstDay - j);

                    classNames = ['cur-month']
                        .concat(curDay.equals(now, 'day') ? 'today' : [])
                        .concat(this.currentDate && curDay.equals(this.currentDate, 'day') ? 'current-date' : [])
                        .concat(curDay.toString('EEEE', 'en').toLowerCase());
                } else {
                    curDay = firstDay.clone().addDays(j - numFirstDay);

                    classNames = ['prev-month', 'other-month'];
                    if (!this.option('showOtherMonths')) {
                        classNames.push('hidden-day');
                    }
                }

                if (this._call(this.option('disableDate'), curDay)) {
                    classNames.push('disabled');
                }

                rowsWeek[0].cellsDay.push({
                    classNames: classNames.join(' '),
                    day: curDay.date()
                });
            }
        }

        curDay = numFirstDay > 0
            ? firstDay.clone().subDays(numFirstDay).addWeek()
            : firstDay.clone();

        while (!endMonth) {
            let cellsDay = [];

            for (let k=0; k<7; k++) {
                if (endMonth) {
                    classNames = ['other-month', 'next-month'].concat(this.option('showOtherMonths') ? [] : ['hidden-day']);
                } else {
                    classNames = ['cur-month']
                        .concat(curDay.equals(now, 'day') ? 'today' : [])
                        .concat(this.currentDate && curDay.equals(this.currentDate, 'day') ? 'current-date' : [])
                        .concat(curDay.toString('EEEE', 'en').toLowerCase());
                }

                if (this._call(this.option('disableDate'), curDay)) {
                    classNames.push('disabled');
                }

                let cellDay = {
                    classNames: classNames.join(' '),
                    day: curDay.date()
                };
                curDay.addDay();

                if (curDay.month() > date.month() || curDay.year() > date.year()) {
                    endMonth = true;
                }

                cellsDay.push(cellDay);
            }

            rowsWeek.push({cellsDay: cellsDay});
        }

        return Mustache.render(tplCalendar, {rowsWeek: rowsWeek});
    },

    _drawDateTimePicker: function()
    {
        const self = this;
        let monthYear = false, i, title = false,
            date = this.getDate(),

        shortDayNames = webf.map(dayName, (i) => {
            return {
                shortName: this._(dayName[(i + 8) % 7].substr(0, 2)).ucfirst()
            }
        });

        let strTimepicker = null;
        if (this.option('timepicker') == 'select') {
            strTimepicker = Mustache.render(tplTimepickerSelect, {time: this._('time')});
        } else if (this.option('timepicker') == 'spinner') {
            strTimepicker = Mustache.render(tplTimepickerSpinner, {time: this._('time')});
        } else if (this.option('timepicker') == 'slider') {
            strTimepicker = Mustache.render(tplTimepickerSlider, {
                time: this._('time'),
                hour: this._('hour'),
                minute: this._('minute'),
                second: this._('second'),
            });
        }

        if (this.option('showMonthYear')) {
            monthYear = {
                months: [],
                years: []
            };

            for (i=0; i<12; i++) {
                monthYear.months.push({
                    selected: i == date.month() ? "selected" : false,
                    value: i,
                    month: this._(monthName[i]).ucfirst(),
                });
            }

            let firstYear = this._getFirstYear(new WebfDate());
            let lastYear = this._getLastYear(new WebfDate());

            for (let j=firstYear; j<=lastYear; j++) {
                monthYear.years.push({
                    selected: j == date.year() ? "selected" : false,
                    year: j
                });
            }
        } else {
            title = this._(monthName[date.month()]) + " " + date.year();
        }

        let strDatetimepicker = Mustache.render(dtpTemplate, {
            dayNames: shortDayNames,
        }, {
            calendar: tplCalendar,
            timepicker: strTimepicker,
            title: title,
            monthYear: Mustache.render(tplMonthYear, monthYear)
        });

        let $datetimepicker = $(strDatetimepicker)
            .hide()
            .appendTo($('body'));

        $datetimepicker.find('.toolbar .select-month').webfSelect({
            filter: false,
            selectClass: 'month'
        });

        $datetimepicker.find('.toolbar .select-year').webfSelect({
            filter: false,
            selectClass: 'year',
            onOpen: function() {
                const $widget = $(this).webfSelect('widget');
                const $selectedItem = $widget.find('.webf-active');

                if ($selectedItem[0]) {
                    const $options = $widget.find('.options');
                    $options.scrollTop(0);
                    $options.scrollTop($selectedItem.position().top - $options.outerHeight() / 2 + $selectedItem.height());
                }
            }
        });

        if (this.option('timepicker') == 'select') {
            let $selectHours = $datetimepicker.find('select.hours');
            let $selectMinutes = $datetimepicker.find('select.minutes');
            let $selectSeconds = $datetimepicker.find('select.seconds');

            $selectHours.on('change', (ev) => {
                this.date.hours($(ev.currentTarget).val());
                this._call(this.option('onTimeChange'), this.date);
            })
            $selectMinutes.on('change', (ev) => {
                this.date.minutes($(ev.currentTarget).val());
                this._call(this.option('onTimeChange'), this.date);
            });
            $selectSeconds.on('change', (ev) => {
                this.date.seconds($(ev.currentTarget).val());
                this._call(this.option('onTimeChange'), this.date);
            });
        }
        else if (this.option('timepicker') == 'slider') {
            $datetimepicker.find('.slider-hours').webfSlider({
                min: 0,
                max: 23,
                value: date.hours(),
                slide: (value, $e) => {
                    let $timePicker = $e.closest('.timepicker');
                    let $textTime = $timePicker.find('.time .text');
                    let tab = $textTime.text().split(':');
                    tab[0] = ($e.webfSlider('value') + '').pad(2, '0', 'left');
                    $textTime.text(tab.join(':'));
                    this.date.hours($e.webfSlider('value'));
                    this._call(this.option('onTimeChange'), this.date);
                }
            });
            $datetimepicker.find('.slider-minutes').webfSlider({
                min: 0,
                max: 59,
                value: date.minutes(),
                slide: (value, $e) => {
                    let $timePicker = $e.closest('.timepicker');
                    let $textTime = $timePicker.find('.time .text');
                    let tab = $textTime.text().split(':');
                    tab[1] = ($e.webfSlider('value') + '').pad(2, '0', 'left');
                    $textTime.text(tab.join(':'));
                    this.date.minutes(value);
                    this._call(this.option('onTimeChange'), this.date);
                }
            });
            $datetimepicker.find('.slider-seconds').webfSlider({
                min: 0,
                max: 59,
                value: date.seconds(),
                slide: (value, $e) => {
                    let $timePicker = $e.closest('.timepicker');
                    let $textTime = $timePicker.find('.time .text');
                    let tab = $textTime.text().split(':');
                    tab[2] = ($e.webfSlider('value') + '').pad(2, '0', 'left');
                    $textTime.text(tab.join(':'));
                    this.date.seconds(value);
                    this._call(this.option('onTimeChange'), this.date);
                }
            });
        }
        else if (this.option('timepicker') == 'spinner') {
            let formatValueSpinner = function() {
                $(this).val(($(this).webfSpinner('value') + '').pad(2, '0', 'left'));
            };

            $datetimepicker.find('.spinners .hours').webfSpinner( {
                min: 0,
                max: 23,
                value: date.hours(),
                onSpin: formatValueSpinner,
                onChange: function() {
                    this.date.hours($(this).webfSpinner('value'));
                    this._call(this.option('onTimeChange'), this.date);
                },
                complete: formatValueSpinner
            });

            $datetimepicker.find('.spinners .minutes').webfSpinner( {
                min: 0,
                max: 59,
                value: date.minutes(),
                onSpin: formatValueSpinner,
                onChange: function() {
                    this.date.minutes($(this).webfSpinner('value'));
                    this._call(this.option('onTimeChange'), this.date);
                },
                complete: formatValueSpinner
            });

            $datetimepicker.find('.spinners .seconds').webfSpinner( {
                min: 0,
                max: 59,
                value: date.seconds(),
                onSpin: formatValueSpinner,
                onChange: function() {
                    this.date.seconds($(this).webfSpinner('value'));
                    this._call(this.option('onTimeChange'), this.date);
                },
                complete: formatValueSpinner
            });
        }

        webf.each(this.option('buttons'), function(index, obj) {
            let func = obj,
                label = index, cls = '';

            if (webf.isObject(obj) && webf.isString(obj.click)) {
                func = obj.click;
            }

            if (webf.isString(func)) {
                obj = {};
                label = self._(func);

                switch(func) {
                    case 'today':
                        obj.cls = 'primary small';
                        obj.click = function() {
                            $(this).webfDatetimepicker('gotoDate', new WebfDate());
                        };
                        break;

                    case 'close':
                        obj.cls = 'secondary small';
                        obj.click = function() {
                            self.close();
                        };
                        break;
                }
            }

            let user_func;
            if (webf.isFunction(obj)) {
                user_func = obj;
            } else {
                label = webf.isUndefined(obj.label) ? label : obj.label;
                cls = webf.isUndefined(obj.cls) ? '' : obj.cls;
                user_func = obj.click;
            }

            let $button = $("<input>")
                .prop('type', 'button')
                .addClass('webf-button ' + cls)
                .val(label);

            $button.on('click', function(ev) {
                self._call((user_func || webf.noop), ev);
            });

            $datetimepicker.find('.buttons').append($button);
        });

        this.style = this.option('style');
        if (this.style == 'auto') {
            this.style = webf.isTouchDevice() ? 'dialog' : 'popover';
        }

        if (this.style == 'dialog') {
            this._on(this.e, {
                click: (ev) => {
                    this.$datetimepicker.webfDialog('open');
                }
            });

            this.$datetimepicker = $datetimepicker.webfDialog({
                dialogClass: 'webf-dialog-datetimepicker',
                autoOpen: false,
                box: false,
                onOpen: function () {
                    let $dtp = $(this).webfDialog('widget'),
                        date = self.getDate();

                    if (self.option('showMonthYear')) {
                        let $selectMonths = $dtp.find('.toolbar .select-month'),
                            $selectYears = $dtp.find('.toolbar .select-year');

                        $selectMonths.find('option').each(function () {
                            if (date.month() == $(this).val()) {
                                $(this).prop('selected', true);
                            }
                        });

                        $selectYears.find('option').each(function () {
                            if (date.year() == $(this).val()) {
                                $(this).prop('selected', true);
                            }
                        });
                    } else {
                        $dtp.find('.toolbar .title').html('<span>' + self.translate(monthName[date.month()]) + " " + date.year() + '</span>');
                    }

                    let $ul = $dtp.find('.ul-calendars');

                    let calendar = self._getHtmlCalendar();
                    $ul.empty();
                    $ul.append(calendar);

                    self.setTime(date.hours(), date.minutes(), date.seconds());

                    self.dtp = $dtp;

                    if (self.option('selectRange')) {
                        self._selectRange(self.d1, self.d2);
                        self._call(self.option('onOpen'));
                    } else {
                        let $cell = $dtp.find('td.cur-month').eq(date.date() - 1);
                        self._call(self.option('onOpen'), $cell);
                    }
                },
                onClose: function () {
                    const $dtp = $(this).webfDialog('widget');
                    self._call(self.option('onClose'));

                    $dtp && $dtp.find('.week-days .ul-calendars').empty();
                }
            });
        }
        else {
            this.e.webfPopover({
                menu: $datetimepicker,
                menuClass: 'webf-dropdown-datetimepicker',
                behavior: 'menu-toggle',
                closeOnClickElement: this.option('closeOnClickElement'),
                position: this.option('position'),
                groups: 'datetimepicker ' + this.option('groups'),
                zIndex: 1110,
                animation: this.option('animation'),
                onOpen: function() {
                    let $dtp = $(this).webfPopover('menu'),
                        date = self.getDate();

                    if (self.option('showMonthYear')) {
                        let $selectMonths = $dtp.find('.toolbar .select-month'),
                            $selectYears = $dtp.find('.toolbar .select-year');

                        $selectMonths.find('option').each(function() {
                            if (date.month() == $(this).val()) {
                                $(this).prop('selected', true);
                            }
                        });

                        $selectYears.find('option').each(function() {
                            if (date.year() == $(this).val()) {
                                $(this).prop('selected', true);
                            }
                        });
                    } else {
                        $dtp.find('.toolbar .title').html('<span>' + self._(monthName[date.month()]) + " " + date.year() + '</span>');
                    }

                    let $ul = $dtp.find('.ul-calendars');

                    let calendar = self._getHtmlCalendar();
                    $ul.empty();
                    $ul.append(calendar);

                    self.setTime(date.hours(), date.minutes(), date.seconds());

                    self.dtp = $dtp;

                    $(this).webfPopover('refreshPosition');

                    if (self.option('selectRange')) {
                        self._selectRange(self.d1, self.d2);
                        self._call(self.option('onOpen'));
                    } else {
                        let $cell = $dtp.find('td.cur-month').eq(date.date() - 1);
                        self._call(self.option('onOpen'), $cell);
                    }
                },
                onClose: function() {
                    const $dtp = $(this).webfDialog('widget');
                    self._call(self.option('onClose'));

                    $dtp && $dtp.find('.week-days .ul-calendars').empty();
                }
            });
        }
    },

    _getFirstYear: function(date) {
        if (webf.isUndefined(date)) {
            date = this.getDate();
        }

        let yearRange = this.option('yearRange').split(':');

        if (yearRange[0].charAt(0) == 'c') {
            if (yearRange[0].charAt(1) == '-') {
                return parseInt(date.year()) - parseInt(yearRange[0].split('-')[1]);
            }
        }

        return null;
    },

    _getLastYear: function(date = this.getDate()) {
        let yearRange = this.option('yearRange').split(':');

        if (yearRange[1].charAt(0) == 'c') {
            if (yearRange[1].charAt(1) == '+') {
                return parseInt(date.year()) + parseInt(yearRange[1].split('+')[1]);
            }
        }

        return null;
    },

    _getCellFromDate: function(date)
    {
        let $widget = this.widget(), $cell;

        if (this.date.equals(date, 'month')) {
            $cell = $widget.find('td.cur-month').eq(date.date() - 1);
        } else if (this.option('showOtherMonths')) {
            if (date.clone().subMonth().equals(this.date, 'month')) {
                $widget.find('td.prev-month').each(function() {
                    if ($(this).children('a').text() == date.day()) {
                        $cell = $(this);
                        return false;
                    }
                });
            } else if (date.clone().addMonth().equals(this.date, 'month')) {
                $cell = $widget.find('td.next-month').eq(date.date() - 1);
            }
        }

        return $cell && $cell[0] ? $cell : null;
    },

    _getDateFromCell: function($cell)
    {
        let date = this.date.clone(),
            time = this.getTime();

        if ($cell.hasClass('other-month')) {
            if ($cell.hasClass('prev-month')) {
                date.startOfMonth().subMonth();
            } else if ($cell.hasClass('next-month')) {
                date.startOfMonth().addMonth();
            }
        }

        date.date($cell.children('a').text());

        if (time !== null) {
            let h = Math.floor(time / 3600),
                m = Math.floor(time / 60) - h * 60,
                s = time - (h * 3600 + m * 60);

            date.hours(h);
            date.minutes(m);
            date.seconds(s);
        }

        return date;
    },

    _bindEvents: function()
    {
        let self = this,
            $widget = this.widget();

        $widget.on('click', '.toolbar .prev-month', () => {
            this.gotoDate(this.date.clone().subMonth());
        }).on('click', '.toolbar .next-month', () => {
            this.gotoDate(this.date.clone().addMonth());
        }).on('click', '.webf-tb-datepicker td a', function() {
            if ($(this).parent().hasClass('disabled')) {
                return;
            }

            let $td = $(this).closest('td'),
                date = self._getDateFromCell($td);

            if (self.option('selectRange')) {
                if (self.d1 && !self.d2) {
                    if (date.isEarlier(self.d1, 'day', true)) {
                        self.d1 = date;
                    } else {
                        self.d2 = date;
                    }
                } else if (!self.d1) {
                    self.d1 = date;
                } else if (self.d1 && self.d2) {
                    self.d1 = date;
                    self.d2 = null;
                }

                self._selectRange(self.d1, self.d2);

                if (self.d1 && !self.d2) {
                    self._call(self.option('onBeginSelectRange'), self.d1);
                } else if (self.d1 && self.d2) {
                    self._call(self.option('onEndSelectRange'), self.d1, self.d2);
                }
            }

            if (!self.d1 && !self.d2) {
                if (self.option('fillInput') !== false) {
                    let $input;

                    if (self.option('fillInput')) {
                        $input = $(self.option('fillInput'));
                    } else if (/date|text/.test(self.e.prop('type'))) {
                        $input = self.e;
                    }

                    if ($input && $input.length) {
                        let fmt = self.option('format') || (self.option('timepicker') ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd");
                        $input.val(new WebfDate(date).toString(fmt));
                    }
                }

                self.currentDate = new WebfDate(date);
                $(this).closest('.webf-tb-datepicker').find('td').removeClass('current-date');
                $(this).closest('td').addClass('current-date');

                self.gotoDate(date, true);
                self._call(self.option('onSelectDate'), date, $(this));
            }
        }).on('change', '.toolbar .select-month', function() {
            self.gotoDate(self.date.clone().month($(this).val()));
        }).on('change', '.toolbar .select-year', function() {
            self.gotoDate(self.date.clone().year($(this).val()));
        }).on('mouseenter', '.webf-tb-datepicker td a', function() {
            let date = self._getDateFromCell($(this).closest('td'));

            if (self.option('selectRange')) {
                if (self.d1 && !self.d2) {
                    $widget.find('.webf-tb-datepicker td').removeClass('selected');

                    if (date.isLater(self.d1, 'day', false)) {
                        self._selectRange(self.d1, date);
                        $widget.find('.webf-tb-datepicker td').removeClass('end');
                    }
                }
            }

            self._call(self.option('onDateHover'), date, $(this));
        }).on('mouseleave', '.webf-tb-datepicker', function() {
            if (self.option('selectRange')) {
                if (self.d1 && !self.d2) {
                    self._selectRange(self.d1, null);
                }
            }

            self._call(self.option('onDateLeave'));
        });
    },

    setRange: function(d1, d2)
    {
        this.d1 = new WebfDate(d1);
        this.d2 = new WebfDate(d2);
    },

    _getRange: function(d1, d2)
    {
        let self = this,
            $widget = this.widget();

        return $widget.find('.webf-tb-datepicker td').filter(function () {
            let $td = $(this),
                date = self._getDateFromCell($td);

            return !!(date.isLater(d1, false) && date.isEarlier(d2, false));
        });
    },

    _adjustDate: function(date)
    {
        this.date = new WebfDate(date || this.date);
        let $dtp = this.widget();

        if (!this.option('showMonthYear')) {
            $dtp.find('.toolbar .title').html('<span>' + this._(monthName[this.date.month()]) + " " + this.date.year() + '<span>');
        }
    },

    gotoDate: function(date, setDate)
    {
        if (this.goingToDate) {
            return;
        }

        if (setDate) {
            this.currentDate = new WebfDate(date);
        }

        let self = this,
            $htmlCalendar = this._getHtmlCalendar(date),
            $dtp = this.widget(),
            wdCurDate = new WebfDate(this.date),
            wdDate = new WebfDate(date),
            $dtpCalendar = $dtp.find('.webf-datetimepicker-calendar'),
            $ulCalendars = $dtpCalendar.find('.ul-calendars'),
            widthCalendar = $ulCalendars.width();

        if (wdDate.isLater(wdCurDate, 'month')) {
            this.goingToDate = true;

            $ulCalendars.css('left', 0);

            $ulCalendars.append($htmlCalendar);

            $ulCalendars.animate({
                left: -(widthCalendar/2)
            }, this.option('durationSlide'), function() {
                setTimeout(() => {
                    $(this).children('li').eq(0).remove();
                }, 1);
                $(this).css( {
                    left: 0
                });
                self._adjustDate(wdDate);
                self.goingToDate = false;

                if (self.option('selectRange')) {
                    setTimeout(() => {
                        self._selectRange(self.d1, self.d2);
                    }, 1);
                }

                self._call(self.option('onDateChange'), self.date);
            });

        } else if (wdDate.isEarlier(wdCurDate, 'month')) {
            this.goingToDate = true;

            $ulCalendars.css('left', -(widthCalendar/2));

            $ulCalendars.prepend($htmlCalendar);

            $ulCalendars.animate({
                left: 0
            }, this.option('durationSlide'), function() {
                setTimeout(() => {
                    $(this).children('li').eq(1).remove();
                }, 1);
                self._adjustDate(wdDate);
                self.goingToDate = false;

                if (self.option('selectRange')) {
                    setTimeout(() => {
                        self._selectRange(self.d1, self.d2);
                    }, 1);
                }

                self._call(self.option('onDateChange'), self.date);
            });
        }

        if (this.option('timepicker')) {
            this.setTime(wdDate.hours(), wdDate.minutes(), wdDate.seconds());
        }

        if (this.option('showMonthYear')) {
            $dtp.find('.select-month').webfSelect('val', wdDate.month());
            $dtp.find('.select-year').webfSelect('val', wdDate.year());
        }

        if (setDate === true) {
            this.setDate(wdDate);
        }
    },

    setDate: function(date)
    {
        this.date = new WebfDate(date);
    },

    setTime: function(h, m, s)
    {
        h = parseInt(h % 24);
        m = parseInt(m % 60);
        s = parseInt(s % 60);

        this.date.hours(h).minutes(m).seconds(s);

        if (this.option('timepicker')) {
            let $tp = this.widget().find('.timepicker');

            switch (this.option('timepicker')) {
                case 'spinner':
                    $tp.find("input.hours").webfSpinner('value', h);
                    $tp.find("input.minutes").webfSpinner('value', m);
                    $tp.find("input.seconds").webfSpinner('value', s);
                    break;

                case 'slider':
                    $tp.find(".slider-hours").webfSlider('value', h);
                    $tp.find(".slider-minutes").webfSlider('value', m);
                    $tp.find(".slider-seconds").webfSlider('value', s);
                    $tp.find(".time .text").text(this.date.toString('HH:mm:ss'));
                    break;

                case 'select':
                    $tp.find("select.hours").val(h);
                    $tp.find("select.minutes").val(m);
                    $tp.find("select.seconds").val(s);
                    break;
            }
        }
    },

    getTime: function()
    {
        if (this.option('timepicker')) {
            let $tp = this.widget().find('.timepicker'),
                h, m, s;

            switch (this.option('timepicker')) {
                case 'spinner':
                    h = $tp.find("input.hours").webfSpinner('value');
                    m = $tp.find("input.minutes").webfSpinner('value');
                    s = $tp.find("input.seconds").webfSpinner('value');
                    break;

                case 'slider':
                    h = $tp.find(".slider-hours").webfSlider('value');
                    m = $tp.find(".slider-minutes").webfSlider('value');
                    s = $tp.find(".slider-seconds").webfSlider('value');
                    break;

                case 'select':
                    h = parseInt($tp.find("select.hours").val());
                    m = parseInt($tp.find("select.minutes").val());
                    s = parseInt($tp.find("select.seconds").val());
                    break;
            }

            return h * 3600 + m * 60 + s;
        }

        return null;
    },

    selectRange: function(d1, d2)
    {
        this.d1 = new new WebfDate(d1);
        this.d2 = new new WebfDate(d2);

        this._selectRange(d1, d2);
    },

    _selectRange: function(d1, d2)
    {
        let $widget = this.widget(), $td;

        $widget.find('.webf-tb-datepicker td').removeClass('start selected end');

        if (d1 && !d2) {
            $td = this._getCellFromDate(d1);
            if ($td) {
                $td.addClass('selected start');
            }
        } else if (d2) {
            if (d2.isLater(this.d1, 'day', false)) {
                $td = this._getCellFromDate(d2);

                if ($td) {
                    $td.addClass('end');
                }

                let $elements = this._getRange(d1, d2);
                if ($elements[0]) {
                    $elements.addClass('selected');

                    let $startCell = this._getCellFromDate(d1);
                    if ($startCell) {
                        $startCell.addClass('start');
                    }
                }
            }
        } else {
            $widget.find('.webf-tb-datepicker td').removeClass('start end selected');
        }
    },

    getRange: function()
    {
        return [this.d1, this.d2];
    },

    getDate: function()
    {
        return this.date;
    },

    widget: function()
    {
        if (this.style == 'dialog')
            return this.$datetimepicker.webfDialog('widget');

        return this.e.webfPopover('menu');
    },

    open: function()
    {
        this.style == 'dialog' && this.$datetimepicker.webfDialog('open');
        this.style == 'popover' && this.e.webfPopover('open');
    },

    close: function()
    {
        this.style == 'dialog' && this.$datetimepicker.webfDialog('close');
        this.style == 'popover' && this.e.webfPopover('close');
    },

    _destroy: function()
    {
        this.style == 'dialog' && this.$datetimepicker.webfDialog('destroy');
        this.style == 'popover' && this.e.webfPopover('destroy');
    }
});
