import webf from '../../utils/core'

(function(webf) {
    webf.lang.fr = webf.lang.fr || {};

    webf.lang.fr.ajaxupload = {
        invExtension: function(filename, allowedExtensions) {
            return "Le fichier " + filename + " ne possède pas une extension valide.\nExtensions valides : " + allowedExtensions;
        },
        invSize: function(filename, filesize, sizeLimit) {
            return "Le fichier " + filename + " de taille " + filesize + " excède la taille limite autorisée de " + sizeLimit;
        },
        emptyFile: function(filename) {
            return "Le fichier " + filename + " est vide";
        }
    };
})(webf);

