import './scrollbar'
import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import jQuery from 'jquery'

/**
 * @Description:
 * Permet de naviguer au clavier et la souris à l'intérieur d'un conteneur. Il est également possible de sélectionner
 * des items à l'aide de combinaisons de touches modificatrices.
 *
 * @Options:
 *  - itemsSelector:    (string|jquery|Element)
 *                                      Sélecteur des éléments sur lesquels naviguer
 *  - tabIndex:         (int)           tab-index du conteneur
 *  - keys:             (Object)        Keycode des touches décleanchant la navigation
 *                                      - prev: '38'
 *                                      - next: '40 9'
 *                                      - active: '13'
 *  - selectedClass:                    Classe CSS pour un item sélectionné
 *  - activeClass:                      Classe CSS pour un item choisi
 *  - curItemClass:                     Classe CSS pour l'item courant
 *  - throttle:         (int)           Temps en ms entre chaque événement de navigation
 *  - prev:             (function($curElement,event)|null)
 *                                      Fonction appelée pour déterminer l'élément précédent par rapport à l'élement courant
 *                                      $curElement. L'événement est passé en second argument.
 *                                      Si null, le plugin choisit lui même l'élément précédent.
 *                                      La fonction passée renvoie un Element ou un objet jQuery, sinon le plugin
 *                                      choisit comment passer à l'élément précédent.
 *  - next:             (function($curElement,event)|null)
 *                                      Fonction appelée pour déterminer l'élément suivant par rapport à l'élement courant
 *                                      $curElement. L'événement est passé en second argument.
 *                                      Si null, le plugin choisit lui même l'élément suivant.
 *                                      La fonction passér renvoie un Element ou un objet jQuery, sinon le plugin
 *                                      choisit comment passer à l'élément suivant.
 *  - loop:             (boolean)       Si true, lorsque le dernier item est sélectionné et que le suivant est demandé
 *                                      le premier item est sélectionné, et réciproquement pour le premier item.
 *  - deltaScrollFix:   (int)           valeur en px pour corriger le scroll lors de l'appel à setItemToViewport
 *  - selectOnMouseover (boolean)       Si true, l'item peut être sélectionné par un event mouseover.
 *  - multiple          (boolean)       Si true, plusieurs items peuvent être sélectionnés et la sélection au clavier
 *                                      gère les tocuhes Shift et Meta
 *  - mouseDraw:        (boolean)       Active la fonctionnalité de sélection d'éléments par dessin d'un rectangle à la souris
 *                                      Fonctionne de pai avec multiple à true
 *  - onItemSelect:     (function($item))
 *                                      Fonction appelée lorsqu'un item est sélectionné
 *  - onMousedown:      (function($draggable, ev))
 *                                      Fonction appelée sur l'event mousedown. Si la fonction retourne true, la sélection
 *                                      du ou des éléments est annulée
 *  - onDrawStart:      (function())
 *                                      Fonction appelée sur l'event mousedown
 *  - selectorDisableDraw: (string)
 *  - mouseEventActive  (string)        Evénements de la souris activant un item séparés par des espaces. (mousedown, click, dblclick)
 *  - onItemActive:     (function($item))
 *                                      Fonction appelée lorsqu'un item est activé.
 *                                      Si l'item est déjà actif, la fonction n'est pas appelée
 *  - debounceActive:   (int)           Si > 0 : l'item est activé après une durée correspondant au nb. de ms renseigné
 *                                      après que l'élément a été sélectionné par une navigation clavier.
 *                                      Ne se déclenche que si un seul item est sélectionné. Si la sélection change avant
 *                                      cette durée, l'activation de l'item est annulé même une fois le délai écoulé.
 *                                      La durée devrait être supérieure à la valeur de throttle.
 *  - onChooseItem:     (function($item, ev))
 *                                      Fonction appelée lorsqu'un item est choisi, càd soit cliqué ou qu'un appui sur
 *                                      une des touches actives a eu lieu sur cet item
 *  - onDblClick        (function($item))
 *                                      Fonction appelée lorsqu'un item est double cliqué
 *
 * @Methods:
 *  - setItemToViewport($item)          Affiche l'item dans le viewport visible du conteneur et de l'écran.
 *  - setItemActive($item)              Ajoute la classe activeClass à l'item
 *  - getAllItems([$itemA, $itemB])     Retourne tous les items compris en itemA et itemB
 *                                      Sans argument, retourne tous les items
 *  - getSelectedItems()                Retourne les items sélectionnés
 *  - getActiveItem()                   Retourne l'item actif
 *  - selectItems([$items])             Sélectionne les items en argument ou tous les items si pas d'argument
 *  - unselectItems([$items])           DéSélectionne les items en argument ou tous les items si pas d'argument
 *  - isItemSelected($item)             Retourne true si l'item est sélectionné
 *  - isItemActive($item)               Retourne true si l'item est actif
 *  - isDrawing($item)                  Retourne true si une sélection par dessin à la souris est en cours
 */
(function($, window) {

    $webf('navigation', {
        options: {
            itemsSelector:      'li',
            tabIndex:           -1,
            keys:               {
                prev: '38',
                next: '40 9',
                active: '13',
            },
            throttle:               80,
            prev:                   null,
            next:                   null,
            selectedClass:          'webf-navigation-selected',
            activeClass:            'webf-navigation-active',
            curItemClass:           'webf-navigation-current',
            loop:                   true,
            deltaScrollFix:         1,
            selectOnMouseover:      false,
            multiple:               false,
            mouseDraw:              true,
            selectorDisableDraw:    '',
            mouseEventActive:       'mousedown',
            debounceActive:         0,
            onDrawStart:            webf.noop,
            onItemSelect:           webf.noop,
            onMousedown:            webf.noop,
            onItemActive:           webf.noop,
            onDblClick:             webf.noop,
            onChooseItem:           webf.noop
        },

        _create()
        {
            var keys = webf.map(this.option('keys'), function(i, keys) {
                if (webf.isString(keys)) {
                    return keys.split(' ')
                }

                return null
            })
            this.keys = Array.prototype.concat.apply([], webf.map(keys, function(key, keyCodes) {
                return keyCodes
            }))

            this.e.prop('tabIndex', this.option('tabIndex'))

            this._bindEvents()
        },

        _bindEvents()
        {
            const selectedClass = this.option('selectedClass');
            const activeClass = this.option('activeClass');
            const selectorItems = this.option('itemsSelector');
            const loop = this.option('loop');
            const mouseDraw = this.option('mouseDraw');
            const multiple = this.option('multiple');
            let disableMouseDraw = false;

            var selectItem = ($item, unselectOthers) => {
                if (webf.isUndefined(unselectOthers)) {
                    unselectOthers = true;
                }

                if (webf.isUndefined($item) || !$item) return
                unselectOthers && unselectItems(this.getAllItems())
                $item.addClass(selectedClass)
            }

            var selectItems = ($items) => {
                $items = webf.isUndefined($items) ? this.getAllItems() : $items
                if (!$items) return

                $items.each((i, item) => {
                    selectItem($(item), false)
                })
            }

            var unselectItem = ($item) => {
                if (webf.isUndefined($item) || !$item) return
                $item.removeClass(selectedClass)
            }

            var unselectItems = ($items) => {
                $items = webf.isUndefined($items) ? this.getAllItems() : $items

                if (webf.isUndefined($items) || !$items) return

                $items.each((i, item) => {
                    unselectItem($(item))
                })
            }

            var activeItem = ($item) => {
                if ($item) {
                    this.setCurItem($item)
                    this.$prevItem = $item

                    this.getAllItems().removeClass([selectedClass, activeClass].join(' '))
                    $item.addClass(activeClass)
                }
            }

            var prev = ($curItem, ev) => {
                var prevItem

                if (webf.isFunction(this.option('prev')) && (prevItem = this._call(this.option('prev'), $curItem, ev))) {
                    return $(prevItem)
                }

                if (!$curItem || ($curItem[0] && !webf.isInDOM($curItem[0]))) {
                    var $lastItem = this.getAllItems().last()

                    if ($lastItem[0]) {
                        return $lastItem
                    }

                    return null
                }

                prevItem = $curItem.prevAll(selectorItems).first()

                if (prevItem[0]) {
                    return prevItem
                } else if (loop) {
                    return this.getAllItems().last()
                }

                return $curItem
            }

            var next = ($curItem, ev) => {
                var nextItem

                if (webf.isFunction(this.option('next')) && (nextItem = this._call(this.option('next'), $curItem, ev))) {
                    return $(nextItem)
                }

                if (!$curItem || ($curItem[0] && !webf.isInDOM($curItem[0]))) {
                    var $firstItem = this.getAllItems().first()

                    if ($firstItem[0]) {
                        return $firstItem
                    }

                    return null
                }

                nextItem = $curItem.nextAll(selectorItems).first()

                if (nextItem[0]) {
                    return nextItem
                } else if (loop) {
                    return this.getAllItems().first()
                }

                return $curItem
            }

            var debounceActiveFunc = webf.debounce(() => {
                var $item = this.getSelectedItems()

                if ($item.length == 1) {
                    if (!this.$prevItem || !this.$prevItem.is($item)) {
                        activeItem($item)
                        this._call(this.option('onItemActive'), $item)
                    }

                    this.$prevItem = $item
                }
            }, this.option('debounceActive'), false)

            if (this.option('debounceActive')) {
                this._on(this.e, {
                    'keydown.debounce': (ev) => {
                        var navKeys = (this.option('keys').prev ? this.option('keys').prev.split(' ') : [])
                            .concat(this.option('keys').next ? this.option('keys').next.split(' ') : [])

                        if (webf.inArray(ev.which, navKeys)) {
                            return debounceActiveFunc()
                        }
                    }
                })
            }

            this._on(this.e, {
                'keydown.preventDefault': (ev) => {
                    if (webf.inArray(ev.which, this.keys)) {
                        ev.preventDefault()
                    }
                },
                'keydown.selectAll': (ev) => {
                    if (multiple && ev.which == 65 && ctrlOrMeta(ev)) {
                        ev.preventDefault()
                        selectItems()
                    }
                },
                'keydown.navigation': webf.throttle((ev) => {
                    var prevKeys = (this.option('keys').prev ? this.option('keys').prev.split(' ') : []),
                        nextKeys = (this.option('keys').next ? this.option('keys').next.split(' ') : []),
                        activeKeys = (this.option('keys').active ? this.option('keys').active.split(' ') : []),
                        shift = webf.inArray('shift', $webf.getModifiersKey(ev))

                    if (multiple && shift) {
                        this.$firstShiftItem = this.$firstShiftItem ? this.$firstShiftItem : this.$curItem

                        if (webf.inArray(ev.which, prevKeys)) {
                            unselectItems(this.getSelectedItems())
                            this.setCurItem(prev(this.$curItem, ev))
                            selectItems(this.getAllItems(this.$firstShiftItem, this.$curItem))
                            this.setItemToViewport(this.$curItem)
                            this.$curItem && this._call(this.option('onItemSelect'), this.$curItem)
                        } else if (webf.inArray(ev.which, nextKeys)) {
                            unselectItems(this.getSelectedItems())
                            this.setCurItem(next(this.$curItem, ev))
                            selectItems(this.getAllItems(this.$firstShiftItem, this.$curItem))
                            this.setItemToViewport(this.$curItem)
                            this._call(this.option('onItemSelect'), this.getSelectedItems())
                        } else if (webf.inArray(ev.which, activeKeys)) {
                            if (!this.isItemActive(this.$curItem)) {
                                activeItem(this.$curItem, ev)
                                this._call(this.option('onItemActive'), this.getSelectedItems())
                            }
                        }
                    } else {
                        if (webf.inArray(ev.which, prevKeys)) {
                            this.setCurItem(prev(this.$curItem, ev))
                            selectItem(this.$curItem)
                            this.$curItem && this._call(this.option('onItemSelect'), this.$curItem)
                            this.$firstShiftItem = null
                            this.setItemToViewport(this.$curItem)
                        } else if (webf.inArray(ev.which, nextKeys)) {
                            this.setCurItem(next(this.$curItem, ev))
                            selectItem(this.$curItem)
                            this.$firstShiftItem = null
                            this.$curItem && this._call(this.option('onItemSelect'), this.$curItem)
                            this.setItemToViewport(this.$curItem)
                        } else if (webf.inArray(ev.which, activeKeys)) {
                            this.$firstShiftItem = null
                            this.setItemToViewport(this.$curItem)
                            activeItem(this.$curItem, ev)
                            this.$curItem && this._call(this.option('onItemActive'), this.$curItem)
                            this._call(this.option('onChooseItem'), this.$curItem, ev);
                        }
                    }
                }, this.option('throttle'), true),
                focus: () => {
                    $(window).scrollTop(this.windowScrolltop || 0)

                    if (!this.$curItem || !webf.isInDOM(this.$curItem[0])) {
                        this.setCurItem(null)

                        this.getAllItems().each((i, item) => {
                            if ($(item).hasClass(selectedClass) || $(item).hasClass(activeClass)) {
                                this.setCurItem($(item))
                            }
                        })
                    }
                }
            })

            ._on(this.e, selectorItems, {
                mouseover: (ev) => {
                    if (this.option('selectOnMouseover')) {
                        const $item = $(ev.target).closest(selectorItems)

                        selectItem($item)
                        this.setCurItem($item)
                        this.$curItem && this._call(this.option('onItemSelect'), $item)
                    }
                },
                mousedown: (ev) => {
                    if (ev.which == 1) {
                        ev.preventDefault()

                        // this.e.focus()

                        var $item = $(ev.target).closest(selectorItems),
                            modifiersKey = $webf.getModifiersKey(ev),
                            shift = webf.inArray('shift', modifiersKey),
                            meta = ctrlOrMeta(ev)

                        if (true == this._call(this.option('onMousedown'), $item, ev)) {
                            return
                        }

                        if (multiple && (meta || shift)) {
                            if (meta) {
                                if ($item.hasClass(selectedClass)) {
                                    unselectItem($item)
                                } else {
                                    selectItem($item, false)
                                }
                                this.setCurItem($item)
                            } else if (webf.inArray('shift', modifiersKey)) {
                                if (this.$curItem) {
                                    if (!this.$curItem.is($item)) {
                                        unselectItems()

                                        this.$firstShiftItem = this.$firstShiftItem || this.$curItem

                                        var direction = $item.prevAll(selectorItems).length > this.$curItem.prevAll(selectorItems).length ? 'down' : 'up'

                                        this.getAllItems(this.$firstShiftItem, $item).each(function() {
                                            selectItem($(this), false)
                                            if (direction == 'down') {
                                                this.setCurItem($(this).prevAll(selectorItems).length > this.$curItem.prevAll(selectorItems).length
                                                    ? $(this) : this.$curItem)
                                            } else {
                                                this.setCurItem($(this).prevAll(selectorItems).length < this.$curItem.prevAll(selectorItems).length
                                                    ? $(this) : this.$curItem)
                                            }
                                        })
                                    }
                                } else {
                                    selectItem($item)
                                    this.$firstShiftItem = null
                                }
                            }
                        } else {
                            selectItem($item)
                            this.$firstShiftItem = null
                        }

                        this.setCurItem($item)
                        this.setItemToViewport(this.$curItem)
                        this._call(this.option('onItemSelect'), this.getSelectedItems())
                    }
                },
                'mousedown click dblclick': (ev) => {
                    const $item = $(ev.target).closest(selectorItems);

                    if (ev.type == 'dblclick') {
                        this._call(this.option('onDblClick'), $item);
                    } else if (ev.type == 'click') {
                        this._call(this.option('onChooseItem'), $item, ev);
                    }

                    if (!$webf.getModifiersKey(ev).length && webf.inArray(ev.type, (this.option('mouseEventActive') || '').split(' '))) {
                        if (!this.isItemActive($item)) {
                            activeItem($item)
                            this._call(this.option('onItemActive'), $item);
                        }
                    }
                },
            })

            ._on(this.e, {
                mousedown: (ev) => {
                    if (ev.which == 1 && !$(ev.target).closest(selectorItems)[0]) {
                        unselectItems()
                    }
                }
            })

            ._on(window, {
                scroll() {
                    this.windowScrolltop = $(window).scrollTop()
                },
                mousemove: (ev) => {
                    if (this.isDrawing()) {
                        ev.preventDefault()

                        var mp = $webf.getMousePos(ev), sp = this.startPoint,
                            frame = $('.webf-navigation-frame-selection')[0],
                            heightWindow = $(window).height(),
                            scrollTopWindow = $(window).scrollTop()

                        if (!frame) {
                            var distance = Math.sqrt(Math.pow(mp.x - sp.x, 2) + Math.pow(mp.y - sp.y, 2))

                            if (distance > 7) {
                                frame = $('<div>').addClass('webf-navigation-frame-selection').appendTo('body')[0]
                            } else {
                                return
                            }
                        }

                        var rc = webf.extend({}, this.e[0].getBoundingClientRect())

                        rc.x += $(window).scrollLeft()
                        rc.y += $(window).scrollTop()

                        $(frame)
                            .css({
                                left:   mp.x > sp.x ? sp.x : Math.max(rc.x, mp.x),
                                top:    mp.y > sp.y ? sp.y : Math.max(rc.y, mp.y),
                                width:  mp.x > sp.x
                                    ? Math.min(rc.x + rc.width - sp.x, mp.x - sp.x)
                                    : Math.min(sp.x - rc.x, sp.x - mp.x),
                                height: mp.y > sp.y
                                    ? Math.min(rc.y + rc.height - sp.y, mp.y - sp.y)
                                    : Math.min(sp.y - rc.y, sp.y - mp.y),
                            })

                        this.getAllItems().each(function () {
                            if (webf.collide(this, frame)) {
                                selectItem($(this), false)
                            } else {
                                unselectItem($(this))
                            }
                        })

                        if (mp.y + 20 >= heightWindow) {
                            this.speedy = 100
                        } else if (mp.y - 20 <= scrollTopWindow) {
                            this.speedy = -100
                        }
                    }
                },
                'mouseup blur': (ev) => {
                    this.startPoint = null
                    $('.webf-navigation-frame-selection').remove()
                }
            })

            this.option('selectorDisableDraw') && this._on(this.e, this.option('selectorDisableDraw'), {
                'mousedown.drawing': (ev) => {
                    disableMouseDraw = true
                }
            })

            this._on(this.e, {
                'mousedown.drawing': (ev) => {
                    if (ev.which == 1 && multiple && mouseDraw && !disableMouseDraw) {
                        this.startPoint = $webf.getMousePos(ev)
                        this._call(this.option('onDrawStart'), ev)
                    }

                    disableMouseDraw = false
                }
            })
        },

        setCurItem($item)
        {
            var curItemClass = this.option('curItemClass')

            this.$curItem = $item
            this.getAllItems().removeClass(curItemClass)
            $item && $item.addClass(curItemClass)
        },

        setItemActive($item)
        {
            const selectedClass = this.option('selectedClass')
            const activeClass = this.option('activeClass')

            this.getAllItems().removeClass([selectedClass, activeClass].join(' '))
            $item.addClass(activeClass)
            this.setCurItem($item)
        },

        unselectItems($items)
        {
            const selectedClass = this.option('selectedClass')

            $items = webf.isUndefined($items) ? this.getAllItems() : $items
            $items.removeClass(selectedClass)
        },

        selectItems($items, trigger = false)
        {
            const selectedClass = this.option('selectedClass')
            $items = webf.isUndefined($items) ? this.getAllItems() : $items
            if (!$items) return

            $items.each(function() {
                $(this).addClass(selectedClass)

                trigger && this._call('onItemSelect', $(this))
            })
        },

        getAllItems($itemA, $itemB)
        {
            const selectorItems = this.option('itemsSelector');
            let $items;

            if (webf.isUndefined($itemA)) {
                $items = this.e.find(selectorItems)
            } else if ($itemA.is($itemB)) {
                $items = $itemA
            } else {
                var posItemA = $itemA.prevAll(selectorItems).length,
                    posItemB = $itemB.prevAll(selectorItems).length

                if (posItemA < posItemB) {
                    $items = $itemA.nextUntil($itemB).add($itemB).add($itemA)
                } else {
                    $items = $itemA.prevUntil($itemB).add($itemB).add($itemA)
                }
            }

            return $items
        },

        getSelectedItems()
        {
            return this.e.find(`${this.option('itemsSelector')}.${this.option('selectedClass')}`)
        },

        getActiveItem()
        {
            return this.e.find(`${this.option('itemsSelector')}.${this.option('activeClass')}`)
        },

        isItemActive($item)
        {
            return !!$item && $item.hasClass(this.option('activeClass'))
        },

        isItemSelected($item)
        {
            return $item.hasClass(this.option('selectedClass'))
        },

        isDrawing()
        {
            return !!this.startPoint
        },

        setItemToViewport($item)
        {
            if (webf.isUndefined($item) || !$item) return

            var webfScrollbar = this.e.closest('.webf-scrollbox')[0],
                heightList = this.e.outerHeight(),
                heightItem = $item.outerHeight(),
                scrollTopList = webfScrollbar
                    ? this.e.webfScrollbar('scrollTop')
                    : this.e.scrollTop(),
                topItem = scrollTopList + $item.offset().top - this.e.offset().top,
                topBottomItem = topItem + heightItem,
                deltaTop = topItem - scrollTopList,
                deltaBottom = topBottomItem - (scrollTopList + heightList),
                deltaScrollFix = this.option('deltaScrollFix')

            if (deltaBottom > 0) {
                if (webfScrollbar) {
                    this.e.webfScrollbar('scrollDeltaY', deltaBottom + deltaScrollFix)
                } else {
                    this.e.scrollTop(this.e.scrollTop() + deltaBottom + deltaScrollFix)
                }
            } else if (deltaTop < 0) {
                if (webfScrollbar) {
                    this.e.webfScrollbar('scrollDeltaY', deltaTop - deltaScrollFix)
                } else {
                    this.e.scrollTop(this.e.scrollTop() + deltaTop - deltaScrollFix)
                }
            }

            // Window
            var scrollTop = $(window).scrollTop(),
                offsetTop = $item.offset().top,
                heightWindow = $(window).height()

            if (scrollTop > offsetTop) {
                $(window).scrollTop(offsetTop)
            } else if (heightItem < heightWindow && scrollTop + heightWindow < offsetTop + heightItem) {
                $(window).scrollTop(scrollTop + heightItem - (scrollTop + heightWindow - offsetTop))
            }
        },
    })

    function ctrlOrMeta(ev)
    {
        var modifiers = $webf.getModifiersKey(ev)
        return /Mac|i(Pod|Phone|Pad)/.test(navigator.platform) && webf.inArray('meta', modifiers) || webf.inArray('ctrl', modifiers)
    }
})(jQuery, window)
