import webf from '../utils/core'
import $webf from '../utils/jquery.webf'
import $ from 'jquery'

/**
 * options :
 *  - handle
 *  - menu
 *  - auto
 *  - position
 *  - closeOnClickMenu
 *  - menuClass
 *  - activeClass
 *  - onFirstOpen
 *  - onOpen
 *  - onClose
 *
 * Méthodes publiques :
 *  - menu
 *  - toggle
 *  - open
 *  - close
 *  - isOpened
 *  - isClosed
 */
$webf('dropdown', {
    options: {
        handle:             null,
        menu:               null,
        auto:               true,
        position:           'left down',
        closeOnClickMenu:   true,
        menuClass:          '',
        activeClass:        'webf-active',
        onFirstOpen:        webf.noop,
        onOpen:             webf.noop,
        onClose:            webf.noop
    },

    _create: function()
    {
        this.e.addClass('webf-dropdown clearfix');

        this.$handle = this.option('handle')
            ? (webf.isString(this.option('handle')) ? this.e.find(this.option('handle')) : this.option('handle'))
            : this.e.children('.webf-dropdown-handle');

        this.$menu = this.option('menu')
            ? (webf.isString(this.option('menu')) ? this.e.find(this.option('menu')) : this.option('menu'))
            : this.e.children('.webf-dropdown-menu');

        this.$handle.addClass('webf-dropdown-handle');
        this.$menu.addClass('webf-dropdown-menu');

        if (this.option('menuClass')) {
            this.$menu.addClass(this.option('menuClass'))
        }

        this._setPosition();

        this._bindEvents();
    },

    _setPosition: function()
    {
        var position = this.option('position').split(' ');

        if (position[0] == 'right') {
            this.e.addClass('alignmenu-right');
        }

        if (position[1] == 'up') {
            this.e.addClass('alignmenu-up');
        }
    },

    _bindEvents: function()
    {
        if (this.option('auto')) {
            this._on(this.$handle, {
                click: (ev) => {
                    ev.stopPropagation();
                    this.stayOpen = true;
                    this.toggle();
                    $(ev.currentTarget).trigger('close.dropdown');
                }
            })._on(this.$menu, {
                click: (ev) => {
                    if (!this.option('closeOnClickMenu')) {
                        ev.stopPropagation();
                    } else {
                        this.close();
                    }
                }
            })._on(document, {
                'click close.dropdown': () => {
                    if (!this.stayOpen) {
                        this.close();
                    }

                    this.stayOpen = false;
                }
            });
        }
    },

    menu: function()
    {
        return this.$menu;
    },

    toggle: function()
    {
        if (this.isClosed()) {
            this.open();
        } else {
            this.close();
        }
    },

    open: function()
    {
        this.e.addClass('open');
        this.$handle.addClass(this.option('activeClass'));
        this.$menu.show();

        if (!this.opened) {
            this.opened = true;
            this._call(this.option('onFirstOpen'));
        }

        this._call(this.option('onOpen'));
    },

    close: function()
    {
        if (this.isOpened()) {
            this.e.removeClass('open');
            this.$handle.removeClass(this.option('activeClass'));

            this._call(this.option('onClose'));
        }
    },

    isOpened: function()
    {
        return this.e.hasClass('open');
    },

    isClosed: function()
    {
        return !this.isOpened();
    },

    _destroy: function()
    {
        this.e.removeClass('webf-dropdown clearfix');
        this.$menu.remove();
    }
});

if (typeof window !== "undefined") {
    $(function() {
        $('.webf-dropdown').webfDropdown();
    });
}
