import $ from 'jquery'
import webf from '../../utils/core'

webf.RichTextEditor.Controls.Unlink = webf.RichTextEditor.Control.extend({
    render: function(editor, $btn) {
        $btn.append("<i class='fas fa-unlink'></i>");
    },
    exec: function(editor, $btn) {
        editor._execCommand('unlink');
    },
    types: 'action',
    text_tooltip: 'unlink',
    shortcut: {
        Ctrl: true,
        Shift: true,
        key: 'K'
    }
});

